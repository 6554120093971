import Component from '@glimmer/component';

import type { AsyncLoaderSignature } from './async-loader.ts';

export interface SimpleListSignature<T> {
    Element: HTMLUListElement;
    Args: {
        /** The items to display in the list. */
        items: T[] | Promise<T[]>;
        /** The size of the spinner to show while items are resolving (defaults to "medium"). */
        spinnerSize?: AsyncLoaderSignature<T>['Args']['spinnerSize'];
    };
    Blocks: {
        /** Renders within the LI element for each item. */
        default: [T, number];
        /** Renders if the passed items are empty. */
        empty: [];
    };
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class SimpleList<T> extends Component<SimpleListSignature<T>> {}
