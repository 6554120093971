import Service from '@ember/service';
import * as TWO_FACTOR_AUTHENTICATION_TYPES from '../enums/TwoFactorAuthenticationType.ts';
import * as RECOGNIZED_DEVICE_TYPES from '../enums/RecognizedDeviceType.ts';

/**
 * @classdesc
 * The base engine config service which can be inherited and extended by consuming applications.
 */
export default abstract class BaseEngineConfig extends Service {
    /**
     * A mapping of string keys to the actual route enum values
     */
    abstract customerPageUrlEnums: Record<string, number>;

    /**
     * Returns the enum values for all "types" of 2FA authentication (e.g. SMS).
     */
    get twoFactorAuthenticationTypes(): Record<keyof typeof TWO_FACTOR_AUTHENTICATION_TYPES, number> {
        return TWO_FACTOR_AUTHENTICATION_TYPES;
    }

    /**
     * Returns the enum values for all recognized device types.
     */
    get recognizedDeviceTypes(): Record<keyof typeof RECOGNIZED_DEVICE_TYPES, number> {
        return RECOGNIZED_DEVICE_TYPES;
    }

    /**
     * Given the route name, returns the numerical enum value of the route ID.
     *
     * @note If the value does not exist, it needs to be added to the CustomerPageUrl enum
     *
     * @param key - The name of the route we want the numerical enum value for.
     */
    getRouteIdFromRouteName(key: string): number {
        return this.customerPageUrlEnums[key];
    }
}
