import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import max from 'date-fns/max';
import min from 'date-fns/min';

import type { SimpleDialogSignature } from '../dialog-modals/simple/simple-dialog';
import type { BasePikadaySignature } from '../../date-time-picker/base-pikaday';

type SimpleDialogSignatureArgs = SimpleDialogSignature['Args'];
type BasePikadaySignatureArgs = BasePikadaySignature['Args'];

interface DatePickerArgs
    extends Pick<BasePikadaySignatureArgs, 'minDate' | 'maxDate'>,
        Pick<SimpleDialogSignatureArgs, 'title'> {
    applyText?: string;
    defaultDate: Date;
    onSelection: (newDate: Date) => void | boolean | Promise<boolean>;
    disableControls?: boolean;
    disableDate?: BasePikadaySignatureArgs['disableDayFn'];
    showWarning?: SimpleDialogSignatureArgs['disableHeaderClose'];
    dateOnly?: boolean;
}

export interface DatePickerDialogSignature {
    Element: SimpleDialogSignature['Element'];
    Args: DatePickerArgs;
    Blocks: {
        /** Renders into a `<ModalOverlay />` component. */
        warning: [];
        /** Renders above the calendar element. */
        'above-date-selection': [];
        /** Renders above the time selector. */
        'options-above': [];
        /** Renders below the time selector. */
        'options-below': [];
        /** Renders at the bottom of the modal. */
        'above-footer-section': [];
    };
}

/**
 * @classdesc
 * A base modal for date picker modals
 */
export default class DatePickerDialog extends Component<DatePickerDialogSignature> {
    /**
     * Currently selected date
     */
    selectedDate: Date = this.args.defaultDate;

    /**
     * Updates the selected date, constrained by the min and max dates passed
     */
    updateSelectedDate(newDate: Date): void {
        const { minDate = newDate, maxDate = newDate } = this.args;
        set(this, 'selectedDate', max([min([newDate, maxDate]), minDate]));
    }

    /**
     * Updates the time when it changes.
     */
    @action
    updateTime(newDate: Date): void {
        if (newDate && newDate.getTime() !== this.selectedDate.getTime()) {
            this.updateSelectedDate(new Date(newDate));
        }
    }

    /**
     * Updates the date when it changes.
     */
    @action
    updateDate(newDate: Date): void {
        if (newDate) {
            this.updateSelectedDate(new Date(newDate));
        }
    }
}
