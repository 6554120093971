import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { alias, equal } from '@ember/object/computed';
import { once } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';
import { assert } from '@ember/debug';
import { EnumAsyncState } from './async.ts';

import type NativeBridgeService from '@adc/app-infrastructure/services/native-bridge';
import type { NativeButton as BridgeButton } from '@adc/app-infrastructure/services/native-bridge';
import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { ButtonAsyncSignature } from './async';

export type NativeButtonType = Omit<BridgeButton, 'type'> & {
    componentId: string;
    type: BridgeButton['type'] | '';
};

/**
 * Publishes the native button if the functionality is available.
 */
function publishNativeButton(this: NativeButton): void {
    if (!this.isRenderedNative) {
        return;
    }

    // Get button's properties.
    const { text, disabled, icon, responsiveText, nativeType, updateNativeToolbarButtons, buttonAction } = this.args;

    if (disabled || !buttonAction) {
        disposeNativeButton.call(this);
        return;
    }

    assert('updateNativeToolbarButtons action not defined on NativeButtons component.', updateNativeToolbarButtons);

    // Publish button.
    updateNativeToolbarButtons([
        {
            componentId: this.elementId,
            // For text always use responsive text; if this is an add button then use "Add" text over text property.
            text: responsiveText || (nativeType == 'add' ? this.intl.t('@adc/ui-components.add') : text),
            icon,
            type: nativeType || '',
            isPromisePending: this.isPending,
            // We are just clicking the button because that will emulate how the whole button behaves.
            // This provides consistent behavior between web and native.
            action: () => {
                (document.querySelector(`button[id="${this.elementId}"]`) as HTMLElement).click();
            }
        }
    ]);
}

/**
 * Disposes this button from the native button collection.
 */
function disposeNativeButton(this: NativeButton): void {
    if (!this.isRenderedNative) {
        return;
    }
    const disposeNativeButtons = this.args.disposeNativeButtons;

    assert('disposeNativeButtons action not defined on NativeButton component', disposeNativeButtons);

    disposeNativeButtons([this.elementId]);
}

export interface ButtonNativeSignature {
    Element: ButtonAsyncSignature['Element'];
    Args: Omit<ButtonAsyncSignature['Args'], 'buttonAction' | 'type' | 'updateAsyncState' | 'text'> & {
        /** Required text to appear in the button. */
        text: string;
        /** Optional ID for the button element. */
        id?: string;
        /** Indicates the button should be disabled. */
        disabled?: boolean;
        /** The type of native button */
        nativeType?: BridgeButton['type'];
        /** A function called when the button is clicked. */
        buttonAction: (...params: any[]) => void;
        /** Called to dispose of mobile app native buttons.  */
        disposeNativeButtons?: (buttons: string[]) => void;
        /** Called to update mobile app native buttons. */
        updateNativeToolbarButtons?: (buttons: NativeButtonType[]) => void;
    };
    Blocks: ButtonAsyncSignature['Blocks'];
}

/**
 * Button that will be displayed as native button if the native bridge supports it.
 */
export default class NativeButton extends Component<ButtonNativeSignature> {
    @service declare nativeBridge: NativeBridgeService;
    @service declare intl: ADCIntlService;

    @tracked asyncState = EnumAsyncState.idle;

    @equal('asyncState', EnumAsyncState.pending)
    declare isPending: boolean;

    /**
     * Is the button actually rendered natively?
     */
    @alias('nativeBridge.doesSupportToolbarButtons')
    declare isRenderedNative: boolean;

    @computed('args.id')
    get elementId(): string {
        return this.args.id ?? guidFor(this);
    }

    /** @override */
    @computed('args.noDelay', 'isRenderedNative')
    get noDelay(): boolean {
        // If button is not rendered native just default to base, otherwise make sure there is no delay.
        //Not sure if this is correct
        return !!(this.isRenderedNative || this.args.noDelay);
    }

    /**
     * Triggered when any of the properties changes that would change a native button.
     */
    @action propertiesChanged(): void {
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        once<NativeButton, typeof publishNativeButton>(this, publishNativeButton);
    }

    /** @override */
    willDestroy(): void {
        disposeNativeButton.call(this);
        super.willDestroy();
    }

    @action updateAsyncState(value: EnumAsyncState): void {
        this.asyncState = value;
    }
}
