export default function toBoolean(v: any): boolean {
    return !!v;
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Converts the passed value to true or false.
         */
        'to-boolean': typeof toBoolean;
    }
}
