import BaseSchedule from './views/base-schedule.ts';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { MINUTES_IN_DAY } from '@adc/ember-utils/constants/time';

import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { TimeBlock, MultiScheduleObject, ScheduleElement, PlanSchedule } from './views/types';

interface MultiScheduleTimeBlock extends TimeBlock {
    icon: string;
    color: string;
}

interface MultiScheduleElement extends ScheduleElement {
    allDaySchedules: {
        planName: string;
        planColor: string;
        planIcon: string;
        planIconTitle?: string;
        planIconDesc?: string;
        planIconIsHiddenForAccessibility?: boolean;
    }[];
}

interface MultiScheduleViewSignature {
    Element: HTMLDivElement;
    Args: {
        /** The array of schedule objects to be rendered. */
        scheduleObjects?: MultiScheduleObject[];
        /** Indicates whether to show the hour labels. */
        showPlanNames?: boolean;
    };
}

/**
 * A schedule grid that shows multiple schedules.
 */
export default class MultiScheduleView extends BaseSchedule<MultiScheduleViewSignature> {
    @service declare intl: ADCIntlService;

    protected getPlanSchedule(schedule: MultiScheduleObject): PlanSchedule & { planName: string; planIcon: string } {
        return Object.assign(super.getPlanSchedule(schedule), {
            planName: schedule.name ?? '',
            planIcon: schedule.iconName ?? 'car'
        });
    }

    @computed('args.scheduleObjects.[]')
    get planSchedules(): (PlanSchedule & { planName: string; planIcon: string })[] {
        return (this.args.scheduleObjects ?? []).map((s) => this.getPlanSchedule(s));
    }

    protected getEmptyScheduleElements(): MultiScheduleElement[] {
        return super.getEmptyScheduleElements().map((s) => ({
            ...s,
            allDaySchedules: []
        }));
    }

    /**
     * Schedule to be rendered into the grid.
     */
    @computed('planSchedules')
    get scheduleElements(): MultiScheduleElement[] {
        const schedules = this.getEmptyScheduleElements();

        // For each plan schedule, create the block elements we need to render from their combinedSchedules property.
        this.planSchedules.forEach(({ combinedSchedules, planName, planIcon, planColor }) => {
            if (combinedSchedules.length) {
                const schedulesSlot = schedules[0].schedules.length;

                schedules.forEach((schedule) =>
                    schedule.schedules.push({
                        planName,
                        planIcon,
                        planColor,
                        timeBlocks: []
                    })
                );

                combinedSchedules.forEach((block: MultiScheduleTimeBlock) => {
                    block.title = planName;
                    block.icon = planIcon;
                    block.color = planColor;
                    this.addTimeBlockToScheduleSlot(block, schedules, schedulesSlot);
                });
            }
        });

        // Remove Sunday from the front of the array and add it to the end
        // We want Monday to be the first weekday on the schedule
        schedules.push(schedules.shift() as MultiScheduleElement);

        return schedules;
    }

    /**
     * Add a schedule to the specified list of schedule days.
     */
    protected addTimeBlockToScheduleSlot(
        timeBlock: MultiScheduleTimeBlock,
        schedules: MultiScheduleElement[],
        schedulesSlot: number
    ): void {
        const dayOfWeek = Math.floor(timeBlock.startMinutesLocal / MINUTES_IN_DAY);

        this.createElementsForTimeBlock(timeBlock, '', (index, label, classes, start, length, planNameLabel) => {
            // If this schedule day is all day.
            if (start === 0 && length === 100) {
                schedules[index].allDaySchedules.push({
                    planName: timeBlock.title ?? '',
                    planColor: timeBlock.color,
                    planIcon: timeBlock.icon
                });

                return;
            }

            schedules[index].schedules[schedulesSlot].timeBlocks.push({
                day: dayOfWeek,
                label,
                planNameLabel,
                classes,
                style: htmlSafe(`left:${start}%;width:${length}%;`)
            });
        });
    }
}
