// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';

/**
 * Inner component used in view-container.
 */
// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/require-tagless-components
export default class ViewContent extends Component {
    classNames = ['view-content'];
}
