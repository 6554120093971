import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { computed, action } from '@ember/object';
import { later } from '@ember/runloop';

import type { SafeString } from 'handlebars';
import type { Color } from '../../utils/color-pickers/color.ts';
import type { BinaryListItem } from '../simple-binary/list.ts';

export type PresetItemProps = {
    id: number;
    labelName: string;
    color: Color;
    backgroundStyle: SafeString;
};

export type PresetItem = BinaryListItem<PresetItemProps>;

/**
 * The parent preset ID and preset ID.
 * This is updated when a new preset is selected.
 */
let lastFocusedPresetItemId: string;

export interface PresetsPickerSignature {
    Element: HTMLDivElement;
    Args: {
        /** The preset items to show. */
        items: PresetItem[];
        /** Optional preset picker width, in pixels (defaults to 216). */
        width?: number;
        /** Optional preset picker height, in pixels (defaults to 216). */
        height?: number;
        /** A dumb property that somebody added instead of adjusting the passed in height (please don't use this). */
        extraHeight?: number;
        /** Triggered when the user selects a new preset value. */
        'on-select': (item: PresetItem) => void;
    };
}

/**
 * @classdesc
 * Provides supports for selecting between a list of preset items. This component is built for the lightbulb-color-picker.
 */
export default class PresetsPickerComponent extends Component<PresetsPickerSignature> {
    // region Properties

    /**
     * The width of the picker.
     */
    get width(): number {
        return this.args.width ?? 216;
    }

    /**
     * The height of the picker.
     */
    get height(): number {
        return this.args.height ?? 216;
    }

    // endregion

    // region Computed Properties

    /**
     * The style for the component.
     */
    @computed('width', 'height', 'args.extraHeight')
    get componentStyle(): SafeString {
        return htmlSafe(`width: ${this.width}px; height: ${this.height + (this.args.extraHeight ?? 0)}px;`);
    }

    // endregion

    /**
     * Focuses a preset if one was previously selected.
     */
    @action focusLastPreset(el: HTMLDivElement & { parentNode: HTMLElement }): void {
        const { parentNode } = el;

        if (lastFocusedPresetItemId === parentNode.dataset.buttonListItemId) {
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            later(() => parentNode.focus(), 0);
        }
    }

    /**
     * Forwards the preset to the on-select action.
     */
    @action onPresetSelected(items: PresetItem[]): void {
        const preset = items.find(({ state }) => state === true);
        if (preset) {
            this.args['on-select'](preset);
            lastFocusedPresetItemId = String(preset.props.id);
        }
    }
}
