export enum WebsocketMessageType {
    event = 'event',
    monitoringEvent = 'monitoringEvent',
    propertyChange = 'propertyChange',
    geofenceCrossing = 'geofenceCrossing',
    statusUpdate = 'statusUpdate'
}

/**
 * @classdesc
 * Base message class for websockets
 * Base abstract for containing message data to be processed by message handlers
 */
export default abstract class BaseWebsocketMessage {
    constructor(unitId: string, deviceId: string) {
        this.unitId = unitId;
        this.deviceId = deviceId;
    }

    /**
     * The Unit ID that the message applies to.
     */
    unitId: string;

    /**
     * The Device ID that the message applies to.
     */
    deviceId: string;

    /**
     * The message type.
     */
    abstract type: string;

    /**
     * The message subtype.
     */
    subtype = '';

    /**
     * The message value.
     */
    value?: string;

    /**
     * Additional message data.
     */
    subvalue?: string;
}
