import Model from '@ember-data/model';
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';

/**
 * The default ISO locale code (en-US).
 */
export const defaultIsoCode = 'en-US';

/**
 * Notes:
 * <ul>
 *     <li>The id here translates to CultureEnum on the backend, and so does the cultureId</li>
 *     <li>Default values are for english culture, so that if something goes terribly wrong, things do not just crap out</li>
 * </ul>
 */
export default class LocaleModel extends Model {
    /**
     * CultureId corresponds to CultureEnum on the backend
     */
    @computed('id')
    get cultureId(): string | number {
        return this.id || 1;
    }

    /**
     * Name for displaying for this culture
     */
    @attr('string', { defaultValue: 'English' })
    declare displayName: string;

    /**
     * ISO code for the locale
     */
    @attr('string', { defaultValue: defaultIsoCode })
    declare isoCode: string;

    /**
     * Language for this culture
     */
    @attr('string', { defaultValue: 'en' })
    declare language: string;

    /**
     * Does the week start on Sunday for this locale?
     */
    @attr('boolean', { defaultValue: true })
    declare weekStartsOnSunday: boolean;
}
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        locale: LocaleModel;
    }
}
