import Component from '@glimmer/component';
import { action } from '@ember/object';

import type { PageViewSignature } from '../page-view';
import type { PageViewContainerSignature } from '../page-view/container';
import type { PageViewDeleteSignature } from './delete';
import type { ActionButtonsSignature } from '../action-buttons';

type ActionButtonArgs = ActionButtonsSignature['Args'];

interface PageViewActionButtons {
    cancelText?: ActionButtonArgs['cancelButtonText'];
    cancelDisabled?: ActionButtonArgs['cancelButtonDisabled'];
    cancelAction?: ActionButtonArgs['cancel-action'];
    confirmText?: ActionButtonArgs['confirmButtonText'];
    confirmDisabled?: ActionButtonArgs['confirmButtonDisabled'];
    confirmAction?: ActionButtonArgs['confirm-action'];
    doNotGoBackAfterAction?: boolean;
}

interface PageViewEditSignature<M> {
    Element: PageViewSignature<M>['Element'];
    Args: Pick<PageViewContainerSignature<M>['Args'], 'model' | 'dataLoaded'> & {
        /** Information about the delete operation. */
        deleteModel?: PageViewDeleteSignature['Args']['model'];
        /** Configuration options for the optional action buttons. */
        buttons: PageViewActionButtons;
        /** Optional CSS class applied to the `<PageView::Container />` component. */
        cssContainer?: string;
    };
    Blocks: {
        default: [PageViewContainerSignature<M>['Blocks']['default'][0], PageViewSignature<M>['Blocks']['default'][1]];
    };
}

export default class PageViewEdit<M> extends Component<PageViewEditSignature<M>> {
    /**
     * Returns the  cancel button function.
     */
    @action getCancelButtonAction(
        navigationApi?: PageViewSignature<M>['Blocks']['default'][1]['navigation']
    ): ActionButtonArgs['cancel-action'] | undefined {
        const { doNotGoBackAfterAction, cancelAction } = this.args.buttons;

        // If there was action defined or click should take user to previous route just use whatever the action was.
        if (cancelAction || !doNotGoBackAfterAction) {
            return cancelAction;
        }

        // Ensure that cancel button works correctly.
        return navigationApi?.transitionToPreviousRoute;
    }
}
