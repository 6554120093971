import BaseInput, { clearErrorMessage, VALUE_CHANGE_ACTION } from '../common/base-input.js';
import { bool } from '@ember/object/computed';
import { set, setProperties, computed, action } from '@ember/object';
import { isEnterOrSpaceCode } from '@adc/ember-utils/utils/a11y';
import { A } from '@ember/array';

// region Constants

/**
 * Public const string representing radio type.
 *
 * @type {String}
 */
export const RADIO = 'radio';

/**
 * Public const string representing checkbox type.
 *
 * @type {String}
 */
export const CHECKBOX = 'checkbox';

/**
 * Public const string representing button type.
 *
 * @type {String}
 */
export const BUTTON = 'button';

// endregion

// region Helper Methods

/**
 * Sends the value-change action if it was specified in the handlebars.
 *
 * If value was specified and there is only one item, it only sends the new value back. If more than one item was specified
 * or value was not specified, it sends back the entire itemsInternal array.
 *
 * @private
 * @instance
 */
function triggerValueChangeAction() {
    clearErrorMessage.call(this);

    // Add value-changed observer if requested
    if (!this.hasValueChangeAction) {
        return;
    }

    let { itemsInternal, value } = this;

    // If they specified a value when creating the component, only send back the new value.
    // For multiple inputs, send back the whole itemsInternal array
    const valueChangeAction = this.get(VALUE_CHANGE_ACTION);

    if (valueChangeAction) {
        valueChangeAction(value !== undefined ? itemsInternal[0].value : itemsInternal);
    }
}

/**
 * Logs assertions if the group contains invalid items.
 *
 * @instance
 * @private
 *
 * @param {Array<BinaryInputItem>} items - The items collection.
 * @param {boolean} isMultiselect - Determines if this is a multiselect input group (e.g. radio group).
 * @param {String} type - Determines string type name of input group.
 */
function validateItems(items, isMultiselect, type) {
    items.forEach((item) =>
        window.console.assert(
            'Item must have label, value and id, item: ' + item,
            item.label !== undefined && item.value !== undefined && item.id !== undefined
        )
    );

    if (!isMultiselect) {
        // Single radio buttons should not be used.
        if (type === RADIO) {
            window.console.assert('Radio groups should contain more than one item.', items.length > 1);
        }

        // Non-multiselect input groups should have at most one selected item.
        window.console.assert(
            'Non-multiselect groups should have at most one selected item.',
            items.reduce((count, item) => (item.value ? ++count : count), 0) <= 1
        );
    }
}

/**
 * Processes the change of state for an item, if allowed.
 *
 * @instance
 *
 * @param {Object} item - The item that was clicked on.
 */
export function processItemClick(item) {
    const { itemsInternal } = this,
        fnSetItemValue = (item, value) => {
            set(item, 'value', value);
            triggerValueChangeAction.call(this);
        };

    // Is the group/input disabled or readonly?
    if (this.disabled || this.readonly || item.disabled) {
        // Do not allow modifications.
        return;
    }

    if (this.multiselect) {
        // Only toggle the item that was clicked.
        fnSetItemValue.call(this, item, !item.value);
        return;
    }

    // Store the current value state of item.
    const itemIsChecked = item.value;

    // Clear all selections.
    itemsInternal.setEach('value', false);

    const type = this.type;

    if (type === RADIO || type === BUTTON) {
        // Select the item that was clicked.
        fnSetItemValue.call(this, item, true);
        return;
    }

    // In order to select other checkboxes the user needs to deselect the selected checkbox.
    itemsInternal.setEach('disabled', !itemIsChecked);

    setProperties(item, {
        disabled: false,
        value: !itemIsChecked
    });

    triggerValueChangeAction.call(this);
}

/**
 * Uses the data-id attribute to find the corresponding item.
 *
 * @param {Event} event
 * @returns {BinaryInputItem}
 *
 * @private
 * @instance
 */
function getItemFromEvent(event) {
    const { id } = event.target.dataset;

    return this.itemsInternal.find((item) => String(item.id) === id);
}

// endregion

/**
 * @classdesc
 *
 * The BinaryInput class is used for elements that have binary states (e.g. checkbox). This can be a group of binary
 * inputs (e.g. radio group), or a single input (e.g. toggle).
 *
 * The BinaryInput class follows the data-down/actions-up mentality. If you want to tie the checkboxes to your model,
 * pass in an ID with each input, and then read back the value from that corresponding ID when you handle the action for
 * clicking on the input.
 */
export default class BinaryInput extends BaseInput {
    classNames = ['binary-input-group'];

    classNameBindings = ['errorMessage:error', 'disabled', 'inline', 'groupClass', 'justifyCssClass', 'compact'];

    attributeBindings = ['ariaGroupRole:role'];

    /** @override */
    errorTooltipPlace = 'bottom';

    // region Computed Properties

    /**
     * Method that is called when the component receives attributes, either initially or due to an update.
     *
     * @type {boolean}
     */
    @bool(VALUE_CHANGE_ACTION)
    hasValueChangeAction;

    // endregion

    // region Properties

    /**
     * The ID for the element.
     *
     * @type {number}
     */
    id = 1;

    get type() {
        return CHECKBOX;
    }

    /**
     * The label for a single binary input.
     *
     * <strong>Note:</strong> Should only be defined if using a single binary input.
     *
     * @type {String|undefined}
     */
    label;

    /**
     * The value for a single binary input.
     *
     * <strong>Note:</strong> Should only be defined if using a single binary input.
     *
     * @type {boolean|undefined}
     */
    value;

    /**
     * The name of the icon to display next to the label.
     *
     * @type {String|undefined}
     */
    icon;

    /**
     * The title of the icon to display next to the label.
     *
     * @type {String}
     */
    iconTitle = '';

    /**
     * The description of the icon to display next to the label.
     *
     * @type {String}
     */
    iconDesc = '';

    /**
     * Should the input be focusable?
     *
     * @type {Number}
     */
    tabindex = 0;

    /**
     * Should the icon displayed next to the label be hidden for screen readers?
     *
     * @type {boolean|false}
     */
    iconIsHiddenForAccessibility = false;

    /**
     * The CSS class name to be used on the outermost element. It adds '-group' onto the type (e.g. checkbox-group).
     *
     * @returns {string}
     */
    @computed('type')
    get groupClass() {
        return `${this.type}-group`;
    }

    /**
     * Returns the ARIA group name if there is more than one item in the binary group.
     *
     * @ignore
     * @returns {string?}
     */
    @computed('itemsInternal.[]')
    get ariaGroupRole() {
        return this.itemsInternal?.length < 2 ? undefined : 'group';
    }

    /**
     * Label/value pairs for each input. This is populated from the items property and is internal so that the items
     * passed in are not bound to the controller (i.e. clicking an input doesn't change the state of the original items).
     *
     * @type {Ember.Array<BinaryInputItem>}
     */
    @computed(
        'items.@each.{label,value,icon,id}',
        'value',
        'label',
        'icon',
        'iconTitle',
        'iconDesc',
        'iconIsHiddenForAccessibility'
    )
    get itemsInternal() {
        const { items, multiselect, type } = this;

        // Is this an array with at least one element?
        if (Array.isArray(items) && items.length > 0) {
            // Validate passed items.
            validateItems(items, multiselect, type);

            // Make a copy of it so that the values are not bound to the model. Ember does not seem to have a one-way
            // binding yet. We are implementing this as "data-down/actions-up". We get the state of the inputs from the
            // model's data, when user input occurs, we trigger an action and send the new values back to the model where
            // it can process them how it likes.
            return A(
                items.map((item) =>
                    Object.keys(item).reduce((cache, key) => {
                        cache[key] = item[key];
                        return cache;
                    }, {})
                )
            );
        }

        // If they don't specify "items", assume they are using a single checkbox and add it to 'itemsInternal'.
        // This allows the developer to create a single checkbox in this way: {{checkbox-input label='Label' value=true icon='icon-name' iconTitle='icon title'}},
        // instead of needing to create an array that contains a single item.
        const { id, label, value, icon, iconTitle, iconDesc, iconIsHiddenForAccessibility } = this;
        return A([
            {
                id,
                label,
                value: !!value,
                icon,
                iconTitle,
                iconDesc,
                iconIsHiddenForAccessibility
            }
        ]);
    }

    /**
     * Determines if multiple options can be selected (as well as if items can be de-selected). This is used to determine
     * whether a checkbox or a radio group might be rendered.
     *
     * @type {boolean}
     */
    multiselect = false;

    // region Positioning Properties

    /**
     * Determines if multiple inputs should be displayed vertically or horizontally.
     *
     * @type {boolean}
     */
    inline = false;

    /**
     * The default justified value for binary inputs (can be overridden by child classes).
     *
     * @note Use the "justify" property when actually using the component.
     */
    justifyDefault = 'left';

    /**
     * The CSS class to apply for the justify property.
     *
     * @note The "justify-left" class is used if a non-valid value is provided.
     *
     * @type {String}
     */
    @computed('justify', 'justifyDefault')
    get justifyCssClass() {
        const acceptedValues = ['left', 'center', 'right'],
            value = this.justify;

        return `justify-${acceptedValues.includes(value) ? value : this.justifyDefault}`;
    }

    /**
     * Should the input only take up the minimum width it needs?
     *
     * @type {boolean}
     */
    compact = false;

    /**
     * Should the keydown event bubble up?
     *
     * @type {boolean}
     */
    bubbleKeyDown = true;

    // endregion

    /**
     * Class to be applied to each item
     *
     * @type {String}
     */
    itemClass = '';

    // endregion

    // region Actions

    /**
     * Processes the item click.
     */
    @action valueClicked(item) {
        processItemClick.call(this, item);
    }

    // endregion

    // region Events

    /**
     * Listener for keyDown events.
     *
     * @param {KeyboardEvent} evt
     */
    keyDown(evt) {
        if (!isEnterOrSpaceCode(evt.code)) {
            return;
        }

        const item = getItemFromEvent.call(this, evt);
        if (item) {
            processItemClick.call(this, item);
        }

        evt.preventDefault();

        if (this.bubbleKeyDown) {
            return false;
        }
    }

    // endregion
}
