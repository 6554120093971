import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, computed, set, get } from '@ember/object';
import { gt } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { A } from '@ember/array';
import { ON } from '../utils/dimmer-switch.ts';
import { isColorPresetConfigValid, isColorPresetValid } from '../utils/color-pickers/preset.ts';
import { hslFromPercentWarmth } from '../utils/color-pickers/color.ts';
import { PICKER_THUMB_SIZE } from '../utils/color-pickers/common.ts';
import { BinaryListItem } from './simple-binary/list.ts';
import { next } from '@ember/runloop';

import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { PopoverMenuSignature } from './popover-menu.ts';
import type { PresetsPickerSignature } from './color-pickers/presets-picker.ts';
import type { TemperaturePickerSignature } from './color-pickers/temperature-picker.ts';
import type { DimmerState, DimmerArgs } from '../utils/dimmer-switch.ts';
import type { PresetItemProps } from './color-pickers/presets-picker.ts';
import type { Hue, Saturation, Lightness, Warmth, Color } from '../utils/color-pickers/color.ts';

type PopoverMenuSignatureArgs = PopoverMenuSignature['Args'];
type PresetsPickerSignatureArgs = PresetsPickerSignature['Args'];
type TemperaturePickerSignatureArgs = TemperaturePickerSignature['Args'];

/**
 * Unique identifiers for the three main tabs.
 */
const PRESETS_TAB_ID = 1,
    COLORS_TAB_ID = 2,
    TEMPERATURE_TAB_ID = 3;

/**
 * The array of color preset configuration items, one for each color preset.
 */
const defaultPresetItems = [
    ['nebula', [275, 98, 51], 'purple'],
    ['pacific', [194, 97, 66], 'blue'],
    ['fiji', [117, 100, 55], 'teal'],
    ['aurora', [99, 100, 50], 'green'],
    ['daybreak', [58, 98, 50], 'yellow'],
    ['canyon', [27, 98, 50], 'orange'],
    ['dusk', [1, 98, 50], 'red'],
    ['cherryBlossom', [324, 97, 64], 'pink']
].map(([labelName, [hue, saturation, lightness], bg]: [string, [Hue, Saturation, Lightness], string], idx) => ({
    id: idx + 1,
    labelName,
    color: { hue, saturation, lightness },
    backgroundImage: `light-preset-${bg}.png`
}));

export interface LightBulbColorPickerComponentSignature {
    Args: Pick<PopoverMenuSignatureArgs, 'isOpen' | 'anchorSelector' | 'placement' | 'contentId'> &
        Pick<PresetsPickerSignatureArgs, 'width' | 'height'> & {
            /** The currently selected hue value (1 - 360) */
            hue?: Hue;
            /** The currently selected saturation value (1 - 100) */
            saturation?: Saturation;
            /** The currently selected lightness value (50 - 100) */
            lightness?: Lightness;
            /** The currently selected dimmer value (1 - 100) */
            dimPercentage?: Warmth;
            /** The currently selected dimmer state */
            dimmerState?: 'unknown' | 'on' | 'off';
            /** The URI to the preset picker background images (defaults to `/assets/images`). */
            presetBackgroundImagesRepoURI?: string;
            /** The color hue that will appear at the center of the x-axis (1 - 360). */
            baseHue?: Hue;
            /** The size of the color slider thumb, in pixels (defaults to 24). */
            thumbSize?: number;
            /** Indicates whether to show the analogous color picker tab. */
            supportsHSL: boolean;
            isTemperatureFormat: boolean;
            supportsTemperature: boolean;
            /** Triggered when the user selects a new color. */
            colorChanged: (color: Color) => void;
            /** Triggered when the user selects a new dimmer percentage value. */
            dimmerChanged?: (v: DimmerArgs) => void;
            /** The light temperature value. */
            percentWarmth?: TemperaturePickerSignatureArgs['percentWarmth'];
            /** Indicates the dimmer slider should be visible. */
            showDimmer?: boolean;
        };
}

/**
 * Validates that the base hue value is within range of the currently selected hue, and updates if it isn't.
 */
function validateBaseHue(this: LightbulbColorPicker, hue: Hue): void {
    let { baseHue } = this.args;

    if (baseHue && !isEmpty(baseHue)) {
        let minHue = hue - 60,
            maxHue = hue + 60,
            isOutOfRange;

        minHue = minHue < 0 ? 360 + minHue : minHue;
        maxHue = maxHue > 360 ? 360 - maxHue : maxHue;

        if (minHue < maxHue) {
            isOutOfRange = baseHue <= minHue || baseHue >= maxHue;
        } else {
            isOutOfRange = baseHue <= minHue && baseHue > maxHue;
        }

        if (isOutOfRange) {
            baseHue = hue;
        }
    } else {
        baseHue = hue;
    }

    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/no-runloop
    next(this, () => set(this, 'baseHue', baseHue));
}

/**
 * Updates the selected preset based on the passed color.
 */
function updateSelectedPreset(this: LightbulbColorPicker, hslColor: Color): void {
    // Update preset selection.
    const { hue, saturation, lightness } = hslColor;
    this.presetItems.forEach(
        (p) =>
            (p.state =
                p.props.color.hue === hue &&
                p.props.color.saturation === saturation &&
                p.props.color.lightness === lightness)
    );
}

/**
 * @classdesc
 * Allows users to pick a color in three ways: pick from a gradient of colors, pick from a range of light temperatures, pick from a list of preset colors.
 */
export default class LightbulbColorPicker extends Component<LightBulbColorPickerComponentSignature> {
    @service declare intl: ADCIntlService;

    /**
     * option for focus-trap modifier
     */
    focusTrapOptions = {
        escapeDeactivates: false,
        allowOutsideClick: true,
        initialFocus: '.tab-content > *:first-child'
    };
    // region Passed properties

    /**
     * The current hue (for RGB light format).
     */
    get hue(): Hue {
        return this.args.hue ?? 0;
    }

    /**
     * The current saturation (for RGB light format).
     */
    get saturation(): Saturation {
        return this.args.saturation ?? 0;
    }

    /**
     * The current lightness (for RGB light format).
     */
    get lightness(): Lightness {
        return this.args.lightness ?? 50;
    }

    /**
     * The light dim percentage (can be from 0 to 100).
     */
    get dimPercentage(): Warmth {
        return this.args.dimPercentage ?? 90;
    }

    /**
     * Holds the state of the dimmer.
     * Can be used to determine whether or not the dim percentage should be taken into consideration.
     */
    get dimmerState(): DimmerState {
        return this.args.dimmerState ?? ON;
    }

    /**
     * Where to get the background images for the default color presets from?
     */
    get presetBackgroundImagesRepoURI(): string {
        return this.args.presetBackgroundImagesRepoURI ?? '/assets/images';
    }

    // endregion

    // region Component state

    /**
     * The hue this range of colors will be based on.
     */
    @tracked _baseHue: Hue | undefined;
    get baseHue(): Hue | undefined {
        return this._baseHue ?? this.args.baseHue;
    }
    set baseHue(v: Hue | undefined) {
        this._baseHue = v;
    }

    /**
     * The picker thumb size (height and width).
     */
    get thumbSize(): number {
        return this.args.thumbSize ?? PICKER_THUMB_SIZE;
    }

    /**
     * The color presets to display.
     */
    presetItems: BinaryListItem<PresetItemProps>[] = defaultPresetItems
        .filter((config) => isColorPresetConfigValid(config))
        .map(
            (config) =>
                new BinaryListItem<PresetItemProps>({
                    label: this.intl.t(`@adc/ui-components.${config.labelName}`),
                    state: false,
                    props: {
                        ...config,
                        // generate some extra props
                        backgroundStyle: htmlSafe(
                            `background-image: linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.1)), url('${this.presetBackgroundImagesRepoURI}/${config.backgroundImage}'); background-size: cover;`
                        )
                    }
                })
        )
        .filter((preset) => isColorPresetValid(preset));

    // endregion

    // region Computed Properties

    /**
     * Indicated which tab is active right now. Initially the Colors tab is active.
     */
    @tracked _activeTabId?: number;
    set activeTabId(v: number) {
        this._activeTabId = v;
    }
    get activeTabId(): number {
        return this._activeTabId ?? (this.args.isTemperatureFormat ? TEMPERATURE_TAB_ID : COLORS_TAB_ID);
    }

    /**
     * The tabs to display.
     */
    @computed('activeTabId', 'args.{supportsHSL,supportsTemperature}')
    get tabItems(): BinaryListItem<{ id: number }>[] {
        const { supportsTemperature, supportsHSL } = this.args,
            { activeTabId } = this;

        const items: [boolean, number, string, string?][] = [
            [supportsHSL, PRESETS_TAB_ID, 'presets'],
            [supportsHSL, COLORS_TAB_ID, 'colors', 'picker'],
            [supportsTemperature, TEMPERATURE_TAB_ID, 'temperature']
        ];

        return A(
            items.map(([check, id, labelName, icon = labelName]) => {
                return check
                    ? new BinaryListItem<{ id: number }>({
                          label: this.intl.t(`@adc/ui-components.${labelName}`),
                          icon: `color-${icon}`,
                          state: activeTabId === id,
                          props: { id }
                      })
                    : undefined;
            })
        ).compact();
    }

    /**
     * Indicates the tab bar should be visible (more than one tab).
     */
    @gt('tabItems.length', 1)
    showTabBar?: boolean;

    /**
     * Whether or not the presets picker tab is selected.
     */
    @computed('args.supportsHSL', 'activeTabId')
    get isPresetsTabSelected(): boolean {
        return this.args.supportsHSL && this.activeTabId === PRESETS_TAB_ID;
    }

    /**
     * Whether or not the colors picker tab is selected.
     */
    @computed('args.supportsHSL', 'activeTabId')
    get isColorsTabSelected(): boolean {
        return this.args.supportsHSL && this.activeTabId === COLORS_TAB_ID;
    }

    /**
     * Whether or not the temperature picker tab is selected.
     */
    @computed('args.supportsTemperature', 'activeTabId')
    get isTemperatureTabSelected(): boolean {
        return this.args.supportsTemperature && this.activeTabId === TEMPERATURE_TAB_ID;
    }

    // endregion

    /**
     * Validates the base hue property.
     */
    @action validateBaseHue() {
        if (this.args.supportsHSL) {
            validateBaseHue.call(this, this.hue);
        }
    }

    /**
     * Changes the dimmer state and dim percentage.
     */
    @action onDimmerChange(dimmerState: string, dimPercentage: number): void {
        if (dimmerState !== this.dimmerState || dimPercentage !== this.dimPercentage) {
            this.args.dimmerChanged?.({ dimmerState, dimPercentage });
        }
    }

    /**
     * Activates the new tab.
     */
    @action onTabChange(items: LightbulbColorPicker['tabItems']): void {
        set(this, 'activeTabId', A(items).findBy('state')!.props.id);
    }

    /**
     * Activates the new preset.
     */
    @action onPresetSelect(preset: any): void {
        const { color } = preset.props;

        // Did the color NOT change?
        if (['hue', 'saturation', 'lightness'].every((n) => get(this, n as keyof this) === color[n])) {
            return;
        }

        // Adjust base hue for new color.
        validateBaseHue.call(this, color.hue);

        // Announce the new selected color.
        this.args.colorChanged(color);
    }

    /**
     * Changes the selected HSL color.
     */
    @action changeHSLColor(hslColor: Color): void {
        const { baseHue, ...cleanHslColor } = hslColor;

        // Did base hue change?
        if (!isEmpty(baseHue)) {
            set(this, 'baseHue', baseHue);
        }

        updateSelectedPreset.call(this, cleanHslColor);

        // Notify the color changed.
        this.args.colorChanged(cleanHslColor);
    }

    /**
     * Changes the selected temperature color.
     */
    @action changeTemperatureColor(temperatureColor: Color): void {
        updateSelectedPreset.call(this, hslFromPercentWarmth(temperatureColor.percentWarmth as Warmth));

        // Notify the color changed.
        this.args.colorChanged(temperatureColor);
    }
}
