import { default as BaseSlider } from './slider/base-slider.js';
import { VALUE_CHANGE_ACTION } from './common/base-input.js';
import { action, get, set } from '@ember/object';
import { once } from '@ember/runloop';

/**
 * Stores array of tick objects.
 *
 * @param {number} currentValue Current value of slider. By default this will be the currently set value property. This may also be the current non-set value during sliding the slider.
 */
function updateTicks(currentValue = this.value) {
    // Create an array of size max value minus min value (with null at each index).
    const ticks = Array.apply(null, Array(this.maxValue - this.minValue + 1))
        // For each array item, create a tick using the index.
        .map((_, n) => getTick.call(this, n + this.minValue, currentValue));

    set(this, 'ticks', ticks);
}

/**
 * Get Tick based on given tick index.
 *
 * @param {number} index Index inclusively between min and max values.
 * @param {number} currentValue Current value of slider. This could be the set one by default or the current one during sliding the slider.
 *
 * @return {Tick}
 */
function getTick(index, currentValue) {
    const tickTexts = {
        [this.minValue]: this.minTickText,
        [this.maxValue]: this.maxTickText,
        [this.suggestedTick]: this.suggestedTickText
    };

    return {
        // We need the index so that handlebar doesn't take any shortcuts rendering.
        // Without this, the ticks will be selected out of order during value changes.
        index,
        // Selected tick is to the left of slider marker.
        cssClass: index <= currentValue ? 'selected' : '',
        // Get text (if any) that goes above the tick.
        text: tickTexts[index]
    };
}

/**
 * @classdesc A slider control that has tick markers for each slider option.
 */
export default class TickedSlider extends BaseSlider {
    /**
     * Text that will appear on left most tick.
     *
     * @type {String}
     */
    minTickText;

    /**
     * Text that will appear on right most tick.
     *
     * @type {String}
     */
    maxTickText;

    /**
     * Suggested tick index.
     *
     * @type {number}
     */
    suggestedTick;

    /**
     * Suggested tick's text.
     *
     * @type {String}
     */
    suggestedTickText;

    /**
     * Update ticks every time any of the tick-related values change.
     */
    @action valueChangeObserver() {
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        once(this, updateTicks);
    }

    /**
     * Updates slider and reverts value if request fails.
     *
     * @param {number} newTickValue - The new value of the slider.
     * @param {boolean} [isFromSlider=true] - Was the change made by the slider?
     * @param {boolean} [isMouseUp=true] - Is the mouse currently in the release position?
     */
    @action sliderChanged(newTickValue, isFromSlider = true, isMouseUp = true) {
        // Was a new tick value given?
        if (newTickValue !== undefined) {
            const numberOfTicks = this.ticks?.length - 1;

            // If the mouse event is done and it's from the slider.
            if (isMouseUp && isFromSlider) {
                // Round to the nearest tick's percentage.
                set(this, 'sliderPercentage', (newTickValue / numberOfTicks) * 100);
            }

            // Only update the ticks if this is from the slider.
            if (isFromSlider) {
                updateTicks.call(this, (this.sliderPercentage * numberOfTicks) / 100);
            }
        }

        // Was the value-change action defined in the handlebars?
        if (get(this, VALUE_CHANGE_ACTION)) {
            // Send back the new value, along with a boolean indicating if the value was changed using the slider element.
            get(this, VALUE_CHANGE_ACTION)(newTickValue, isFromSlider, isMouseUp);
        }
    }
}

/**
 * The definition of a Tick.
 *
 * @typedef {{
 *     index: number,
 *      cssClass: string,
 *     text: String,
 *     textStyle: string
 * }} Tick
 *
 * @property {number} index Index of tick that is between min and max values.
 * @property {String} cssStyle CSS class name for tick.
 * @property {String} text Text that will be displayed above the tick.
 * @property {String} textStyle In-line styling for text positioning.
 *
 * @components.TickedSlider
 * @private
 */
