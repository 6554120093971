import Component from '@glimmer/component';
import { action } from '@ember/object';
import { gt } from '@ember/object/computed';
import { A } from '@ember/array';

import type { SimpleBinaryButtonSignature } from './button';
import type { CommonInputErrorTooltipArgs } from '../error-tooltip';
import type { BinaryListItem } from './list';

type BinaryButtonListItem = SimpleBinaryButtonSignature['Args']['item'];

export interface SimpleBinaryButtonListSignature<T extends BinaryButtonListItem> {
    Element: HTMLUListElement;
    Args: CommonInputErrorTooltipArgs & {
        /** The binary list items to render. */
        items: T[] | Promise<T[]>;
        /** Optional icon name to be used for the selection indicator. */
        selectedIcon?: string;
        /** Indicates the list is multi select. */
        isMultiSelect?: boolean;
        /** Indicates that items can be deselected in single selection mode. */
        allowDeselect?: boolean;
        /** The list orientation (defaults to "vertical"). */
        orientation?: 'vertical' | 'horizontal';
        /** Indicates all buttons in the list should be disabled. */
        disabled?: boolean;
        /** Triggered when the user changes the binary list item state. */
        onchange?: (items: T[]) => void;
    };
    Blocks: {
        default: [T];
    };
}

export default class SimpleBinaryButtonList<T extends BinaryButtonListItem> extends Component<
    SimpleBinaryButtonListSignature<T>
> {
    get isMultiSelect(): boolean {
        return this.args.isMultiSelect ?? true;
    }

    @gt('args.items.length', 7)
    declare isLongList: boolean;

    get listCss(): string {
        return A([`o-${this.args.orientation ?? 'vertical'}`, this.isLongList ? 'is-long' : undefined])
            .compact()
            .join(' ');
    }

    private async listItemChanged(item: BinaryListItem, state: boolean): Promise<void> {
        const items = await this.args.items;

        // Is this NOT a multiselect list AND is the new state selected?
        if (!this.isMultiSelect && state) {
            // Clear all other selections.
            A(items).setEach('state', false);
        }

        item.state = state;
        this.args.onchange?.(items);
    }

    @action buttonClicked(item: BinaryListItem): void {
        const { isMultiSelect } = this,
            { allowDeselect } = this.args;

        if (isMultiSelect || allowDeselect || !item.state) {
            this.listItemChanged(item, !item.state);
        }

        // If not multiselect and not able to deselect, run onchange again on click.
        if (!isMultiSelect && !allowDeselect && item.state === true) {
            this.listItemChanged(item, item.state);
        }
    }

    @action binaryToggled(item: BinaryListItem, evt: InputEvent): void {
        this.listItemChanged(item, (evt.target as HTMLInputElement).checked);
    }
}
