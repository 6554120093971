export interface ErrorPayload {
    firstObject?: {
        message?: string;
    };
}

export default function validationErrorHelper(
    propertyError?: ErrorPayload
): undefined | string {
    if (propertyError && typeof propertyError === 'object') {
        // Get the first error object and return the message
        return propertyError.firstObject?.message;
    }

    return undefined;
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Accepts an ADC api error payload and return the first error message.
         *
         * @example @errorMessage={{validation-error this.resolvedModel.errors.address}}
         */
        'validation-error': typeof validationErrorHelper;
    }
}
