/**
 * This enum is a TS representation of NJsonApi.Common.AjaxResponseHttpCodeEnum and it must be manually kept in sync w/ the C# version.
 *
 * @module
 */

export const NotLoggedIn = 401;

export const InvalidAntiForgeryToken = 403;

export const NoPermissionForEmber = 406;

export const TwoFactorAuthenticationRequired = 409;

export const ValidationError = 422;

export const ProcessingError = 423;

export const ServerProcessingError = 500;

export const ServiceUnavailable = 503;
