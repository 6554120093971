import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';

import type ModalService from '../../../../services/modals';
import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { ButtonAsyncSignature } from '../../../button/async';

export interface ModalFooterSignature {
    Element: HTMLDivElement;
    Args: {
        /** Indicates the close button should not be added to the collection of footer buttons. */
        disableCloseButton?: boolean;
        /** The buttons to render in the modal footer. */
        buttons?: Array<ModalButtonConfig>;
        /** Optional text for the automatic close button. */
        closeButtonText?: string;
    };
    Blocks: {
        default: [];
    };
}

type ModalFooterButtonAction = ButtonAsyncSignature['Args']['buttonAction'];

export interface ModalButtonConfig {
    css?: string;
    action?: ModalFooterButtonAction;
    disabled?: boolean;
    text?: string;
}

/**
 * Build a simple modal footer button
 */
export function getModalFooterButton(
    text: string,
    action: ModalFooterButtonAction = () => true,
    css?: string,
    disabled?: boolean,
    isPrimary = false
): ModalButtonConfig {
    return {
        css: A([isPrimary ? 'btn-color-primary' : undefined, css])
            .compact()
            .join(' '),
        action,
        disabled: disabled ?? false,
        text
    };
}

export { getModalFooterButton as modalFooterButton };

/**
 * @classdesc
 * Footer for modal component, contains list of buttons
 */
export default class ModalFooter extends Component<ModalFooterSignature> {
    @service declare intl: ADCIntlService;
    @service declare modals: ModalService;

    /**
     * Get buttons to display as part of the footer
     */
    @computed('args.{buttons.@each.disabled,closeButtonText,disableCloseButton}')
    get buttonsToRender(): ModalButtonConfig[] {
        const { args } = this,
            buttons = A(args.buttons ?? []).compact();

        if (!(args.disableCloseButton === false)) {
            return buttons;
        }

        return [
            getModalFooterButton(
                args.closeButtonText ?? this.intl.t('@adc/ui-components.close'),
                () => true,
                'close-btn'
            ),
            ...buttons
        ];
    }

    private handleActionResult(result: any): void {
        if (result !== false) {
            this.modals.closeModal();
        }
    }

    /**
     * Handle modal button actions.
     */
    @action
    clickButton(buttonAction: ModalFooterButtonAction, buttonAnimation?: Promise<boolean>): void | Promise<void> {
        const p = buttonAction(buttonAnimation);

        // Is the return value a promise?
        if (p !== undefined && typeof p.then === 'function') {
            return (async () => {
                const result = await p;
                this.handleActionResult(result);
            })();
        }

        this.handleActionResult(p);
    }
}
