import templateOnlyComponent from '@ember/component/template-only';

export type PtzButtonsArgs = {
    /** Actions to be performed on click */
    actions: {
        panUp: VoidFunction;
        panDown: VoidFunction;
        panLeft: VoidFunction;
        panRight: VoidFunction;
        zoomIn: VoidFunction;
        zoomOut: VoidFunction;
        resetZoom: VoidFunction;
    };
    /** Indicates whether buttons are disabled */
    disabledButtons: {
        up: boolean;
        down: boolean;
        left: boolean;
        right: boolean;
        zoomIn: boolean;
        zoomOut: boolean;
        reset: boolean;
    };
    /** Indicates whether the reset button is primary */
    isResetButtonPrimary: boolean;
};

interface PtzButtonsSignature {
    Element: HTMLDivElement;
    Args: PtzButtonsArgs;
    Blocks: {
        default: [];
    };
}

const PtzButtons = templateOnlyComponent<PtzButtonsSignature>();

export default PtzButtons;
