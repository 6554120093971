import templateOnlyComponent from '@ember/component/template-only';

import type SimpleListItem from './list-item';

export interface SimpleListItemSignature {
    Element: HTMLDivElement;
    Args: {
        /** Data for the current item. */
        item: any | SimpleListItem;
        onClick?: VoidFunction;
    };
    Blocks: {
        /** Renders on the left side of the item, typically (but not limited to) an icon. */
        icon: [];
        /** Renders in the center of the item, will render description and details if omitted. */
        detail: [];
        /** Renders to the right of the item, could be an icon, or some value, or really anything. */
        value: [];
    };
}

const SimpleListItemComponent = templateOnlyComponent<SimpleListItemSignature>();
export default SimpleListItemComponent;
