import RESTAdapter from '@ember-data/adapter/rest';
import AdapterError, { InvalidError } from '@ember-data/adapter/error';
import { typeOf } from '@ember/utils';
import { getErrorWithStatusCode } from '@adc/ajax/utils/errors';
import { ValidationError } from '@adc/ajax/enums/AjaxResponseHttpCode';
import { getCookie } from '@adc/ember-utils/utils/browser-helpers';

import type BaseModel from '../models/base-model.ts';

interface HeaderProps {
    Accept: string;
    SourcePath: string;
    SourceQueryParams: string;
    [key: string]: any;
}

/**
 * @classdesc
 * Alarm.com's base adapter implementation.
 */
export default class BaseAdapter extends RESTAdapter {
    // region adapter options for retrieving lists of models

    // Tells the adapter that it can bundle up ids in one request, as necessary.
    coalesceFindRequests = true;

    // endregion

    /**
     * Request headers appended to each request.
     */
    get headers(): HeaderProps {
        const { pathname, search } = window.location;

        return {
            Accept: 'application/vnd.api+json',
            AjaxRequestUniqueKey: getCookie('afg'),
            SourcePath: pathname,
            SourceQueryParams: search
        };
    }

    /** @override */
    handleResponse(status: number, headers: any, payload: any, requestData: any): any {
        let jsonPayload;

        try {
            // Make sure payload is an object (likely remove once https://github.com/emberjs/data/issues/6936 is fixed).
            jsonPayload = typeOf(payload) === 'string' ? JSON.parse(payload) : payload;
        } catch (ex) {
            jsonPayload = payload;
        }

        const response = super.handleResponse(status, headers, jsonPayload, requestData) as any;

        // Is this an error?
        if (response && response.isAdapterError) {
            const { errors } = jsonPayload;

            // Is this a validation error?
            if (status === ValidationError) {
                return new InvalidError(errors);
            }

            // Do we care about this error?
            const statusData = getErrorWithStatusCode(status);
            if (statusData) {
                const {
                    code = status,
                    name = response.name,
                    message = response.message,
                    title = response.description
                } = statusData;

                // Return adapter error with our custom values.
                return Object.assign(
                    {
                        number: code,
                        description: title,
                        name,
                        errors
                    },
                    new AdapterError(message)
                );
            }
        }

        return response;
    }

    /**
     * This method is used to indicate when there is a network connection error. It can be overridden in your project to handle this error however you wish.
     */
    noInternetConnection(): any {
        return null;
    }

    /**
     * Submits custom api call that is not the standard REST verb.
     *
     * This serializes `rawData` into JSON, so do not pass in a JSON string for `rawData` or else it will be doubly serialized.
     * Instead, just pass in the un-serialized object itself.
     */
    submitCustomApiCall(model: BaseModel, verb: string, method = 'POST', rawData: any): Promise<any> {
        // Does the raw data have a serialize method?
        if (
            rawData !== null &&
            typeof rawData === 'object' &&
            'serialize' in rawData &&
            typeof rawData.serialize === 'function'
        ) {
            rawData = rawData.serialize();
        }

        // For GET methods, the data gets appended to the URI query string. We don't want JSON there, so just leave the data as is.
        return this.ajax(
            // This is how we fetch the modelName, but we need to cast it due to ember-data type support being far behind
            `${this.buildURL((model.constructor as unknown as { modelName: string }).modelName, model.id)}/${verb}`,
            method,
            rawData !== undefined ? { data: rawData } : undefined
        );
    }
}
