import BaseTimezoneControl from './base-timezone-control.ts';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, computed, setProperties } from '@ember/object';
import { equal } from '@ember/object/computed';
import { guidFor } from '@ember/object/internals';
import { VALUE_CHANGE_ACTION } from './common/base-input.js';
import { BinaryListItem } from './simple-binary/list.ts';
import { intlPath } from '@adc/i18n/path';
import { A } from '@ember/array';

import subMinutes from 'date-fns/subMinutes';
import isEqual from 'date-fns/isEqual';
import isSameDay from 'date-fns/isSameDay';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import max from 'date-fns/max';
import min from 'date-fns/min';

import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { BaseTimezoneControlSignature } from './base-timezone-control';
import type { DateChangePayload } from './smart-date-range';
import type { ButtonIconSignature } from './button/icon';

const DATE_SINGLE = 'single',
    DATE_RANGE = 'range';

type DatePropName = 'startSingle' | 'endSingle' | 'startRange' | 'endRange';

export interface DateRangePickerComponentSignature extends BaseTimezoneControlSignature {
    Element: ButtonIconSignature['Element'];
    Args: BaseTimezoneControlSignature['Args'] & {
        /** The range start date. */
        startDate: Date;
        /** The range end date. */
        endDate: Date;
        /** Triggered when the user changes the date range. */
        'value-change': (dates: DateChangePayload) => void;
        /** Optional minimum date range value. */
        minDate?: Date;
        /** Optional maximum date range value. */
        maxDate?: Date;
        /** Indicates we should show seconds in the date range time pickers. */
        showSeconds?: boolean;
        /** Called to validate selected dates, disabled the "Apply" button when it returns `false`. */
        validationFn?: (startDate: Date, endDate: Date) => boolean;
    };
    Blocks: {
        default: [];
    };
}

/**
 * Returns the currently selected dates (single or range).
 *
 * @private
 */
function getSelectedDates(this: DateRangePickerComponent): DateChangePayload {
    const type = this.isRangeSelected ? 'Range' : 'Single',
        [startDate, endDate] = ['start', 'end'].map((p) => this[`${p}${type}` as DatePropName]);

    return { startDate, endDate };
}

type OptionItemProps = {
    id: string;
    isRange: boolean;
};

/**
 * @classdesc
 * A button, that when clicked, opens a popup with options for choosing a date/time range.
 */
@intlPath({ module: '@adc/ui-components', path: 'date-range-picker' })
export default class DateRangePickerComponent extends BaseTimezoneControl<DateRangePickerComponentSignature> {
    @service declare intl: ADCIntlService;

    // region Component state.

    /**
     * Indicates the date range picker popup is open.
     */
    @tracked isOpen = false;

    /**
     * The internal value used to track the start date for a single day range.
     */
    @tracked startSingle = new Date();

    /**
     * The internal value used to track the end date for a single day range.
     */
    @tracked endSingle = new Date();

    /**
     * The internal value used to track the start date for a multi-day range.
     */
    @tracked startRange = new Date();

    /**
     * The internal value used to track the end date for a multi-day range.
     */
    @tracked endRange = new Date();

    /**
     * The currently select range type, single day or multi-day.
     */
    @tracked selectedType = DATE_RANGE;

    /**
     * Indicates a multi-day range is currently selected.
     */
    @equal('selectedType', DATE_RANGE)
    declare isRangeSelected: boolean;

    /**
     * The computed date range string.
     */
    @computed('args.{endDate,showSeconds,startDate}', 'endDate', 'showSeconds', 'startDate', 'zone')
    get displayText(): string {
        const timeFmt: Intl.DateTimeFormatOptions = {
            hour: 'numeric',
            minute: '2-digit'
        };

        if (this.args.showSeconds) {
            timeFmt.second = '2-digit';
        }

        const dates = [this.args.startDate, this.args.endDate],
            { zone } = this,
            fnFormat = (dates: Date[], options: Partial<Intl.DateTimeFormatOptions>) => {
                if (zone) {
                    options.timeZone = zone;
                }

                // Do not copy this deprecated usage. If you see this, please fix it
                // eslint-disable-next-line @adc/ember/require-tz-functions
                return dates.map((d) => this.intl.formatDate(d, options));
            },
            [startDate, endDate] = fnFormat(dates, {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric'
            }),
            [startTime, endTime] = fnFormat(dates, timeFmt);

        // Is this a single date range?
        if (startDate === endDate) {
            return `${startDate}, ${startTime} - ${endTime}`;
        }

        return `${startDate} ${startTime} - ${endDate} ${endTime}`;
    }

    /**
     * The date range options to show (single versus multi).
     */
    @computed('selectedType')
    get options(): BinaryListItem<OptionItemProps>[] {
        return [DATE_SINGLE, DATE_RANGE].map(
            (id) =>
                new BinaryListItem<OptionItemProps>({
                    label: this.intl.tc(this, id === DATE_RANGE ? 'selectDateRange' : 'selectDate'),
                    state: id === this.selectedType,
                    props: {
                        id,
                        isRange: id === DATE_RANGE
                    }
                })
        );
    }

    /**
     * Indicates the currently selected date range is invalid.
     */
    @computed('startSingle', 'endSingle', 'startRange', 'endRange', 'selectedType', 'args.validationFn')
    get invalidDateRange(): boolean {
        const { validationFn } = this.args,
            { startDate, endDate } = getSelectedDates.call(this);

        return !!(startDate > endDate || (validationFn && !validationFn(startDate, endDate)));
    }

    // endregion

    // region Actions

    /**
     * Updates the internal picker date values for the passed start and end date.
     */
    @action configurePicker(): void {
        const seed = new Date(),
            { endDate = new Date(seed), startDate = subMinutes(seed, 1000), minDate, maxDate } = this.args,
            props = {} as Pick<Pick<this, keyof this>, keyof this>,
            fnAddDate = (name: DatePropName, value: Date): void => {
                if (!isEqual(value, this[name])) {
                    props[name] = new Date(value);
                }
            };

        if (isSameDay(this.getZonedDate(startDate), this.getZonedDate(endDate))) {
            props.selectedType = DATE_SINGLE;

            fnAddDate('startSingle', startDate);
            fnAddDate('endSingle', endDate);
        } else {
            props.selectedType = DATE_RANGE;

            // Calculate start and end of today (or nearest day within range) for unselected single day range.
            const fn = (v: Date, fn: (datesArray: (Date | number)[]) => Date, c?: Date): Date => (c ? fn([v, c]) : v),
                singleDate = fn(fn(seed, min, maxDate), max, minDate),
                startSingle = this.getUtcDateFromZone(startOfDay(this.getZonedDate(singleDate))),
                endSingle = this.getUtcDateFromZone(endOfDay(this.getZonedDate(singleDate)));

            fnAddDate('startSingle', minDate ? max([minDate, startSingle]) : startSingle);
            fnAddDate('endSingle', maxDate ? min([endSingle, maxDate]) : endSingle);
        }

        fnAddDate('startRange', startDate);
        fnAddDate('endRange', endDate);

        setProperties(this, props);
    }

    /**
     * Change the currently selected range type.
     */
    @action switchType(items: DateRangePickerComponent['options']): void {
        this.selectedType = A(items).findBy('state')?.props.id === DATE_RANGE ? DATE_RANGE : DATE_SINGLE;
    }

    /**
     * Toggles the date range picker popup opened or closed.
     */
    @action togglePicker(): void {
        this.isOpen = !this.isOpen;
    }

    /**
     * Cancels any changes and closes picker.
     */
    @action cancelPicker(): void {
        this.togglePicker();
        this.configurePicker();
    }

    /**
     * Called when one set of date range values has changed.
     *
     * @param dates An object with the date value(s) that have changed.
     * @param isRange Indicates if the selection was made from a date range selector, or the single day selector.
     */
    @action changeTimeValues(dates: Partial<DateChangePayload>, isRange: boolean): void {
        setProperties(
            this,
            Object.keys(dates).reduce(
                (props, key) => ({
                    ...props,
                    [key.replace('Date', isRange ? 'Range' : 'Single')]: dates[key]
                }),
                {
                    // Add selected type in case it changed via user date/time change.
                    selectedType: isRange ? DATE_RANGE : DATE_SINGLE
                }
            )
        );
    }

    /**
     * Validates days for selected range and then notifies consumer of newly selected date range.
     */
    @action selectRange(): void {
        // Grab form for currently selected range type.
        const form = document.querySelector(
            `#${guidFor(this)}_${this.isRangeSelected ? 'range' : 'single'}`
        ) as HTMLFormElement;

        // Are form inputs valid?
        if (form.reportValidity()) {
            // Close picker.
            this.togglePicker();

            // Get new start and end dates, and notify consumers.
            this.args[VALUE_CHANGE_ACTION](getSelectedDates.call(this));
        }
    }

    // endregion
}
