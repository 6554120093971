import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * QR code image sizes.
 */
const QRCodeSizes = {
    small: '144',
    medium: '240',
    large: '400'
};

/**
 * QRErrorCorrectLevel extracted from the qrcode.js library.
 */
const QRErrorCorrectLevel = {
    L: 1,
    M: 0,
    Q: 3,
    H: 2
};

/**
 * The QRCode from the qrcode.js library.
 */
declare class QRCode {
    constructor(el: HTMLElement | string, vOption: Record<string, unknown> | string);

    makeCode(data: string): void;
}

interface QrCodeSignature {
    Args: {
        /** The size of the QR symbol to render. */
        size?: 'small' | 'medium' | 'large';
        /** The QR data. */
        data: string;
        /** The hex value of the QR symbol dark color (defaults to `#000`). */
        darkColor?: string;
        /** The hex value of the QR symbol light color (defaults to `#fff`). */
        lightColor?: string;
        /** The QR correction level (defaults to "L"). */
        correctionLevel?: 'L' | 'M' | 'Q' | 'H';
    };
}

/**
 * @classdesc QR code generation in js.
 *
 * @note This implementation allows for only 1 QR code per page (only 1 QR code can be on the DOM).
 *
 * @note This qr-code component uses a qrcode.js file from a third party to generate a QR code using javascript.
 *       Another .js file could be substituted for the one we use here in the future if necessary.
 */
export default class QrCodeComponent extends Component<QrCodeSignature> {
    /**
     * The QR Code.
     */
    qrcode?: QRCode;

    /**
     * The data for the QR code to represent.
     *
     * @type {String}
     */
    get data(): string {
        return this.args.data;
    }

    /**
     * Generates the QR code.
     */
    @action
    initializeQRCode(): void {
        const { args } = this,
            { data } = args,
            size = args.size,
            qrDimension = QRCodeSizes[size ?? 'small'] || QRCodeSizes['small'];

        this.qrcode = new QRCode('qr-code', {
            text: data,
            width: qrDimension,
            height: qrDimension,
            colorDark: args.darkColor ?? '#000000',
            colorLight: args.lightColor ?? '#ffffff',
            correctLevel: QRErrorCorrectLevel[args.correctionLevel ?? 'L']
        });

        this.qrcode.makeCode(data);
    }

    /**
     * Recreates the QR code if the data changes.
     */
    @action
    makeQRCode(_: any, [data]: [string]): void {
        this.qrcode?.makeCode(data);
    }
}
