// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { Promise as EmberPromise } from 'rsvp';
import { equal } from '@ember/object/computed';
import { computed } from '@ember/object';
import { later } from '@ember/runloop';
import { isEnterOrSpaceCode } from '@adc/ember-utils/utils/a11y';
import { inject as service } from '@ember/service';
import { convertAutoDestroyToDuration } from './error-tooltip.ts';
import { isTestEnvironment } from '../utils/general.ts';

// region Variables

/**
 * Mapping of input sizes to partial class names.
 *
 * @private
 * @type {Object<String, String>}
 * @memberof components.Button
 */
const sizeMap = {
    'x-small': 'x-sm',
    small: 'sm',
    large: 'lg',
    'x-large': 'x-lg'
};

const ON_CLICK = 'on-click';

/**
 * Possible defined promise states.
 *
 * @type {string}
 * @ignore
 */
const SUCCESS = 'success',
    PENDING = 'pending',
    ERROR = 'error';

/**
 * Classes used for asynchronous button states.
 *
 * @type {String}
 * @private
 * @ignore
 */
const BUTTON_STATE_CLASSES = {
    [SUCCESS]: 'btn-safe',
    [PENDING]: 'btn-default',
    [ERROR]: 'btn-danger'
};

/**
 * The duration between when the on-click promise is resolved and the success callback method is called.
 *
 * @note This is also the length of time before the button changes from success back to its normal state.
 *
 * @private
 * @instance
 * @memberof components.Button
 *
 * @returns {number}
 */
function getSuccessCallbackDelay() {
    return this.noCallbackDelay ? 0 : 700;
}

/**
 * Duration of time before the buttons changes from error state back to its normal state.
 *
 * @private
 * @instance
 * @memberof components.Button
 *
 * @returns {number}
 */
function getFailureCallbackDelay() {
    return this.noCallbackDelay ? 0 : 3000;
}

/**
 * Observes the passed promise to animate the button.
 *
 * @param {Promise} promise The promise to observe.
 *
 * @private
 * @instance
 * @memberof components.Button
 */
function observePromise(promise) {
    let animationDelay = getSuccessCallbackDelay.call(this),
        fnUpdateStateClass = (state) => {
            // Is the button still active?
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line @adc/ember/no-is-destroyed
            if (!this.isDestroyed) {
                // Update promise state class.
                this.set('promiseStateClass', state);
            }
        };

    // Update CSS class to pending.
    fnUpdateStateClass(PENDING);

    promise
        .then(() => {
            // Promise fulfilled.
            fnUpdateStateClass(SUCCESS);
        })
        .catch(() => {
            if (!this.showFailureAnimation) {
                fnUpdateStateClass(PENDING);
                return;
            }

            // Promise rejected.
            fnUpdateStateClass(ERROR);

            // Set button animation length to be longer for errors because they are likely staying on the same page.
            animationDelay = getFailureCallbackDelay.call(this);
        })
        .finally(() => {
            // Reset button to original state (undefined) after a certain time period.
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            later(() => {
                fnUpdateStateClass(undefined);
            }, animationDelay);
        });
}

/**
 * Checks the attributes to see if there is an on-click method that should be called. If there is an on-click method, it calls that
 * method with a callback helper as an argument (linkPromise) to allow our button to know when the promise has been settled.
 * The on-click method (if asynchronous) should define a promise and call the helper method passed to link the promise to the button.
 *
 * @param {Object} args
 *
 * @private
 * @instance
 * @memberof components.Button
 */

function executeAction(args) {
    // Is there no on-click handler?
    const onClickMethod = this.getAttr(ON_CLICK);

    if (!onClickMethod) {
        // Nothing to do, but allow bubbling.
        return true;
    }

    const { disabled, readonly, promiseStateClass } = this;

    // Is the button disabled, readonly or in the middle of an async task?
    if (disabled || readonly || promiseStateClass !== undefined) {
        // Do nothing, but return false to stop action bubbling.
        return false;
    }

    // Was an on-click method defined in the handlebars?
    if (typeof onClickMethod === 'function') {
        let delayedResolver;

        // This resolves the delayed promise after the animation finishes.
        const delayedHandler = new EmberPromise((resolve) => {
            delayedResolver = resolve;
        });

        // The button's action should return a promise, so we can animate the button's state.
        const promise = onClickMethod(delayedHandler, args);

        // Is the returned value a promise?
        if (promise && promise.then) {
            // Observe the promise in order to animate the button.
            observePromise.call(this, promise);

            // Animation finished.
            promise
                .then(() => {
                    // Resolve the delayed promise, in case the developer defined their own actions to be taken.
                    // Do not copy this deprecated usage. If you see this, please fix it
                    // eslint-disable-next-line ember/no-runloop
                    later(() => {
                        delayedResolver();
                    }, getSuccessCallbackDelay.call(this));
                })
                .catch(() => {});
        }
    }
}

// endregion

/**
 * Button component for use in all ADC applications.
 *
 * @class Button
 * @extends Ember.Component
 * @memberof components
 */
// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-classes
const Button = Component.extend(
    /** @lends components.Button# **/ {
        intl: service(),

        // region Ember Properties

        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/require-tagless-components
        tagName: 'button',

        classNames: ['btn'],
        classNameBindings: [
            'sizeClass',
            'primary:btn-primary',
            'safe:btn-safe',
            'danger:btn-danger',
            'link:btn-link',
            'icon:btn-icon',
            'noBackground:no-bg',
            'iconOnly',
            'promiseStateClass',
            'selected',
            'hideSelectedCheckmark',
            'asyncButtonClass'
        ],
        attributeBindings: [
            'ariaLabel:aria-label',
            'disabled',
            'type',
            'ariaSelected:aria-selected',
            'tabindex',
            'ariaHasPopup:aria-haspopup',
            'dataId:data-id'
        ],

        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-component-lifecycle-hooks
        didReceiveAttrs() {
            this._super();

            const iconOnly = this.getAttr('iconOnly'),
                text = this.getAttr('text');

            // Text should always be defined for an iconOnly button because it is used for accessibility purposes.
            if (iconOnly && !(text && text.length)) {
                console.warn('An "iconOnly" button should have the text property defined.');
            }
        },

        // endregion

        // region Custom Properties

        /**
         * Data ID attribute of the element.
         *
         * @type {String|undefined}
         */
        dataId: undefined,

        /**
         * Text to be shown inside the button.
         *
         * <strong>Note:</strong> If both text and responsiveText are defined, the responsiveText takes precedence on smaller responsive screens.
         *
         * @type {String|undefined}
         */
        text: undefined,

        /**
         * Is the button disabled?
         *
         * @type {boolean}
         */
        disabled: false,

        /**
         * Is the button a popover enu trigger?
         *
         * @type {boolean}
         */
        isPopoverTrigger: false,

        /**
         * Determines the aria-haspopup attribute value.
         *
         * @function
         * @returns {String|undefined}
         */
        ariaHasPopup: computed('isPopoverTrigger', function () {
            return this.isPopoverTrigger ? 'true' : undefined;
        }),

        /**
         * Should the btn-primary class be applied?
         *
         * @type {boolean}
         */
        primary: false,

        /**
         * Should the btn-safe class be applied?
         *
         * @type {boolean}
         */
        safe: false,

        /**
         * Should the btn-danger class be applied?
         *
         * @type {boolean}
         */
        danger: false,

        /**
         * Should the btn-link class be applied?
         *
         * @type {boolean}
         */
        link: false,

        /**
         * Button size
         *
         * @type {String|undefined}
         */
        size: undefined,

        /**
         * Determines if the button is selected (i.e. its state is "on").
         *
         * @type {boolean}
         */
        selected: false,

        /**
         * Determines the aria-selected attribute of the button.
         *
         * @function
         * @returns {Boolean|undefined}
         */
        ariaSelected: computed('selected', function () {
            return this.selected || undefined;
        }),

        /**
         * Hides the checkmark displayed by default when the button is selected.
         *
         * @type {boolean}
         */
        hideSelectedCheckmark: false,

        /**
         * Whether or not to show the checkmark when the button is selected.
         *
         * @function
         * @returns {boolean}
         */
        hasVisibleCheckmark: computed('selected', 'hideSelectedCheckmark', function () {
            return this.selected && !this.hideSelectedCheckmark;
        }),

        // region Error Handling

        /**
         * The error message to display inside the tooltip.
         *
         * @type {String}
         */
        errorMessage: undefined,

        /**
         * The number of seconds to wait before closing the error tooltip.
         *
         * @type {number}
         */
        autoDestroy: undefined,

        /**
         * Calculated error tooltip duration.
         */
        errorDuration: computed('autoDestroy', function () {
            return convertAutoDestroyToDuration(this.autoDestroy);
        }),

        // endregion

        /**
         * Returns the class size to be applied based on the passed in size.
         *
         * @function
         * @returns {String}
         */
        sizeClass: computed('size', function () {
            const classSize = sizeMap[this.size];
            return (classSize && `btn-${classSize}`) || '';
        }).readOnly(),

        // endregion

        // region Icon Button

        /**
         * More descriptive button text to be displayed when responsive buttons take up the full width of the screen.
         *
         * @type {String|undefined}
         */
        responsiveText: undefined,

        /**
         * Screen reader text.
         *
         * @function
         * @returns {String}
         */
        screenReaderText: computed('isPopoverTrigger', function () {
            const { isPopoverTrigger } = this;

            return isPopoverTrigger ? this.intl.t('@adc/ui-components.triggerDropdown') : '';
        }),

        /**
         * Determines the ARIA label to use for accessibility purposes.
         *
         * @function
         * @returns {String}
         */
        ariaLabel: computed('iconOnly', 'text', 'responsiveText', 'screenReaderText', function () {
            const { iconOnly, text, responsiveText, screenReaderText } = this;

            return screenReaderText ? screenReaderText : iconOnly ? text : responsiveText;
        }),

        /**
         * Name of the icon to be displayed within the button.
         *
         * @type {String|undefined}
         */
        icon: undefined,

        /**
         * Determines if only an icon should be displayed or if text can accompany the icon in the button.
         *
         * Note: This allows developers to specify text for accessibility purposes without having it appear in the button.
         *
         * @type {boolean}
         */
        iconOnly: false,

        /**
         * Icon is accompanied by a tooltip that contains its title.
         *
         * @type {string}
         */
        svgTitle: computed('text', 'iconTitle', function () {
            const { text, iconTitle } = this;

            return iconTitle ? iconTitle : text;
        }),

        /**
         * Should the background of the button be transparent?
         *
         * Note: this is ignored if there is text specified for the button.
         *
         * @type {boolean}
         */
        noBackground: false,

        /**
         * Should the click event bubble up?
         *
         * @type {boolean}
         */
        bubbleClickEvent: false,

        /**
         * Should the keydown event bubble up?
         *
         * @type {boolean}
         */
        bubbleKeydownEvent: true,

        /**
         * Should this button end with a failure animation?
         *
         * @type {boolean}
         */
        showFailureAnimation: true,

        // endregion

        // region Asynchronous Button

        /**
         * String representation of the state of the promise in an async task.
         *
         * The promise can be in one of the following states:
         *
         * <ul>
         *     <li><strong>Settled</strong>: not waiting for async task (i.e. undefined)</li>
         *     <li><strong>Pending</strong>: waiting for fulfilled or rejected status</li>
         *     <li><strong>Fulfilled</strong>: the async task succeeded</li>
         *     <li><strong>Rejected</strong>: the async task failed</li>
         * </ul>
         *
         * @type {String|undefined}
         */
        promiseStateClass: undefined,

        /**
         * Is the promise in pending state?
         *
         * <strong>Note:</strong> This property is used in the handlebars to show the spinning svg icon.
         *
         * @function
         * @returns {boolean}
         */
        isPromisePending: equal('promiseStateClass', PENDING),

        /**
         * Is the promise in a fulfilled/success state?
         *
         * <strong>Note:</strong> This property is used in the handlebars to show the check svg icon.
         *
         * @function
         * @returns {boolean}
         */
        isPromiseFulfilled: equal('promiseStateClass', SUCCESS),

        /**
         * Is the promise in a rejected/error state?
         *
         * <strong>Note:</strong> This property is used in the handlebars to show the exclamation svg icon.
         *
         * @function
         * @returns {boolean}
         */
        isPromiseRejected: equal('promiseStateClass', ERROR),

        /**
         * Returns the button color based on promise state.
         *
         * @function
         * @returns {String}
         */
        asyncButtonClass: computed('link', 'noBackground', 'promiseStateClass', function () {
            if (this.noBackground || this.link) {
                return undefined;
            }

            return BUTTON_STATE_CLASSES[this.promiseStateClass];
        }),

        /**
         * Should there be no delay between promise being fulfilled and delayed handler being triggered?
         *
         * @type {Boolean}
         */
        noCallbackDelay: computed(function () {
            return isTestEnvironment.call(this);
        }),

        // endregion

        // region Actions

        /**
         * Listener for keyDown events.
         *
         * @param {KeyboardEvent} e
         */
        keyDown(e) {
            if (isEnterOrSpaceCode(e.code)) {
                e.preventDefault();
                executeAction.call(this, ...arguments);

                return this.bubbleKeydownEvent;
            }
        },

        /**
         * Called when the button component is clicked.
         */
        click(...args) {
            executeAction.call(this, args);

            return this.bubbleClickEvent;
        }

        // endregion
    }
);

export default Button;
