import { debounce } from '@ember/runloop';
import { computed, action } from '@ember/object';
import { isDestroyed } from '@adc/ember-utils/utils/ember-helpers';
import { convertAutoDestroyToDuration } from './error-tooltip.ts';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { scheduleOnce } from '@ember/runloop';

import type DomService from '@adc/ember-utils/services/dom';
import type { CommonInputErrorTooltipArgs } from './error-tooltip';

export interface TextInputSignature {
    Element: HTMLInputElement;
    Args: Pick<CommonInputErrorTooltipArgs, 'errorMessage' | 'errorTooltipPlace'> & {
        /** the text input value. */
        value?: any;
        /** Indicates the input should only allow number. */
        onlyAllowNumbers?: boolean;
        /** Optional CSS class for the input container element. */
        containerClass?: string;
        /** Optional number of milliseconds before closing tooltip (defaults to zero so tooltip won't close). */
        autoDestroy?: CommonInputErrorTooltipArgs['errorDuration'];
        /** Indicates whether to show an error icon when there is an error message. */
        showErrorIcon?: boolean;
        /** Optional input type (defaults to "text"). */
        type?: string;
        /** Called when the users presses the ESC key. */
        'escape-press'?: (v: string, e: KeyboardEvent) => any;
        /** Called on user key down. */
        'key-down'?: (v: string, e: KeyboardEvent) => any;
        /** Called on user key up. */
        'key-up'?: (v: string, e: KeyboardEvent) => any;
        /** Called when the user changes the input value. */
        'value-change'?: (internalValue?: string, value?: string) => any;
        /** Called when teh user presses the enter key. */
        enter?: (v?: string) => any;
        /** Called on user key down, with no debounce delay. */
        'key-down-non-debounced'?: (v: string, e: KeyboardEvent) => any;
        /** Called on user key up, with no debounce delay. */
        'key-up-non-debounced'?: (v: string, e: KeyboardEvent) => any;
        /** Called when the input receives focus. */
        'on-focus-in'?: (v: string, e: FocusEvent) => any;
        /** Called when the input loses focus. */
        'on-focus-out'?: (v: string, e: FocusEvent) => any;
        /** Action that get's called when component is created */
        didInsert?: () => void;
        /** Action that get's called when component is destroyed */
        willDestroy?: () => void;
    };
    Blocks: {
        /** Renders above the input element, and yields the current value as well as an action for clearing the input. */
        default: [string | undefined, VoidFunction];
        /** Renders below the input element, and yields the current value as well as an action for clearing the input. */
        'after-input': [string | undefined, VoidFunction];
    };
}

/**
 * Debounce delay for triggering typing actions.
 */
const DEBOUNCE_ACTION_DELAY = 200;

/**
 * Triggers a value change action.
 *
 * @private
 */
function valueChangeTrigger(this: TextInput): void {
    if (isDestroyed(this)) {
        return;
    }

    const { internalValue } = this;
    const { value } = this.args;

    const onValueChange = this.args['value-change'];

    // Send value change action up the chain
    if (onValueChange) {
        onValueChange(internalValue, value);
    }
}

/**
 * Triggers the key-up action.
 *
 * @private
 */
function keyUpTrigger(this: TextInput, value: string, e: KeyboardEvent): void {
    const onKeyUp = this.args['key-up'];

    // Send value change action up the chain
    if (onKeyUp) {
        onKeyUp(value, e);
    }
}

/**
 * Triggers the key-down action.
 *
 * @private
 */
function keyDownTrigger(this: TextInput, value: string, e: KeyboardEvent): void {
    const onKeyDown = this.args['key-down'];

    // Send value change action up the chain
    if (onKeyDown) {
        onKeyDown(value, e);
    }
}

/**
 * Toggles the error message visibility.
 *
 * @private
 */
function toggleErrorMessageVisibility(this: TextInput, errorMessage?: string): void {
    if (!isDestroyed(this)) {
        this.hideErrorMessage = !errorMessage;
    }
}

/**
 * Restricts input for the given input to the given filter function.
 * @note This handles drag and drop, copy/paste, typing, etc.
 *
 * @private
 */
function setInputFilter(this: TextInput, textInput: HTMLInputElement, inputFilter: (v: string) => boolean) {
    type TextInputElement = HTMLInputElement & {
        oldValue: string;
        oldSelectionStart: number;
        oldSelectionEnd: number;
    };

    ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach((event) => {
        this.dom.addListener(this, textInput, event, function (e: InputEvent & { target: TextInputElement }) {
            const input = e.target;
            if (inputFilter(input.value)) {
                Object.assign(input, {
                    oldValue: input.value,
                    oldSelectionStart: input.selectionStart,
                    oldSelectionEnd: input.selectionEnd
                });
            } else if (Object.prototype.hasOwnProperty.call(input, 'oldValue')) {
                input.value = input.oldValue;
                input.setSelectionRange(input.oldSelectionStart, input.oldSelectionEnd);
            } else {
                input.value = '';
            }
        });
    });
}

/**
 * Component that wraps input text element in an ADC specific instance so that we can utilize all the styles and positioning.
 */
export default class TextInput extends Component<TextInputSignature> {
    @service declare dom: DomService;

    constructor(owner: unknown, args: TextInputSignature['Args']) {
        super(owner, args);

        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        scheduleOnce('afterRender', this, this.onExternalValueChange);
    }

    /**
     * The internal value of the input.
     */
    @tracked internalValue?: string;

    /**
     * Should the error message be hidden?
     */
    @tracked hideErrorMessage = false;

    /**
     * The value of the maxLength attribute on the input element.
     */
    @tracked maxLength = -1;

    /**
     * The error message for the current input value.
     */
    get resolvedErrorMessage(): string | undefined {
        return this.hideErrorMessage ? '' : this.args.errorMessage;
    }

    /**
     * Converts the autoDestroy value to be used as the ErrorTooltip components duration property.
     */
    @computed('args.autoDestroy')
    get errorDuration(): number | undefined {
        return convertAutoDestroyToDuration(this.args.autoDestroy);
    }

    /**
     * Reacts on changes in text box value change.
     */
    updateInternalValue(value: string, sendUpdate = true): void {
        const { internalValue, maxLength } = this;

        if (internalValue === value) {
            return;
        }

        // If the input element has a maxLength attribute set,
        // then do not allow the input to exceed the character limit.
        // The Android Mobile App does not use the maxLength attribute.
        if (maxLength > 0 && value.length > maxLength) {
            value = value.slice(0, maxLength);
        }

        this.internalValue = value;

        // Clear any possible error messages
        toggleErrorMessageVisibility.call(this);

        // If there is a value change action mapped, then trigger debounced call
        if (sendUpdate && this.args['value-change']) {
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            debounce<TextInput, typeof valueChangeTrigger>(this, valueChangeTrigger, DEBOUNCE_ACTION_DELAY);
        }
    }

    // region actions

    /**
     * Reacts on changes in value passed into the addon.
     */
    @action onExternalValueChange(): void {
        // Set internal value to be a copy of the passed in value
        let stringValue = '';
        const { value } = this.args;

        if (value || value === 0 || typeof value === 'boolean') {
            stringValue = String(value);
        }

        if (this.args.onlyAllowNumbers) {
            stringValue = /^-?\d*$/.test(stringValue) ? stringValue : '';
        }

        this.updateInternalValue(stringValue, false);
    }

    /**
     * Reacts to the input value changes.
     */
    @action handleInputChange(e: InputEvent & { target: HTMLInputElement }): void {
        this.updateInternalValue(e.target.value);
    }

    /**
     * Clears the error message, which in turn hides the tooltip and ads the listeners.
     */
    @action onComponentInsert(element: HTMLDivElement): void {
        toggleErrorMessageVisibility.call(this);

        const input = element.querySelector<HTMLInputElement>('.ember-text-field');

        if (!input) {
            return;
        }

        this.maxLength = input.maxLength;

        if (this.args.onlyAllowNumbers) {
            setInputFilter.call(this, input, function (value: string) {
                return /^-?\d*$/.test(value);
            });
        }
    }

    /**
     * Triggers a non-debounced keydown action.
     *
     * <strong>WARNING:</strong> Non debounced, bind to it only if absolutely necessary.
     */
    @action onKeyDownNonDebounced(currentValue: string, event: KeyboardEvent): void {
        const onKeyDownNonDebounced = this.args['key-down-non-debounced'];

        // Trigger non-debounced action
        if (onKeyDownNonDebounced) {
            onKeyDownNonDebounced(currentValue, event);
        }

        // Trigger debounced action
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        debounce<TextInput, typeof keyDownTrigger>(this, keyDownTrigger, currentValue, event, DEBOUNCE_ACTION_DELAY);
    }

    /**
     * Triggers a non-debounced keyup action.
     *
     * <strong>WARNING:</strong> Non debounced, bind to it only if absolutely necessary.
     */
    @action onKeyUpNonDebounced(currentValue: string, event: KeyboardEvent): void {
        const onKeyUpNonDebounced = this.args['key-up-non-debounced'];

        // Trigger non-debounced action
        if (onKeyUpNonDebounced) {
            onKeyUpNonDebounced(currentValue, event);
        }

        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        debounce<TextInput, typeof keyUpTrigger>(this, keyUpTrigger, currentValue, event, DEBOUNCE_ACTION_DELAY);
    }

    /**
     * Clears the input value.
     */
    @action clearInput(): void {
        this.updateInternalValue('');

        // Trigger an enter action to update the search, if an enter action is provided.
        if (this.args.enter) {
            this.args.enter('');
        }
    }

    /**
     * Triggers when the error message changes.
     */
    @action onErrorMessageChange(): void {
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        scheduleOnce<TextInput, typeof toggleErrorMessageVisibility>(
            'afterRender',
            this,
            toggleErrorMessageVisibility,
            this.args.errorMessage
        );
    }

    // endregion
}
