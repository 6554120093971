import Service, { inject as service } from '@ember/service';
import { getWebsocketMessage } from '../websockets/messages/utils.ts';
import { GET } from '@adc/ajax/services/adc-ajax';
import { later } from '@ember/runloop';
import {
    NormalClosure,
    EndpointUnavailable as Establish,
    PolicyViolation as RejectedToken
} from '../enums/Websockets.ts';
import { A } from '@ember/array';

import type Store from '@ember-data/store';
import type EnvSettingsService from './env-settings.ts';
import type AjaxService from '@adc/ajax/services/adc-ajax';
import type BaseWebsocketMessageHandler from '../websockets/base-handler.ts';

export interface IncomingMessage {
    UnitId: string;
    DeviceId: string;
    [key: string]: string;
}

/**
 * Event Code for mock-socket stopping
 */
const TestClosure = 0;

/**
 * @classdesc
 *
 * Websocket service for Real Time Updates
 * Handles relaying websocket messages to message handlers for updating device state in data stores
 */
export default class WebsocketService extends Service {
    @service declare store: Store;
    @service declare ajax: AjaxService;
    @service declare envSettings: EnvSettingsService;

    /**
     * A websocket instance used for connecting to the server
     */
    socket?: WebSocket;

    /**
     * Endpoint address for websocket service
     */
    endpoint?: string;

    /**
     * Authentication token for websocket service
     */
    authToken?: string;

    /**
     * Keeps track of the number of reconnection attempts so the reconnection timeout can be delayed
     * exponentially the longer the websocket server is offline.
     */
    reconnectionAttempts = 0;

    /**
     * A list of message handlers that will be passed messages as they are received.
     */
    messageHandlers: BaseWebsocketMessageHandler[] = [];

    /**
     * Exponential Backoff Delay
     *
     * Implements exponential backoff delay to randomly space out reconnection attempts between many connected clients
     * so that they don't all hammer the server by using the same predefined set of timeouts.
     */
    static getReconnectionTimeout(attempts: number): number {
        const ceiling = 50,
            initialMax = 10,
            exponentialBase = 1.5,
            exponentialDelay = 5,
            max = Math.min(Math.pow(exponentialBase, attempts - exponentialDelay) + initialMax, ceiling),
            min = 5,
            range = max - min;

        // Max here so that we always stay at least above minimum timeout, if somehow the exponential results below that.
        return Math.floor(Math.max(Math.random() * range + min, min) * 1000);
    }

    /**
     * Called to start the websocket service.
     */
    async start(messageHandlers: { new (owner: WebsocketService): BaseWebsocketMessageHandler }[]): Promise<void> {
        // Verify that websockets are supported by browser
        if (!window.WebSocket) {
            return;
        }

        this.messageHandlers = messageHandlers.map((C) => new C(this));
        // Open Websocket Connection
        await this.establishConnection(Establish);
    }

    /**
     * In event of abrupt closure attempt to reconnect the websocket every 15 seconds until limit extends time or
     * websocket connection is re-established
     */
    async establishConnection(eventCode: number): Promise<void> {
        // Destroy websocket object to prevent collecting multiple websockets in memory
        if (this.socket) {
            this.socket.close(NormalClosure);
        }

        // Clean close, stop trying to connect
        if (eventCode === NormalClosure || eventCode === TestClosure) {
            return;
        }

        // Get a new token if rejected with code 1008
        if (eventCode === RejectedToken) {
            this.authToken = undefined;
        }

        await this.socketConnect();
    }

    /**
     * Connect the socket and if connection is valid, assign message and onclose handlers
     */
    async socketConnect(): Promise<void> {
        // Make sure we have a valid token
        if (!this.authToken) {
            if (!(await this.retrieveToken())) {
                return;
            }
        }

        this.socket = new WebSocket(this.endpoint + '?f=1&auth=' + this.authToken);

        this.socket.addEventListener('open', () => {
            this.reconnectionAttempts = 0;
        });

        this.socket.addEventListener('close', (event) => {
            this.reconnectionAttempts++;

            const delay = WebsocketService.getReconnectionTimeout(this.reconnectionAttempts);

            // This is breaking unit tests for some reason without the try catch...
            try {
                if (!this.envSettings.isTestEnvironment()) {
                    // Do not copy this deprecated usage. If you see this, please fix it
                    // eslint-disable-next-line ember/no-runloop
                    later(this, this.establishConnection, event.code, delay);
                }
                // eslint-disable-next-line no-empty
            } catch {}
        });

        this.socket.addEventListener('message', (event) => {
            this.handleMessage(event);
        });
    }

    /**
     * Retrieves JWT token to authenticate against Websocket Server
     */
    private async retrieveToken(): Promise<boolean> {
        try {
            const tokenResponse = await this.ajax.apiRequest<{
                value: string;
                metaData: {
                    endpoint: string;
                };
            }>('websockets/token', undefined, null, GET);

            this.authToken = tokenResponse.value;
            this.endpoint = tokenResponse.metaData.endpoint;
        } catch {
            return false;
        }

        return this.authToken?.length !== 0 && this.endpoint?.length !== 0;
    }

    /**
     * Translates raw data into an internal websocket message, hands message to message handlers
     */
    handleMessage(event: MessageEvent): void {
        let data: IncomingMessage = { UnitId: '', DeviceId: '' };

        // Parse JSON data packet, don't explode for malformed JSON
        try {
            data = JSON.parse(event.data);
        } catch {
            // Do nothing, malformed JSON
            return;
        }

        /**
         * Internal websocket message format for message handlers
         */
        const message = getWebsocketMessage(data);

        if (message) {
            const handlers = A(this.messageHandlers.filter((handler) => handler.willHandle(message)));

            // Let each message handler attempt to process the message.
            handlers.invoke('process', message);
        }
    }
}
