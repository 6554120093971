import RouteView from '../route-view.js';

/**
 * @classdesc
 * Root component for all "model details" routes.
 */
export default class DetailsView extends RouteView {
    /** @override */
    useRouteViewClass = true;

    /** @override */
    containerMaxWidth = 'md';

    /**
     * Optional definition for a delete model button.
     *
     * @type {components.route-view.content.DeleteModel.DeleteModelType|undefined}
     */
    deleteModel;
}
