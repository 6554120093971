/**
 * Utility helper module for debugging.
 *
 * @module
 */

/**
 * Logs an error indicating that the supplied context Object <strong>must</strong> implement the supplied member.
 *
 * @param context The Ember object (route, controller, component, etc) that should implement the abstract member.
 * @param memberName The name of the missing abstract member.
 *
 * @deprecated Please use TypeScript and make methods abstract instead.
 */
export function logMissingAbstractMember(context: { toString(): string }, memberName: string): void {
    console.error(`${context.toString()} must implement ${memberName}.`);
}
