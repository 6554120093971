import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { logMissingAbstractMember } from '@adc/ember-utils/utils/debug';

import type BaseRoute from '../routes/base-route';
import type EnvSettingsService from './env-settings.ts';
import type RouterService from '@ember/routing/router-service';

export interface RouteItem {
    pageTitle: string;
    url: string;
    isNative: boolean;
    color: string;
    icon: string;
    isNavigationItem: boolean;
    isHiddenFromNavigation: boolean;
    showOnlyIfChildRoutesExist: boolean;
}

export interface RouteData {
    routeItem: RouteItem;
    routeName: string;
    queryParams?: Record<string, unknown>;
    route?: BaseRoute;
    pageTitle: string;
    isWizard?: boolean;
}

/**
 * @classdesc
 * The base context manager service which can be inherited and extended by consuming applications.
 *
 * @abstract
 */
export default class ContextManager extends Service {
    @service declare router: RouterService;
    @service declare envSettings: EnvSettingsService;

    declare isAdcInternalDevDealer: boolean;

    /**
     * Sets the new route data for a transition.
     */ // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setCurrentRouteData(_routeData: RouteData, _wasReplaceTransition: boolean): void {
        logMissingAbstractMember(this, 'setCurrentRouteData');
    }

    /**
     * Sets whether the current route is currently loading data.
     */ // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setIsLoading(_isLoading: boolean): void {
        logMissingAbstractMember(this, 'setIsLoading');
    }

    /**
     * Logout logged in user from the current session.
     *
     * @param {Boolean} useReturnUrl
     */ // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logout(_useReturnUrl: boolean): void {
        logMissingAbstractMember(this, 'logout');
    }

    /**
     * Returns the route item matching the specified routeId, or undefined if not found.
     */ // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getRouteItem(_routeId: number): RouteItem {
        logMissingAbstractMember(this, 'getRouteItem');
        return {} as RouteItem;
    }

    /**
     * Redirect to an external URL.
     */ // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transitionToUrl(_url: string, _replaceUrl = false): void {
        logMissingAbstractMember(this, 'transitionToUrl');
    }

    /**
     * Resets the visited routes stack.
     */
    resetVisitedRoutesStack(): RouteItem[] {
        return [] as RouteItem[];
    }

    /**
     * Transitions to the previous route on the stack. Functions as the "Back" button.
     *
     */ // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    transitionToPreviousRoute(_defaultPageIfStackIsEmpty?: number): void {}

    /**
     * Gets the length of the visited routes array.
     */
    getVisitedRoutesStackLength(): number {
        return -1;
    }

    isSystemInstall(): boolean {
        return this.envSettings.isTestEnvironment()
            ? !(
                  this.router.currentURL.includes('login-information') ||
                  this.router.currentURL.includes('account-management/login-setup')
              )
            : this.router.rootURL.includes('system-install');
    }
}
