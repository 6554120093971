import { guidFor } from '@ember/object/internals';

export default function guidForFn(context: any): string {
    return guidFor(context);
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Returns a unique ID for tied to the passed instance.
         *
         * @example <dd>{{guid-for this}}</dd>
         */
        'guid-for': typeof guidForFn;
    }
}
