import Cookie from './cookie';
import SameSite from './samesite-enum';

export default class EssentialCookie extends Cookie {
    constructor(name: string, value?: string, expirationDate?: Date, sameSite?: SameSite, domain?: string) {
        super('adc_e', name, value, expirationDate, sameSite, domain);
    }

    /**
     * Is user accepting cookies?
     */
    isAllowed(): boolean {
        return true;
    }
}
