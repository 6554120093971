import Component from '@glimmer/component';
import { assert } from '@ember/debug';
import { getSimpleButtonStyle } from './simple.ts';

import type { ButtonSimpleSignature } from './simple';
import type { ButtonIconSignature } from './icon';

type ButtonSimpleSignatureArgs = ButtonSimpleSignature['Args'];
type ButtonIconSignatureArgs = ButtonIconSignature['Args'];

interface ButtonSelectedSimpleArgs extends ButtonSimpleSignatureArgs {
    /** Indicates the button should show it's selected state. */
    isSelected: boolean;
    icon?: undefined;
    iconOnly?: undefined;
}

interface ButtonSelectedIconArgs extends ButtonIconSignatureArgs {
    /** Indicates the button should show it's selected state. */
    isSelected: boolean;
}

export interface ButtonSelectedSignature {
    Element: ButtonSimpleSignature['Element'];
    Args: ButtonSelectedIconArgs | ButtonSelectedSimpleArgs;
    Blocks: {
        default: [];
    };
}

export default class ButtonSelected extends Component<ButtonSelectedSignature> {
    constructor(owner: unknown, args: ButtonSelectedSignature['Args']) {
        super(owner, args);

        const fnTestArg = (type: string, value: string, src = '') => {
            assert(
                `[@adc/ui-components] The ${type} is not supported by the <Button::Selected /> component`,
                !src.split(' ').includes(value)
            );
        };

        fnTestArg('"link" @buttonStyle', 'link', this.args.buttonStyle);
        fnTestArg('"stealth" @buttonColor', 'stealth', this.args.buttonColor);
    }

    /**
     * Removes non-selected buttons styles.
     */
    get simpleButtonStyle(): ReturnType<typeof getSimpleButtonStyle> {
        return getSimpleButtonStyle(this.args.buttonStyle);
    }
}
