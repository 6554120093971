import EmberObject from '@ember/object';
import { assert } from '@ember/debug';

/**
 * ADC route-view component initializer for injecting property values.
 *
 * @param {Ember.Application} application
 */
export function initialize(application) {
    class AbstractNavigationApi extends EmberObject {
        getVisitedRoutesStackLength() {
            assert('[@adc/ui-components] RouteView component missing navigationApi', false);
            return 0;
        }

        transitionToPreviousRoute() {
            assert('[@adc/ui-components] RouteView component missing navigationApi', false);
        }
    }

    application.register('route-view:main', AbstractNavigationApi, {
        singleton: true
    });
}

export default {
    name: 'setup-route-view',
    initialize
};
