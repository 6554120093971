import BaseAdapter from './base-adapter.ts';

/**
 * @class adapters.JsonApiContentAdapter
 */
export default class BaseJsonApiContentAdapter extends BaseAdapter {
    /** @override */
    get headers() {
        return {
            ...super.headers,
            'content-type': 'application/vnd.api+json'
        };
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
    export default interface AdapterRegistry {
        'base-json-api-content-adapter': BaseJsonApiContentAdapter;
    }
}
