import { alias } from '@ember/object/computed';
import DropdownActionItem from './dropdown-action-item.js';

/**
 * The name of the DeleteActionItem icon.
 *
 * @static
 * @type {String}
 */
const DELETE_ICON = 'trash';

/**
 * The name of the class used by default to style DeleteActionItem.
 *
 * @static
 * @type {String}
 */
const DELETE_CSS = 'delete-action';

/**
 * The name of the EditActionItem icon.
 *
 * @static
 * @type {String}
 */
const EDIT_ICON = 'edit';

/**
 * An action item with an associated click action for the row.
 *
 * @typedef {Object} ActionItem
 * @extends DropdownActionItem
 *
 * @property {String} text - The text to display next to the icon.
 * @property {String} type - The type of the action item.
 * @property {boolean} visible - Determines if the item is visible.
 */
export const ActionItem = DropdownActionItem.extend({
    /**
     * The text to display next to the icon.
     *
     * @type {string}
     */
    text: '',

    /**
     * Whether or not the item should be visible.
     *
     * @type {boolean}
     */
    visible: true,

    /**
     * Sync the text property with DropdownActionItem's name property
     *
     * @type {string}
     */
    name: alias('text')
});

/**
 * Delete action item.
 *
 * @typedef {Object} DeleteActionItem
 * @extends ActionItem
 */
export const DeleteActionItem = ActionItem.extend({
    /**
     * The name of the icon.
     *
     * @type {string}
     */
    icon: DELETE_ICON,

    /**
     * The name of the class used to style the item.
     *
     * @type {string}
     */
    className: DELETE_CSS
});

/**
 * Edit action item.
 *
 * @typedef {Object} EditActionItem
 * @extends ActionItem
 */
export const EditActionItem = ActionItem.extend({
    /**
     * The name of the icon.
     *
     * @type {string}
     */
    icon: EDIT_ICON
});

export default ActionItem;
