import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';

export interface ElementHostSignature {
    Args: {
        /** A valid CSS selector to the element that should host the yielded content. */
        hostSelector: string;
    };
    Blocks: {
        default: [];
    };
}

export default class ElementHost extends Component<ElementHostSignature> {
    @tracked isReady = false;

    /**
     * Returns the target host element.
     */
    get hostEl(): HTMLElement {
        return document.querySelector(this.args.hostSelector)!;
    }

    @action scheduleRender(): void {
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        scheduleOnce('afterRender', this, this.updateIsReady);
    }

    private updateIsReady(): void {
        this.isReady = true;
    }
}
