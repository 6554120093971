import BaseTimezoneControl from './base-timezone-control.ts';

import type { BaseTimezoneControlSignature } from './base-timezone-control';

export interface BaseTimeControlSignature {
    Args: BaseTimezoneControlSignature['Args'] & {
        /** The input date/time value. */
        date?: Date;
        /** Optional minimum input date. */
        minDate?: Date;
        /** Optional maximum input date. */
        maxDate?: Date;
        /** Optional label text to appear next to input. */
        label?: string;
        /** Called when the user modifies the date/time value. */
        onchange: (date: Date) => void;
    };
}

/**
 * @classdesc
 * A base class for date and time pickers.
 */
export default class BaseTimeControl<
    T extends BaseTimeControlSignature = BaseTimeControlSignature
> extends BaseTimezoneControl<T> {
    /**
     * Indicates we should display native controls for time/date manipulation.
     */
    useNativeControl = false;

    /**
     * The passed date, adjusted for computed timezone.
     */
    get zonedDate(): Date | undefined {
        const { date } = this.args;
        return date && this.getZonedDate(date);
    }
}
