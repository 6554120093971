import RouteView from '../route-view.js';

/**
 * @classdesc
 * Root component for all "list" routes.
 */
export default class ListView extends RouteView {
    /** @override */
    useRouteViewClass = true;
}
