import BaseSchedule from './views/base-schedule.ts';
import { computed } from '@ember/object';

import type { BaseScheduleSignature } from './views/base-schedule';

export interface ScheduleTypeViewSignature extends BaseScheduleSignature {
    Args: BaseScheduleSignature['Args'] & {
        /** The text to display when the timetable is 24/7. */
        allTimesText?: string;
        /** The text to display when the timetable is empty. */
        emptyScheduleText?: string;
        /** The text to display when the timetable is neither all times nor empty. */
        customScheduleText?: string;
        // Temporary Access Plan timetable with start/end times when timetable takes affect.
        temporaryAccessPlanScheduleText?: string;
    };
}

/**
 * Displays the type of schedule. (No Schedule, All Times, Custom)
 */
export default class ScheduleTypeView extends BaseSchedule<ScheduleTypeViewSignature> {
    /**
     * Is the schedule empty?
     */
    @computed('planSchedules.[]')
    get isScheduleEmpty(): boolean {
        return (this.planSchedules ?? []).every((planSchedule) => !planSchedule.combinedSchedules.length);
    }

    @computed('args.{allTimesText,emptyScheduleText,customScheduleText}', 'isScheduleEmpty', 'isScheduleAllTimes')
    get scheduleTypeText(): string | undefined {
        const { args } = this;

        if (args.temporaryAccessPlanScheduleText) {
            return args.temporaryAccessPlanScheduleText;
        }

        if (this.isScheduleAllTimes) {
            return args.allTimesText;
        }

        if (this.isScheduleEmpty) {
            return args.emptyScheduleText;
        }

        return args.customScheduleText;
    }
}
