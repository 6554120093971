import BaseWebsocketMessage, { WebsocketMessageType } from './base.ts';

/**
 * @classdesc
 * Message class for SerializedStatusUpdate websocket message
 */
export default class StatusUpdateWebsocketMessage extends BaseWebsocketMessage {
    constructor(unitId: string, deviceId: string, newState: string, flagMask: string) {
        super(unitId, deviceId);

        Object.assign(this, {
            value: newState,
            subvalue: flagMask
        });
    }

    type = WebsocketMessageType.statusUpdate;
}
