export default function toString(v: any): string {
    return String(v);
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Converts the passed value to a string.
         */
        'to-string': typeof toString;
    }
}
