import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { once } from '@ember/runloop';

import type { SafeString } from 'handlebars';

// How many pixels the container should scroll when the 'Scroll' button is clicked
const DEFAULT_SCROLL_CLICK_AMOUNT = 100;

interface ScrollPositionContainerSignature {
    Element: HTMLDivElement;
    Args: {
        /** The text to display.  NOTE this text cannot have user input as it will be marked safe, so will not be sanitized during render. */
        documentText: string | SafeString;
        /** The text content for the "scroll" button overlay. */
        buttonText: string;
        /** If the container should scroll to the end of the container when the "scroll" button overlay is clicked or a few lines on each click. */
        scrollToBottom?: boolean;
        /** How many pixels should be scrolled when the "scroll" button overlay is clicked if `scrollToBottom = false`. */
        scrollAmount?: number;
        /** Indicates the scroll button overlay should be visible. */
        useButton?: boolean;
        /** Indicates the scroll button overlay should reappear when scrolling up if the bottom of the container was already reached. */
        displayButtonOnScrollUp?: boolean;
        /** Optional `aria-label` text. */
        ariaLabelText?: string;
        /** ?Triggered when the bottom of the container is scrolled to or if the container doesn't have a scrollbar, the action will be called instantaneously. */
        scrollCompletedAction: () => void;
    };
    Blocks: {
        default: [];
    };
}

/**
 * The amount of padding being used for the component.
 */
const PADDING = 55;

/**
 * @classdesc
 *
 * The scroll position container forces the user to scroll (if available)
 * to the end of the container before continuing. There is also a "scroll"
 * button included which doubles as a visual aid that scrolling is needed to
 * continue and as a button to scroll.
 */
export default class ScrollPositionContainer extends Component<ScrollPositionContainerSignature> {
    /**
     * Tracks when the scroll was completed.
     */
    @tracked
    isScrollComplete = false;

    /**
     * If the scroll completed action was already called. Is useful if displayButtonOnScrollUp
     * argument is being used so multiple calls to the action aren't preformed.
     */
    wasActionCalled = false;

    /**
     * The id tied to the parent container element.
     */
    containerId = guidFor(this);

    /**
     * If the scroll button should be displayed.
     */
    @computed('isScrollComplete', 'args.useButton')
    get isScrollButtonVisible(): boolean {
        // Scrolled to the bottom of the container or the scroll button overlay isn't being used.
        return this.isScrollComplete ? false : this.args.useButton ?? true;
    }

    /**
     * Handles when the document text is updated and resets the necessary values
     * to calculate when the end is reached for the new text.
     */
    @action
    initScroll(el: HTMLElement): void {
        const parent = el.parentElement;
        if (parent) {
            // Scroll to the top of the document container when the text changes or the page loads.
            parent.scrollTop = 0;
            this.isScrollComplete = false;

            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            once(this, this.checkScrollComplete, parent);
        }
    }

    /**
     * Handles when the window is resized to recalculate the button overlay
     * position and if it should still be visible.
     */
    @action
    handleResize(el: HTMLElement): void {
        this.isScrollComplete = false;
        if (el.firstElementChild) {
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            once(this, this.checkScrollComplete, el.firstElementChild);
        }
    }

    /**
     * Handles the scroll events that are triggered and determines if the end of the
     * container has been reached.
     */
    @action
    handleScroll(event: WheelEvent & { target: HTMLElement }): void {
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        once(this, this.checkScrollComplete, event.target);
    }

    /**
     * Allows the container to be scrolled by clicking on the 'scroll' button.
     */
    @action
    clickScrollButton(): void {
        // Get the correct element from the passed in event target.
        const el = document.getElementById(this.containerId)?.querySelector('.scroll-position-container-text');
        el?.scrollTo({
            top:
                this.args.scrollToBottom ?? true
                    ? el.scrollHeight
                    : this.args.scrollAmount ?? DEFAULT_SCROLL_CLICK_AMOUNT,
            behavior: 'smooth'
        });
    }

    /**
     * Handles calling the action only if allowed based on if the end
     * of the scrolling document container was reached for the first time.
     */
    private checkScrollComplete(el: Element): void {
        const { scrollTop, clientHeight, scrollHeight } = el;
        const parent = el.closest('.scroll-position-container');
        if (parent) {
            parent.classList.toggle('has-list-indicator', scrollHeight !== clientHeight);
            parent.classList.toggle('is-scrolled', !!scrollTop);
        }

        if (this.args.displayButtonOnScrollUp || !this.isScrollComplete) {
            // Update isScrollComplete and notify consumer if it is scrolled.
            if (
                (this.isScrollComplete =
                    scrollHeight - clientHeight - scrollTop < PADDING && this.args.documentText !== '')
            ) {
                if (!this.wasActionCalled) {
                    // Do not copy this deprecated usage. If you see this, please fix it
                    // eslint-disable-next-line ember/no-runloop
                    once(this, this.args.scrollCompletedAction);
                    this.wasActionCalled = true;
                }
            }
        }
    }
}
