import { isArrowDownCode, isArrowCode, isArrowLeftCode, isArrowUpCode } from '@adc/ember-utils/utils/a11y';
import { guidFor } from '@ember/object/internals';

import type DomService from '@adc/ember-utils/services/dom';

interface Coordinates {
    clientX: number;
    clientY: number;
}

/**
 * The picker thumb size (height and width).
 */
export const PICKER_THUMB_SIZE = 24;

export const LEFT_MOUSE_BUTTON_CODE = 1;

/**
 * Cancels the propagation of the event.
 */
export function cancelEvent(e: Event): void {
    e.stopPropagation();
    e.stopImmediatePropagation();
    if (!(e instanceof TouchEvent)) {
        e.preventDefault();
    }
}

/**
 * Get coordinates for the picker selector
 */
function getSelectorCoordinates(element: HTMLElement): Coordinates {
    const { left, top, width, height } = element.getBoundingClientRect();

    return {
        clientX: left + width / 2,
        clientY: top + height / 2
    };
}

/**
 * Determines the normalized coordinates of the event relative to the picker interactive surface.
 * After normalization, the coordinates will have a fractional value between 0 and 1.
 */
export function getNormalizedEventCoordinates(
    this: { width: number; height?: number },
    e: KeyboardEvent & { clientX: number; clientY: number }
): Array<number> {
    // Get the event coordinates.
    let { clientX, clientY } = e;
    const el = document.getElementById(guidFor(this)) as HTMLElement;

    // Cache document scroll, position/size of element (adjusted for scroll) and keyboard event code.
    const picker = el.querySelector('.picker') as HTMLElement;
    const { top, left } = picker.getBoundingClientRect(),
        { width, height } = this,
        { code } = e;

    if (isArrowCode(code)) {
        const selector = el.querySelector('.selector') as HTMLElement;
        const selectorCoordinates = getSelectorCoordinates(selector),
            isUp = isArrowUpCode(code),
            step = isUp || isArrowLeftCode(code) ? -5 : 5;

        clientX = selectorCoordinates.clientX;
        clientY = selectorCoordinates.clientY;

        // Is this a vertical arrow?
        if (isUp || isArrowDownCode(code)) {
            clientY += step;
        } else {
            clientX += step;
        }
    }

    // Normalize the X and Y coordinates. This outputs values between 0 and 1.
    return [
        [clientX, left, width],
        [clientY, top, height ?? 0]
    ].map(([coordinate, base, dimension]) => Math.max(Math.min((coordinate - base) / dimension, 0.99), 0.01));
}

/**
 * Validates the event, acquires the coordinates, trigger the color update.
 */
function handlePickerInteractionEvent(e: TouchEvent): void {
    // Cancel the event and check for touches.
    cancelEvent(e);

    const { touches } = e;

    // Was this a touch event?
    if (touches !== undefined) {
        // Use the first touch event.
        const touch = Array.from(touches || []).pop();

        // Is there no touch?
        if (!touch) {
            // The touchend event doesn't have any touches, so there is nothing to do.
            return;
        }
    }
}

/**
 * Handles the mouseDown and touchStart events.
 */
export function processEvent(this: { isBusy: boolean; dom: DomService }, e: MouseEvent | TouchEvent): void {
    const { body } = window.document,
        isTouchEvent = e instanceof TouchEvent && e.touches;

    // Set the busy flag.
    this.isBusy = true;

    // Add move and up listeners.
    this.dom.addListener(
        this,
        body,
        `${isTouchEvent ? 'touch' : 'mouse'}move`,
        handlePickerInteractionEvent.bind(this)
    );
    this.dom.addListener(this, body, `${isTouchEvent ? 'touchend' : 'mouseup'}`, (e: TouchEvent) => {
        // Clear the listeners and busy status.
        this.isBusy = false;
        this.dom.removeAllListeners(this);

        // Handle the event to update the color.
        handlePickerInteractionEvent.call(this, e);
    });
}
