import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';
import { computed, action } from '@ember/object';
import { A } from '@ember/array';
import {
    PICKER_THUMB_SIZE,
    LEFT_MOUSE_BUTTON_CODE,
    getNormalizedEventCoordinates,
    processEvent
} from '../../utils/color-pickers/common.ts';
import {
    fnGradient,
    TEMPERATURES_GRADIENT_VALUE,
    hslFromPercentWarmth,
    getHSLCss
} from '../../utils/color-pickers/color.ts';
import { BinaryListItem } from '../simple-binary/list.ts';
import { isArrowCode } from '@adc/ember-utils/utils/a11y';

import type { SafeString } from 'handlebars';
import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type DomService from '@adc/ember-utils/services/dom';
import type { PresetItemProps } from './presets-picker.ts';
import type { Warmth } from '../../utils/color-pickers/color.ts';

/**
 * Temperature presets configuration.
 */
const defaultPresets = [
    ['warmWhite', 0],
    ['softWhite', 30],
    ['dayWhite', 70],
    ['coolWhite', 100]
].map(([label, percentWarmth]: [string, Warmth], idx) => ({
    id: idx + 1,
    labelName: `@adc/ui-components.${label}`,
    color: {
        ...hslFromPercentWarmth(percentWarmth),
        percentWarmth
    }
}));

export interface TemperaturePickerSignature {
    Element: HTMLDivElement;
    Args: {
        /** Optional preset picker width, in pixels (defaults to 216). */
        width?: number;
        /** Optional preset picker height, in pixels (defaults to 216). */
        height?: number;
        /** Current warmth percentage. */
        percentWarmth?: Warmth;
        /** Triggered when the user selects a new temperature. */
        'on-select': (item: { percentWarmth?: Warmth }) => void;
    };
}

/**
 * @classdesc
 * Adds support for picking a color temperature. Outputs both the HSL color and the percentWarmth percentage value [0, 100].
 */
export default class TemperaturePickerComponent extends Component<TemperaturePickerSignature> {
    @service declare intl: ADCIntlService;
    @service declare dom: DomService;

    // region Properties

    @tracked isBusy = false;

    /**
     * The picker width, in pixels.
     */
    @computed('args.width')
    get width(): number {
        return this.args.width ?? 216;
    }

    /**
     * The warmth percent.
     */
    @computed('args.percentWarmth')
    get percentWarmth(): number {
        return this.args.percentWarmth ?? 70;
    }

    // endregion

    // region Component state

    /**
     * Whether or not the presets tab is open.
     */
    @tracked isPresetsSelected = true;

    // endregion

    // region Computed Properties

    /**
     * The collection of color temperature preset items.
     */
    @computed('args.percentWarmth')
    get presetItems(): BinaryListItem<PresetItemProps>[] {
        const { percentWarmth } = this;

        return A(
            defaultPresets.map((config) => {
                const { labelName, color } = config;

                return new BinaryListItem<PresetItemProps>({
                    label: this.intl.t(labelName),
                    state: color.percentWarmth === percentWarmth,
                    props: {
                        ...config,
                        backgroundStyle: htmlSafe(`background-color: ${getHSLCss(color)}`)
                    }
                });
            })
        ).toArray();
    }

    /**
     * The adjusted height of the picker. We subtract from the original height to exclude the tab buttons height.
     */
    @computed('args.height')
    get internalHeight(): number {
        const height = this.args.height ?? 216;

        return height - PICKER_THUMB_SIZE;
    }

    /**
     * Sets the width and height for the clickable surface of the picker, according to the values of the *width* and *height* properties.
     */
    @computed('width', 'internalHeight')
    get pickerSurfaceStyle(): SafeString {
        return htmlSafe(`width: ${this.width}px; height: ${this.internalHeight}px;`);
    }

    /**
     * Generates the background image for the temperature picker clickable area.
     */
    get pickerBackgroundStyle(): SafeString {
        return htmlSafe(`background-image: ${fnGradient('right', TEMPERATURES_GRADIENT_VALUE)}`);
    }

    /**
     * Combines the picker surface style (width and height) with the background style.
     */
    @computed('pickerSurfaceStyle', 'pickerBackgroundStyle')
    get pickerStyle(): SafeString {
        return htmlSafe(`${this.pickerBackgroundStyle}; ${this.pickerSurfaceStyle}`);
    }

    /**
     * Generates the style for the color selector (the position of the circle which indicates the currently selected color).
     */
    @computed('percentWarmth', 'width', 'internalHeight')
    get selectorPositionStyle(): SafeString {
        return htmlSafe(
            `transform: translate(${(this.percentWarmth / 100) * this.width}px, ${this.internalHeight / 2}px);`
        );
    }

    // endregion

    /**
     * Executes the on-select action.
     */
    @action changeSelectedColor(e: Event): void {
        const [x] = getNormalizedEventCoordinates.call(this, e);
        if (x) {
            this.args['on-select']({
                percentWarmth: Math.round(x * 100) as Warmth
            });
        }
    }

    /**
     * Selects the clicked tab.
     */
    @action switchActiveTab(isPresetsSelected: boolean, event: MouseEvent): void {
        event.preventDefault();

        this.isPresetsSelected = isPresetsSelected;
    }

    /**
     * Executes when a preset is picked. It calls the temperature pickers "on-select" action, passing it the color from the preset.
     */
    @action onPresetSelect(preset: TemperaturePickerComponent['presetItems'][0]): void {
        this.args['on-select']({
            percentWarmth: preset.props.color?.percentWarmth
        });
    }

    /**
     * Selects a color from a keyup on the thumb.
     */
    @action keyUpOnThumb(e: KeyboardEvent): void {
        if (isArrowCode(e.code)) {
            this.changeSelectedColor(e);
        }
    }

    /**
     * Updates the selected hue and lightness based on the position of the touch.
     */
    @action touchStartAction(e: TouchEvent): void {
        processEvent.call(this, e);

        this.changeSelectedColor(e);
    }

    /**
     * Updates the selected hue and lightness based on the position of the left mouse click.
     */
    @action mouseDownAction(e: MouseEvent): void {
        if (e.which === LEFT_MOUSE_BUTTON_CODE) {
            processEvent.call(this, e);

            this.changeSelectedColor(e);
        }
    }
}
