import templateOnlyComponent from '@ember/component/template-only';

import type CheckboxTreeItem from '../../../utils/dropdown-select-item';

export interface DetailedCheckboxContentSignature {
    Args: {
        item: CheckboxTreeItem;
    };
}
const DetailedCheckboxContent = templateOnlyComponent<DetailedCheckboxContentSignature>();
export default DetailedCheckboxContent;
