import CommonSerializer from './common-serializer.ts';

/* eslint-disable ember/use-ember-data-rfc-395-imports */
import type DS from 'ember-data';
import type ModelRegistry from 'ember-data/types/registries/model';

interface PayloadData {
    id: string;
    type: string;
    attributes?: Record<string, unknown>;
    relationships?: Record<string, { data: PayloadData }>;
}

/**
 * @classdesc
 * Alarm.com's base serializer implementation to move attributes and relationships to the root node.
 *
 * @note We should change the backend to accept true JSONApi payload so we don't need to do this.
 *       We are currently dropping type attribute values because type can't coexist with the model type (which is required).
 */
export default class BaseSerializer extends CommonSerializer {
    serialize<K extends keyof ModelRegistry>(snapshot: DS.Snapshot<K>, options = {}): object {
        // Extract data from payload.
        const json = (
                super.serialize<K>(snapshot, Object.assign({}, options, { includeId: true })) as { data: PayloadData }
            ).data,
            { attributes = {}, relationships = {} } = json;

        // Blow away a type attribute (if present) because it will override the model type (this demonstrates why our serialization is actually broken).
        delete attributes.type;

        // Move attributes to the main node.
        delete json.attributes;
        Object.assign(json, attributes);

        // Move relationships to the main node.
        delete json.relationships;
        Object.assign(json, Object.fromEntries(Object.entries(relationships).map(([key, value]) => [key, value.data])));

        return json;
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
    export default interface SerializerRegistry {
        'base-serializer': BaseSerializer;
    }
}
