import templateOnlyComponent from '@ember/component/template-only';

import type { SafeString } from 'handlebars';

export interface FieldsetDescriptionSignature {
    Element: HTMLDivElement;
    Args: {
        /** The text to be shown in the fieldset description. */
        text: string | SafeString;
    };
}

const FieldsetDescription = templateOnlyComponent<FieldsetDescriptionSignature>();
export default FieldsetDescription;
