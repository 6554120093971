import { htmlSafe } from '@ember/template';

import type { SafeString } from 'handlebars';

export default function fmtDegree(value: number): SafeString {
    return htmlSafe(`${String(value)}&deg;`);
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Accepts a number and returns an HTML safe degree value using that number.
         *
         * @example <p>{{fmt-degree forecast.maxTemperature}}</p>
         */
        'fmt-degree': typeof fmtDegree;
    }
}
