import type ADCIntlService from '../services/adc-intl.ts';

/** @module dates */

type DateFormattingOptions = Intl.DateTimeFormatOptions & { format?: string };

/**
 * Adds the global time zone (if defined) to the passed formatting options, if time zone is not already present.
 *
 * @private
 */
function getFormattingOptions(options: DateFormattingOptions = {}, timeZone = ''): DateFormattingOptions {
    // Is there a global timezone AND NO passed timezone?
    if (timeZone && !options.timeZone) {
        // Add global timezone.
        return {
            timeZone,
            ...options
        };
    }

    return options;
}

/**
 * Formats a date, using the optional global timezone if no timezone was supplied in the options.
 */
export function formatDateTz(
    intl: ADCIntlService,
    value: Parameters<ADCIntlService['formatDate']>[0],
    options: DateFormattingOptions
): ReturnType<ADCIntlService['formatDate']> {
    return intl.formatDate(value, getFormattingOptions(options, intl.timeZone));
}

/**
 * Formats a date, using the optional global timezone if no timezone was supplied in the options.
 */
export function formatTimeTz(
    intl: ADCIntlService,
    value: Parameters<ADCIntlService['formatTime']>[0],
    options: DateFormattingOptions
): ReturnType<ADCIntlService['formatTime']> {
    return intl.formatTime(value, getFormattingOptions(options, intl.timeZone));
}

/**
 * Checks if the two dates are the same day in the timezone set for @see ADCIntlService
 * @param intl
 * @param date1
 * @param date2
 * @returns True if the two dates are on the same day; otherwise, false
 */
export function isSameDayTz(
    intl: ADCIntlService,
    date1: Parameters<ADCIntlService['formatDate']>[0],
    date2: Parameters<ADCIntlService['formatDate']>[0]
): boolean {
    // using the ADCIntlService, we can determine if the days match
    // for the current user's
    return computeDayKeyTz(intl, date1) === computeDayKeyTz(intl, date2);
}

/**
 * Converts a date to a key that can be used to match another date
 * in the timezone set for @see ADCIntlService
 * @param intl
 * @param date
 * @returns
 */
function computeDayKeyTz(
    intl: ADCIntlService,
    date: Parameters<ADCIntlService['formatDate']>[0]
): ReturnType<ADCIntlService['formatDate']> {
    // only need day, month, and year; time doesn't matter for this key
    return formatDateTz(intl, date, {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
    });
}
