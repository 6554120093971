import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';
import { computed, action, getProperties } from '@ember/object';
import { isPresent } from '@ember/utils';
import { assert } from '@ember/debug';
import {
    getNormalizedEventCoordinates,
    LEFT_MOUSE_BUTTON_CODE,
    processEvent
} from '../../utils/color-pickers/common.ts';
import { fnGradient, constrainHueValue } from '../../utils/color-pickers/color.ts';
import { isArrowCode } from '@adc/ember-utils/utils/a11y';
import { inject as service } from '@ember/service';

import type { SafeString } from 'handlebars';
import type { Color, Hue, Lightness } from '../../utils/color-pickers/color.ts';
import type DomService from '@adc/ember-utils/services/dom';

const ON_SELECT_ACTION = 'on-select';
const TRANSPARENT_GRADIENT_VALUE = 'rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.67) 50%, #ffffff';

/**
 * The half size of the hue values range to show in the picker interactive surface.
 * One half before and one half after the baseHue value.
 */
const HUE_STEP = 60 as const;

export interface AnalogousPickerSignature {
    Element: HTMLDivElement;
    Args: {
        /** Optional color sheet width, in pixels (defaults to 216). */
        width?: number;
        /** Optional color sheet height, in pixels (defaults to 216). */
        height?: number;
        /** The current base hue (defaults to 25). */
        baseHue?: Hue;
        /** The currently selected hue (defaults to 25). */
        hue?: Hue;
        /** The currently selected lightness value (defaults to 51). */
        lightness?: Lightness;
        /** Triggered when the user selects a new color. */
        [ON_SELECT_ACTION]: (item: Color) => void;
    };
}

/**
 * @classdesc
 * Adds support for selecting a color and returns it in the HSL format.
 */
export default class AnalogousPickerComponent extends Component<AnalogousPickerSignature> {
    // region Properties

    @service declare dom: DomService;
    @tracked isBusy = false;

    /**
     * The picker height, in pixels.
     */
    @computed('args.height')
    get height(): number {
        return this.args.height ?? 216;
    }

    /**
     * The picker width, in pixels.
     */
    @computed('args.width')
    get width(): number {
        return this.args.width ?? 216;
    }

    /**
     * The hue this range of colors will be based on.
     */
    @computed('args.baseHue')
    get baseHue(): Hue {
        return this.args.baseHue ?? 25;
    }

    /**
     * The selected hue (should be within 60 degrees of the base hue).
     */
    @computed('args.hue')
    get hue(): Hue {
        return this.args.hue ?? 25;
    }

    /**
     * The selected lightness (should be between 50 and 100).
     */
    @computed('args.lightness')
    get lightness(): Lightness {
        return this.args.lightness ?? 51;
    }

    // endregion

    // region Computed Properties

    /**
     * Generates the background image CSS for the analogous picker interactive surface.
     */
    @computed('baseHue')
    get pickerBackgroundStyle(): SafeString {
        const hueRange = [-HUE_STEP, 0, HUE_STEP]
            .map((offset) => constrainHueValue(offset, this.baseHue))
            .map((hue, idx) => `hsl(${hue}, 100%, 50%)${idx === 1 ? ' 50%' : ''}`);

        return htmlSafe(
            `background-image: ${fnGradient('top', TRANSPARENT_GRADIENT_VALUE)}, ${fnGradient(
                'right',
                hueRange.join(', ')
            )}`
        );
    }

    /**
     * The slider thumb color style.
     */
    @computed('baseHue')
    get sliderThumbColorStyle(): SafeString {
        return htmlSafe(`color: hsl(${this.baseHue}, 100%, 50%);`);
    }

    /**
     * Sets the width and height for the clickable surface of the picker, according to the values of the *width* and *height* properties.
     */
    @computed('width', 'height')
    get pickerSurfaceStyle(): SafeString {
        return htmlSafe(`width: ${this.width}px; height: ${this.height}px;`);
    }

    /**
     * Combines the inherited picker surface style with the background style.
     */
    @computed('pickerSurfaceStyle', 'pickerBackgroundStyle')
    get pickerStyle(): SafeString {
        return htmlSafe(`${this.pickerBackgroundStyle}; ${this.pickerSurfaceStyle}`);
    }

    /**
     * Generates the position transform CSS for the selector.
     */
    @computed('baseHue', 'hue', 'lightness', 'width', 'height')
    get selectorPositionStyle(): SafeString {
        const { baseHue, hue, lightness, width, height } = this,
            bottomRange = constrainHueValue(-HUE_STEP, baseHue),
            hueDelta = hue >= bottomRange ? hue - bottomRange : hue + (360 - bottomRange);

        return htmlSafe(
            `transform: translate(${(hueDelta / (2 * HUE_STEP)) * width}px, ${(1 - (lightness - 50) / 50) * height}px);`
        );
    }

    // endregion

    /**
     * Calls the 'on-select' action.
     */
    @action changeSelectedColor(e: Event): void {
        const [x, y] = getNormalizedEventCoordinates.call(this, e);
        if (x && y) {
            const hue = constrainHueValue(Math.round((x - 0.5) * (HUE_STEP * 2)), this.baseHue),
                lightness = Math.round((1 - y) * 50 + 50) as Lightness;

            // Pass new color value out of picker.
            this.args[ON_SELECT_ACTION]({
                hue,
                saturation: 100,
                lightness
            });
        }
    }

    /**
     * Validates the passed component attributes.
     */
    @action validateAttributes() {
        const requiredProperties = getProperties(this, 'baseHue', 'hue', 'lightness', 'height', 'width'),
            fnGetErrorText = (reason: string) => `[@adc/ui-components] Analogous Color Picker: ${reason}`,
            fnCompareRange = (name: keyof typeof requiredProperties, min: number, max: number) => {
                const value = requiredProperties[name];
                assert(
                    fnGetErrorText(`The "${name}" value (${value}) must be between ${min} and ${max} (inclusive)`),
                    value >= min && value <= max
                );
            };

        // Verify all values are present and are numbers.
        Object.keys(requiredProperties).forEach((name: keyof typeof requiredProperties) => {
            const value = requiredProperties[name];
            assert(
                fnGetErrorText(`The "${name}" must be a valid number`),
                isPresent(value) && typeof value === 'number'
            );
        });

        // Verify hues and lightness are within supported levels.
        ['baseHue', 'hue'].forEach((name: keyof typeof requiredProperties) => fnCompareRange(name, 0, 360));
        fnCompareRange('lightness', 50, 100);

        const { baseHue, hue } = requiredProperties,
            minValue = constrainHueValue(-HUE_STEP, baseHue),
            maxValue = constrainHueValue(HUE_STEP, baseHue);

        assert(
            fnGetErrorText(`The "hue" and "baseHue" values must be within ${HUE_STEP} degrees of one another`),
            minValue > maxValue ? hue >= minValue || hue <= maxValue : hue >= minValue && hue <= maxValue
        );
    }

    /**
     * Changes the base hue, preserving the current baseHue/hue relationship.
     */
    @action onHueSliderChange(newBaseHue: Hue): void {
        // Get current base hue and selected hue.
        const { baseHue, hue, lightness } = this;

        // Did the hue actually change?
        if (newBaseHue !== baseHue) {
            // Pass new color value out of picker.
            this.args[ON_SELECT_ACTION]({
                baseHue: newBaseHue,
                hue: constrainHueValue(hue - baseHue, newBaseHue),
                saturation: 100,
                lightness
            });
        }
    }

    /**
     * Updates the selected hue and lightness based on the position of the left mouse click.
     */
    @action mouseDownAction(e: MouseEvent): void {
        // If this event was fired by left-click
        if (e.which === LEFT_MOUSE_BUTTON_CODE) {
            try {
                processEvent.call(this, e);
                this.changeSelectedColor(e);
            } catch (e) {
                // VID-58056: clicked on the edge of picker
            }
        }
    }

    /**
     * Updates the selected hue and lightness based on the position of the touch.
     */
    @action touchStartAction(e: Event): void {
        processEvent.call(this, e);
        this.changeSelectedColor(e);
    }

    /**
     * Selects a color from a keyup on the thumb.
     */
    @action keyUpOnThumb(e: KeyboardEvent): void {
        if (isArrowCode(e.code)) {
            this.changeSelectedColor(e);
        }
    }
}
