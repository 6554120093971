import RouteView from '../route-view.js';
import { computed, set } from '@ember/object';

/**
 * @classdesc
 * Root component for all "edit" routes.
 */
export default class EditView extends RouteView {
    /** @override */
    useRouteViewClass = true;

    /** @override */
    containerMaxWidth = 'md';

    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/classic-decorator-hooks
    init(...args) {
        super.init(...args);

        if (!this.buttons) {
            console.error('Buttons object is not defined');
            // Fake buttons object so that things do not crash.
            set(this, 'buttons', {});
        } else if (!this.buttons.confirmAction) {
            console.error('Action for confirm button is not defined');
        }
    }

    /**
     * Optional definition for a delete model button.
     *
     * @type {components.route-view.content.DeleteModel.DeleteModelType|undefined}
     */
    deleteModel;

    /**
     * Buttons for action buttons.
     *
     * @type {components.route-view.EditView.RouteViewActionButtonsType|undefined}
     */
    buttons;

    /**
     * Text for the cancel button.
     *
     * @type {String}
     */
    @computed('buttons.cancelText', 'intl')
    get cancelButtonText() {
        return this.buttons.cancelText || this.intl.t('generic.cancel');
    }

    /**
     * Text for the confirm button.
     *
     * @type {String}
     */
    @computed('buttons.confirmText', 'intl')
    get confirmButtonText() {
        return this.buttons.confirmText || this.intl.t('generic.save');
    }

    /**
     * Action to be passed to the cancel button.
     *
     * @type {Function}
     */
    @computed('buttons.{cancelAction,doNotGoBackAfterAction}', 'navigationApi.transitionToPreviousRoute')
    get cancelButtonAction() {
        const { doNotGoBackAfterAction, cancelAction } = this.buttons;

        // If there was action defined or click should take user to previous route just use whatever the action was.
        if (cancelAction || !doNotGoBackAfterAction) {
            return cancelAction;
        }

        // Ensure that cancel button works correctly.
        return this.navigationApi?.transitionToPreviousRoute;
    }
}

/**
 * Buttons object definition for route-view subclasses
 *
 * @typedef {{
 *      cancelText: String=,
 *      cancelDisabled: boolean=,
 *      cancelAction: Function=,
 *      confirmText: String=,
 *      confirmDisabled: boolean=,
 *      confirmAction: Function,
 *      doNotGoBackAfterAction: boolean=
 * }} components.route-view.EditView.RouteViewActionButtonsType
 *
 * @property {String=} cancelText - Optional text for cancel button. If none defined, "Cancel" is used.
 * @property {boolean=} cancelDisabled - If set to true, cancel button will be disabled.
 * @property {Function=} cancelAction - Optional function to be executed when cancel button is clicked.
 * @property {String=} confirmText - Optional text for confirm button. If none defined, "Save" is used.
 * @property {boolean=} confirmDisabled - If set to true, confirm button will be disabled.
 * @property {Function} confirmAction - Function to be executed when confirm button is clicked.
 * @property {boolean} doNotGoBackAfterAction - Action buttons will by default redirect to the previous route after action. Setting this to true will prevent that.
 *
 * @memberof components.route-view.EditView
 */
