import templateOnlyComponent from '@ember/component/template-only';

import type { SafeString } from 'handlebars';

export interface BinaryLabelSignature {
    Element: HTMLDivElement;
    Args: {
        /** The binary control label text. */
        text?: string | number | SafeString;
        /** Optional name of the icon to render within the label (minus the "icn-" prefix). */
        icon?: string;
        /** Optional hex color value for for the icon fill. */
        iconFill?: string;
    };
}

const BinaryLabel = templateOnlyComponent<BinaryLabelSignature>();
export default BinaryLabel;
