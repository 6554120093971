import BaseModel from './base-model.ts';
import { attr } from '@ember-data/model';

/**
 * @classdesc
 * Endpoint and info for MicroAPI service
 */
export default class MicroApiEndpoint extends BaseModel {
    /**
     * URL for the base endpoint
     */
    @attr('string', { defaultValue: '' })
    declare baseApiEndpoint: string;

    /**
     * Namespace for the API call
     */
    @attr('string', { defaultValue: '' })
    declare namespace: string;

    /**
     * JWT used for authenticating to the MicroAPI service
     */
    @attr('string')
    declare encodedJwtToken: string;

    /**
     * JWT expiration datetime
     */
    @attr('date')
    declare jwtExpirationDate: Date;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'micro-api-endpoint': MicroApiEndpoint;
    }
}
