import EmberObject from '@ember/object';

// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-classes
export default EmberObject.extend({
    // region Default Properties

    /**
     * The name of the icon to be used for this action item.
     *
     * @type {string}
     */
    icon: '',

    /**
     * The title of the icon to be used for this action item.
     *
     * @type {string}
     */
    iconTitle: '',

    /**
     * The description of the icon to be used for this action item.
     *
     * @type {string}
     */
    iconDesc: '',

    /**
     * Should the icon be hidden for screen readers?
     *
     * @type {boolean}
     */
    iconIsHiddenForAccessibility: false,

    /**
     * The name to be rendered in the list for this action item.
     *
     * @type {string}
     */
    name: '',

    /**
     * The name of the class to style the action item.
     *
     * @type {string}
     */
    className: '',

    /**
     * The description text for this action item.
     */
    description: '',

    /**
     * The tooltip text to be rendered when the user hovers the action item.
     *
     * @type {string}
     */
    tooltipText: '',

    /**
     * Whether or not this item is disabled. If true, it should not be able to trigger the action.
     *
     * @type {boolean}
     */
    disabled: false,

    /**
     * Whether or not the item should appear selected.
     *
     * @type {boolean}
     */
    selected: false,

    /**
     * The action function to be executed when the item is activated.
     *
     * @type {undefined|string}
     */
    action: '',

    /**
     * The arguments to trigger the action with
     *
     * @type {Array}
     */
    actionArguments: null

    // endregion
});
