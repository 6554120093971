import EmberObject from '@ember/object';

/**
 * ADC pikaday component initializer for injecting property values.
 *
 * @param {Ember.Application} application
 */
export function initialize(application) {
    // Create object to hold translations values that will be populated once we know the locale (see locale service).
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/no-classic-classes
    const defaults = EmberObject.extend({});

    application.register('pikaday:main', defaults, { singleton: true });
}

export default {
    name: 'setup-pikaday',
    initialize
};
