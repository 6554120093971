import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { BinaryListItem } from '../simple-binary/list.ts';
import { intlPath } from '@adc/i18n/path';
import { inject as service } from '@ember/service';
import { POST } from '@adc/ajax/services/adc-ajax';

import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type AdcAjaxService from '@adc/ajax/services/adc-ajax';

// #region Types

class BaseAddress {
    street1: string;
    street2: string;
    subCity: string;
    city: string;
    state: string;
    subState: string;
    zip: string;

    protected constructor(props: Partial<BaseAddress>) {
        this.city = props.city ?? '';
        this.state = props.state ?? '';
        this.street1 = props.street1 ?? '';
        this.street2 = props.street2 ?? '';
        this.subCity = props.subCity ?? '';
        this.subState = props.subState ?? '';
        this.zip = props.zip ?? '';
    }
}

// This matches the Address class within ABO and is what the API expects.
export class Address extends BaseAddress {
    countryId: number;

    constructor(props: Partial<Address>) {
        super(props);
        this.countryId = props.countryId ?? 1;
    }

    /**
     * Are all the required fields filled out for the address to be considered complete?
     */
    isComplete(): boolean {
        return !!(this.street1 && this.city && this.state && this.zip);
    }
}

export type ValidatedAddress = BaseAddress & {
    countryCode: string;
    // We consider an address to be valid if the result code contains either AV24 or AV25.
    addressIsValid: boolean;
    // Whether the API call was processed successfully.
    checkedSuccessfully: boolean;
};

export type ValidatedAddressApiResponse = {
    validatedAddress: ValidatedAddress;
    isValidatedAddressDifferent: boolean;
    countryDisplay: string;
};

/**
 * The props that are passed to the BinaryListItem component.
 */
type AddressVerificationItemProps = Pick<BaseAddress, 'street1' | 'street2' | 'city' | 'state' | 'zip'> & {
    country: string;
};

/**
 * The selected address in the action sheet.
 */
enum SelectedAddress {
    None = -1,
    Original,
    Validated
}

export type AddressVerificationSignature = {
    Args: {
        /** The response from the API that contains the validated address. */
        validatedAddressResponse?: ValidatedAddressApiResponse;
        /** The address that was entered by the user. */
        address?: Address;
        /** Function that is called once an address is chosen from the Action Sheet and the submit button is pressed. */
        onSubmit: (validatedAddress?: ValidatedAddress) => Promise<void>;
        /** Function that is called when the action sheet is closed without the confirm button being pressed. */
        onClose?: VoidFunction;
    };
    Element: HTMLDivElement;
};

// #endregion

// #region Exported Functions

/**
 * Fetches the validated address from the API using the provided address.
 */
export async function fetchValidatedAddress(
    address: Address,
    ajax: AdcAjaxService
): Promise<ValidatedAddressApiResponse> {
    const response = await ajax.apiRequest<{ value: ValidatedAddressApiResponse }>(
        'accountManagement/validateAddress',
        undefined,
        { ...address },
        POST
    );
    return response.value;
}

// #endregion

@intlPath({ module: '@adc/ui-components', path: 'address-verification' })
export default class AddressVerification extends Component<AddressVerificationSignature> {
    @service declare ajax: AdcAjaxService;
    @service declare intl: ADCIntlService;

    // #region Properties

    @tracked isAddressSuggestionActionSheetOpen = false;
    @tracked selectedRadioItem: SelectedAddress = SelectedAddress.None;

    get addressSuggestionList(): BinaryListItem<AddressVerificationItemProps>[] {
        const { address, validatedAddressResponse } = this.args;
        if (!validatedAddressResponse || !address) {
            return [];
        }

        return [address, validatedAddressResponse.validatedAddress].map(
            (data, idx) =>
                new BinaryListItem<AddressVerificationItemProps>({
                    label: this.intl.tc(this, idx === SelectedAddress.Original ? 'original' : 'recommended'),
                    state: idx === this.selectedRadioItem,
                    props: {
                        street1: data.street1,
                        street2: data.street2,
                        city: data.city,
                        state: data.state,
                        zip: data.zip,
                        country: validatedAddressResponse.countryDisplay
                    }
                })
        );
    }

    // #endregion

    // #region Actions

    @action async onValidatedAddressResponseChange(): Promise<void> {
        const { validatedAddressResponse } = this.args;
        if (!validatedAddressResponse) {
            this.isAddressSuggestionActionSheetOpen = false;
            return;
        }

        if (!this.args.address?.isComplete()) {
            this.handleActionSheetSubmit(true);
            return;
        }

        if (
            validatedAddressResponse?.isValidatedAddressDifferent &&
            validatedAddressResponse?.validatedAddress.checkedSuccessfully
        ) {
            this.selectedRadioItem = SelectedAddress.Validated;
            this.isAddressSuggestionActionSheetOpen = true;
        } else {
            this.handleActionSheetSubmit(true);
        }
    }

    /**
     * Is called when the address selected in the action sheet changes.
     */
    @action selectedAddressChange(items: BinaryListItem<AddressVerificationItemProps>[]): void {
        items.find((item, idx) => {
            if (item.state) {
                this.selectedRadioItem = idx;
                return true;
            }
            return false;
        });
    }

    /**
     * Is called when the user selects the suggested address from the action sheet
     * to save.
     */
    @action async handleActionSheetSubmit(forceQuit = false): Promise<void> {
        try {
            await this.args.onSubmit(
                !forceQuit && this.selectedRadioItem === SelectedAddress.Validated
                    ? this.args.validatedAddressResponse?.validatedAddress
                    : undefined
            );
        } finally {
            this.isAddressSuggestionActionSheetOpen = false;
        }
    }

    @action close(): void {
        this.isAddressSuggestionActionSheetOpen = false;
        this.args?.onClose?.();
    }

    // #endregion
}
