/**
 * This enum is a TS representation of WebsocketCloseStatusEnum and it must be manually kept in sync w/ the C# version.
 *
 * @module
 */

export const NormalClosure = 1000;

export const EndpointUnavailable = 1001;

export const PolicyViolation = 1008;
