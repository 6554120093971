import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { A } from '@ember/array';
import { once, later } from '@ember/runloop';
import { VALUE_CHANGE_ACTION } from './common/base-input.js';
import {
    MINUTES_IN_HALF_HOUR as segmentLength,
    MINUTES_IN_HOUR,
    MINUTES_IN_DAY
} from '@adc/ember-utils/constants/time';
import { newDate } from '@adc/ember-utils/utils/date-time';
import DropdownSelectItem, { SELECTED } from '../utils/dropdown-select-item.js';
import {
    HALF_HOURS_IN_DAY,
    MILLISECONDS_IN_HOUR,
    MILLISECONDS_IN_MINUTE,
    MILLISECONDS_IN_SECOND,
    isValidTimeValue
} from '../utils/time.ts';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import type IntlService from '@adc/i18n/services/adc-intl';

interface ResultValues {
    selectedDays?: number[];
    beginTimeInMinutes: number;
    endTimeInMinutes: number;
    beginTimeInSeconds: number;
    endTimeInSeconds: number;
    isEndTimeNextDay: boolean;
}

interface SchedulePickerSignature {
    Element: HTMLDivElement;
    Args: {
        /** The title and `aria-label` of the element. */
        title?: string;
        /** The label value for the collection of days. */
        daysPlaceholder?: string;
        /** Indicates whether the day buttons and begin/end time dropdowns should be disabled. */
        disabled?: boolean;
        /** The locale code the schedule picker should be localized for (e.g., 'en-US', 'fr-FR', 'nb-NO', etc).  Localization includes day names, time formats and first day of the week. */
        localeCode?: string;
        /** Indicates the days of the week should begin with Sunday (as opposed to Monday). */
        weekStartsOnSunday?: boolean;
        /** How far after the start time should we reset the end time, given resetEndTimeOnBeginTimeChange is true? */
        resetTimeMinutes?: number;
        /** Should the "(next-day)" text be displayed in the end dropdown if the end time would be on the next day? */
        allowNextDayTimes?: boolean;
        /** Indicates the buttons should be compact, narrower but taller (44px by 44px, optimized for mobile touch) with just the first letter of each day. */
        compactMode?: boolean;
        /** The elapsed number of minutes after midnight, used to initialize the begin time value. */
        beginTimeInMinutes?: number;
        /** The elapsed number of seconds after midnight, used to initialize the begin time value. */
        beginTimeInSeconds?: number;
        /** The elapsed number of minutes after midnight, used to initialize the end time value. */
        endTimeInMinutes?: number;
        /** The elapsed number of seconds after midnight, used to initialize the end time value. */
        endTimeInSeconds?: number;
        /** Placeholder for the begin time select element. */
        beginPlaceholder?: string;
        /** Placeholder for the end time select element. */
        endPlaceholder?: string;
        /** When the begin time changes, should we reset the end time to be 1 hour past that? */
        resetEndTimeOnBeginTimeChange?: boolean;
        /** An array of the days of the week indices that should be selected (e.g., 0 = Sunday, 1 = Monday, etc). */
        selectedDays?: number[];
        /** An array of the days of the week indices that should be disabled (e.g., 0 = Sunday, 1 = Monday, etc). */
        disabledDays?: number[];
        /** ? */
        beginTimeBound?: number;
        /** ? */
        endTimeBound?: number;
        /** Hide built in time picker. */
        hideTimePicker?: boolean;
        /** Called when the schedule value changes. */
        'value-change': (value: ResultValues) => void;
    };
}

const { Intl } = window;

/**
 * Computes the segment index of a given milliseconds time value.
 */
function getSegmentIndex(time = 0): number {
    const minutesAfterMidnight = time / MILLISECONDS_IN_MINUTE;

    return Math.floor(minutesAfterMidnight / segmentLength);
}

/**
 * Computes a milliseconds after midnight value for the given segment index.
 */
function getTimeFromSegmentIndex(segmentIndex = 0): number {
    const minutes = segmentIndex * segmentLength;

    return minutes * MILLISECONDS_IN_MINUTE;
}

/**
 * Creates a DateTimeFormat for the supplied options.
 */
function getFormatter(localeCode: string, options: Intl.DateTimeFormatOptions): Intl.DateTimeFormat {
    return Intl.DateTimeFormat(localeCode, options);
}

/**
 * If we are set to update the end time when begin time changes, update the end time hour and minutes here.
 * The amount of time we reset is based on resetTimeMinutes in the component, or it is 60 minutes by default.
 */
function getValidatedInternalEndTime(
    resetTimeMinutes: string,
    internalEndTimeInMilliseconds: number,
    internalBeginTimeInMilliseconds: number,
    updateEndTimeWithBeginTime?: boolean,
    oldBeginTimeInMilliseconds?: number
): number {
    // Don't update the endTime if we don't have an existing beginTime - this component is new and will use default values.
    if (!oldBeginTimeInMilliseconds) {
        return internalEndTimeInMilliseconds;
    }

    let resetTimeAmount = parseInt(resetTimeMinutes);

    // Check the flag to see: if we update the begin time, should we update the end time to be immediately after it?
    if (updateEndTimeWithBeginTime && oldBeginTimeInMilliseconds !== internalBeginTimeInMilliseconds) {
        if (!Number.isInteger(resetTimeAmount) || resetTimeAmount < segmentLength || resetTimeAmount > MINUTES_IN_DAY) {
            resetTimeAmount = MINUTES_IN_HOUR;
        } else {
            // Otherwise, resetTimeMinutes is a valid integer - just make sure it is rounded down to the nearest 30 minute increment.
            resetTimeAmount -= resetTimeAmount % segmentLength;
        }

        internalEndTimeInMilliseconds = internalBeginTimeInMilliseconds + resetTimeAmount * MILLISECONDS_IN_MINUTE;
    }

    return internalEndTimeInMilliseconds;
}

type Day = {
    id: number;
    fullDayName: string;
    text: string;
    selected?: boolean;
    disabled: boolean;
};

/**
 * @classdesc
 * A day schedule picker (days and begin and end times).
 */
export default class SchedulePicker extends Component<SchedulePickerSignature> {
    @service declare intl: IntlService;

    /** @override */
    constructor(owner: unknown, args: SchedulePickerSignature['Args']) {
        super(owner, args);

        // Delay initialization for the next event cycle, lest we make Ember very sad.
        // Initialize selected hours.
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        later(() => this.setInternalTime(), 0);
    }

    /**
     * Internal time tracked in milliseconds
     */
    @tracked
    internalBeginTimeInMilliseconds?: number;

    /**
     * Internal time tracked in milliseconds
     */
    @tracked
    internalEndTimeInMilliseconds?: number;

    /**
     * The current localization code (en-US, nb-NO, etc).
     */
    @computed('args.localeCode')
    get localeCode(): string {
        return this.args.localeCode ?? 'en-US';
    }

    /**
     * Data ID for the current active element.
     */
    currentActiveElementDataID?: string | null;

    /**
     * The indices of selected days, zero (Sunday) to 6 (Saturday).
     */
    @computed('args.selectedDays')
    get selectedDays(): number[] {
        return this.args.selectedDays ?? [];
    }

    /**
     * The indices of any disabled days, zero (Sunday) to 6 (Saturday).
     */
    @computed('args.disabledDays')
    get disabledDays(): number[] {
        return this.args.disabledDays ?? [];
    }

    /**
     * Internal begin time value in milliseconds, representing the selected start time after midnight.
     */
    beginTimeMilliseconds = 0;

    /**
     * Indicates whether or not the end time is selected in the next time.
     */
    @computed('internalBeginTimeInMilliseconds', 'internalEndTimeInMilliseconds')
    get isEndTimeNextDay(): boolean {
        return (
            getSegmentIndex(this.internalEndTimeInMilliseconds) <= getSegmentIndex(this.internalBeginTimeInMilliseconds)
        );
    }

    /**
     * How far after the start time should we reset the end time, given resetEndTimeOnBeginTimeChange is true?
     */
    @computed('args.resetTimeMinutes')
    get resetTimeMinutes(): number {
        return this.args.resetTimeMinutes ?? 60;
    }

    /**
     * The next-day text.
     */
    @computed('args.allowNextDayTimes')
    get nextDayText(): string {
        return this.args.allowNextDayTimes ? this.intl.t('@adc/ui-components.nextDayText') : '';
    }

    /**
     * The "day" buttons to show.
     */
    @computed('localeCode', 'args.{compactMode,weekStartsOnSunday}', 'selectedDays', 'disabledDays')
    get days(): Day[] {
        // Calculate the first day of the week.
        const formatter = getFormatter(this.localeCode, {
                weekday: this.args.compactMode ? 'narrow' : 'short'
            }),
            longDayNameFormatter = getFormatter(this.localeCode, {
                weekday: 'long'
            }),
            { selectedDays, disabledDays } = this,
            firstDayIndex = this.args.weekStartsOnSunday ?? true ? 0 : 1,
            today = new Date(),
            currentDay = today.getDay(),
            firstDay = new Date(
                today.setDate(today.getDate() - currentDay + (currentDay ? firstDayIndex : -7 + firstDayIndex))
            );

        // Create day buttons array.
        return Array.from(new Array(7), (_, idx) => {
            const weekday = new Date(new Date(firstDay).setDate(firstDay.getDate() + idx)),
                id = weekday.getDay();

            return {
                id,
                fullDayName: longDayNameFormatter.format(weekday).toLowerCase(),
                text: formatter.format(weekday),
                selected: selectedDays?.includes(id),
                disabled: !!disabledDays?.includes(id)
            };
        });
    }

    /**
     * The drop down hours for begin time.
     */
    @computed('localeCode', 'internalBeginTimeInMilliseconds', 'args.{beginTimeBound,endTimeBound}')
    get beginHours(): DropdownSelectItem[] {
        return this.getHours(false, this.internalBeginTimeInMilliseconds);
    }

    /**
     * The drop down hours for end time.
     */
    @computed(
        'localeCode',
        'internalBeginTimeInMilliseconds',
        'internalEndTimeInMilliseconds',
        'nextDayText',
        'args.endTimeBound'
    )
    get endHours(): DropdownSelectItem[] {
        return this.getHours(true, this.internalEndTimeInMilliseconds);
    }

    /**
     * Returns an array of dropdown times (in 30 minute increments) based on the current locale code.
     */
    private getHours(isEndHours: boolean, time?: number): DropdownSelectItem[] {
        // Get hour formatter.
        const formatter = getFormatter(this.localeCode, {
            hour: 'numeric',
            minute: 'numeric'
        });

        // Calculate first and last values in dropdown, depending on if endHours, and bounds.
        let hourOffset = 0,
            segmentCount = HALF_HOURS_IN_DAY;

        // For endHours, we want to start the endHours dropdown with <segmentLength> minutes after the selected start time, and end at end bound if set.
        if (isEndHours) {
            hourOffset += getSegmentIndex(this.internalBeginTimeInMilliseconds) + 1;
            if (this.args.endTimeBound) {
                segmentCount = getSegmentIndex(this.args.endTimeBound * MILLISECONDS_IN_MINUTE) - hourOffset + 1;
            }
        }
        // For begin time, we want to start at 0 or the begin time bounds if set.
        // If end bounds set, end <segmentLength> minutes before the end bound, or resetTimeMinutes minutes before end bound, depending if resetEndTimeOnBeginTimeChange is true.
        else {
            if (this.args.beginTimeBound) {
                hourOffset += getSegmentIndex(this.args.beginTimeBound * MILLISECONDS_IN_MINUTE);
            }
            if (this.args.endTimeBound) {
                let extraEndTimeBuffer = 1;
                if (this.args.resetEndTimeOnBeginTimeChange) {
                    extraEndTimeBuffer = Math.ceil(this.resetTimeMinutes / segmentLength);
                }
                segmentCount =
                    getSegmentIndex(this.args.endTimeBound * MILLISECONDS_IN_MINUTE) -
                    hourOffset -
                    extraEndTimeBuffer +
                    1;
            } else {
                segmentCount -= hourOffset;
            }
        }

        // Is the selected index less than zero?
        let selectedIdx = getSegmentIndex(time) - hourOffset;
        if (selectedIdx < 0) {
            // Then this selected time is for the next day - add max index value to get correct dropdown value.
            selectedIdx += segmentCount;
        }

        // Return segment values.
        const displayedNextDayText = (idx: number) => {
            return this.nextDayText && isEndHours && idx >= HALF_HOURS_IN_DAY - hourOffset
                ? ' ' + this.nextDayText
                : '';
        };

        return Array.from(new Array(segmentCount), (_, idx) =>
            DropdownSelectItem.create({
                name: `${formatter.format(
                    newDate(0, getTimeFromSegmentIndex(idx + hourOffset) / MILLISECONDS_IN_MINUTE)
                )}${displayedNextDayText(idx)}`,
                value: String(idx + hourOffset),
                state: idx === selectedIdx ? SELECTED : undefined,
                isSelectable: true
            })
        );
    }

    /**
     * Validates that internalEndTimeInMilliseconds is later than internalBeginTimeInMilliseconds (adjusting if necessary), and updates them with new values.
     */
    private validateAndUpdateTimes({
        internalBeginTimeInMilliseconds,
        internalEndTimeInMilliseconds
    }: {
        internalBeginTimeInMilliseconds: number;
        internalEndTimeInMilliseconds: number;
    }): void {
        // Update the internalEndTimeInMilliseconds hours and minutes if the component is set to automatically do that after setting the begin time.
        internalEndTimeInMilliseconds = getValidatedInternalEndTime(
            String(this.resetTimeMinutes),
            internalEndTimeInMilliseconds,
            internalBeginTimeInMilliseconds,
            this.args.resetEndTimeOnBeginTimeChange,
            this.internalBeginTimeInMilliseconds
        );

        // Save new values.
        Object.assign(this, {
            internalBeginTimeInMilliseconds,
            internalEndTimeInMilliseconds
        });
    }

    /**
     * Notifies listeners that the time/day components have changed (via value-change closure method).
     */
    private notifyChangeListeners() {
        // Get results (including a copy of selected days).
        const results: ResultValues = {
                selectedDays: this.selectedDays?.slice(),
                beginTimeInMinutes: Math.floor((this.internalBeginTimeInMilliseconds ?? 0) / MILLISECONDS_IN_MINUTE),
                endTimeInMinutes: Math.floor((this.internalEndTimeInMilliseconds ?? 0) / MILLISECONDS_IN_MINUTE),
                beginTimeInSeconds: Math.floor((this.internalBeginTimeInMilliseconds ?? 0) / MILLISECONDS_IN_SECOND),
                endTimeInSeconds: Math.floor((this.internalEndTimeInMilliseconds ?? 0) / MILLISECONDS_IN_SECOND),
                isEndTimeNextDay: this.isEndTimeNextDay
            },
            fnAction = this.args[VALUE_CHANGE_ACTION]; // Is there an action handler?

        if (fnAction) {
            // Call action handler.
            fnAction(results);
        }
    }

    /**
     * Sets the values for begin and end time in milliseconds. The values of initialBeginTime and initialEndTime are used if exist, otherwise a fallback default value is used.
     */
    private setInternalTime(): void {
        const unit = isValidTimeValue(Number(this.args.beginTimeInMinutes)) ? 'Minutes' : 'Seconds',
            initialBeginTime = this.args[`beginTimeIn${unit}` as 'beginTimeInMinutes' | 'beginTimeInSeconds'] ?? 0,
            initialEndTime = this.args[`endTimeIn${unit}` as 'endTimeInMinutes' | 'endTimeInSeconds'] ?? 0,
            toMillisecondsMultiplier = unit === 'Minutes' ? MILLISECONDS_IN_MINUTE : MILLISECONDS_IN_SECOND,
            beginTimeInMilliseconds = initialBeginTime * toMillisecondsMultiplier,
            endTimeInMilliseconds = initialEndTime * toMillisecondsMultiplier,
            fnGetTime = (n: number) => getTimeFromSegmentIndex(getSegmentIndex(n));

        // Validate/initialize time properties.
        this.validateAndUpdateTimes({
            internalBeginTimeInMilliseconds: fnGetTime(
                isValidTimeValue(beginTimeInMilliseconds) ? beginTimeInMilliseconds : 12 * MILLISECONDS_IN_HOUR
            ),
            internalEndTimeInMilliseconds: fnGetTime(
                isValidTimeValue(endTimeInMilliseconds) ? endTimeInMilliseconds : 14 * MILLISECONDS_IN_HOUR
            )
        });
    }

    /**
     * Listens for data changes and passes them out of the component.
     */
    @action
    pickerChangeUpdate(): void {
        // If any of these internal values are undefined, we probably should not propagate them to the listeners.
        if (
            this.internalBeginTimeInMilliseconds === undefined ||
            this.internalEndTimeInMilliseconds === undefined ||
            this.selectedDays === undefined
        ) {
            return;
        }

        // Schedule listener notification.
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        once(this, this.notifyChangeListeners);
    }

    /**
     * Listens for begin time updates from outside the component and updates internal values.
     */
    @action
    externalBeginTimeUpdate(): void {
        this.setInternalTime();
    }

    /**
     * Listens for end time updates from outside the component and updates internal values.
     */
    @action
    externalEndTimeUpdate(): void {
        this.setInternalTime();
    }

    @action
    focusActiveElement(element: HTMLDivElement): void {
        if (this.currentActiveElementDataID) {
            (element.querySelector(`[data-id='${this.currentActiveElementDataID}']`) as HTMLElement)?.focus();
        }
    }

    /**
     * Toggles the selected state of the passed button.
     */
    @action
    selectDay(button: Day): void {
        const { id } = button,
            selectedDays = A(this.selectedDays);

        // Update selectedDays collection.
        const newSelectedDays = button.selected
            ? selectedDays.reject((idx) => idx === id)
            : selectedDays.concat(id).sort();

        const activeElement = document.activeElement;

        this.currentActiveElementDataID = activeElement ? activeElement.getAttribute('data-id') : undefined;

        // Get results (including a copy of selected days).
        const results: ResultValues = {
                selectedDays: newSelectedDays.slice(),
                beginTimeInMinutes: Math.floor((this.internalBeginTimeInMilliseconds ?? 0) / MILLISECONDS_IN_MINUTE),
                endTimeInMinutes: Math.floor((this.internalEndTimeInMilliseconds ?? 0) / MILLISECONDS_IN_MINUTE),
                beginTimeInSeconds: Math.floor((this.internalBeginTimeInMilliseconds ?? 0) / MILLISECONDS_IN_SECOND),
                endTimeInSeconds: Math.floor((this.internalEndTimeInMilliseconds ?? 0) / MILLISECONDS_IN_SECOND),
                isEndTimeNextDay: this.isEndTimeNextDay
            },
            fnAction = this.args[VALUE_CHANGE_ACTION]; // Is there an action handler?

        if (fnAction) {
            // Call action handler.
            fnAction(results);
        }
    }

    /**
     * Updates (and validates) the time values.
     */
    @action
    updateTime(name: string, segmentIndex: string): void {
        // Update time and validate.
        this.validateAndUpdateTimes({
            internalBeginTimeInMilliseconds: Number(this.internalBeginTimeInMilliseconds),
            internalEndTimeInMilliseconds: Number(this.internalEndTimeInMilliseconds),
            [name]: getTimeFromSegmentIndex(parseInt(segmentIndex, 10)) // will overwrite one of the properties above
        });
    }
}
