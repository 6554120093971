import { MINUTES_IN_DAY } from '../constants/time.ts';

/**
 * Utility helper module for date-time-related code.
 *
 * @module
 */

/**
 * Returns a new Date object, using the optional base time, hours and minutes.
 *
 * @static
 */
export function newDate(hours = 0, minutes = 0, base: number | Date = Date.now()): Date {
    base = new Date(base);
    return new Date(base.setHours(hours, minutes, 0, 0));
}

/**
 * Returns a new Date object representing a given number of days in the future, rounded down
 * (backwards) to the nearest minute.
 */
export function nowPlusDays(days: number): Date {
    const date = new Date();
    date.setUTCSeconds(0);
    date.setUTCMilliseconds(0);
    date.setDate(date.getDate() + days);
    return date;
}

/**
 * Returns the duration in minutes of a daily schedule. It ranges between 0 and 1440 (24hrs).
 */
export function getDayScheduleDuration(beginTime = 0, endTime = 0): number {
    let difference = endTime - beginTime;

    if (difference === 0) {
        difference = MINUTES_IN_DAY;
    } else if (difference < 0) {
        difference += MINUTES_IN_DAY;
    }

    return Math.min(++difference, MINUTES_IN_DAY);
}
