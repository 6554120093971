/**
 * General constants related to time.
 */
export const MINUTES_IN_HOUR = 60,
    MINUTES_IN_HALF_HOUR = MINUTES_IN_HOUR / 2,
    HOURS_IN_DAY = 24,
    DAYS_IN_WEEK = 7,
    MINUTES_IN_DAY = MINUTES_IN_HOUR * HOURS_IN_DAY,
    MINUTES_IN_WEEK = MINUTES_IN_DAY * DAYS_IN_WEEK,
    MAX_END_TIME = MINUTES_IN_DAY - 1;
