import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { formatDateTz } from '../utils/dates.ts';

import type ADCIntlService from '../services/adc-intl.ts';

type FormatDateTzArgs = Parameters<typeof formatDateTz>;
type FormatDateTzValue = FormatDateTzArgs[1];
type FormatDateTzOptions = FormatDateTzArgs[2];

export interface FormatDateTzSignature {
    Args: {
        Positional: [FormatDateTzValue];
        Named: FormatDateTzOptions;
    };
    Return: string;
}

/**
 * @classdesc
 * Format a date, using the intl service global timezone if no explicit timezone is passed.
 */
export default class FormatDateTzHelper extends Helper<FormatDateTzSignature> {
    @service declare intl: ADCIntlService;

    compute([value]: [FormatDateTzValue], options: FormatDateTzOptions): ReturnType<typeof formatDateTz> {
        return formatDateTz(this.intl, value, options);
    }
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Returns the date formatted to the specified options, using the globally specified timezone value.
         */
        'format-date-tz': typeof FormatDateTzHelper;
    }
}
