import Component from '@glimmer/component';

import type { PageViewSignature } from '../page-view';
import type { PageViewContainerSignature } from '../page-view/container';
import type { NoResultsContentSignature } from '../route-view/view-container/no-results-content';

interface PageViewListSignature<M> {
    Element: PageViewSignature<M>['Element'];
    Args: Pick<PageViewContainerSignature<M>['Args'], 'model' | 'dataLoaded'> & {
        /** Translated text to indicate the type of items being filtered (passed to `generic.noFilterItems` or `generic.noItems`). */
        noResultsTypeText?: NoResultsContentSignature['Args']['typeText'];
        /** Optional CSS class applied to the `<PageView::Container />` component. */
        cssContainer?: string;
    };
    Blocks: {
        toolbar: [PageViewSignature<M>['Blocks']['toolbar'][0]];
        default: [PageViewContainerSignature<M>['Blocks']['default'][0], PageViewSignature<M>['Blocks']['default'][1]];
    };
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class PageViewList<M> extends Component<PageViewListSignature<M>> {}
