import Service from '@ember/service';
import { inject as service } from '@ember/service';

/**
 * @classdesc
 *
 * A general service used to house browser/desktop specific functionalities.
 */
export default class BrowserService extends Service {
    @service nativeBridge;

    /**
     * Copies the given message to the clipboard.
     *
     * @param message
     *
     * @type {boolean}
     */
    copyToClipboard(message) {
        // Create a DOM element, add the text, copy it to clipboard, then remove it
        const textArea = document.createElement('textarea');
        textArea.visible = false;
        textArea.value = message;

        document.body.appendChild(textArea);
        textArea.select();

        // The document.execCommand('copy') command will return false if there's an error, such as time expiration.
        // However, the above is an obsolete feature, so just in case of failure the Clipboard API is used as a backup option.
        const success = document.execCommand('copy') || navigator.clipboard.writeText(message);

        document.body.removeChild(textArea);
        return success;
    }

    /**
     * Correctly copies to clipboard depending
     * on what device/platform is in use.
     *
     * @param message
     *
     * @returns {Ember.RSVP.Promise}
     */
    universalCopyToClipboard(subject, anchorElement, message) {
        // Mainly for iOS.
        // doesSupportLaunchShareActionDialog fulfills the check for isMobileApp.
        if (this.nativeBridge.doesSupportLaunchShareActionDialog) {
            return this.nativeBridge.launchShareActionDialog(subject, message, anchorElement);
        }

        // Desktop only.
        return (async () => {
            this.copyToClipboard(message);
        })();
    }
}
