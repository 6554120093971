import Service from '@ember/service';
import { computed } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { isEmpty } from '@ember/utils';
import { addWeakListener } from '@adc/ember-utils/utils/event-listeners';
import { later, cancel } from '@ember/runloop';
import { RSVPPromise, RSVPReject } from '@adc/ember-utils/utils/rsvp';
import {
    Initialize,
    SetTitle,
    SetToolbarButtons,
    ShowConfirmationDialog,
    GoBack,
    HandleApplicationError,
    RefreshWebView,
    ReloadAssociatedSystems,
    LaunchShareActionDialog,
    TransitionToNativeView,
    CloseWebView,
    LaunchImportContact,
    LaunchBrowser,
    CloseWebViewV2,
    TerminateAppSession,
    LaunchNewContext,
    PersistAppSession,
    EnablePushNotifications,
    EnableBluetooth,
    CheckBluetoothPermissions,
    StartBleAdvertising,
    StopBleAdvertising
} from '../enums/NativeBridgeFeature.ts';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { BluetoothPermissionEnum } from '../enums/BluetoothPermissionCategory.ts';
import { None } from '../enums/ContextSwitchType.ts';
import { sentryWithScopeAndError } from '../utils/sentry.ts';

// region Constants.

/**
 * Type for button that should transition the view back.
 *
 * @private
 * @static
 * @memberof services.NativeBridge
 *
 * @type {string}
 */
export const BUTTON_TYPE_BACK = 'back';

/**
 * Type for button that cancels an action, but does not go back to a previous route.
 *
 * @private
 * @static
 * @memberof services.NativeBridge
 *
 * @type {string}
 */
export const BUTTON_TYPE_CANCEL = 'cancel';

/**
 * Type for button that confirms an action or saves a form.
 *
 * @private
 * @static
 * @memberof services.NativeBridge
 *
 * @type {string}
 */
export const BUTTON_TYPE_CONFIRM = 'confirm';

/**
 * Type for button that triggers a screen to add an item.
 *
 * @private
 * @static
 * @memberof services.NativeBridge
 *
 * @type {string}
 */
const BUTTON_TYPE_ADD = 'add';
/**
 * Key representing the toolbar button action.
 *
 * @ignore
 * @private
 * @static
 * @memberof services.NativeBridge
 *
 * @type {string}
 */
export const RUN_TOOLBAR_BUTTON_ACTION_NAME = 'runToolbarButtonAction';

/**
 * Key representing the modal button action.
 *
 * @ignore
 * @private
 * @static
 * @memberof services.NativeBridge
 *
 * @type {string}
 */
const RUN_MODAL_BUTTON_ACTION_NAME = 'runModalButtonAction';

/**
 * Key representing function for resolving promise.
 *
 * @ignore
 * @private
 * @static
 * @memberof services.NativeBridge
 *
 * @type {string}
 */
const RUN_RESOLVE_PROMISE_ACTION = 'resolvePromise';

// endregion
// region Helper methods.

/**
 * Executes method on the native bridge.
 *
 * @private
 * @instance
 * @memberof services.NativeBridge
 *
 * @param {Number} featureEnum
 * @param {Object} data
 * @returns {Promise<*>}
 */
function runMethod(featureEnum, data) {
    if (!this.isEnabled) {
        return RSVPReject();
    }

    if (!doesSupportFeature.call(this, featureEnum)) {
        return RSVPReject();
    }

    // Define new RSVPPromise that we will store in cache.
    const promise = RSVPPromise(),
        // Create id for the new promise.
        returnPromiseId = guidFor(promise);

    // Store promise in the cache.
    this.promiseCache[returnPromiseId] = promise;

    let content = {
        feature: featureEnum
    };

    if (data) {
        content.data = data;
    }

    if (returnPromiseId) {
        content.returnPromiseId = returnPromiseId;
    }

    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/no-runloop
    later(() => {
        // Pass data to native bridge.
        this.bridge?.postMessage.call(this.bridge, JSON.stringify(content));
    }, 1);

    return promise.promise;
}

/**
 * Returns toolbar button collection with the specified id.
 *
 * @private
 * @instance
 * @memberof services.NativeBridge
 *
 * @param {String} id
 * @returns {NativeToolbarType}
 */
function getButtonCollectionWithId(id) {
    return getCollectionWithId.call(this, 'toolbarsStack', id);
}

/**
 * Returns collection with collection name and id.
 *
 * @private
 * @instance
 * @memberof services.NativeBridge
 *
 * @param {String} collectionName
 * @param {String} id
 * @returns {*}
 */
function getCollectionWithId(collectionName, id) {
    return this.get(collectionName).findBy('id', id);
}

/**
 * Returns instance of the native bridge that provides communication between Ember and an app.
 *
 * @private
 * @instance
 * @memberof services.NativeBridge
 *
 * @returns {Object|undefined}
 */
function getBridge() {
    const { bridgeName } = this;
    let bridge = (window[bridgeName] = window[bridgeName] || ((window.webkit || {}).messageHandlers || {})[bridgeName]);

    if (!bridge?.postMessage) {
        bridge = window.NativeBridge = undefined;
    }

    return bridge;
}

/**
 * Does the native application have support for the specified feature?
 *
 * @private
 * @instance
 * @memberof services.NativeBridge
 *
 * @param {Number} featureEnum Feature to check support for.
 * @returns {boolean}
 */
function doesSupportFeature(featureEnum) {
    return this.isEnabled && (this.supportedNativeBridgeFeatures || []).includes(featureEnum);
}

/**
 * Does the native application have support for transitioning to the specified view?
 *
 * @private
 * @instance
 * @memberof services.NativeBridge
 *
 * @param {Number} viewEnum View to check support for.
 * @returns {boolean}
 */
function doesSupportNativeView(viewEnum) {
    return (this.supportedNativeViews || []).includes(viewEnum);
}

/**
 * Creates a copy of an object with id property.
 *
 * @private
 * @static
 * @memberof services.NativeBridge
 *
 * @param {Object} object
 * @returns {Object}
 */
function getObjectWithoutId(object) {
    // eslint-disable-next-line no-unused-vars
    let { id, ...newObject } = object;

    return newObject;
}

// endregion

// region Bridge interface extensions.

/**
 * Sets up all button actions that will handle clicks of the native buttons.
 *
 * @private
 * @instance
 * @memberof services.NativeBridge
 */
function setupActionsOnBridge() {
    const { bridge } = this;

    // Define how to execute a toolbar button function.
    bridge[RUN_TOOLBAR_BUTTON_ACTION_NAME] = (toolbarId, buttonPath, index) => {
        const toolbar = getButtonCollectionWithId.call(this, toolbarId);

        if (!toolbar) {
            console.error(`Tried to access non existing native buttons toolbar, toolbarId=${toolbarId}`);
            return;
        }

        const buttons = toolbar[buttonPath];

        if (!buttons) {
            console.error(
                `Tried to access non existing buttonPath in a toolbar, toolbarId=${toolbarId}, buttonPath=${buttonPath}`
            );
            return;
        }

        const button = buttons[index];

        if (!button) {
            console.error(
                `Tried to access non existing button in a toolbar, toolbarId=${toolbarId}, buttonPath=${buttonPath}, index=${index}`
            );
            return;
        }

        const action = button.action;

        // This should never happen.
        if (!action) {
            console.error(
                `Button does not have an action defined, toolbarId=${toolbarId}, buttonPath=${buttonPath}, index=${index}`
            );
            return;
        }

        // Finally run the button action.
        return action();
    };

    // Define modal actions.
    // This is much simpler because there is always at most one modal open.
    bridge[RUN_MODAL_BUTTON_ACTION_NAME] = (buttonIndex) => {
        const button = (this.modalButtons || []).objectAt(buttonIndex);

        if (!button) {
            console.error(`Tried to access non existing modal button, index=${buttonIndex}`);
            return;
        }

        const { action } = button;

        // This should never happen.
        if (!action) {
            console.error(`Modal button does not have an action, index=${buttonIndex}`);
        }

        // Run button action.
        return action();
    };

    // Define method for resolving promises.
    bridge[RUN_RESOLVE_PROMISE_ACTION] = ({ promiseId, data, error } = {}) => {
        if (!promiseId) {
            console.error('No promise id defined when resolving promise from App.');
            return;
        }

        // Get promise.
        const promise = this.promiseCache[promiseId];

        if (!promise) {
            // Log an error to sentry. Send extra data to help better track the problem.
            const wasPreviouslyResolved = promiseId in this.previouslyResolvedPromiseIds,
                sentryExtras = {
                    promiseId,
                    wasPreviouslyResolved,
                    latestResolvedTime: this.previouslyResolvedPromiseIds[promiseId] ?? '',
                    data,
                    error
                },
                sentryTags = { wasPreviouslyResolved };

            sentryWithScopeAndError(
                'native-bridge: No promise found when resolving action from App',
                sentryExtras,
                sentryTags
            );

            return;
        }

        // Resolve or reject promise.
        if (error) {
            console.error(error);
            promise.reject(error);
        } else {
            // If data was not undefined, it should have come as a stringified object.
            // Therefore, try to deserialize it and return as a result of the promise.
            if (data !== undefined) {
                try {
                    data = JSON.parse(data);
                } catch (e) {
                    // Do nothing; we will just use data as it came in, but log an error so that we can look at it.
                    // The reason for logging error is that we expect data to be returned serialized.
                    console.error(`Could not deserialize data returned from NativeBridge method, data=${data}`);
                }
            }

            promise.resolve(data);
        }

        // Remove promise from cache.
        this.previouslyResolvedPromiseIds[promiseId] = new Date().toISOString();
        delete this.promiseCache[promiseId];
    };
}

// endregion

// region Title processing.

/**
 * Sends the current title to the app.
 *
 * @private
 * @instance
 * @memberof services.NativeBridge
 */
function sendTitleToApp() {
    (async () => runMethod.call(this, SetTitle, getObjectWithoutId(this.titleStack?.lastObject || {})))();
}

// endregion

// region Buttons processing.

/**
 * Transforms button into a button object that can be passed to the native app.
 *
 * @private
 * @instance
 * @memberof services.NativeBridge
 *
 * @param {NativeButtonType} buttonObject
 * @param {Function} bridgeMethodKey Method to run on the bridge when a native button is clicked.
 * @param {Array} params Array of arguments that should be passed into the bridge method to be executed.
 * @returns {{text: String, description: String=, icon: String=, type: String=, promisePending: boolean, actionPath: String}}
 */
function transformButtonObject(buttonObject, bridgeMethodKey, ...params) {
    let { text, description, icon, type, isPromisePending } = buttonObject;

    // Predefine params string that will be used for method eval.
    let paramsString = '';

    // If we got params then put them in a format consumable by eval.
    if (!isEmpty(params)) {
        paramsString = `'${params.join("','")}'`;
    }

    isPromisePending = isPromisePending ? isPromisePending.toString() : 'false';

    return {
        text,
        description,
        icon,
        type,
        isPromisePending,
        actionPath: wrapJsInTryCatch(`window.${this.bridgeName}.${bridgeMethodKey}(${paramsString})`)
    };
}

/**
 * Translates NativeToolbarType to something that is consumable by the native app.
 *
 * @private
 * @instance
 * @memberof services.NativeBridge
 *
 * @param toolbar
 * @returns {*}
 */
function translateToolbarButtons(toolbar) {
    if (!toolbar) {
        return null;
    }

    const { id } = toolbar;

    // Create object for toolbar buttons that we will be using to communicate to the app.
    const data = {
        id,
        backButtons: [],
        contextButtons: []
    };

    // Transform buttons.
    ['backButtons', 'contextButtons'].forEach((buttonPath) => {
        (toolbar[buttonPath] || []).forEach((button, index) => {
            // Skip a button if it does not have an action. This should not happen.
            if (!button.action) {
                console.error(
                    `Skipping a button, because it does not have an action. buttonPath=${buttonPath}, index=${index}`
                );
                return;
            }

            data[buttonPath].push(
                transformButtonObject.call(this, button, RUN_TOOLBAR_BUTTON_ACTION_NAME, id, buttonPath, index)
            );
        });
    });

    return data;
}

/**
 * Sends the current toolbar button interface to the app.
 *
 * @private
 * @instance
 * @memberof services.NativeBridge
 */
function sendToolbarButtonsToApp() {
    (async () =>
        runMethod.call(
            this,
            SetToolbarButtons,
            getObjectWithoutId(translateToolbarButtons.call(this, this.toolbarsStack?.lastObject || {}))
        ))();
}

// endregion

// region Processing collections.

/**
 * Processes update of collection.
 *
 * @private
 * @instance
 * @memberof services.NativeBridge
 *
 * @param {String} stackType Key for the stack name.
 * @param {Object} newCollection Collection to be updated.
 * @param {Function} updateAction Function to be executed to send data to the app.
 * @param {Number} featureEnum Key for checking support for this feature.
 */
function processCollectionUpdate(stackType, newCollection, updateAction, featureEnum) {
    if (!doesSupportFeature.call(this, featureEnum)) {
        return;
    }

    if (!newCollection) {
        return;
    }

    const { id } = newCollection,
        existingCollection = getCollectionWithId.call(this, stackType, id),
        stack = this.get(stackType);

    // Now figure out if we need to push the toolbar to the app or not.
    if (existingCollection) {
        const indexOfExisting = stack.indexOf(existingCollection);

        // If the existing collection is the last one, pop it so that it can be re-added.
        // In this case we need to send update.
        if (indexOfExisting === stack.get('length') - 1) {
            stack.popObject();
        } else {
            // Update the object at the index. No update is necessary.
            stack[indexOfExisting] = newCollection;

            return;
        }
    }

    // Push new set to the stack.
    stack.pushObject(newCollection);

    // Send stuff to app.
    updateAction.call(this);
}

/**
 * Processes disposal of property when it is removed from the view.
 *
 * @private
 * @instance
 * @memberof services.NativeBridge
 *
 * @param {String} stackType Key for the stack name.
 * @param {String} id Id of the collection to be disposed.
 * @param {Function} updateAction Function to be executed to send data to the app.
 * @param {Number} featureEnum Key for checking support for this feature.
 */
function processCollectionDispose(stackType, id, updateAction, featureEnum) {
    if (!doesSupportFeature.call(this, featureEnum)) {
        return;
    }

    const existingCollection = getCollectionWithId.call(this, stackType, id),
        stack = this.get(stackType);

    // If this is the last update, then need to update the collection in the app.
    const needsUpdate = stack.get('lastObject') === existingCollection;

    stack.removeObject(existingCollection);

    if (needsUpdate) {
        updateAction.call(this);
    }
}

// endregion

// region Initialization.

/**
 * Threshold for logging initialization duration as warning.
 *
 * @ignore
 * @private
 * @instance
 * @memberof services.NativeBridge
 *
 * @type {number}
 */
const INITIALIZATION_WARN_THRESHOLD = 500;

/**
 * Threshold for logging initialization error and stopping the initialization process.
 *
 * @ignore
 * @private
 * @instance
 * @memberof services.NativeBridge
 *
 * @type {number}
 */
const INITIALIZATION_ERROR_THRESHOLD_MS = 5000;

/**
 * Initializes bridge and its properties.
 *
 * @private
 * @instance
 * @memberof services.NativeBridge
 *
 * @returns {Promise<void>}
 */
async function initializeBridge() {
    const promiseResolveDataReplaceTemplate = '{data}',
        startTime = new Date();

    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/no-runloop
    const initializationFailSafe = later(() => {
        console.error(
            `NativeBridge initialization took longer than ${INITIALIZATION_ERROR_THRESHOLD_MS}ms, marking as initialized and disabled as fail safe.`
        );

        // Only resolve if this is not dev so that we can catch issues.
        if (!this.envSettings.isDevelopmentEnvironment()) {
            this.set('isEnabled', false);
        }
    }, INITIALIZATION_ERROR_THRESHOLD_MS);

    try {
        // Start setup. Need to make sure that this completes before anything else.
        await runMethod.call(
            this,
            Initialize,
            {
                promiseResolvePath: wrapJsInTryCatch(
                    `window.${this.bridgeName}.${RUN_RESOLVE_PROMISE_ACTION}(${promiseResolveDataReplaceTemplate});`
                ),
                promiseResolveDataReplaceTemplate
            },
            true
        );

        // Cancel fail safe because everything went well.
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        cancel(initializationFailSafe);

        const endTime = new Date(),
            duration = endTime.getTime() - startTime.getTime();

        // Log if time was less than a threshold, warn if more.
        (duration > INITIALIZATION_WARN_THRESHOLD ? console.warn : console.log)(
            `NativeBridge initialization took ${duration}ms`
        );
    } catch (e) {
        console.error(e);

        this.set('isEnabled', false);
    }
}

// endregion

/**
 * @classdesc
 *
 * Native bridge functionality between the Ember application and a Native application.
 *
 * @class NativeBridge
 * @extends Service
 * @memberof services
 */
// TODO: This ignore is an existing deprecation that must be refactored, do not copy this usage
// eslint-disable-next-line ember/no-classic-classes
export default Service.extend(
    /** @lends services.NativeBridge */ {
        envSettings: service(),

        /**
         * Name of the bridge.
         *
         * @type {String}
         */
        bridgeName: 'NativeBridge',

        /**
         * Variable to hold a cache of promises that will be resolved by the native application.
         *
         * @type {Array<RSVPPromise>}
         */
        promiseCache: (() => [])(),

        /**
         * Is the bridge enabled and usable?
         *
         * @type {boolean}
         */
        isEnabled: false,

        /**
         * A list of IDs of previously resolved promises.
         *
         * @note Using this to help debug a Sentry error where the promiseId from the app
         * is not within the promiseCache.
         *
         * @type {{ [ id: string ]: timestamp: string }}
         */
        previouslyResolvedPromiseIds: null,

        /**
         * Initializes native bridge service with the supported features.
         *
         * @param {Array<Number>} supportedNativeBridgeFeatures
         * @param {Array<Number>} supportedNativeViews
         * @returns {Promise<void>}
         */
        async initialize(supportedNativeBridgeFeatures, supportedNativeViews) {
            const bridge = getBridge.call(this);
            const isEnabled = bridge && !isEmpty(supportedNativeBridgeFeatures);

            this.setProperties({
                isEnabled,
                bridge,
                supportedNativeBridgeFeatures,
                supportedNativeViews,
                previouslyResolvedPromiseIds: {}
            });

            if (!isEnabled) {
                // Nothing to initialize, so resolve initialization.
                return;
            }

            // Need to setup actions on bridge before we attempt any communication.
            setupActionsOnBridge.call(this);

            // Initialize bridge communication.
            await initializeBridge.call(this);

            // Make sure that the toolbar gets reset if the page gets unloaded.
            addWeakListener(this, window, 'beforeunload', () => this.resetToolbarButtons());
        },

        // region Feature supports.

        /**
         * Does the app support changing of the header title?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportTitle: computed(function () {
            return doesSupportFeature.call(this, SetTitle);
        }),

        /**
         * Does the app support creation of action buttons?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportToolbarButtons: computed(function () {
            return doesSupportFeature.call(this, SetToolbarButtons);
        }),

        /**
         * Does the app support showing a confirmation dialog?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportConfirmationDialog: computed(function () {
            return doesSupportFeature.call(this, ShowConfirmationDialog);
        }),

        /**
         * Does the app support telling the app to go back in the App's view stack?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportGoBack: computed(function () {
            return doesSupportFeature.call(this, GoBack);
        }),

        /**
         * Does the app support refreshing of the WebView?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportRefreshWebView: computed(function () {
            return doesSupportFeature.call(this, RefreshWebView);
        }),

        /**
         * Does the app support refreshing Available Systems?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportReloadAssociatedSystems: computed(function () {
            return doesSupportFeature.call(this, ReloadAssociatedSystems);
        }),

        /**
         * Does the app support launching share dialog?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportLaunchShareActionDialog: computed(function () {
            return doesSupportFeature.call(this, LaunchShareActionDialog);
        }),

        /**
         * Does the app support transitioning to a native view?
         *
         * NOTE: Consider using doesSupportTransitionToNativeViewWithId instead.
         *
         * @function
         * @returns {boolean}
         */
        doesSupportTransitionToNativeView: computed(function () {
            return doesSupportFeature.call(this, TransitionToNativeView);
        }),

        /**
         * Does the app support transitioning to native view with specified id?
         *
         * @function
         * @param viewEnum NativeView to transition to
         * @returns {boolean}
         */
        doesSupportTransitionToNativeViewWithId: function (viewEnum) {
            return doesSupportFeature.call(this, TransitionToNativeView) && doesSupportNativeView.call(this, viewEnum);
        },

        /**
         * Does the app support closing the current web view?
         *
         * @function
         * @returns {boolean}
         *
         * @deprecated Use CloseWebViewV2 instead.
         */
        doesSupportCloseWebView: computed(function () {
            return doesSupportFeature.call(this, CloseWebView);
        }),

        /**
         * Does the app support importing a contact?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportLaunchImportContact: computed(function () {
            return doesSupportFeature.call(this, LaunchImportContact);
        }),

        /**
         * Does the app support launching an external browser?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportLaunchBrowser: computed(function () {
            return doesSupportFeature.call(this, LaunchBrowser);
        }),

        /**
         * Does the app support version 2 of closing a webview?
         *
         * CloseWebViewV2 contains a bug fix for CloseWebView.
         *
         * @function
         * @returns {boolean}
         */
        doesSupportCloseWebViewV2: computed(function () {
            return doesSupportFeature.call(this, CloseWebViewV2);
        }),

        /**
         * Does the app support terminating a user's session?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportTerminateAppSession: computed(function () {
            return doesSupportFeature.call(this, TerminateAppSession);
        }),

        /**
         * Does the app support launching passed content a new fragment (Android) or modal (iOS)?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportLaunchNewContext: computed(function () {
            return doesSupportFeature.call(this, LaunchNewContext);
        }),

        /**
         * Does the app support persisting the session?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportPersistAppSession: computed(function () {
            return doesSupportFeature.call(this, PersistAppSession);
        }),

        /**
         * Does the app support enabling push notifications on the device?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportEnablePushNotifications: computed(function () {
            return doesSupportFeature.call(this, EnablePushNotifications);
        }),

        /**
         * Does the app support enabling bluetooth?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportEnableBluetooth: computed(function () {
            return doesSupportFeature.call(this, EnableBluetooth);
        }),

        /**
         * Does the app support checking bluetooth permissions?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportCheckBluetoothPermissions: computed(function () {
            return doesSupportFeature.call(this, CheckBluetoothPermissions);
        }),

        /**
         * Does the app support start BLE advertising?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportStartBleAdvertising: computed(function () {
            return doesSupportFeature.call(this, StartBleAdvertising);
        }),

        /**
         * Does the app support stop BLE advertising?
         *
         * @function
         * @returns {boolean}
         */
        doesSupportStopBleAdvertising: computed(function () {
            return doesSupportFeature.call(this, StopBleAdvertising);
        }),

        // endregion

        // region Title.

        /**
         * Stack of title that correspond to an id that wants them to be shown.
         *
         * @function
         * @returns {Array<NativeTitleType>}
         */
        titleStack: computed(function () {
            return A();
        }),

        /**
         * Sets header title in the app.
         *
         * @param {NativeTitleType} titleCollection
         */
        setTitle(titleCollection) {
            processCollectionUpdate.call(this, 'titleStack', titleCollection, sendTitleToApp, SetTitle);
        },

        /**
         * Removes title with the specified id.
         *
         * @param {String} id
         */
        removeTitle(id) {
            processCollectionDispose.call(this, 'titleStack', id, sendTitleToApp, SetTitle);
        },

        // endregion

        // region Toolbar buttons.

        /**
         * Stack of toolbar buttons.
         *
         * @function
         * @returns {Array<{NativeToolbarType}>}
         */
        toolbarsStack: computed(function () {
            return A();
        }),

        /**
         * Sets toolbar buttons in the app.
         *
         * @param {NativeToolbarType} toolbar
         */
        setToolbarButtons(toolbar) {
            processCollectionUpdate.call(this, 'toolbarsStack', toolbar, sendToolbarButtonsToApp, SetToolbarButtons);
        },

        /**
         * Removes toolbar buttons with the specified component id.
         *
         * @param {String} id
         */
        removeToolbarButtons(id) {
            processCollectionDispose.call(this, 'toolbarsStack', id, sendToolbarButtonsToApp, SetToolbarButtons);
        },

        /**
         * Resets all toolbar buttons.
         *
         * This can happen when the Ember application is completely unloaded so that artifacts don't remain on the screen/
         */
        resetToolbarButtons() {
            if (!this.doesSupportToolbarButtons) {
                return;
            }

            this.toolbarsStack.clear();

            sendToolbarButtonsToApp.call(this);
        },

        // endregion

        // region Button Type.
        // NOTE: The following getters are used by addons that depend on an injected native bridge service.

        /**
         * Returns the back button type.
         */
        getButtonTypeBack() {
            return BUTTON_TYPE_BACK;
        },

        /**
         * Returns the cancel button type.
         */
        getButtonTypeCancel() {
            return BUTTON_TYPE_CANCEL;
        },

        /**
         * Returns the confirm button type.
         */
        getButtonTypeConfirm() {
            return BUTTON_TYPE_CONFIRM;
        },

        /**
         * Returns the add button type.
         */
        getButtonTypeAdd() {
            return BUTTON_TYPE_ADD;
        },

        // endregion

        // region Confirmation dialog.

        /**
         * Shows native confirmation dialog.
         *
         * @param {String} title
         * @param {String} message
         * @param {Array<NativeButtonType>} buttons
         * @returns {Promise}
         */
        async showConfirmationDialog(title, message, buttons) {
            this.set('modalButtons', buttons);

            if (isEmpty(buttons)) {
                console.error('No buttons defined for confirmation modal.');
                return;
            }

            // Return the promise so that the caller can know whether the modal opening succeeded or not.
            return runMethod.call(this, ShowConfirmationDialog, {
                title,
                message,
                buttons: buttons.map((button, index) =>
                    transformButtonObject.call(this, button, RUN_MODAL_BUTTON_ACTION_NAME, index)
                )
            });
        },

        /**
         * Shows native share dialog.
         *
         * @param {String} subject
         * @param {String} message
         * @param {HTMLElement} anchorElement - The anchor element where the popover should be positioned. (iPad uses a popover)
         * @returns {Promise}
         */
        async launchShareActionDialog(subject, message, anchorElement) {
            const rect = anchorElement.getBoundingClientRect(),
                buttonWidth = rect.right - rect.left;

            return runMethod.call(this, LaunchShareActionDialog, {
                subject,
                message,
                xCoordinate: rect.left + buttonWidth / 2,
                yCoordinate: rect.top
            });
        },

        // endregion

        // region Import contact alert

        /**
         * Launches import contact dialog and recieves contact information when client resolves promise.
         *
         * @returns {Promise<*>}
         */
        async launchImportContact() {
            return runMethod.call(this, LaunchImportContact);
        },

        // endregion

        // region Application transition.

        /**
         * Tells the native application to go back.
         *
         * @returns {Promise}
         */
        async goBack() {
            return runMethod.call(this, GoBack);
        },

        /**
         * Tells the native application to handle Ember error.
         *
         * @returns {Promise<*>}
         */
        async handleError() {
            return runMethod.call(this, HandleApplicationError);
        },

        /**
         * Tells the native application to refresh the whole WebView.
         *
         * @returns {Promise<*>}
         */
        refreshWebView() {
            return runMethod.call(this, RefreshWebView);
        },

        // endregion

        // region Reload Associated Systems.

        /**
         * Tells the native application to reload the list of associated systems.
         *
         * @returns {Promise<*>}
         */
        reloadAssociatedSystems() {
            return runMethod.call(this, ReloadAssociatedSystems);
        },

        // endregion

        // region Transition to Native View.

        /**
         * Tells the native application to transition to the requested native view.
         *
         * @param nativeViewId {Number} The id of the native view to which we will transition.
         * @param transitionData {NativeViewTransitionDataType} Contains extra data used in determining how to handle the transition.
         *
         * @returns {Promise<*>}
         */
        transitionToNativeView(nativeViewId, transitionData = {}) {
            if (isEmpty(nativeViewId)) {
                console.error(`Could not transition to NativeView, nativeViewId: ${nativeViewId}`);
                return;
            }

            return runMethod.call(this, TransitionToNativeView, {
                nativeViewId,
                shouldAvoidReloadingWebView: false,
                deviceID: null,
                macAddress: null,
                showBackArrow: true,
                returnToWebView: true,
                contextSwitchType: None,
                customerId: null,
                groupId: null,
                ...transitionData
            });
        },

        // endregion

        /**
         * Tells the native application to close the whole WebView.
         *
         * @returns {Promise<*>}
         *
         * @deprecated Use CloseWebViewV2 instead.
         */
        closeWebView() {
            return runMethod.call(this, CloseWebView);
        },

        /**
         * Tells the native application to open an external browser with the given url.
         *
         * @param url {String} The url to load.
         * @param isForSso {String} Will this be used for SSO?
         *
         * @returns {Promise<*>}
         */
        launchBrowser(url, isForSso = false) {
            return runMethod.call(this, LaunchBrowser, {
                url,
                isForSso
            });
        },

        /**
         * Tells the native application to close the whole WebView.
         * CloseWebViewV2 contains a bug fix from the original CloseWebView and should be used instead.
         *
         * @returns {Promise<*>}
         */
        closeWebViewV2() {
            return runMethod.call(this, CloseWebViewV2);
        },

        /**
         * Tells the native application to terminate the user's session and return to the login screen
         *
         * @returns {Promise<*>}
         */
        terminateAppSession() {
            return runMethod.call(this, TerminateAppSession);
        },

        /**
         * Tells the native application to open a webView in a new fragment (Android) or modal (iOS)
         *
         * @param emberId {Number} The specific Ember webpage ID to load in the mobile app, takes precedence over URL if set.
         * @param url {String} The hard-coded URL to load in the event we are not loading an Ember page in the new context.
         *
         * @returns {Promise<*>}
         */
        launchNewContext(emberId, url) {
            return runMethod.call(this, LaunchNewContext, { emberId, url });
        },

        /**
         * Tells the native application to keep the session alive and terminates all other sessions.
         *
         * @returns {Promise<*>}
         */
        persistAppSession() {
            return runMethod.call(this, PersistAppSession);
        },

        /**
         * Tells the native application to prompt the user to enable push notifications.
         * If notification types are provided, save notifications of that type.
         *
         * @param {Number[]} notificationTypes  The notification types from CustomerNotificationTypeEnum to save as push notifications.
         *
         * @returns {Promise<{enabled: boolean} | void>}
         */
        enablePushNotifications(notificationTypes) {
            return runMethod.call(this, EnablePushNotifications, { notificationTypes });
        },

        /**
         * Tells the native application to enable bluetooth
         *
         * @returns {Promise<boolean | void>}
         */
        enableBluetooth() {
            return runMethod.call(this, EnableBluetooth);
        },

        /**
         * Tells the native application to check bluetooth permissions
         *
         * @param {number} permissionToCheck The numerical value of BluetoothPermissionCategory, for the permission to check
         *
         * @returns {Promise<boolean | void>}
         */
        checkBluetoothPermissions(permissionToCheck) {
            if (!Object.values(BluetoothPermissionEnum).includes(permissionToCheck)) return null;
            return runMethod.call(this, CheckBluetoothPermissions, { permissionToCheck });
        },

        /**
         * Tells the native application to start BLE advertising
         *
         * @returns {Promise<boolean | void>}
         */
        startBleAdvertising() {
            return runMethod.call(this, StartBleAdvertising);
        },

        /**
         * Tells the native application to stop BLE advertising
         *
         * @returns {Promise<boolean | void>}
         */
        stopBleAdvertising() {
            return runMethod.call(this, StopBleAdvertising);
        },

        willDestroy() {
            this._super();

            delete this.bridge;
            delete window.NativeBridge;
        }
    }
);

/**
 * Wraps javascript in a try/catch block.
 *
 * @param {string} jsString
 */
export function wrapJsInTryCatch(jsString) {
    return `try { ${jsString} } catch (e) { console.error(e); }`;
}

// region Type definitions.

/**
 * Type definition for constructing a native button in App that runs Ember as a WebView.
 *
 * @typedef {{
 *     text: String,
 *     description: String,
 *     icon: String,
 *     isPromisePending: boolean,
 *     action: Function
 * }} NativeButtonType
 *
 * @property {String} text The text of the button.
 * @property {String=} description Description of the button.
 * @property {String=} icon Icon name that should be rendered in the native app.
 * @property {boolean} isPromisePending Indicates if the button has a promise pending for its action.
 * @property {Function} action Action to be called when button is pressed.
 *
 * @memberof services.NativeBridge
 */

/**
 * Type definition for constructing a native toolbar in App that runs Ember as a WebView.
 *
 * @typedef {{
 *     id: String,
 *     backButtons: Array<NativeButtonType>,
 *     contextButtons: Array<NativeButtonType>
 * }} NativeToolbarType
 *
 * @property {String} id Uniquely identifies this button collection.
 * @property {Array<NativeButtonType>=} backButtons Definition for a button that will be providing a "cancel" functionality, or simply going "back" functionality. This is defined as a list, but it really should only contain one item at all times.
 * @property {Array<NativeButtonType>=} contextButtons List of context buttons that can trigger a functionality. These are on the right side.
 *
 * @memberof services.NativeBridge
 */

/**
 * Type definition for constructing a native title text in App that runs Ember as WebView.
 *
 * @typedef {{
 *     id: String,
 *     title: String
 * }} NativeTitleType
 *
 * @property {String} id Uniquely identifies this title collection. Usually a component id.
 * @property {String} title Title to be shown in the App's header.
 *
 * @memberof services.NativeBridge
 */

/**
 * Data to be sent with the TransitionToNativeView feature.
 *
 * @note Updating this Type will require an APP change in order to know what to do with the data.
 *
 * @typedef {{
 *     shouldAvoidReloadingWebView: boolean,
 *     deviceID: String,
 *     macAddress: String,
 *     showBackArrow: boolean,
 *     returnToWebView: boolean,
 *     contextSwitchType: Number,
 *     customerId: Number
 * }} NativeViewTransitionDataType
 *
 * @property {boolean} shouldAvoidReloadingWebView - Should the WebView avoid reloading itself if the native view transitions back to the WebView? NOTE: This only affects iOS currently.
 * @property {String} deviceID - The device ID of a Managed Device. The device ID is expected to be split at the '-'.
 * @property {String} macAddress - The MAC address of a Managed Device.
 * @property {boolean} showBackArrow - Should the back arrow be shown on the native view. Used for CarSettingsV2.
 * @property {boolean} returnToWebView - Should the back arrow return the user to the original webview. Used for CarSettingsV2.
 * @property {Number} contextSwitchType - The enum representing the direction of the context switch (e.g. enterprise to single-system).
 * @property {Number} customerId - The ID of the customer we are transitioning to.
 *
 * @memberof services.NativeBridge
 */

// endregion
