import { isNone } from '@ember/utils';
import { assert } from '@ember/debug';
import config from 'ember-get-config';

import type { TOptions } from 'ember-intl/services/intl';
import type Component from '@glimmer/component';
import type EmberObject from '@ember/object';
import type ADCIntlService from '../services/adc-intl';

/** @module GetTranslation */

/**
 * Regex for extracting the module, path, and type from the custom `getIntlPath()` context method.
 *
 * @private
 * @ignore
 */
const CUSTOM_TRANSLATION_CONTEXT_REGEX = new RegExp(
    '^<([^:]+)\\@(component|controller|route|model|service){1}:([^:]+)(::)'
);

/**
 * Regex for extracting the path and type from a concrete context object.
 *
 * @private
 * @ignore
 */
const TRANSLATION_CONTEXT_REGEX = new RegExp('^<(?:[^:]+)\\@(component|controller|route|model|service){1}:([^:]+)(::)');

/**
 * Regex for extracting the path and type from a generated context object.
 *
 * @private
 * @ignore
 */
const GENERATED_CONTEXT_REGEX = new RegExp('^\\(generated ([^\\s]+) ([^\\s]+)\\)$');

/**
 * These types need to have their type prefixed in the path for translation when using them as context.
 *
 * @private
 * @ignore
 */
const TYPES_WITH_PREFIX = ['component', 'model', 'service'];

// eslint-disable-next-line @typescript-eslint/ban-types
export type IntlContext = Component<any> | Component<{ Args: { Named: {}; Positional: [] } }> | EmberObject;

export interface IntlTextOptions extends TOptions {
    links?: string[];
}

type IntlPathInfo = {
    path: string[];
    type: string;
    module?: string;
};

/**
 * Tries to extract the module, path, and type from the context, using known patterns to match against, returning null if unable to extract.
 *
 * @private
 */
function getCustomPath(context: IntlContext): IntlPathInfo | null {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const text = context.getIntlPath?.();
    if (!text) {
        return null;
    }

    const concreteMatch = text.match(CUSTOM_TRANSLATION_CONTEXT_REGEX);
    if (!concreteMatch) {
        return null;
    }

    const module = concreteMatch[1];

    // Return match.
    return {
        module: module !== config.modulePrefix ? module : undefined,
        path: concreteMatch[3].split('/'),
        type: concreteMatch[2]
    };
}

/**
 * Tries to extract the path and type from the context, using known patterns to match against, returning null if unable to extract.
 *
 * @private
 */
function getStandardPath(context: IntlContext): IntlPathInfo | null {
    // Match against both patterns.
    const text = context.toString?.();
    if (!text) {
        return null;
    }

    const concreteMatch = text.match(TRANSLATION_CONTEXT_REGEX),
        generatedMatch = text.match(GENERATED_CONTEXT_REGEX),
        fnCreateResponse = (path: string, type: string) => ({
            path: path.split('/'),
            type
        });

    // Is there a concrete match?
    if (concreteMatch) {
        // Return match.
        return fnCreateResponse(concreteMatch[2], concreteMatch[1]);
    }

    // Is there a generated match?
    if (generatedMatch) {
        // Return match.
        return fnCreateResponse(generatedMatch[1], generatedMatch[2]);
    }

    // No match found 😞.
    return null;
}

/**
 * Returns dot delimited translation path for Ember class.
 *
 * @private
 */
function getTranslationPathFromContext(context?: IntlContext): string {
    // Was no context supplied?
    if (!context) {
        return '';
    }

    // Can we NOT determine the context path and type?
    const contextPath = getCustomPath(context) ?? getStandardPath(context),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value = context.getIntlPath?.() ?? context.toString?.() ?? '';

    assert(
        `[@adc/i18n] The returned i18n context path of the object passed to the intl service tc method (or template helper) does not return a usable value: "${value}"`,
        contextPath
    );

    const { path, type, module } = contextPath;

    // If this is a type that need to be prefixed, add the prefix to the path.
    if (TYPES_WITH_PREFIX.includes(type)) {
        path.unshift(`${type}s`);
    }

    // Is there a custom module?
    if (module) {
        // Add custom module to beginning of path.
        path.unshift(module);
    }

    // Add element (to get trailing dot).
    path.push('');

    // Join and return.
    return path.join('.');
}

/**
 * Gets the translation key and replacement tokens for the arguments passed into the function.
 *
 * @private
 */
function getTranslationKeyAndTokens(
    contextOrKey: IntlContext | string,
    keyOrReplaceTokens?: string | IntlTextOptions,
    replaceTokensOnly?: IntlTextOptions
): {
    key: string;
    replaceTokens?: IntlTextOptions;
} {
    const hasContext = typeof contextOrKey !== 'string',
        context = hasContext ? (contextOrKey as IntlContext) : undefined;

    assert(
        '[@adc/i18n] No context was passed to the intl service tc method (or template helper)',
        !hasContext || context
    );

    const key = (hasContext ? keyOrReplaceTokens : contextOrKey) as string,
        replaceTokens = (hasContext ? replaceTokensOnly : keyOrReplaceTokens) as IntlTextOptions | undefined;

    assert('[@adc/i18n] The passed translation key was empty', key);

    return {
        key: `${getTranslationPathFromContext(context)}${key}`,
        replaceTokens
    };
}

/**
 * Returns the translated string for the context, translation key and replace tokens
 *
 * @example
 * import getTranslation from '@adc/i18n/utils/get-translation';
 *
 * getTranslation(intl, 'home.locks.accessTime', {
 *     dateTime: '12:54 PM'
 * });          // "at 12:54 PM"
 *
 * @example
 * // If calling this from the home/locks route:
 * import getTranslation from '@adc/i18n/utils/get-translation';
 *
 * getTranslation(this, 'accessTime', {
 *     dateTime: '12:54 PM'
 * });          // "at 12:54 PM"
 */
export default function getTranslation(
    intl: ADCIntlService,
    contextOrKey: IntlContext | string,
    keyOrReplaceTokens?: string | IntlTextOptions,
    replaceTokensOnly?: IntlTextOptions
): string {
    // Get the full translation key and correct replaceTokens.
    const { key, replaceTokens = {} } = getTranslationKeyAndTokens(contextOrKey, keyOrReplaceTokens, replaceTokensOnly);

    // Get translation.
    let text = intl.lookup(key);

    const optionKeys = Object.keys(replaceTokens);

    // Were replacement tokens passed?
    if (text && optionKeys.length) {
        const options: Record<string, unknown> = { ...replaceTokens };
        optionKeys.forEach((k) => {
            if (!['links', 'default', 'htmlSafe', 'locale'].includes(k)) {
                const v = options[k];
                options[k] = String(isNone(v) ? '' : v);
            }
        });

        // Replace tokens.
        text = intl.formatMessage(text, options);
    }

    return text as string;
}
