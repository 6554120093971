import Component from '@glimmer/component';
import { action } from '@ember/object';
import { A } from '@ember/array';

import type { CommonInputErrorTooltipArgs } from './error-tooltip';
import type { BinaryLabelSignature } from './simple-binary/label';

export type InputType = 'checkbox' | 'radio';
type BinaryLabelSignatureArgs = BinaryLabelSignature['Args'];

export interface SimpleBinaryArgs
    extends CommonInputErrorTooltipArgs,
        Pick<BinaryLabelSignatureArgs, 'icon' | 'iconFill'> {
    /** The binary type to show (do not use `list`, it's internal). */
    type?: 'checkbox' | 'radio' | 'toggle' | 'list';
    /** The current binary state (defaults to `false`). */
    checked?: true | false | 'mixed';
    /** Text for the input label. */
    label?: BinaryLabelSignatureArgs['text'];
    /** Optional CSS class for the binary input container element. */
    css?: string;
    /** Indicates the positions of the input/icon/label should be reversed. */
    reverse?: boolean;
    /** Indicates the label text should wrap.  NOTE this will likely mess up vertical centering. */
    wrap?: boolean;
}

export interface SimpleBinarySignature {
    Element: HTMLInputElement;
    Args: SimpleBinaryArgs;
    Blocks: {
        default: [];
    };
}

export default class SimpleBinary extends Component<SimpleBinarySignature> {
    get type(): InputType {
        return this.args.type === 'radio' ? 'radio' : 'checkbox';
    }

    get css(): string {
        const { args } = this;

        return A([
            'simple-binary',
            args.type === 'toggle' ? 'is-toggle' : undefined,
            args.css,
            args.errorMessage ? 'has-error' : undefined,
            args.reverse ? 'is-reversed' : undefined,
            args.wrap ? 'is-wrapped' : undefined
        ])
            .compact()
            .join(' ');
    }

    @action updateIndeterminate(el: HTMLInputElement): void {
        el.indeterminate = this.args.checked === 'mixed';
    }
}
