// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import layout from '../../templates/components/base-dropdown/wrapper.hbs';
import { or } from '@ember/object/computed';
import { computed, observer } from '@ember/object';
import { debounce, once } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { buildClassListString, isDestroyed } from '@adc/ember-utils/utils/ember-helpers';

// region Component

/**
 * @classdesc
 *
 * Wraps the dropdown content in a popover on large screens and a full-screen div on small screens.
 *
 * @class Wrapper
 * @extends Ember.Component
 * @memberof components.base-dropdown
 */
// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-classes, ember/require-tagless-components
export default Component.extend(
    /** @lends components.base-dropdown.Wrapper# */ {
        // region Property Overrides
        dom: service(),

        /** @override */
        layout,

        /** @override */
        classNames: ['dropdown-wrapper'],

        /** @override */
        classNameBindings: ['isOpen:open'],

        // endregion

        // region Internal Properties

        /**
         * Whether or not the popover wrapper should adapt to match the the trigger element's width.
         *
         * @type {boolean}
         */
        matchTriggerElementWidth: true,

        /**
         * Should the popover be focusable?
         *
         * @type {boolean}
         */
        popoverIsFocusable: true,

        /**
         * The selector of the element to be focused.
         *
         * @type {String}
         */
        focusSelector: '',

        // endregion

        // region Hooks

        /** @override */
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-component-lifecycle-hooks
        didInsertElement() {
            this._super(...arguments);

            // Listen for window size changes to adapt popover width accordingly
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            this.dom.addListener(this, window, 'resize', () => debounce(this, setPopoverWidth, 200));

            setPopoverWidth.call(this);
        },

        // endregion

        // region Computed Properties

        /**
         * The CSS selector that matches the dropdown's trigger element.
         *
         * @note Used as the popover's default anchor.
         *
         * @ignore
         * @function
         * @returns {String}
         */
        triggerSelector: computed('triggerId', function () {
            return `#${this.triggerId}`;
        }),

        /**
         * The popover's CSS selector used to find the element to anchor to.
         *
         * @ignore
         * @function
         * @returns {String}
         */
        anchorSelectorForPopover: or('popoverAnchor', 'triggerSelector'),

        /**
         * Is the popover's anchor selector the same as the default?
         *
         * @ignore
         * @function
         * @returns {boolean}
         */
        isDefaultAnchorSelector: computed('anchorSelectorForPopover', 'triggerSelector', function () {
            return this.anchorSelectorForPopover === this.triggerSelector;
        }),

        /**
         * Requests a popover width update when the matchTriggerElementWidth flag changes.
         *
         * @ignore
         * @function
         */
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-observers
        matchTriggerElementWidthObserver: observer('matchTriggerElementWidth', function () {
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            once(this, setPopoverWidth);
        }),

        // endregion

        // region @adc/popover Properties

        /**
         * A HtmlElement or document.querySelectorAll selector for finding the element that anchors this popover.
         *
         * @see @adc/popover
         *
         * @type {String|HTMLElement}
         */
        popoverAnchor: '',

        /**
         * Returns the classes that will be applied to the popover.
         * Always includes 'dropdown-content', as well as any classes provided in popoverClass.
         *
         * @see @adc/popover
         *
         * @function
         * @returns {String}
         */
        popoverClasses: computed('popoverClass', function () {
            return buildClassListString([
                [true, 'dropdown-content'],
                [!!this.popoverClass, this.popoverClass]
            ]);
        }),

        /**
         * The placement of the popover.
         *
         * @see @adc/popover
         *
         * @function
         * @returns {String}
         */
        placement: computed('popoverPlacement', function () {
            return this.popoverPlacement || 'bottom-start';
        }),

        /**
         * The element which will define the boundaries of the popover position.
         * The popover will never be placed outside of the defined boundaries.
         *
         * @see @adc/popover
         *
         * @type {String|HTMLElement}
         */
        boundariesElement: 'viewport',

        /**
         * Additional padding for the popover boundaries.
         *
         * @see @adc/popover
         *
         * @type {number}
         */
        boundariesPadding: 8,

        /**
         * Is the popover currently open?
         *
         * @see @adc/popover
         *
         * @ignore
         * @type {boolean}
         */
        isOpen: false,

        /**
         * The width of the popover.
         *
         * @see @adc/popover
         *
         * @type {undefined|number}
         */
        popoverWidth: undefined,

        /**
         * The maximum width of the popover.
         *
         * @see @adc/popover
         *
         * @type {undefined|number}
         */
        popoverMaxWidth: undefined,

        /**
         * The maximum height of the popover.
         *
         * @see @adc/popover
         *
         * @type {undefined|number}
         */
        popoverMaxHeight: undefined,

        /**
         * When the popover for this is rendered, we trigger a focus event on the popover.  Should we prevent the page from scrolling when this focus is triggered.
         *
         * @see @adc/popover
         *
         * @type {boolean}
         */
        preventScroll: false,

        /**
         * Whether or not the popover should stretch on the entire screen when on mobile.
         *
         * @see @adc/popover
         *
         * @type {boolean}
         */
        fullScreenOnMobile: true

        // endregion
    }
);

// endregion

// region Popover Width

/**
 * Sets the dimensions of the dropdown's popover to be the correct width for the screen size.
 *
 * @private
 * @instance
 * @memberof components.base-dropdown.Wrapper
 */
function setPopoverWidth() {
    // Do not try to set value if the component is already destroyed. This is necessary because we are calling this function using debounce.
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line @adc/ember/no-is-destroyed
    if (isDestroyed(this)) {
        return;
    }

    if (!this.matchTriggerElementWidth) {
        // At this point, the existing matchTriggerElementWidth value is not wanted anymore.
        this.set('popoverWidth', undefined);

        return;
    }

    const triggerElement = window.document.querySelector(this.triggerSelector);

    if (!triggerElement) {
        return;
    }

    // This width is ignored by popover while on mobile screens.
    this.set('popoverWidth', triggerElement.getBoundingClientRect().width);
}

// endregion
