import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { gt } from '@ember/object/computed';
import DropdownSelectItem from '../utils/dropdown-select-item.js';
import DropdownActionItem from '../utils/dropdown-action-item.js';
import { assert } from '@ember/debug';
import { intlPath } from '@adc/i18n/path';

import type { Placement } from 'popper.js';
import type ADCIntlService from '@adc/i18n/services/adc-intl';

interface PaginationBarSignature {
    Element: HTMLDivElement;
    Args: {
        changePage: (page: number) => void;
        changePageSize?: (pageSize: number) => void;
        itemCount?: number;
        pageSizeOptions?: number[];
        selectedPageSize?: number;
        selectedPage?: number;
        menuPlacement?: Placement;
        linkRoute?: string;
        linkText?: string;
    };
    Blocks: {
        default: [];
    };
}

interface PageButton {
    text: string;
    page: number;
    disabled: boolean;
    css: string;
}

interface OverflowButton {
    pages: DropdownActionItem[];
}

/**
 * @classdesc
 * A pagination component with pages, arrows and a dropdown for page size.
 *
 * This control will always show the "PREV" button, the first button, last button, "NEXT" button and the three buttons surrounding the current page.  If there are pages not represented
 * by that collection we will show popup menus for those pages, unless there is only one page in the popup.
 */
@intlPath({ module: '@adc/ui-components', path: 'pagination-bar' })
export default class PaginationBar extends Component<PaginationBarSignature> {
    @service declare intl: ADCIntlService;

    defaultPageSize = 100;

    constructor(owner: unknown, args: PaginationBarSignature['Args']) {
        super(owner, args);

        // Verify required closure functions.
        assert(`The changePage closure function must be passed to the pagination bar component`, this.args.changePage);

        // Optionally verify changePageSize if needed, but don't assert
        if (this.args.changePageSize) {
            assert(`The changePageSize must be a function`, typeof this.args.changePageSize === 'function');
        }
    }

    /**
     * The value of the selected page size.
     */
    @computed('args.selectedPageSize', 'defaultPageSize')
    get selectedPageSize(): number {
        return this.args.selectedPageSize ?? this.defaultPageSize;
    }

    /**
     * The computed number of pages.
     */
    @computed('args.itemCount', 'selectedPageSize')
    get pageCount(): number {
        const { selectedPageSize } = this,
            { itemCount = 0 } = this.args;

        return Math.ceil(itemCount / selectedPageSize);
    }

    get showPageSizeDropdown(): boolean {
        return !!this.args.changePageSize;
    }

    /**
     * Determines visibility of the pageButtons.
     */
    @gt('pageCount', 1)
    declare showPages: boolean;

    /**
     * The computed page buttons to render.
     *
     * This control will always show the first button, last button, and three buttons surrounding the current page.  If there are pages not represented by that collection we will show
     * popup menus for those pages, unless there is only one page in the popup.
     */
    @computed('args.{changePage,selectedPage}', 'pageCount')
    get pageButtons(): (PageButton | OverflowButton)[] {
        const { selectedPage = 1 } = this.args,
            { pageCount } = this;

        // Are there NO pages?
        if (pageCount === 0) {
            // Nothing to render.
            return [];
        }

        const buttons: (PageButton | OverflowButton)[] = [],
            fnGetRangeOfPages = (start: number, end: number): number[] =>
                Array.from({ length: end - start + 1 }, (_, idx) => idx + start),
            fnGetButton = (text: string, page: number, css: string): PageButton => ({
                text,
                page,
                disabled: page === selectedPage,
                css
            }),
            fnAddPageBtn = (page: number): number =>
                buttons.push(fnGetButton(String(page), page, `page${page === selectedPage ? ' active' : ''}`)),
            fnAddRangeMenuBtn = (pages: number[]): void => {
                // Does the menu only have one page?
                if (pages.length === 1) {
                    // Show page button for that single page.
                    fnAddPageBtn(pages[0]);
                    return;
                }

                // Add dropdown actions button.
                buttons.push({
                    pages: pages.map((page) =>
                        DropdownActionItem.create({
                            name: String(page),
                            action: () => this.args.changePage(page)
                        })
                    )
                });
            };

        // Calculate index of previous and next page.
        const previousPage = Math.max(selectedPage - 1, 1),
            nextPage = Math.min(selectedPage + 1, pageCount);

        // Assume we will show all pages in selection range.
        let selectionLowerBound = 1,
            selectionUpperBound = pageCount;

        // Are there more than six pages?
        if (pageCount > 6) {
            // Selection lower bound is the previous page, constrained to between 1 and the total page count minus 2.
            selectionLowerBound = Math.min(previousPage, pageCount - 2);

            // Selection upper bound is the next page, constrained to between 3 and the total page count.
            selectionUpperBound = Math.max(nextPage, 3);
        }

        // Get array of buttons around currently selected page, based on calculated lower and upper bound.
        const selectionRange = fnGetRangeOfPages(selectionLowerBound, selectionUpperBound);

        // Does the selection range NOT include the first page?
        if (!selectionRange.includes(1)) {
            // Add the first page.
            fnAddPageBtn(1);
        }

        // Does the selection range NOT include the second page?
        if (!selectionRange.includes(2)) {
            // Add menu button for the second page through the last page NOT present within the selection range.
            fnAddRangeMenuBtn(fnGetRangeOfPages(2, selectionLowerBound - 1));
        }

        // Add all selection range buttons.
        selectionRange.forEach(fnAddPageBtn);

        // Does the selection range NOT include the second to last page?
        if (!selectionRange.includes(pageCount - 1)) {
            // Add menu button for the first page after the selection range through the second to last page.
            fnAddRangeMenuBtn(fnGetRangeOfPages(selectionUpperBound + 1, pageCount - 1));
        }

        // Does the selection range NOT include the last page?
        if (!selectionRange.includes(pageCount)) {
            // Add the last page.
            fnAddPageBtn(pageCount);
        }

        // Return page buttons surrounded by the previous and next buttons.
        return [
            fnGetButton(this.intl.tc(this, 'previousPage'), previousPage, 'ctrl'),
            ...buttons,
            fnGetButton(this.intl.tc(this, 'nextPage'), nextPage, 'ctrl')
        ];
    }

    /**
     * Drop down items for page size.
     */
    @computed('args.pageSizeOptions.[]', 'selectedPageSize')
    get pageSizeItems(): DropdownSelectItem[] {
        return (this.args.pageSizeOptions ?? [10, 25, 50, 100]).map((value) =>
            DropdownSelectItem.create({
                name: this.intl.tc(this, 'perPage', { value }),
                value: String(value)
            })
        );
    }

    /**
     * Called when the user changes the page size dropdown value.
     * New page begins with first items from previous page.
     */
    @action onPageSizeChanged(size: string): void {
        this.args.changePageSize?.(parseInt(size, 10));
    }
}
