import Component from '@glimmer/component';

import type { DropdownActionsSignature } from './dropdown-actions';

export interface StatusToggleSignature {
    Element: HTMLDivElement;
    Args: {
        /** The text to display. */
        statusText: string;
        /** Optional icon to display. */
        statusIcon?: string;
        /** Optional menu items to show when status is clicked. */
        menuItems?: DropdownActionsSignature['Args']['items'];
        /** An optional status color (defaults to gray-60). */
        color?:
            | 'red'
            | 'orange'
            | 'yellow'
            | 'lime'
            | 'green'
            | 'greenblue'
            | 'greencyan'
            | 'cyanblue'
            | 'blue'
            | 'navy'
            | 'purple'
            | 'pink'
            | 'gray-100'
            | 'gray-80'
            | 'gray-60'
            | 'gray-40'
            | 'gray-20'
            | 'gray-10'
            | 'gray-0';
    };
}

export default class StatusToggle extends Component<StatusToggleSignature> {
    /**
     * A computed CSS class based on the optional status color.
     */
    get statusColor(): string {
        return `adc-${this.args.color ?? 'gray-60'}-text`;
    }
}
