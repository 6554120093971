// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { getOwner } from '@ember/owner';
import { computed, action } from '@ember/object';
import { schedule, once, bind } from '@ember/runloop';
import { isPresent } from '@ember/utils';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { isEnterOrSpaceCode } from '@adc/ember-utils/utils/a11y';
import { addWeakListener, removeListener } from '@adc/ember-utils/utils/event-listeners';
import Pikaday from 'pikaday';
import 'pikaday/css/pikaday.css';

const MONTHS = Array.from(
        new Array(12),
        (_, idx) => new Date(`2018-${'00'.substring(0, 2 - String(idx + 1).length) + (idx + 1)}-01T12:00:00Z`)
    ),
    WEEKDAYS = Array.from(new Array(7), (_, idx) => new Date(`2018-10-${21 + idx}T12:00:00Z`));

/**
 * Converts the passed time to the passed time zone (if present).
 *
 * @param {Date} date The date to convert.
 * @param {String} timeZone The time zone to convert to.
 *
 * @returns {Date}
 *
 * @private
 */
function convertDateToZone(date, timeZone) {
    return isPresent(timeZone) ? utcToZonedTime(date, timeZone) : date;
}

/**
 * Calculates the pikaday year range.
 *
 * @param {number|String} [yearRange] The passed year range.
 *
 * @returns {number}
 *
 * @private
 */
function determineYearRange(yearRange) {
    // Was a range not passed?
    if (!yearRange) {
        // Use default value.
        return 10;
    }

    // Is this NOT a formatted date string?
    if (yearRange.indexOf(',') === -1) {
        // Return passed value.
        return yearRange;
    }

    const yearArray = yearRange.split(',');

    if (yearArray[1] === 'currentYear') {
        yearArray[1] = new Date().getFullYear();
    }

    return yearArray;
}

/**
 * Converts the boundary value for timezone and schedules an update of the pikaday value if it is outside this boundary value.
 *
 * @param {Date} boundaryValue The boundary date to compare.
 * @param {function} fnCompare A date comparison function to determine if current value is outside this boundary.
 *
 * @returns {Date|null} Returns the boundary value (converted for timezone) if present.
 *
 * @private
 * @instance
 */
function updateBoundaryDate(boundaryValue, fnCompare) {
    const { pikaday, value } = this,
        { timeZone } = this.computedOptions;

    // Is there a value for this boundary?
    if (boundaryValue) {
        // Compute timezone value.
        const local = convertDateToZone(boundaryValue, timeZone);

        // If the current date is outside the bounds we will update to that value after render.
        if (value && fnCompare(convertDateToZone(value, timeZone), local)) {
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            schedule('afterRender', () => pikaday.setDate(local));
        }

        // Return timezone value so we can update pikaday boundary.
        return local;
    }

    return null;
}

/**
 * Pikaday sets passed dates to beginning of day, so this function will reset the time components of the returned date to the passed time.
 *
 * @param {Date} date The date object to reset.
 * @param {Date} [time] The time to reset the date object to.
 *
 * @returns {Date}
 */
function synchronizeTimeValues(date, time) {
    const d = new Date(date);

    // Was a time passed?
    if (time) {
        // Synchronize.
        d.setHours(time.getHours());
        d.setMinutes(time.getMinutes());
        d.setSeconds(time.getSeconds());
        d.setMilliseconds(time.getMilliseconds());
    }

    return d;
}

/**
 * Called when the user selects a new date in the pikaday component.
 *
 * @param {Date} selectedDate
 *
 * @instance
 */
function onPikadaySelect(selectedDate) {
    let { value } = this,
        { timeZone } = this.computedOptions;

    // Is there a timezone?
    if (timeZone) {
        // Was an initial date value supplied?
        if (value) {
            // Reset time components to original timezone values.
            selectedDate = synchronizeTimeValues(selectedDate, convertDateToZone(value, timeZone));
        }

        // Convert back to browser relative UTC.
        selectedDate = zonedTimeToUtc(selectedDate, timeZone);
    } else {
        // Reset time components to original values.
        selectedDate = synchronizeTimeValues(selectedDate, value);
    }

    // Notify date changed.
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/no-runloop
    once(this, 'onSelection', selectedDate);
}

/**
 * Base class for the pikaday components.
 */
export default class BasePikadayComponent extends Component {
    tagName = '';

    get defaults() {
        return getOwner(this).lookup('pikaday:main');
    }

    /**
     * Indicates a specific time zone has been supplied.
     *
     * @type {boolean}
     */
    @computed('computedOptions.timeZone')
    get hasTimeZone() {
        return isPresent('computedOptions.timeZone');
    }

    // region Options

    /**
     * Returns the pikaday options computed from defaults, globally passed values and locally passed values.
     *
     * @type {Object}
     */
    @computed('defaults', 'i18n', 'options', 'position', 'reposition', 'timeZone')
    get computedOptions() {
        const options = this.getDefaultOptions(),
            fnAddOverrides = (src = {}, ...props) => {
                props.forEach((name) => {
                    const value = src[name];
                    if (isPresent(value)) {
                        options[name] = value;
                    }
                });
            };

        // Apply global defaults, then passed properties to options.
        fnAddOverrides(this.defaults, 'i18n', 'timeZone', 'firstDay');
        fnAddOverrides(this, 'i18n', 'timeZone', 'firstDay', 'position', 'reposition');

        const { timeZone } = options;

        // Is there a time zone?
        if (isPresent(timeZone)) {
            ['min', 'max'].forEach((n) => {
                const name = `${n}Date`,
                    value = options[name];

                // Is these a min/max value?
                if (value) {
                    // Convert for time zone
                    options[name] = convertDateToZone(value, timeZone);
                }
            });
        }

        Object.assign(options, this.options || {});

        return options;
    }

    /**
     * Returns default pikaday options.
     *
     * @returns {Object}
     */
    getDefaultOptions() {
        const {
            firstDay,
            field,
            pikadayContainer,
            format: dateFormat,
            minDate,
            maxDate,
            theme,
            yearRange,
            disableDayFn
        } = this;

        return {
            field: field,
            container: pikadayContainer,
            bound: !pikadayContainer,
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            onOpen: bind(this, this.onPikadayOpen),
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            onClose: bind(this, this.onPikadayClose),
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            onSelect: bind(this, this.onPikadaySelect),
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            onDraw: bind(this, this.onPikadayRedraw),
            firstDay: firstDay === undefined ? 0 : parseInt(firstDay, 10),
            format: dateFormat || 'dd.MM.yyyy',
            yearRange: determineYearRange(yearRange),
            minDate: minDate ? minDate : null,
            maxDate: maxDate ? maxDate : null,
            theme: theme || null,
            keyboardInput: true,
            showDaysInNextAndPreviousMonths: true,
            timeZone: undefined,
            disableDayFn: disableDayFn,
            toString(date, fmt) {
                return format(date, fmt);
            },
            i18n: {
                previousMonth: '',
                nextMonth: '',
                months: MONTHS.map((d) => format(d, 'MMMM')),
                weekdays: WEEKDAYS.map((d) => format(d, 'EEEE')),
                weekdaysShort: WEEKDAYS.map((d) => format(d, 'EEEEEE'))
            }
        };
    }

    // endregion

    // region Pikaday configuration

    /**
     * Initialize the pikaday component.
     */
    setupPikaday() {
        this.pikaday = new Pikaday(this.computedOptions);
        this.setPikadayDate();

        const { pikadayContainer } = this;
        if (pikadayContainer) {
            this.keyListener = addWeakListener(this, pikadayContainer, 'keydown', (event) => {
                const { target } = event;

                // This keydown listener is added to the pikaday-container to avoid adding listeners to each button every time pikaday redraws.
                // This will ensure the target is a button and that it isn't disabled.
                if (
                    isEnterOrSpaceCode(event.code) &&
                    target.classList.contains('pika-button') &&
                    !target.parentElement.classList.contains('is-disabled')
                ) {
                    const { pikaYear: year, pikaMonth: month, pikaDay: day } = target.dataset;
                    if (!year || !month || !day) {
                        return;
                    }

                    onPikadaySelect.call(this, new Date(year, month, day));
                }
            });
        }
    }

    /**
     * Update the pikaday value, converting for timezone and min/max dates.
     *
     * @function
     */
    @action setPikadayDate() {
        const { value } = this;
        if (value) {
            this.pikaday.setDate(convertDateToZone(value, this.computedOptions.timeZone), true);
        }
    }

    /**
     * Update pikaday minimum date.
     */
    setMinDate() {
        const minDate = updateBoundaryDate.call(this, this.minDate, isBefore);
        if (minDate) {
            this.pikaday.setMinDate(minDate);
        }
    }

    /**
     * Update pikaday maximum date.
     */
    setMaxDate() {
        const maxDate = updateBoundaryDate.call(this, this.maxDate, isAfter);
        if (maxDate) {
            this.pikaday.setMaxDate(maxDate);
        }
    }

    /**
     * Update the pikaday configuration.
     */
    updateOptions() {
        this.pikaday.config(this.computedOptions);
    }

    // endregion

    // region Pikaday Events

    /**
     * Called when pikaday component is opened.
     */
    onOpen() {}

    /**
     * Called when pikaday component is closed.
     */
    onClose() {}

    /**
     * Called when user selects a new date.
     */
    onSelection() {}

    /**
     * Called when pikaday component is drawn.
     */
    onDraw() {}

    /**
     * Called when the user selects a new date in the pikaday component.
     */
    onPikadaySelect() {
        onPikadaySelect.call(this, this.pikaday.getDate());
    }

    /**
     * Called when the pikaday component redraws.
     */
    onPikadayRedraw() {
        this.onDraw();
    }

    // endregion

    @action cleanupPikaday() {
        const { keyListener } = this;
        if (keyListener) {
            removeListener(keyListener);
        }

        this.pikaday.destroy();
    }
}
