import { isArray } from '@ember/array';
import { computed, get } from '@ember/object';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';

/**
 * @classdesc
 * Promise wrapper for standard WebApi request result.
 */
export default class WebApiPromise extends DS.PromiseObject<any> {
    declare isFulfilled: boolean;

    declare content: any;

    /**
     * Returns true if the request has been fulfilled and has items.
     */
    @computed('content', 'isFulfilled', 'hasNoResults')
    get hasData(): boolean {
        return this.isFulfilled && this.content && !this.hasNoResults;
    }

    /**
     * Returns true if the request has been fulfilled but does not contain any data.
     */
    @computed('isFulfilled', 'content', 'content.meta.totalCount')
    get hasNoResults(): boolean {
        const { content, isFulfilled } = this;

        return (
            isFulfilled &&
            content &&
            (get(content, 'meta.totalCount') === 0 || (isArray(content) && content.length === 0))
        );
    }
}
