import templateOnlyComponent from '@ember/component/template-only';

export interface ExpandButtonSignature {
    Element: HTMLButtonElement;
    Args: {
        /** Indicates the button is in "expanded" state. */
        isExpanded?: boolean;
        /** The direction to display the arrow icon. */
        direction?: 'up' | 'down';
        /** Triggered when the user chooses to expand/collapse the element. */
        onChange?: VoidFunction;
    };
    Blocks: {
        default: [];
    };
}

const ExpandButton = templateOnlyComponent<ExpandButtonSignature>();
export default ExpandButton;
