import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { set, setProperties } from '@ember/object';
import { cancel, debounce, later } from '@ember/runloop';
import { A } from '@ember/array';
import { logMissingAbstractMember } from '@adc/ember-utils/utils/debug';
import { addWeakListener, removeListener } from '@adc/ember-utils/utils/event-listeners';

import type { EmberRunTimer } from '@ember/runloop/types';

export interface SessionActivityArgs {
    shouldTimeout: boolean;
    logoutTimeoutMs: number;
    enableKeepAlive: boolean;
    inactivityWarningTimeoutMs?: number;
    keepAliveUrl?: string;
}

/**
 * Default logout timeout (ms).
 *
 * @private
 */
const DEFAULT_LOGOUT_TIMEOUT = 5 * 60 * 1000;

/**
 * Number of consecutive times keepAlive has failed.
 *
 * @private
 */
let keepAliveFailures = 0;

/**
 * Number of times keepAlive can fail before we trigger logout.
 *
 * @private
 */
const maxKeepAliveFailures = 10;

/**
 * Triggers logout event.
 *
 * @private
 */
function triggerLogoutTimeout(this: SessionActivity): void {
    // Force route transition timeout no matter what the case is.
    this.forceLogoutOnNextTransition = true;

    // Trigger log out event only if the route/page allows it.
    if (!this.routeIgnoresSessionTimeout) {
        this.baseLogout();
    }
}

/**
 * Triggers inactive event.
 *
 * @private
 */
function triggerInactivityWarningTimeout(this: SessionActivity): void {
    // Trigger inactivity warning event only if the route/page allows it.
    if (!this.routeIgnoresSessionTimeout) {
        // Set a flag that the user is inactive.
        this.inactive = true;
        this.triggerInactivityWarning();
    }
}

/**
 * Cancels passed run timers.
 *
 * @private
 */
function cancelTimers(...timers: Array<EmberRunTimer | undefined>) {
    // Cancel any running timeouts.
    A(timers)
        .compact()
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        .forEach((timeout) => cancel(timeout));
}

/**
 * Cancels running logout timeouts.
 *
 * @private
 */
function cancelLogoutTimeouts(this: SessionActivity): void {
    // Cancel any running timeouts.
    cancelTimers(this.logoutTimeoutEvent, this.inactivityWarningTimeoutEvent);
}

/**
 * Trigger keep-alive sequence.
 *
 * @private
 */
async function keepAlive(this: SessionActivity): Promise<void> {
    // If the session is not currently active, we don't want to reactivate it.
    // It must be reactivated deliberately.
    if (this.inactive || !this.enableKeepAlive) {
        return;
    }

    try {
        // Run an ajax to ping the session to tell it that the user is still active.
        const stillAlive = await this.keepSessionActive();

        // If keepAlive doesn't throw, we reset the counter.
        keepAliveFailures = 0;

        if (!stillAlive) {
            this.logout(true);
        }
    } catch (err) {
        console.error(err);

        // We keep track of keepAlive failures and trigger a logout if the limit is reached.
        // If keepAlive continuously fails, we're assuming something is messed up w/ the session
        // and log the user out to force a cleanup.
        keepAliveFailures++;

        if (keepAliveFailures > maxKeepAliveFailures) {
            keepAliveFailures = 0;
            console.error('KeepAlive reached maximum number of failures. Logging out.');

            this.baseLogout();
            return;
        }
    }

    cancelTimers(this.keepAliveTimeout);

    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/no-runloop
    this.keepAliveTimeout = debounce(this, keepAlive, this.keepAliveFrequencyMs);
}

/**
 * Handles user activity event.
 *
 * @private
 */
function handleUserActivityEvent(this: SessionActivity) {
    const { inactive, doNotTimeoutSession, logoutTimeoutMs, inactivityWarningTimeoutMs } = this;

    if (doNotTimeoutSession || inactive) {
        return;
    }

    // Clear timeouts.
    cancelLogoutTimeouts.call(this);

    // There will always be logout timeout.
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/no-runloop
    this.logoutTimeoutEvent = later(this, triggerLogoutTimeout, logoutTimeoutMs as number);

    // Set inactivity warning timeout if it exists.
    if (inactivityWarningTimeoutMs) {
        set(
            this,
            'inactivityWarningTimeoutEvent',
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            later(this, triggerInactivityWarningTimeout, inactivityWarningTimeoutMs)
        );
    }
}

/**
 * Remove all event listeners.
 *
 * @private
 */
function removeAllListeners(this: SessionActivity) {
    this.listenerIds.forEach((listenerId) => removeListener(listenerId));

    // Clear the listener ids.
    this.listenerIds = [];
}

/**
 * @classdesc
 * Service that handles triggering events based on inactivity timeouts.
 */
export default class SessionActivity extends Service {
    /**
     * Is the user inactive? (No action has been triggered within the inactivityWarningTimeoutMs).
     */
    @tracked inactive = false;

    /**
     * Does this current route/page ignore the session time out?
     */
    @tracked routeIgnoresSessionTimeout = false;

    /**
     * Should we run the Keep Alive process?
     */
    @tracked enableKeepAlive = false;

    /**
     * URL to ping if there is a keep alive
     */
    @tracked keepAliveUrl?: string;

    /**
     * Force session timeout as soon as the user transitions out of the current route/page.
     */
    @tracked forceLogoutOnNextTransition = false;

    /**
     * Timeout to triggering logout event (ms).
     */
    logoutTimeoutMs?: number;

    /**
     * Timeout to triggering inactivity warning event (ms).
     */
    inactivityWarningTimeoutMs?: number;

    /**
     * Whether to never time out session and keep it alive forever.
     */
    doNotTimeoutSession = false;

    /**
     * Time that we should wait between keepAlive pings (in ms).
     */
    keepAliveFrequencyMs = 60000;

    listenerIds: string[] = [];
    keepAliveTimeout?: EmberRunTimer;
    logoutTimeoutEvent?: EmberRunTimer;
    inactivityWarningTimeoutEvent?: EmberRunTimer;

    /**
     * Cleanup.
     */
    willDestroy(): void {
        super.willDestroy();

        removeAllListeners.call(this);
        cancelTimers(this.keepAliveTimeout, this.logoutTimeoutEvent, this.inactivityWarningTimeoutEvent);
    }

    /**
     * Sets logout and inactivity timeouts.
     */
    initialize(applicationSessionProperties: SessionActivityArgs): void {
        cancelLogoutTimeouts.call(this);
        removeAllListeners.call(this);

        const { shouldTimeout, enableKeepAlive, keepAliveUrl } = applicationSessionProperties,
            doNotTimeoutSession = !shouldTimeout;

        let { logoutTimeoutMs, inactivityWarningTimeoutMs } = applicationSessionProperties;

        if (doNotTimeoutSession) {
            console.info('Never time out session enabled, continuous keepalive triggered');
        } else {
            if (!logoutTimeoutMs || logoutTimeoutMs < 0) {
                logoutTimeoutMs = DEFAULT_LOGOUT_TIMEOUT;
                console.error(
                    'No timeout provided for session that should timeout, setting to default.',
                    logoutTimeoutMs
                );
            }

            if (
                inactivityWarningTimeoutMs &&
                (inactivityWarningTimeoutMs > logoutTimeoutMs || inactivityWarningTimeoutMs < 0)
            ) {
                inactivityWarningTimeoutMs = undefined;
                console.error('Inactivity warning timeout not set properly, no warning will be shown.');
            }
        }

        setProperties(this, {
            enableKeepAlive,
            doNotTimeoutSession,
            logoutTimeoutMs,
            inactivityWarningTimeoutMs,
            keepAliveUrl
        });

        // Set event handler for each event to update the timeouts for logout and inactivity warnings.
        ['keydown', 'mousedown', 'scroll', 'touchstart'].forEach((event) => {
            // Add event listener unless the session should never timeout.
            if (!doNotTimeoutSession) {
                this.listenerIds.push(
                    addWeakListener(this, window, event, handleUserActivityEvent.bind(this), false, true, true)
                );
            }
        });

        // Start keepAlive.
        (() => keepAlive.call(this))();

        // Start activity timeouts.
        handleUserActivityEvent.call(this);
    }

    /**
     * Returns the duration between inactivity is detected and logout is triggered.
     */
    getInactivityWarningDuration(): number {
        const { logoutTimeoutMs = -1, inactivityWarningTimeoutMs = -1 } = this;
        if (logoutTimeoutMs === -1 || inactivityWarningTimeoutMs === -1) {
            return 0;
        }

        return (logoutTimeoutMs - inactivityWarningTimeoutMs) / 1000;
    }

    /**
     * Clears inactive status of the session.
     */
    clearInactiveStatus(): void {
        this.inactive = false;

        this.refreshApplication();

        (() => keepAlive.call(this))();
        handleUserActivityEvent.call(this);
    }

    /**
     * Update session timeout flag.
     */
    updateSessionTimeout(doNotTimeOut: boolean): void {
        // Do we need to force a session time out now due to a page being alive for too long?
        if (this.forceLogout()) {
            this.baseLogout();
        }

        // Let the user activity know to turn on/off the session time out event based on this route.
        this.routeIgnoresSessionTimeout = doNotTimeOut;
    }

    /**
     * Should we force logout on the next route/page transition?
     */
    forceLogout(): boolean {
        return this.forceLogoutOnNextTransition;
    }

    /**
     * Internal logout function.  We don't want consumers to need to call super so all internal state keeping should be done here.
     *
     * @private
     */
    baseLogout(): void {
        // We cancel any outstanding timers and set the state to inactive to make sure the service does not make any further state changes.
        this.inactive = true;
        cancelLogoutTimeouts.apply(this);
        this.logout(true);
    }
    /**
     * Handler to trigger any actions that should take place when the user is forced to logout.
     *
     * @abstract
     */ // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logout(_useReturnUrl?: boolean): void {
        logMissingAbstractMember(this, 'logout');
    }

    /**
     * Handler to trigger any actions that should take place when the user has gone idle and is near being logged out.
     *
     * @abstract
     */
    triggerInactivityWarning(): void {
        logMissingAbstractMember(this, 'triggerInactivityWarning');
    }

    /**
     * Handler called when activity is restored by calling clearInactiveStatus.
     *
     * @abstract
     */
    refreshApplication(): void {
        logMissingAbstractMember(this, 'refreshApplication');
    }

    /**
     * Handler called to keep te server session alive.
     *
     * @abstract
     */
    async keepSessionActive(): Promise<boolean> {
        logMissingAbstractMember(this, 'keepSessionActive');
        return true;
    }
}
