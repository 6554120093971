import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { getOwner } from '@ember/owner';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import type { InternalOwner } from '@ember/-internals/owner';

export interface BaseTimezoneControlSignature {
    Args: {
        /** Optional time zone to use for formatted dates.  If omitted the global application time zone will be used. */
        timeZone?: string;
    };
}

/**
 * @classdesc
 * Base class for date/time controls that need to work with time zones.
 */
export default class BaseTimezoneControl<
    T extends BaseTimezoneControlSignature = BaseTimezoneControlSignature
> extends Component<T> {
    /**
     * The computed timezone value to use for date conversions.
     */
    @computed('args.timeZone')
    get zone(): string {
        const { timeZone } = this.args;
        if (timeZone !== undefined) {
            return timeZone;
        }

        const owner = getOwner(this),
            cfg = owner && (owner as InternalOwner).lookup('@adc/ui-components:appValues');

        if (cfg) {
            return (cfg as { timeZone: string }).timeZone ?? '';
        }

        return '';
    }

    /**
     * Converts the passed date to be relative to the passed timezone.
     */
    getZonedDate(date: Date): Date {
        const { zone } = this;
        return zone ? utcToZonedTime(date, zone) : date;
    }

    /**
     * Converts the passed date to be relative to UTC.
     */
    getUtcDateFromZone(date: Date): Date {
        const { zone } = this;
        return zone ? zonedTimeToUtc(date, zone) : date;
    }
}
