/**
 * Route name for the legacy page.
 * @note Making it a constant, because we use it in a bunch of places.
 */
export const LEGACY_PAGE_ROUTE_NAME = 'legacy-page';

/**
 * Classes to define the max width of the content on a page.
 */
export const PAGE_MAX_WIDTH_MEDIUM = 'page-max-width-md';

/**
 * Parses url into segments.
 *
 * @note Returns undefined if URL was not well formed.
 */
export function parseUrl(fullUrl: string): { url: string; search?: string; hash?: string } | undefined {
    // Parse URL into segments.
    const [match, url, search, hash] = fullUrl.match(new RegExp('^(.[^?#]+)([?].[^#]*)?(#.*)?')) || [];

    // Is URL malformed?
    if (!match) {
        return undefined;
    }

    return {
        url,
        search,
        hash
    };
}
