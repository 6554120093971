import { isArrowDownCode, isArrowLeftCode, isArrowUpCode, isArrowRightCode } from '@adc/ember-utils/utils/a11y';
import { isRtl, DIR_CHANGED_EVENT_KEY } from '@adc/ember-utils/utils/html-dir-helpers';
import { computed, action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import { Placement } from 'popper.js';
import { VALUE_CHANGE_ACTION } from './common/base-input.js';
import { default as BaseSlider, getValueFromSliderPercentage } from './slider/base-slider.js';

import type { Registry as ServiceRegistry } from '@ember/service';
import type { SafeString } from 'handlebars';
import type { BaseSliderSignature } from './slider/base-slider';

type OrientationType = [
    'clientY' | 'clientX',
    'pageY' | 'pageX',
    'top' | 'left',
    'scrollTop' | 'scrollLeft',
    'height' | 'width'
];
type EventType = MouseEvent | Touch | undefined;
type HandlerType = VoidFunction | ((e: MouseEvent | TouchEvent) => void);
type EventTouple<T> = [string, T, HandlerType];

// region Constants

/**
 * The names used to cache event listeners.
 */
const SLIDER_RTL_LISTENER = 'SliderRtlObserver',
    MOVE_LISTENER = 'ClearMoveEvt',
    END_LISTENER = 'ClearEndEvt',
    OUTSIDE_LISTENER = 'ClearOutsideEvt';

// endregion

function isTouchEvent(e: MouseEvent | TouchEvent): e is TouchEvent {
    return typeof TouchEvent !== 'undefined' && e instanceof TouchEvent && !!e.touches;
}

export interface SliderAdcSignature {
    Element: HTMLDivElement;
    Args: BaseSliderSignature['Args'] & {
        title: string;
        showValue?: boolean;
        showVertically?: boolean;
    };
    Blocks: {
        default: [];
    };
}

/**
 * @classdesc A slider control that allows a user to select values between a given range.
 */
export default class SliderAdc extends BaseSlider<SliderAdcSignature> {
    @service declare dom: ServiceRegistry['dom'];

    /** @override */
    constructor(...args: any[]) {
        super(...args);

        this.id = guidFor(this);

        this.initRtlObserver();

        this.updateThumbStyle();
    }

    // region Style Properties
    id = '';
    @tracked
    showVertically = false;
    @tracked
    valueText = '';
    @tracked
    step = 0;

    /** Determines if the slider is currently being dragged. */
    @tracked
    isDragging = false;

    /** The style attribute value to be applied to the slider thumb element. */
    @tracked
    thumbStyle: SafeString = htmlSafe('');

    // region Error Handling

    /** @override */
    @tracked
    errorTooltipPlace: Placement = 'bottom';

    // endregion

    [SLIDER_RTL_LISTENER]?: string;

    private touchEventListeners = {
        [MOVE_LISTENER]: '',
        [END_LISTENER]: '',
        [OUTSIDE_LISTENER]: ''
    };

    [VALUE_CHANGE_ACTION]?: CallableFunction;

    /** The orientation of the slider.*/
    get orientation(): string {
        return this.showVertically ? 'vertical' : 'horizontal';
    }

    /**
     * The value of the aria-valuetext attribute.
     * The text read by the screen reader on value change.
     */
    get ariaValueText(): string {
        return this.ariaValueNow + this.valueText;
    }

    /** Computes the style attribute value to be applied to the slider range element.*/
    get rangeStyle(): SafeString {
        const rangeAxis = this.showVertically ? 'height' : 'width';
        // When the minValue is nonzero, the user is able to slide the thumb past 100%, so we need to cap the range to 100%.
        // Additionally, when the user slides the thumb past 0%, the thumb gets stuck on a nonzero value (i.e. 10%)
        // so we need to set the range to 0% if the current value is equal to the min value.
        const axisPercentage = this.value === this.minValue ? 0 : Math.min(this.sliderPercentage, 100);

        return htmlSafe(`${rangeAxis} : ${axisPercentage}%;`);
    }

    // endregion

    // region ARIA Properties

    /** The unit to move the slider with one key press. */
    @computed('step')
    get stepToMove(): number {
        return this.step ?? 1;
    }

    /** The current value of the slider for the ARIA attribute. */
    get ariaValueNow(): number {
        return getValueFromSliderPercentage.call(this);
    }

    // endregion

    // region Trigger Methods

    /**
     * Updates the sliderPercentage property to the given percentage and triggers the value-change action if defined.
     *
     * @param {number} percentage - The new value used to set the slider.
     * @param {boolean} [isFromSlider=false] - Determines if the change was from the slider itself or by another means.
     * @param {boolean} [isMouseUp=true] - Allows the application to know if the mouse is pressed/released (helpful for triggering debounced actions).
     */
    private updateSliderPercentage(percentage: number, isFromSlider = false, isMouseUp = true): void {
        // Set the internal slider percentage.
        this.sliderPercentage = Math.max(Math.min(percentage, 100), 0);

        // Send back the new value, along with a boolean indicating if the value was changed using the slider element.
        this[VALUE_CHANGE_ACTION]?.(getValueFromSliderPercentage.call(this), isFromSlider, isMouseUp);
    }

    // endregion

    // region Calculation Methods

    /**
     * There are two cases when we need to show the slider progress in reverse mode:
     *  - When showing the slider vertically (the max slider value should at the top)
     *  - When showing the slider horizontally and RTL is activated (the max slider value should be on the left side)
     */
    private shouldReverseSliderPercentage(): boolean {
        return this.showVertically || isRtl();
    }

    /**
     * Returns the location of the slider handle in terms of a percentage relative to the length of the slider.
     * (e.g. clicking on the very left of the slider will return 0, while the very right returns 100).
     */
    private getSliderPercentageFromEvent(e: MouseEvent | TouchEvent): number {
        // Was this a touch event?
        let event: EventType = e instanceof MouseEvent ? e : undefined;

        if (isTouchEvent(e)) {
            // Use the first touch event.
            event = Array.from(e.touches).pop();
        }

        if (event === undefined) {
            return 0;
        }

        // Get the screen position of the entire slider container so we can determine where the mouse was clicked inside the slider.
        const el: HTMLElement | null = document.querySelector(`#${this.id}`),
            sliderOffset: DOMRect | undefined = el?.getBoundingClientRect(),
            [clientMainAxis, pageMainAxis, scrollSide, scrollProp, dimensionProp]: OrientationType = this.showVertically
                ? ['clientY', 'pageY', 'top', 'scrollTop', 'height']
                : ['clientX', 'pageX', 'left', 'scrollLeft', 'width'],
            // Get the absolute coordinate of the event.
            // The generic name "coordinate" is used because it holds X or Y depending if the slider is showing horizontally or vertically.
            eventCoordinate = event[pageMainAxis] ?? event[clientMainAxis] ?? 0,
            bodyScroll = document.body[scrollProp],
            elementScroll = document.documentElement[scrollProp],
            scrollSideOffset: number = sliderOffset?.[scrollSide] ?? 0,
            scrollSideOffsetMainAxis: number = sliderOffset?.[dimensionProp] ?? 0,
            absoluteCoordinate = eventCoordinate + bodyScroll + elementScroll,
            // Get the coordinate relative to the slider.
            coordinateRelativeToTheSlider = absoluteCoordinate - scrollSideOffset,
            // Turn the coordinate into a percentage.
            percentage = Math.round((coordinateRelativeToTheSlider / scrollSideOffsetMainAxis) * 100);

        // Don't allow values less than 0 or more than 100.
        const validatedPercentageValue = Math.min(Math.max(percentage, 0), 100);

        if (this.shouldReverseSliderPercentage()) {
            return 100 - validatedPercentageValue;
        }

        return validatedPercentageValue;
    }

    // endregion

    // region Mouse and Touch Properties/Events

    /**
     * Handles mouseDown and touchStart events.
     */
    private processTouchEvent(e: MouseEvent | TouchEvent): boolean {
        // Stop event propagation.
        // Note: Not doing this causes the slider to be "HTML draggable" and can hinder the slider handle from moving properly.
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        // Is the slider disabled?
        if (this.disabled) {
            // Don't process any events.
            return false;
        }

        // Update the slider percentage to where they clicked.
        this.updateSliderPercentage(this.getSliderPercentageFromEvent(e), true, false);

        // type EventTouple = [string, keyof typeof this.touchEventListeners, HandlerType];

        const isTouch = isTouchEvent(e);
        // Add listeners for drag events and mouse/touch events.
        const listeners: EventTouple<keyof typeof this.touchEventListeners>[] = [
            [isTouch ? 'touchmove' : 'mousemove', MOVE_LISTENER, this.mouseMove],
            [isTouch ? 'touchend' : 'mouseup', END_LISTENER, this.stopMouseInteraction],
            [isTouch ? 'touchcancel' : 'mouseleave', OUTSIDE_LISTENER, this.stopMouseInteraction]
        ];

        listeners.forEach(([event, listener, handler]) => {
            this.touchEventListeners[listener] = this.dom.addListener(
                this,
                window.document.body,
                event,
                handler.bind(this),
                false,
                false,
                isTouch
            );
        });

        return true;
    }

    /**
     * A helper function for mouseup and mouseleave
     * that removes the mouse dragging and mouse down
     * interactions and removes the touch event listeners.
     *
     * mouseup: Removes mouse functionality with
     * the slider when the mouse is no longer
     * pressed down.
     *
     * mouseleave: Removes mouse functionality
     * with the slider when a user exits
     * the browser window.
     */
    private stopMouseInteraction(): void {
        // Disable dragging on mouse move.
        this.isDragging = false;

        // Set the new percentage and trigger the value-change action (if defined).
        this.updateSliderPercentage(this.sliderPercentage, true);

        const listeners: (keyof typeof this.touchEventListeners)[] = [MOVE_LISTENER, END_LISTENER, OUTSIDE_LISTENER];

        // Remove the event listeners.
        listeners.forEach((name): void => {
            this.dom.removeListener(this, this.touchEventListeners[name]);
        });
    }

    /**
     * Updates the value of the slider based on the current position of the drag event.
     *
     * @param {MouseEvent} e
     */
    private mouseMove(e: MouseEvent | TouchEvent): void {
        // Is the slider disabled?
        if (this.disabled) {
            // Don't process any events.
            return;
        }

        // Tell the component we are dragging.
        this.isDragging = true;

        // Set the new percentage and trigger the value-change action if defined.
        this.updateSliderPercentage(this.getSliderPercentageFromEvent(e), true, false);
    }

    // endregion

    // region RTL Observer

    /**
     * Initializes the observer for watching changes to the HTML element's dir attribute.
     */
    private initRtlObserver(): void {
        const rtlListener = this.dom.addListener(
            this,
            document,
            DIR_CHANGED_EVENT_KEY,
            this.updateThumbStyle.bind(this)
        );

        this[SLIDER_RTL_LISTENER] = rtlListener;
    }

    // endregion

    // region Helper methods

    /**
     * Decreases the slider percentage with the value mentioned in step.
     */
    private decreasePercentage(): number {
        const percentage = this.sliderPercentage - this.stepToMove;

        return percentage >= this.minValue ? percentage : this.minValue;
    }

    /**
     * Increases the slider percentage with the value mentioned in step.
     */
    private increasePercentage(): number {
        const percentage = this.sliderPercentage + this.stepToMove;

        return percentage <= this.maxValue ? percentage : this.maxValue;
    }

    /**
     * Moves the slider horizontally when keyboard is used.
     */
    private moveHorizontally(code: string): void {
        const rtl = isRtl();
        let percentage = this.sliderPercentage;

        if (isArrowRightCode(code)) {
            percentage = rtl ? this.decreasePercentage() : this.increasePercentage();
            this.updateSliderPercentage(percentage, true, false);
        }

        if (isArrowLeftCode(code)) {
            percentage = rtl ? this.increasePercentage() : this.decreasePercentage();
            this.updateSliderPercentage(percentage, true, false);
        }
    }

    /**
     * Moves the slider vertically when keyboard is used.
     */
    private moveVertically(code: string): void {
        let percentage = this.sliderPercentage;

        if (isArrowUpCode(code)) {
            percentage = this.increasePercentage();
            this.updateSliderPercentage(percentage, true, true);
        }

        if (isArrowDownCode(code)) {
            percentage = this.decreasePercentage();
            this.updateSliderPercentage(percentage, true, true);
        }
    }

    // endregion

    // region Actions

    /** Updates the value of the slider based on the position of the left mouse click. */
    @action mouseClicked(e: MouseEvent | TouchEvent): boolean {
        // Was it NOT a main button (left) click?
        if (e instanceof MouseEvent && e.button !== 0) {
            // Do NOT process the event.
            return false;
        }

        this.processTouchEvent(e);

        return true;
    }

    /** Listener for keyDown events. */
    @action keyPressed(e: KeyboardEvent): boolean {
        if (this.disabled) {
            return false;
        }

        const { code } = e;

        if ([isArrowDownCode, isArrowUpCode, isArrowLeftCode, isArrowRightCode].some((fn) => fn(code))) {
            e.preventDefault();
            this.showVertically ? this.moveVertically(code) : this.moveHorizontally(code);
        }

        return true;
    }

    /** Listener for KeyUp events. */
    @action keyReleased(e: KeyboardEvent): boolean {
        if (this.disabled) {
            return false;
        }

        const { code } = e;

        if ([isArrowDownCode, isArrowUpCode, isArrowLeftCode, isArrowRightCode].some((fn) => fn(code))) {
            e.preventDefault();
            this.updateSliderPercentage(this.sliderPercentage, true, true);
        }

        return true;
    }

    /** Updates the value of the slider based on the position of the touch. */
    @action touchStarted(e: TouchEvent): void {
        this.processTouchEvent(e);
    }

    // endregion

    /** Updates the thumbStyle property when the slider percentage changes.*/
    @action updateThumbStyle(): void {
        let direction = isRtl() ? 'right' : 'left';
        // When the minValue is nonzero, the user is able to slide the thumb past 100%, so we need to cap the range to 100%.
        // Additionally, when the user slides the thumb past 0%, the thumb gets stuck on a nonzero value (i.e. 10%)
        // so we need to set the range to 0% if the current value is equal to the min value.
        const directionPercentage = this.value === this.minValue ? 0 : Math.min(this.sliderPercentage, 100);

        if (this.showVertically) {
            direction = 'bottom';
        }

        this.thumbStyle = htmlSafe(`${direction}: ${directionPercentage}%;`);
    }

    // endregion
}
