interface Range {
    min: number;
    max: number;
}

/**
 * Validates that the given range is a valid object with a min and a max property.
 */
export function isInvalidRange(range: Range): boolean {
    return !range || typeof range.min !== 'number' || typeof range.max !== 'number' || range.min > range.max;
}

/**
 * Verifies if the number is in the min/max range.
 */
export function isNumberInRange(x: number, range: Range, includeSuperiorEdge = false): boolean {
    if (isInvalidRange(range)) {
        console.error(
            'utils/common: Invalid range provided to "isNumberInRange" function. It must be object with a min and a max numeric property.'
        );

        return false;
    }

    if (x < range.min || x > range.max) {
        return false;
    }

    if (!includeSuperiorEdge && x === range.max) {
        return false;
    }

    return true;
}

/**
 * Normalizes the received value to the given range, returning a float value between 0 and 1.
 **/
export function normalizeNumberToRange(x: number, range: Range): number | undefined {
    if (isInvalidRange(range)) {
        console.error(
            'utils/common: Invalid range provided to "normalizeNumberToRange" function. It must be object with a min and a max numeric property.'
        );

        return;
    }

    if (x < range.min) {
        return 0;
    }

    if (x > range.max) {
        return 1;
    }

    return (x - range.min) / (range.max - range.min);
}
