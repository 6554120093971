import Service from '@ember/service';
import { init as initSentry, configureScope } from '@sentry/ember';
import { CaptureConsole } from '@sentry/integrations';
import {
    InvalidAntiForgeryToken,
    ValidationError,
    ProcessingError,
    ServerProcessingError,
    TwoFactorAuthenticationRequired
} from '@adc/ajax/enums/AjaxResponseHttpCode';
import { set } from '@ember/object';
import { addWeakListener } from '@adc/ember-utils/utils/event-listeners';

import type {
    Event as SentryEvent,
    EventHint,
    Breadcrumb,
    BreadcrumbHint,
    Extras,
    Primitive,
    SeverityLevel
} from '@sentry/types';
import type { BrowserOptions } from '@sentry/ember';
import type { TEmberAjaxError } from '@adc/ajax/services/adc-ajax';

type SentryTags = {
    [key: string]: Primitive;
};

type ADCException =
    | TEmberAjaxError
    | {
          errors: TEmberAjaxError;
      };

export type ADCEventHint = EventHint & {
    originalException?: ADCException;
};

interface ErrorReportingConfig extends BrowserOptions {
    serverName: string;
    showDebugInfo: boolean;
}

export interface ErrorsToIgnore {
    propertyType: string;
    value: string;
}

interface ErrorReportingContext {
    user: {
        id: string;
        userName?: string;
        email?: string;
        ip_address?: string;
    };
    tags: SentryTags;
    extra?: Extras;
    fingerprint?: string[];
}

export interface ErrorReportingConfiguration {
    apiKey: string | null;
    environment: 'dev' | 'test' | 'prod';
    isEnabled: boolean;
    logErrorsToConsole: boolean;
    machineName: string;
    providerName: string;
    redirectToErrorRouteOnUnhandledError: boolean;
    scriptUrl: string | null;
    showNotificationOnError: boolean;
}

type FakeConsole = Pick<Console, 'error' | 'warn' | 'info' | 'log' | 'debug' | 'trace'>;

/**
 * @note I am on purpose assigning the texts in case the levels will change.
 */
const LEVEL_TO_CONSOLE_FN_MAP: Record<SeverityLevel, keyof FakeConsole> = {
    fatal: 'error',
    error: 'error',
    warning: 'warn',
    info: 'info',
    debug: 'debug',
    log: 'log'
};

/**
 * Default config options.
 */
const DEFAULT_CONFIG = {
    attachStacktrace: true,
    environment: 'unset',
    serverName: window.location.hostname,
    release: 'untagged'
};

/**
 * Is this a recognized server error that we should ignore sending to Sentry?
 */
function isRecognizedServerError(this: BaseErrorReporting, hint: ADCEventHint): boolean {
    const code = getErrorCodeFromHint.call(this, hint);

    if (!code || isNaN(code)) {
        return false;
    }

    return [
        InvalidAntiForgeryToken,
        ValidationError,
        ProcessingError,
        ServerProcessingError,
        TwoFactorAuthenticationRequired
    ].some((errorCode) => errorCode === parseInt(String(code), 10));
}

/**
 * Attempts to get the HTTP error code from the hint object.
 */
function getErrorCodeFromHint(this: BaseErrorReporting, hint: ADCEventHint): number | undefined {
    const fnLog = (text: string) =>
        logMessage.call(this, `[@adc/app-infrastructure::getErrorCodeFromHint] ${text}.`, hint);

    const { originalException } = hint;
    if (!originalException) {
        return;
    }

    let { errors } = originalException;
    if (!errors) {
        fnLog('Original exception errors do not exist');
        return;
    }
    if (errors instanceof String || typeof errors === 'string') {
        fnLog(`Original exception errors is a string: ${errors}`);
        return;
    }
    if (!Array.isArray(errors)) {
        if (!('errors' in errors)) {
            fnLog('Original exception errors is not array and does not contain errors collection.');
            return;
        }

        errors = errors.errors;
    }

    return Array.isArray(errors) && errors.length > 0 ? errors[0].code : undefined;
}

/**
 * Checks whether a specific event should be ignored or not.
 */
function shouldEventBeIgnored(this: BaseErrorReporting, hint: ADCEventHint): boolean {
    return this.errorsToIgnore.some((errorToIgnore) =>
        this.getExceptionStringValue(hint, errorToIgnore.propertyType)
            .toLowerCase()
            .includes(errorToIgnore.value.toLowerCase())
    );
}

/**
 * Sets up configuration object for Sentry.
 */
function getConfiguration(
    this: BaseErrorReporting,
    errorReportingConfiguration: ErrorReportingConfig
): ErrorReportingConfig {
    return Object.assign(DEFAULT_CONFIG, errorReportingConfiguration, {
        debug: false,
        beforeSend: this.beforeSend.bind(this),
        beforeBreadcrumb: this.beforeBreadcrumb.bind(this),
        integrations: [
            new CaptureConsole({
                levels: ['warn', 'error']
            })
        ],
        ignoreErrors: [
            // Random plugins/extensions
            'top.GLOBALS',
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            'http://tt.epicplay.com',
            "Can't find variable: ZiteReader",
            'jigsaw is not defined',
            'ComboSearch is not defined',
            'http://loading.retry.widdit.com/',
            'atomicFindClose',
            // Facebook borked
            'fb_xd_fragment',
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage',
            // https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
            'ResizeObserver loop limit exceeded',
            // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
            'ResizeObserver loop completed with undelivered notifications'
        ],
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
        ]
    });
}

/**
 * Logs Sentry message to console.
 */
function logMessage(this: BaseErrorReporting, ...args: any[]): void {
    if (this.configuration?.showDebugInfo) {
        console.debug('Sentry:', ...args);
    }
}

// #endregion

/**
 * @classdesc
 * Client side error reporting service.
 */
export default class BaseErrorReporting extends Service {
    console?: FakeConsole;

    /**
     * Holds Sentry configuration.
     */
    configuration?: ErrorReportingConfig;

    /**
     * Holds current Sentry context setup.
     */
    context?: ErrorReportingContext;

    constructor(owner?: object) {
        super(owner);

        // For some reason sentry is not catching and logging unhandled promises on its own.  So we setup logging for them here.
        // We should probably upgrade Sentry and try to get it to do this on its own.
        addWeakListener(this, window, 'unhandledrejection', (evt: Event) => {
            // Not sure that we should stop propagation, but this is pre-existing behavior that I was scared to change.
            // Again, the best course of action is likely for us to try and remove this entirely and get Sentry to take care of it.
            evt.preventDefault();
            evt.stopPropagation();
            evt.stopImmediatePropagation();
        });
    }

    /**
     * Helper method to generate a single "error to ignore" configuration.
     */
    protected getETI(value: string, propertyType = 'message'): ErrorsToIgnore {
        return { propertyType, value };
    }

    /**
     * List of errors to ignore when processing events.
     */
    get errorsToIgnore(): ErrorsToIgnore[] {
        return [
            this.getETI('NS_ERROR_NOT_INITIALIZED', 'name'),
            this.getETI('AbortError', 'name'),
            this.getETI('The adapter operation was aborted'),
            this.getETI('The Ajax operation was aborted'),
            this.getETI('InvalidAntiForgeryToken'),
            this.getETI('Network request failed'),
            this.getETI("Can't execute code from a freed script"),
            this.getETI('Permission denied'),
            // This happens when there are too many observations occurring in a single animation frame. Note: the website will not break }.
            // Some version of Chrome (i.e. 68) will throw this on the saved clips hero card when playing a saved clip }.
            // More info: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded }
            this.getETI('ResizeObserver loop limit exceeded'),
            this.getETI('Error when processing route:'),
            // We don't care about any Chrome extension errors. There was one in particular causing lots of errors for a very small number of users:
            // https://sentry.io/organizations/alarmcom-zc/issues/887082368/?project=1377221&query=is%3Aunresolved&sort=freq&statsPeriod=14d
            this.getETI('chrome-extension://', 'stack')
        ];
    }

    /**
     * List of xhr requests that we do not want to send breadcrumbs to Sentry.
     *
     * This would include things like keep alive, performance monitoring, etc.
     */
    get xhrRequestsToIgnoreInBreadcrumbs(): string[] {
        return [];
    }

    /**
     * Gets the specified string value from the original exception as a lower case string.
     */
    getExceptionStringValue(hint: ADCEventHint = {}, propertyType: string): string {
        const { originalException, data } = hint;

        // NOTE: Some "hints" (i.e. original error objects) don't contain an originalException.
        // Instead, they have a data object containing a stack that contains the error data.
        const errorData = originalException || (data || {}).stack;

        if (!errorData) {
            return '';
        }

        // If original exception is a string itself, just return it.
        if (typeof errorData === 'string') {
            return errorData;
        }

        return typeof errorData[propertyType] === 'string' ? errorData[propertyType] : '';
    }

    /**
     * Configures the error reporting session.
     */
    configure(errorReportingConfiguration: Partial<ErrorReportingConfig>): void {
        if (this.configuration) {
            logMessage.call(this, 'Service was already initialized, skipping duplicate initialization.');
            return;
        }

        // Make sure that we are logging messages from the console.
        // Note: We cannot use the Debug integration because I could not figure out how to override Sentry's Logger class so
        //      that it does not use console's methods because those get pushed to Sentry.
        // mockErrorReportingForConsole.call(this);

        const configuration = getConfiguration.call(this, errorReportingConfiguration);

        // Initialize service.
        initSentry(configuration);

        set(this, 'configuration', configuration);

        logMessage.call(this, 'Configured.', configuration);
    }

    /**
     * Set Sentry scope context.
     */
    setContext(context: ErrorReportingContext): void {
        if (!this.configuration) {
            console.error(
                "Sentry: Service cannot set context because it was not configured yet. Please call 'configure' first."
            );
            return;
        }

        const { user, tags, extra, fingerprint } = context;

        // Configure Sentry scope with attributes.
        configureScope((scope) => {
            // Set default level.
            scope.setLevel('fatal');

            // Set user data.
            scope.setUser(user || {});

            // Set tags.
            if (tags) {
                scope.setTags(tags);
            }

            if (extra) {
                scope.setExtras(extra);
            }

            if (fingerprint) {
                scope.setFingerprint(fingerprint);
            }
        });

        set(this, 'context', context);

        logMessage.call(this, 'Scope context was configured', context);
    }

    /**
     * Method called before sending an event to Sentry.
     *
     * @param event - Sentry error event.
     * @param hint - Includes original event that caused the Sentry event.
     *
     * @returns Must return either the original Event or null. If null is returned the event is not sent to Sentry.
     */
    beforeSend(event: SentryEvent, hint: ADCEventHint = {}): SentryEvent | null {
        if (!event) {
            return null;
        }

        if (isRecognizedServerError.call(this, hint)) {
            logMessage.call(this, 'Dropping event because it is a processing or validation error.', event, hint);
            return null;
        }

        if (shouldEventBeIgnored.call(this, hint)) {
            logMessage.call(this, 'Dropping event because it should be ignored.', event, hint);
            return null;
        }

        // If we want to show debug info then log the original error to the console.
        if (this.configuration?.showDebugInfo) {
            logMessage.call(this, event, hint);

            const { level } = event;
            if (level) {
                this.console?.[LEVEL_TO_CONSOLE_FN_MAP[level]](hint.originalException);
            }
        }

        return event;
    }

    /**
     * Method called before Sentry breadcrumb is constructed.
     *
     * @param breadcrumb - Sentry breadcrumb.
     * @param hint - Includes original event that caused the Sentry event.
     *
     * @returns Must return either the original Breadcrumb or null. If null is returned the breadcrumb is not sent to Sentry.
     */
    beforeBreadcrumb(breadcrumb: Breadcrumb, hint: BreadcrumbHint): Breadcrumb | null {
        // Do not log breadcrumbs for some XHR requests.
        if (breadcrumb.category === 'xhr') {
            const url = (breadcrumb.data?.url ?? '').toLowerCase();

            if (this.xhrRequestsToIgnoreInBreadcrumbs.some((r) => url.includes(r))) {
                logMessage.call(this, 'Dropping breadcrumb because XHR should be ignored.', breadcrumb);

                return null;
            }
        }

        // Massage clicked ui elements so that we get better info from them for debugging.
        if (breadcrumb.category === 'ui.click') {
            const { target } = hint.event || {};

            if (target) {
                // The original message is a path to the element.
                const path = breadcrumb.message;

                const values: string[] = [];

                // Try to extract some data.
                [['id'], ['innerText', 'text'], ['title'], ['ariaLabel'], ['className']].forEach(([key, targetKey]) => {
                    targetKey = targetKey || key;

                    const value = target[key];

                    if (value) {
                        values.push(`${targetKey}: ${value}`);
                    }
                });

                breadcrumb.message = `Clicked: ${target.tagName.toLowerCase()}(${values.join(', ')}) | path: ${path}`;
            }
        }

        return breadcrumb;
    }
}
