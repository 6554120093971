import BaseTimezoneControl from './base-timezone-control.ts';
import { action } from '@ember/object';

import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import setSeconds from 'date-fns/setSeconds';
import getSeconds from 'date-fns/getSeconds';
import getMinutes from 'date-fns/getMinutes';
import getHours from 'date-fns/getHours';
import setYear from 'date-fns/setYear';
import setMonth from 'date-fns/setMonth';
import setDate from 'date-fns/setDate';
import getDate from 'date-fns/getDate';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import isEqual from 'date-fns/isEqual';

import type { BaseTimezoneControlSignature } from './base-timezone-control';

export type DateChangePayload = {
    [index: string]: Date;
    startDate: Date;
    endDate: Date;
};

type DateArg = 'start' | 'end';

export interface SmartDateRangeSignature extends BaseTimezoneControlSignature {
    Element: HTMLFormElement;
    Args: BaseTimezoneControlSignature['Args'] & {
        /** Indicates a multi-day date range should be shown (as opposed to single day). */
        showMultiDayRange?: boolean;
        /** The range starting date. */
        startDate: Date;
        /** The range ending date.  */
        endDate: Date;
        /** Minimal range date. */
        minDate?: Date;
        /** maximum range date. */
        maxDate?: Date;
        /** Indicates whether to show seconds in the smart time picker. */
        showSeconds?: boolean;
        /** Called when the component changes the date range. */
        onchange: (dates: Partial<DateChangePayload>, isRange: boolean) => void;
    };
}

/**
 * Updates the passed date using the hours, minutes, and seconds from the passed value.
 *
 * @private
 */
function updateTime(date: Date, value: Date): Date {
    return setHours(setMinutes(setSeconds(date, getSeconds(value)), getMinutes(value)), getHours(value));
}

/**
 * Updates the passed date using the day, month, and year from the passed value.
 *
 * @private
 */
function updateDate(date: Date, value: Date): Date {
    return setYear(setMonth(setDate(date, getDate(value)), getMonth(value)), getYear(value));
}

/**
 * @classdesc
 * A component for displaying a single day, or multi-day date/time range picker, using smart time and date pickers.
 */
export default class SmartDateRangeComponent extends BaseTimezoneControl<SmartDateRangeSignature> {
    /**
     * Updates the indicated set of internal date values (range versus single).
     */
    private notifyDateChange(fields: DateArg[], value: Date, fnRecalculate: (date: Date, value: Date) => Date): void {
        const updatedProps = fields
            .map((prefix) => `${prefix}Date`)
            .reduce((props: Partial<DateChangePayload>, name: keyof SmartDateRangeSignature['Args']) => {
                const v = this.args[name] as Date,
                    computedValue = this.getUtcDateFromZone(
                        fnRecalculate(this.getZonedDate(v), this.getZonedDate(value))
                    );

                // Check for inequality, because updating an object reference that is technically equal to the current value will still fire update events.
                if (!isEqual(v, computedValue)) {
                    props[name] = computedValue;
                }

                return props;
            }, {});

        if (Object.keys(updatedProps).length) {
            this.args.onchange(updatedProps, this.args.showMultiDayRange ?? false);
        }
    }

    // region Actions

    /**
     * Updates a time value.
     *
     * @param isStart Indicates the value to change is a start time.
     * @param value The date to use for new hour, minutes, and seconds.
     */
    @action changeTime(isStart: boolean, value: Date): void {
        this.notifyDateChange([isStart ? 'start' : 'end'], value, updateTime);
    }

    /**
     * Updates a date value.
     *
     * @param isStart Indicates the value to change is a start date.
     * @param value The date to use for new day, month, and year.
     */
    @action changeDate(isStart: boolean, value: Date): void {
        const fields: DateArg[] = this.args.showMultiDayRange ? [isStart ? 'start' : 'end'] : ['start', 'end'];
        this.notifyDateChange(fields, value, updateDate);
    }

    // endregion
}
