import Component from '@glimmer/component';
import { set, action } from '@ember/object';
import { SELECTED, UNSELECTED } from '../../../utils/checkbox-tree-item.js';

import type CheckboxTreeItem from '../../../utils/dropdown-select-item';

export interface DetailCheckboxSignature {
    Element: HTMLDivElement;
    Args: {
        item: CheckboxTreeItem;
        idPrefix?: string;
        'on-action-execute'?: VoidFunction;
        'items-changed'?: (items: CheckboxTreeItem[]) => void;
        'toggle-subitems-visibility': (item: CheckboxTreeItem) => void;
        isFocusable?: boolean;
        maxItemCount?: number;
        showGroupSeparator?: boolean;
        hideSubitems?: boolean;
        index: number | string;
    };
}

/**
 * @classdesc
 * A wrapper component for rendering the checkbox associated with a {@link components.checkbox-tree.TreeItem}.
 */
export default class DetailedCheckboxInput extends Component<DetailCheckboxSignature> {
    private executeAction(): void {
        this.args['on-action-execute']?.();
    }

    /**
     * Toggles the {@link CheckboxTreeItem} object's state along with each subitem's state.
     */
    @action itemClicked(item: CheckboxTreeItem, evt: Event & { target: HTMLInputElement }): void {
        this.executeAction();

        set(item, 'internalState', evt.target.checked ? SELECTED : UNSELECTED);
    }

    /**
     * Selects all subitems within the {@link CheckboxTreeItem}.
     */
    @action selectAll(item: CheckboxTreeItem): void {
        this.executeAction();

        set(item, 'internalState', SELECTED);
    }

    /**
     * Deselects all subitems within the {@link CheckboxTreeItem}.
     */
    @action selectNone(item: CheckboxTreeItem): void {
        this.executeAction();

        set(item, 'internalState', UNSELECTED);
    }
}
