import Component from '@glimmer/component';

import type { ComponentLike, WithBoundArgs } from '@glint/template';
import type ResponsiveTableCellSignature from './responsive-table/cell-signature';

export interface ResponsiveTableSignature<T> {
    Element: HTMLTableElement;
    Args: {
        /** An array of objects, each object containing the necessary data for a row. If the value of this parameter is a promise, a spinner will be shown until the promise is resolved. */
        data: T[] | Promise<T[]>;
    };
    Blocks: {
        default: [
            {
                /** Represents a column within the current row. */
                cell: WithBoundArgs<ComponentLike<ResponsiveTableCellSignature<T>>, 'row' | 'rowIndex'>;
            }
        ];
    };
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class ResponsiveTable<T> extends Component<ResponsiveTableSignature<T>> {}
