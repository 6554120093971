import { equal, alias } from '@ember/object/computed';
import { VALUE_CHANGE_ACTION } from './common/base-input.js';
import { default as BaseSlider, getValueFromSliderPercentage } from './slider/base-slider.js';
import { computed, action, get, set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { ON, OFF, UNKNOWN } from '../utils/dimmer-switch.ts';

// region Trigger Methods

/**
 * Updates the dimmer's state and sends the value change action if requested.
 *
 * @param {number} state - The new state of the dimmer.
 * @param {number} [percentage=undefined] - The new percentage of the dimmer.
 * @param {boolean} [isFromSlider=false] - Determines if the change came from the slider or the buttons.
 * @param {boolean} [isMouseUp=true] - Is the mouse currently in the released position?
 */
function updateDimmerState(state, percentage = undefined, isFromSlider = false, isMouseUp = true) {
    set(this, 'state', state);

    // Was a new percentage given?
    if (percentage !== undefined) {
        // Set the internal slider percentage.
        set(this, 'sliderPercentage', percentage);
    }

    // Was the value-change action NOT defined in the handlebars?
    if (!get(this, VALUE_CHANGE_ACTION)) {
        // Do NOT send the action.
        return;
    }

    // Send back the new value, along with a boolean indicating if the value was changed using the slider element.
    get(this, VALUE_CHANGE_ACTION)(state, getValueFromSliderPercentage.call(this), isFromSlider, isMouseUp);
}

// endregion

/**
 * @classdesc A slider control in between on/off buttons that control the state of the slider.
 */
export default class DimmerSwitch extends BaseSlider {
    @service intl;

    /**
     * Determines the state that the dimmer is in.
     *
     * @type {String}
     */
    state = UNKNOWN;

    /**
     * Determines if the dimmer state is ON.
     *
     * @type {boolean}
     */
    @equal('state', ON)
    isDimmerOn;

    // region Button Properties

    /**
     * Determines if the buttons should be iconOnly.
     *
     * @type {boolean}
     */
    iconOnlyButtons = true;

    /**
     * The name of the icon to display in the left button.
     *
     * @type {String}
     */
    leftButtonIcon = 'light-off';

    /**
     * The title of the icon displayed in the left button.
     *
     * @type {String}
     */
    leftButtonIconTitle = '';

    /**
     * The description of the icon displayed in the left button.
     *
     * @type {String}
     */
    leftButtonIconDesc = '';

    /**
     * The text to display in the button to the left of the slider.
     *
     * @type {String}
     */
    leftButtonText;

    /**
     * The name of the icon to display in the right button.
     *
     * @type {String}
     */
    rightButtonIcon = 'light-on';

    /**
     * The title of the icon displayed in the right button.
     *
     * @type {String}
     */
    rightButtonIconTitle = '';

    /**
     * The description of the icon displayed in the right button.
     *
     * @type {String}
     */
    rightButtonIconDesc = '';
    /**
     * The text to display in the button to the right of the slider.
     *
     * @type {String}
     */
    rightButtonText;

    /**
     * The title of the icon displayed in the right button.
     *
     * @type {String}
     */
    @computed('rightButtonIconTitle')
    get rightIconTitle() {
        if (isEmpty(this.rightButtonIconTitle)) {
            return this.intl.t('@adc/ui-components.lightOn');
        }

        return this.rightButtonIconTitle;
    }

    set rightIconTitle(value) {
        set(this, 'rightButtonIconTitle', value);
    }

    /**
     * The title of the icon displayed in the left button.
     *
     * @type {String}
     */
    @computed('leftButtonIconTitle')
    get leftIconTitle() {
        if (isEmpty(this.leftButtonIconTitle)) {
            return this.intl.t('@adc/ui-components.lightOff');
        }

        return this.leftButtonIconTitle;
    }

    set leftIconTitle(value) {
        set(this, 'leftButtonIconTitle', value);
    }

    /**
     * Indicates that the dimmer ON/OFF buttons order should be reversed.
     *
     * USAGE: When the dimmer is shows vertically the slider must progress from bottom to top,
     * this requires the dimmer ON button to be at the top. Otherwise the button
     * should be on the right side.
     *
     * @type {boolean}
     */
    @alias('showVertically')
    reverseButtons;

    // endregion

    /**
     * Turns the dimmer's state to ON.
     */
    @action turnOn() {
        // Note: Do not move the slider percentage, unless it was zero, then set it to the max percentage.
        updateDimmerState.call(this, ON, this.sliderPercentage || 100);
    }

    /**
     * Turns the dimmer's state to OFF.
     */
    @action turnOff() {
        // Note: Do not move the slider percentage.
        updateDimmerState.call(this, OFF);
    }

    /**
     * Updates the dimmer state (sends action if requested) whenever the slider changes value.
     *
     * @param {number} newPercentage - The new value of the slider.
     * @param {boolean} [isFromSlider=true] - Was the change made by the slider?
     * @param {boolean} [isMouseUp=true] - Is the mouse currently in the release position?
     */
    @action sliderValueChanged(newPercentage, isFromSlider = true, isMouseUp = true) {
        updateDimmerState.call(this, newPercentage > 0 ? ON : OFF, newPercentage, isFromSlider, isMouseUp);
    }
}
