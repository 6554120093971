import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import type ModalService from '../services/modals';

type MethodParameters = Parameters<ModalService['showModal']>;

export interface ShowModalSignature {
    Args: {
        Named: {
            type: MethodParameters[0];
            model?: MethodParameters[1];
            onClose?: MethodParameters[2];
        };
    };
    Return: () => ReturnType<ModalService['showModal']>;
}

/**
 * Returns a function that when executed will open the specified modal dialog.
 */
export default class ShowModal extends Helper<ShowModalSignature> {
    @service declare modals: ModalService;

    compute(
        _: never[],
        {
            type,
            model,
            onClose
        }: { type: MethodParameters[0]; model?: MethodParameters[1]; onClose?: MethodParameters[2] }
    ): () => ReturnType<ModalService['showModal']> {
        return () => this.modals.showModal(type, model, onClose);
    }
}
