import BaseSchedule from './views/base-schedule.ts';
import { computed } from '@ember/object';

import type { BaseScheduleSignature } from './views/base-schedule';
import type { ScheduleElement } from './views/types';

export interface ScheduleViewSignature extends BaseScheduleSignature {
    Args: BaseScheduleSignature['Args'] & {
        /** The text to display when the timetable is 24/7. */
        allTimesText?: string;
        /** Optional CSS class used to set color for all times text and bar (will use light gray if not present). */
        allTimesColor?: 'red' | 'orange' | 'yellow' | 'green' | 'blue' | 'purple' | 'greenblue';
    };
}

/**
 * Displays the weekly schedule for a timetable
 */
export default class ScheduleView extends BaseSchedule<ScheduleViewSignature> {
    /**
     * Schedule to be rendered into the grid
     */
    @computed('args.allTimesText', 'planSchedules.[]')
    get scheduleElements(): ScheduleElement[] {
        return this.getScheduleElements(false, this.args.allTimesText);
    }
}
