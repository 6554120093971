import BaseTimeControl from './base-time-control.ts';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isNativeTimeInputSupported } from '../utils/time.ts';
import { getIsTouchDevice } from '../utils/general.ts';

import format from 'date-fns/format';
import parse from 'date-fns/parse';
import setHours from 'date-fns/setHours';
import getHours from 'date-fns/getHours';
import setMinutes from 'date-fns/setMinutes';
import getMinutes from 'date-fns/getMinutes';
import getSeconds from 'date-fns/getSeconds';
import setSeconds from 'date-fns/setSeconds';
import isSameDay from 'date-fns/isSameDay';

import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { BaseTimeControlSignature } from './base-time-control';

/**
 * Returns an updated date based on passed input element value.
 *
 * @private
 */
function getAdjustedTime(this: SmartTimeComponent, value: string): Date {
    const date = parse(value, this.outputFormat, new Date());
    return setHours(
        setMinutes(setSeconds(this.zonedDate ?? new Date(), getSeconds(date)), getMinutes(date)),
        getHours(date)
    );
}

/**
 * Returns a string describing the time boundary errors, if they exist.
 *
 * @private
 */
function getBoundaryErrorMessage(this: SmartTimeComponent, newDate: Date): string {
    const { intl } = this,
        { minDate, maxDate } = this.args,
        fnGetText = (key: string, date: Date) =>
            intl.t(`@adc/ui-components.${key}`, {
                // Do not copy this deprecated usage. If you see this, please fix it
                // eslint-disable-next-line @adc/ember/require-tz-functions
                time: intl.formatTime(date, this.intlFormat)
            });

    if (minDate) {
        const zonedMin = this.getZonedDate(minDate);
        if (zonedMin > newDate) {
            return fnGetText('timeInvalidMin', zonedMin);
        }
    }

    if (maxDate) {
        const zonedMax = this.getZonedDate(maxDate);
        if (zonedMax < newDate) {
            return fnGetText('timeInvalidMax', zonedMax);
        }
    }

    return '';
}

interface SmartTimeSignature {
    Element: HTMLInputElement;
    Args: BaseTimeControlSignature['Args'] & {
        /** Indicates whether to display seconds in the time picker. */
        showSeconds?: boolean;
    };
}

/**
 * @classdesc
 * A time picker that uses a native control, if possible, or a pattern restricted input if not possible.
 */
export default class SmartTimeComponent extends BaseTimeControl<SmartTimeSignature> {
    @service declare intl: ADCIntlService;

    hasTouchEvents?: boolean;

    constructor(owner: unknown, args: SmartTimeSignature['Args']) {
        super(owner, args);
        getIsTouchDevice(this);
    }

    // region Component state.

    /** @override */
    useNativeControl = isNativeTimeInputSupported;

    /**
     * Indicates the time format will have a token for am/pm.
     */
    get useMeridiem(): boolean {
        return !this.intl
            .formatTimeTz(new Date('2020-06-04T13:00:00.000Z'), {
                hour: 'numeric',
                minute: '2-digit',
                timeZone: 'UTC'
            })
            .includes('13');
    }

    /**
     * The format object for locale aware date formatting.
     */
    get intlFormat(): Intl.DateTimeFormatOptions {
        const fmt: Intl.DateTimeFormatOptions = {
            hour: 'numeric',
            minute: '2-digit'
        };

        if (this.args.showSeconds) {
            fmt.second = '2-digit';
        }

        return fmt;
    }

    /**
     * The format string for non-locale aware native control formatting.
     */
    get outputFormat(): string {
        const expectMeridiem = this.useMeridiem && !isNativeTimeInputSupported,
            pattern = [expectMeridiem ? 'h' : 'HH'];

        pattern.push(':mm');

        if (this.args.showSeconds) {
            pattern.push(':ss');
        }

        if (expectMeridiem) {
            pattern.push(' a');
        }

        return pattern.join('');
    }

    /**
     * The time input placeholder value.
     */
    get placeholder(): string {
        return `HH:MM${this.args.showSeconds ? ':SS' : ''}${this.useMeridiem ? ' AM' : ''}`;
    }

    /**
     * The time input pattern, for validation.
     */
    get pattern(): string {
        const segments = [],
            { useMeridiem } = this;

        if (isNativeTimeInputSupported || !useMeridiem) {
            // 00 - 23 Hours.
            segments.push('(0?[0-9]|1[0-9]|2[0-3])');
        } else {
            // 00 - 12 Hours.
            segments.push('(0?[1-9]|1[012])');
        }

        // 00 - 59 Minutes.
        segments.push('(:[0-5][0-9])');

        // Should we show seconds?
        if (this.args.showSeconds) {
            // 00 - 59 Seconds.
            segments.push('{2}');
        }

        if (!isNativeTimeInputSupported && useMeridiem) {
            // am/aM/Am/AM - pm/pM/Pm/PM
            segments.push('\\s[APap][mM]');
        }

        return `^${segments.join('')}$`;
    }

    private getFormattedDate(d?: Date): string | undefined {
        const { zonedDate } = this;

        if (!d || !zonedDate) {
            return undefined;
        }

        const v = this.getZonedDate(d);
        return isSameDay(v, zonedDate) ? format(v, this.outputFormat) : undefined;
    }

    /**
     * The minimum date value (if passed).
     */
    get min(): string | undefined {
        return this.getFormattedDate(this.args.minDate);
    }

    /**
     * The maximum date value (if passed).
     */
    get max(): string | undefined {
        return this.getFormattedDate(this.args.maxDate);
    }

    // endregion

    /**
     * The time input value.
     */
    get displayTime(): string | undefined {
        const { zonedDate } = this;
        if (!zonedDate) {
            return;
        }

        return isNativeTimeInputSupported
            ? format(zonedDate, this.outputFormat)
            : // Do not copy this deprecated usage. If you see this, please fix it
              // eslint-disable-next-line @adc/ember/require-tz-functions
              this.intl.formatTime(zonedDate, this.intlFormat);
    }

    // endregion

    // region Actions

    /**
     * Determines if we should listen to the change event.
     */
    @action inputChanged(evt: Event & { target: HTMLInputElement }): void {
        if (this.hasTouchEvents) {
            this.changeTime(evt);
        }
    }

    /**
     * Determines if we should listen to the blur event.
     */
    @action inputBlurred(evt: FocusEvent & { target: HTMLInputElement }): void {
        if (!this.hasTouchEvents) {
            this.changeTime(evt);
        }
    }

    /**
     * Updates the custom validity message for the target input element.
     */
    @action invalidInput(evt: Event & { target: HTMLInputElement }): void {
        const { target } = evt;

        target.setCustomValidity(
            getBoundaryErrorMessage.call(this, getAdjustedTime.call(this, target.value)) ||
                this.intl.t('@adc/ui-components.timeFormat', {
                    format: this.placeholder
                })
        );
    }

    /**
     * Validates the current time input value and notifies consumer if valid.
     */
    private changeTime(evt: Event & { target: HTMLInputElement }): void {
        const { target } = evt;
        if (!target.value || target.value === this.displayTime) {
            // No change.
            return;
        }

        // Clear validity message.
        target.setCustomValidity('');

        // Is input value valid?
        if (target.reportValidity()) {
            const newDate = getAdjustedTime.call(this, target.value);

            if (!isNativeTimeInputSupported) {
                const errorText = getBoundaryErrorMessage.call(this, newDate);

                if (errorText) {
                    target.setCustomValidity(errorText);
                    target.reportValidity();
                    target.focus();
                    return;
                }
            }

            // Pass new value out to consumer.
            this.args.onchange(this.getUtcDateFromZone(newDate));
        }
    }

    // endregion
}
