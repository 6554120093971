import templateOnlyComponent from '@ember/component/template-only';

export interface BinaryInputAndLabelWrapperSignature {
    Element: HTMLDivElement;
    Args: {
        'on-click': (e: Event) => void;
    };
    Blocks: {
        default: [];
    };
}
const BinaryInputAndLabelWrapper = templateOnlyComponent<BinaryInputAndLabelWrapperSignature>();
export default BinaryInputAndLabelWrapper;
