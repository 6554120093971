import { modifier } from 'ember-modifier';
import { assert } from '@ember/debug';
import { later } from '@ember/runloop';

export interface AddDelayedCssSignature {
    Element: HTMLElement;
    Args: {
        Positional: [string, number?];
    };
}

/**
 * Adds the passed CSS class to the passed HTML element (with optional delay) after element is rendered.  Useful for animation after rendering.
 */
const addDelayedCssSignature: ReturnType<
    typeof modifier<AddDelayedCssSignature>
> = modifier<AddDelayedCssSignature>(function addDelayedCss(
    element: HTMLElement,
    [css, delay = 0]: [css: string, delay: number]
): void {
    assert(
        '[@adc/template-helpers] The add-delayed-css modifier expects a CSS class to apply',
        css
    );
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/no-runloop
    later(() => element.classList.add(css), delay);
});
export default addDelayedCssSignature;

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Adds the passes CSS class to the modified element, after render (and using the delay value if passed).
         *
         * @example <div {{add-delayed-css "is-open"}} />
         */
        'add-delayed-css': typeof addDelayedCssSignature;
    }
}
