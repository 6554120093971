import templateOnlyComponent from '@ember/component/template-only';

import type { SimpleDialogSignature } from '../simple/simple-dialog';

export interface ConfirmationDialogSignature {
    Element: HTMLDivElement;
    Args: {
        model: Pick<SimpleDialogSignature['Args'], 'title' | 'buttons'> & {
            /** The text to display in the confirmation modal body. */
            description: string;
            /** Optional CSS class applied to the modal `<div />`. */
            cssClass?: string;
        };
    };
}

const ConfirmationDialog = templateOnlyComponent<ConfirmationDialogSignature>();
export default ConfirmationDialog;
