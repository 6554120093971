import Component from '@glimmer/component';
import { action } from '@ember/object';
import { once } from '@ember/runloop';

export interface ScrollContainerSignature {
    Element: HTMLDivElement;
    Blocks: {
        /** Yields a function that can be called to force the scroll to recompute. */
        default: [(scrollableChild: HTMLElement) => void];
    };
}

/**
 * @classdesc
 * A container element indicating its content can scroll vertically. Adds a small top shadow if the content can be scrolled up, and a bottom gradient if the content can scroll down.
 *
 * @see https://bitbucket.corp.adcinternal.com/projects/FALCOR/repos/adc-web-frontend/browse/addons/adc-ui-components/package/doc/scroll-container.md
 */
export default class ScrollContainer extends Component<ScrollContainerSignature> {
    /**
     * Calculates current scrollable state.
     */
    @action toggleScrollCss(scrollableContent: Element): void {
        const parent = scrollableContent.parentElement;
        if (parent) {
            const scrollShadowHostClassList = parent.classList,
                { scrollTop, clientHeight, scrollHeight } = scrollableContent;

            scrollShadowHostClassList.toggle('can-scroll-up', scrollTop > 0);
            scrollShadowHostClassList.toggle('can-scroll-down', scrollHeight - scrollTop - clientHeight > 1);
        }
    }

    /**
     * Action to handle element scrolling.
     */
    @action handleScroll(evt: Event & { target: HTMLElement }): void {
        this.toggleScrollCss(evt.target);
    }

    /**
     * Action yielded to consumer so child components can trigger the scrollable calculation.
     */
    @action recomputeScroll(scrollableChild: HTMLElement): void {
        const sc = scrollableChild.closest('.scroll-container');
        if (sc) {
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            once(this, this.toggleScrollCss, sc);
        }
    }
}
