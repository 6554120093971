import templateOnlyComponent from '@ember/component/template-only';

import type DropdownActionItem from '../../utils/dropdown-action-item.ts';
import type { DropdownActionsListItemSignature } from './list-item';

export interface DropdownActionsItemsListSignature {
    Element: HTMLUListElement;
    Args: Pick<DropdownActionsListItemSignature['Args'], 'on-item-click'> & {
        /** The drop down action items to render. */
        items: DropdownActionItem[] | null;
    };
}

const ItemsListComponent = templateOnlyComponent<DropdownActionsItemsListSignature>();
export default ItemsListComponent;
