import BaseView from './views/base-view.ts';
import { tracked } from '@glimmer/tracking';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import {
    MINUTES_IN_HOUR,
    MINUTES_IN_DAY,
    MINUTES_IN_WEEK,
    DAYS_IN_WEEK,
    HOURS_IN_DAY
} from '@adc/ember-utils/constants/time';
import { isEnterOrSpaceCode } from '@adc/ember-utils/utils/a11y';
import setDay from 'date-fns/setDay';
import { utcToZonedTime } from 'date-fns-tz';

import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { SafeString } from 'handlebars';
import type { TimeBlock } from './views/types';
import type LocaleService from '@adc/app-infrastructure/services/locale';

export type WeekViewElements = {
    id?: number;
    day: number;
    label: string;
    classes: string;
    style: SafeString;
    planNameLabel?: string;
};

type WeekViewElementsCollection = WeekViewElements[][];

const FIRST_DAY_IN_WEEK = 1; // First day of the week is Monday.

/**
 * Is the schedule all times?
 */
export function isScheduleAllTimes(timeBlocks: TimeBlock[]): boolean {
    // It's only possible that the schedule is All Times if there is only 1 time block.
    if (!timeBlocks || timeBlocks.length !== 1) {
        return false;
    }

    const { startMinutesLocal, endMinutesLocal } = timeBlocks[0];
    return endMinutesLocal - startMinutesLocal === MINUTES_IN_WEEK;
}

export interface WeekViewSignature {
    Element: HTMLElement;
    Args: {
        /** The time blocks to render. */
        timeBlocks: TimeBlock[];
        /** The text to display when the timetable is 24/7. */
        allTimesText: string;
        /** Indicates we should display the time block labels. */
        areTimeBlockLabelsVisible?: boolean;
        /** The ID of the currently active time block. */
        selectedTimeBlockId?: number;
        /** Disables interaction with the time table. */
        isReadOnly?: boolean;
        /** Called when the user clicks a time block, provided `isReadOnly` is not `true`. */
        timeBlockClicked: (id: number) => void;
        /** Called when the user clicks empty space within the time table. */
        emptyAreaClicked: (day: number, time: number) => void;
    };
}

/**
 * @classdesc
 * Displays a week view that can be populated with time blocks to display. Can fire events based on user interaction.
 *
 * timeBlockClicked: Fired when a user clicks on a time span.
 *      id - id of the time block that was clicked.
 *
 * emptyAreaClicked: Fired when a user clicks on a blank area of the week.
 *      day - Day number that was clicked.
 *      time - Time of the day in minutes that was clicked rounded to the nearest 30 minute interval.
 */
export default class WeekView extends BaseView<WeekViewSignature> {
    @service declare intl: ADCIntlService;
    @service declare locale: LocaleService;

    /**
     * Text to display on a time block if it spans 24/7.
     */
    get allTimesText(): string {
        return this.args.allTimesText ?? '';
    }

    /**
     * Should we display the labels in the time blocks?
     */
    get areTimeBlockLabelsVisible(): boolean {
        return this.args.areTimeBlockLabelsVisible ?? true;
    }

    /**
     * The highlighted time block id.
     */
    @tracked activeTimeBlockId?: number;

    /**
     * Array of hours that should have lines drawn on the grid.
     */
    hourLines = Array.from({ length: HOURS_IN_DAY + 1 }, (_, i) => i);

    /**
     * Array of the hours that should have labels next to them.
     */
    hourLabels = Array.from({ length: HOURS_IN_DAY / 2 + 1 }, (_, i) => i * 2);

    /**
     * Days of the week labels
     */
    @computed('locale.timeZone')
    get daysOfWeek(): Date[] {
        const now = new Date(),
            days = Array.from({ length: DAYS_IN_WEEK }, (_, i) => {
                return setDay(now, i);
            }),
            zonedNow = utcToZonedTime(new Date(), this.locale.timeZone);

        // Rotate the array so we have the right first day of the week.
        if (zonedNow.getDay() == now.getDay()) {
            days.push(...days.splice(0, FIRST_DAY_IN_WEEK));
        } else if (zonedNow.getDay() < now.getDay()) {
            days.push(...days.splice(0, 2));
        }

        return days;
    }

    /**
     * Add a schedule to the specified list of schedule days.
     */
    private addTimeBlockToSchedule(timeBlock: TimeBlock, schedule: WeekViewElementsCollection): void {
        const dayOfWeek = Math.floor(timeBlock.startMinutesLocal / MINUTES_IN_DAY);

        this.createElementsForTimeBlock(
            timeBlock,
            this.allTimesText,
            (
                index: number,
                label: string,
                classes: string,
                start: number,
                length: number,
                planNameLabel: string
            ): void => {
                const style = htmlSafe(`top: ${start}%; height: ${length}%;`);

                schedule[index % 7].push({
                    id: timeBlock.id,
                    day: dayOfWeek,
                    label,
                    planNameLabel,
                    classes,
                    style
                });
            }
        );
    }

    /**
     * Is the current schedule All Times?
     */
    @computed('args.timeBlocks.[]')
    get isScheduleAllTimes(): boolean {
        return isScheduleAllTimes(this.args.timeBlocks);
    }

    /**
     * Elements to be rendered
     */
    @computed('allTimesText', 'args.timeBlocks.[]')
    get timeBlockElementsArray(): WeekViewElementsCollection {
        const schedule = Array.from({ length: 7 }, () => []);

        (this.args.timeBlocks ?? []).forEach((timeBlock) => this.addTimeBlockToSchedule(timeBlock, schedule));

        // Rotate the array so we have the right first day of the week.
        schedule.push(...schedule.splice(0, FIRST_DAY_IN_WEEK));

        return schedule;
    }

    /**
     * Called when a day element is clicked.
     */
    @action dayClicked(dayClicked: number, event: MouseEvent & { target: HTMLElement }): void {
        const { emptyAreaClicked } = this.args;

        // Return immediately if the grid is read only or an action is not defined.
        if (this.args.isReadOnly || !emptyAreaClicked) {
            return;
        }

        const { height, top } = event.target.getBoundingClientRect(),
            halfHourMinutes = MINUTES_IN_HOUR / 2,
            halfHourHeight = height / (MINUTES_IN_DAY / halfHourMinutes);

        emptyAreaClicked(
            // Day of the week that was clicked. 0 = Monday ... 6 = Sunday.
            (dayClicked + FIRST_DAY_IN_WEEK) % DAYS_IN_WEEK,

            // Start time floored to nearest half hour interval.
            Math.floor((event.clientY - top) / halfHourHeight) * halfHourMinutes
        );
    }

    /**
     * Triggered when a time block is clicked.
     */
    @action handleTimeBlockClick(timeBlockId: number): void {
        // If the grid is not read only send the action.
        if (!this.args.isReadOnly) {
            this.args.timeBlockClicked?.(timeBlockId);
        }
    }

    /**
     * For a11y, handles a space or enter while focusing on a day as a click.
     */
    @action onKeyDown(dayClicked: number, event: KeyboardEvent): void {
        if (isEnterOrSpaceCode(event.code)) {
            event.preventDefault();
            this.args.emptyAreaClicked((dayClicked + FIRST_DAY_IN_WEEK) % DAYS_IN_WEEK, 0);
        }
    }
}
