import BaseTimeControl from './base-time-control.ts';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isNativeDateInputSupported } from '../utils/time.ts';
import { getIsTouchDevice } from '../utils/general.ts';

import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { BaseTimeControlSignature } from './base-time-control';

const outputFormat = 'yyyy-MM-dd';

/**
 * Notifies the consumer that the date has changed.
 *
 * @private
 */
function emitChangeEvent(this: SmartDateComponent, year: number, month: number, day: number): void {
    const date = new Date(year, month, day, 12);
    if (isValid(date)) {
        this.args.onchange(this.getUtcDateFromZone(date));
    }
}

export interface SmartDateSignature {
    Element: HTMLInputElement;
    Args: Omit<BaseTimeControlSignature['Args'], 'onchange'> & {
        /** Called when the user modifies the date/time value. */
        onchange: (date: Date | null) => void;
        /** Whether to allow the control to accept `null` values. */
        allowNull?: boolean;
    };
}

/**
 * @classdesc
 * A date picker that uses a native control, if possible, or the pikaday component if not possible.
 * export default class SmartTimeComponent extends BaseTimeControl<SmartTimeComponentArgs> {
 */
export default class SmartDateComponent extends BaseTimeControl<SmartDateSignature> {
    @service declare intl: ADCIntlService;

    hasTouchEvents?: boolean;

    constructor(owner: unknown, args: SmartDateSignature['Args']) {
        super(owner, args);
        getIsTouchDevice(this);
    }

    // region Component state.

    /** @override */
    useNativeControl = isNativeDateInputSupported;

    /**
     * The date placeholder.
     */
    get placeholder(): string {
        return 'mm/dd/yyyy';
    }

    /**
     * The date pattern for input validation.
     */
    get pattern(): string {
        if (isNativeDateInputSupported) {
            // Only one format for native time input.
            return '^\\d{4}-\\d{2}-\\d{2}$';
        }

        return '^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$';
    }

    private getFormattedDate(d?: Date): string | undefined {
        return d && format(this.getZonedDate(d), outputFormat);
    }

    /**
     * The minimum date value (if passed).
     */
    get min(): string | undefined {
        return this.getFormattedDate(this.args.minDate);
    }

    /**
     * The maximum date value (if passed).
     */
    get max(): string | undefined {
        return this.getFormattedDate(this.args.maxDate);
    }

    /**
     * The formatted, selected date.
     */
    get displayDate(): string {
        return this.getFormattedDate(this.args.date) ?? '';
    }

    // endregion

    // region Actions

    /**
     * Determines if we should listen to the change event.
     */
    @action inputChanged(evt: Event & { target: HTMLInputElement }): void {
        if (this.hasTouchEvents) {
            this.changeDate(evt);
        }
    }

    /**
     * Determines if we should listen to the blur event.
     */
    @action inputBlurred(evt: FocusEvent & { target: HTMLInputElement }): void {
        if (!this.hasTouchEvents) {
            this.changeDate(evt);
        }
    }

    /**
     * Updates the date, as indicated by the native date picker.
     */
    private changeDate(evt: Event & { target: HTMLInputElement }): void {
        const { target } = evt,
            { value } = target;

        // Did the value change?
        if (value !== this.displayDate) {
            // Clear validity message.
            target.setCustomValidity('');

            // Is input value valid?
            if (target.reportValidity()) {
                if (value === '' && this.args.allowNull) {
                    this.args.onchange(null);
                }
                const [year, month, day] = value.split('-').map(Number);
                emitChangeEvent.call(this, year, month - 1, day);
            }
        }
    }

    // endregion
}
