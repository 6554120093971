import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

import type ModalService from '../../../services/modals.ts';
import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { ModalButtonConfig } from '../../modals/base/footer/modal-footer';

type DeleteModel = {
    action: ModalButtonConfig['action'];
    text?: string;
    requireConfirmation?: boolean;
    confirmTitle?: string;
    confirmText?: string;
    confirmButtonText?: string;
};

export interface DeleteModelSignature {
    Element: HTMLElement;
    Args: {
        /** Information about the delete operation. */
        model: DeleteModel;
    };
}

/**
 * @classdesc
 * Component for consistent styling of delete model functionality in route-views.
 */
export default class DeleteModelComponent extends Component<DeleteModelSignature> {
    @service declare modals: ModalService;
    @service declare intl: ADCIntlService;

    /**
     * Text to be shown in the delete button.
     */
    @computed('args.model.text')
    get text(): string {
        return this.args.model.text ?? this.intl.t('generic.delete');
    }

    /**
     * Determines the correct action for the button.
     */
    @computed('args.model.{action,confirmButtonText,confirmText,confirmTitle,requireConfirmation}', 'text')
    get deleteAction(): (() => Promise<void>) | VoidFunction | undefined {
        const { intl } = this,
            { action: fnAction, requireConfirmation } = this.args.model;

        if (!fnAction) {
            console.error('Action must be defined for the delete-model');
            return undefined;
        }

        // If confirmation is not required, then just use the action.
        if (!requireConfirmation) {
            return fnAction;
        }

        const {
            confirmTitle = this.text,
            confirmText = intl.t('generic.areYouSure'),
            confirmButtonText = intl.t('generic.delete')
        } = this.args.model;

        // Use confirmation dialog and if user confirms then execute the action.
        return () => {
            this.modals.showConfirmationDialog(confirmTitle, confirmText, {
                text: confirmButtonText,
                css: 'btn-color-danger',
                action: fnAction
            });
        };
    }
}
