// Do not copy this deprecated usage. If you see this, please fix it
/* eslint-disable ember/no-observers */
import { addObserver, removeObserver } from '@ember/object/observers';
import { once } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { default as BaseInput, clearErrorMessage } from '../common/base-input.js';

// region Calculation Methods

/**
 * Calculates the value corresponding to the position of the slider.
 *
 * @returns {number}
 */
export function getValueFromSliderPercentage() {
    const { minValue } = this,
        range = this.maxValue - minValue;

    return Math.round((this.sliderPercentage * range) / 100 + minValue);
}

/**
 * Calculates the position of the slider in terms of a percentage using the value, minValue, and maxValue values.
 *
 * @param {number} specifiedValue
 * @returns {number}
 */
export function getSliderPercentageFromValue(specifiedValue) {
    let { minValue, maxValue, value } = this;

    // Was there a specified value?
    if (specifiedValue !== undefined) {
        // Use the specified value.
        value = specifiedValue;
    }

    if (value <= minValue) {
        return 0;
    }

    if (value >= maxValue) {
        return 100;
    }

    return Math.round(((value - minValue) / (maxValue - minValue)) * 100);
}

// endregion

/**
 * @classdesc The base class that is shared by the slider-adc, ticked-slider and dimmer-switch components.
 */
export default class BaseSlider extends BaseInput {
    tagName = '';
    // region Hooks

    /** @override */
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/classic-decorator-hooks
    init() {
        super.init(...arguments);

        addObserver(this, 'value', this, this.validateAttributes);
        addObserver(this, 'minValue', this, this.validateAttributes);
        addObserver(this, 'maxValue', this, this.validateAttributes);
        addObserver(this, 'sliderPercentage', this, this.clearTooltip);

        this.validateAttributes();
    }

    /** @override */
    didReceiveAttrs() {
        super.didReceiveAttrs(...arguments);

        let value = this.getAttr('value');

        // Was a value NOT provided?
        if (value === undefined || isNaN(value)) {
            // Set value to mid point between min and max values.
            const { minValue, maxValue } = this;
            value = minValue + maxValue / 2;
        }

        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        once(this, 'set', 'value', value);
    }

    /** @override */
    willDestroy() {
        removeObserver(this, 'value', this, this.validateAttributes);
        removeObserver(this, 'minValue', this, this.validateAttributes);
        removeObserver(this, 'maxValue', this, this.validateAttributes);
        removeObserver(this, 'sliderPercentage', this, this.clearTooltip);

        super.willDestroy();
    }
    // endregion

    /**
     * Add observer to validate data received by the component.
     */
    validateAttributes() {
        const { value, minValue, maxValue } = this;

        // Are any of the attribute values NOT numbers?
        if ([value, minValue, maxValue].some((attrValue) => typeof attrValue !== 'number')) {
            console.error('ADC Slider: value, minValue, and maxValue must all be of type "number".');
        }

        if (maxValue <= minValue) {
            console.error('ADC Slider: The maxValue value must be greater than the minValue value.');
        }

        // Set internal value based on the min, max, and current values.
        const newSliderPercentage = getSliderPercentageFromValue.call(this);

        if (this.sliderPercentage !== newSliderPercentage) {
            // Set internal value based on the min, max, and current values.
            this.sliderPercentage = getSliderPercentageFromValue.call(this);

            this.clearTooltip();
        }
    }

    /**
     * Determines if the slider is disabled and cannot be interacted with.
     *
     * @type {boolean}
     */
    @tracked
    disabled = false;

    /**
     * The value of the slider when the thumb is all the way off.
     *
     * @type {number}
     */
    @tracked
    minValue = 0;

    /**
     * The value of the slider when the thumb is all the way on.
     *
     * @type {number}
     */
    @tracked
    maxValue = 100;

    /**
     * The value of the slider, corresponding to the position of the thumb.
     *
     * @note If no value is specified, the default is the minimum value plus half of the maximum.
     *
     * @type {number}
     */
    @tracked
    value = 50;

    /**
     * The percentage value the slider uses to position the thumb.
     *
     * @note This property is internal and should NOT be altered from the component.
     *
     * @type {number}
     */
    @tracked sliderPercentage;

    /**
     * Clears the tooltip error message when the slider value is changed.
     */
    clearTooltip() {
        clearErrorMessage.call(this);
    }
}
