import Cookie from './cookie';
import EssentialCookie from './essential-cookie';
import SameSite from './samesite-enum';

/**
 * The name of the consent cookie.
 */
export const DO_NOT_TRACK_COOKIE = 'donottrack';

/**
 * Sets a cookie.
 */
function setCookie(
    this: CookieService,
    cookieName: string,
    value: string,
    expirationDate?: Date,
    isSecure = false,
    sameSite?: SameSite,
    domain?: string
): void {
    document.cookie = `${cookieName}=${value}; ${sameSite ? ' SameSite=' + SameSite[sameSite] + ';' : ''} ${
        isSecure ? ' Secure;' : ''
    } ${expirationDate ? ' expires=' + expirationDate.toUTCString() + ';' : ''} path=/; ${
        'domain=' + domain ? domain : this.getCookieDomain()
    }`;
}

/**
 * Validates a cookie.
 *
 * @param cookie The cookie.
 *
 * @returns {boolean} Is the cookie valid?
 */
function validateCookie(cookie: Cookie) {
    if (!cookie || !cookie.name || cookie.name?.trim() === '') {
        console.warn('Cookie was not set due to invalid cookie or cookie name.');

        return false;
    }

    return true;
}

/**
 * Gets the date for cookie invalidation.
 *
 * @returns {DateTime} The date.
 */
function getCookieInvalidationDate() {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + -1 * 24 * 60 * 60 * 1000);

    return expirationDate;
}

/**
 * Gets the old, non-prefixed cookie name.
 *
 * @param cookieName The name of the new cookie (with prefix).
 *
 * @returns {string | undefined} The name of the old cookie (without prefix).
 */
function getNonPrefixedCookieName(cookieName: string | undefined): string | undefined {
    if (!cookieName || cookieName?.trim() === '') {
        return undefined;
    }

    return cookieName.substring(6);
}

/**
 * Gets the value of a cookie.
 *
 * @param cookieName The name of the cookie.
 *
 * @returns {string | null} The value of the cookies.
 */
function getCookieValue(cookieName: string | undefined) {
    if (!cookieName || cookieName?.trim() === '') {
        return null;
    }

    return (document.cookie || '').split('; ').reduce((value, cookie) => {
        // Extract name and all values (since value may contain equals signs).
        const [name, ...cookieValue] = cookie.split('=');

        // Return cookie value
        return name === cookieName ? cookieValue.join('=') : value;
    }, null);
}

export default class CookieService {
    /**
     * Returns the value of the cookie.
     *
     * @param cookie The cookie.
     *
     * @returns {string | null} The value of the cookie.
     */
    getCookie(cookie: Cookie): string | null {
        if (!validateCookie(cookie)) {
            return null;
        }

        let value = getCookieValue(cookie.name);

        // TODO: Remove this code with WEB-11431.
        if (!value) {
            value = getCookieValue(getNonPrefixedCookieName(cookie.name));
        }

        return value;
    }

    /**
     * Updates / Sets a cookie
     *
     * @param cookie The cookie.
     *
     * @returns {boolean} Was the cookie updated/set?
     */
    setCookie(cookie: Cookie): boolean {
        if (validateCookie(cookie)) {
            // TODO: Remove this code with WEB-11431.
            const trimmedCookie = getNonPrefixedCookieName(cookie.name);

            if (trimmedCookie) {
                setCookie.call(this, trimmedCookie, '', getCookieInvalidationDate());

                const consentCookieValue = this.getCookie(new EssentialCookie(DO_NOT_TRACK_COOKIE));
                if (cookie.isAllowed(consentCookieValue)) {
                    setCookie.call(
                        this,
                        cookie.name,
                        cookie.value,
                        cookie.expirationDate,
                        cookie.isSecure(),
                        cookie.sameSite,
                        cookie.domain
                    );

                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Invalidates a cookie.
     *
     * @param cookie The cookie.
     *
     * @returns {boolean} Was the cookie invalidated?
     */
    deleteCookie(cookie: Cookie): boolean {
        if (validateCookie(cookie)) {
            if (this.getCookie(cookie) != null) {
                setCookie.call(this, cookie.name, '', getCookieInvalidationDate());

                return true;
            }
        }

        return false;
    }

    /**
     * Checks the truthy/falsy state for a cookie.
     *
     * @param cookie The cookie.
     *
     * @returns {boolean} The truthy/falsy value of the cookie.
     */
    hasTruthyCookieValue(cookie: Cookie): boolean {
        const cookieValue = this.getCookie(cookie);

        return !(
            cookieValue === '0' ||
            cookieValue === 'false' ||
            cookieValue === null ||
            cookieValue === 'null' ||
            cookieValue === 'undefined'
        );
    }

    /**
     * Checks the domain of a cookie.
     *
     * @returns {string} The value of the domain.
     */
    getCookieDomain(): string {
        const domain = location.hostname;

        if (domain.includes('.alarm.com')) {
            return '.alarm.com';
        }

        if (domain.includes('.adcinternal.com')) {
            return '.adcinternal.com';
        }

        return domain;
    }
}
