/**
 * @module
 */
/**
 * @enum {number}
 */
export const Initialize = 1;
/**
 * @enum {number}
 */
export const SetTitle = 2;
/**
 * @enum {number}
 */
export const SetToolbarButtons = 3;
/**
 * @enum {number}
 */
export const ShowConfirmationDialog = 4;
/**
 * @enum {number}
 */
export const GoBack = 5;
/**
 * @enum {number}
 */
export const HandleApplicationError = 6;
/**
 * @enum {number}
 */
export const RefreshWebView = 7;
/**
 * @enum {number}
 */
export const ReloadAssociatedSystems = 8;
/**
 * @enum {number}
 */
export const LaunchShareActionDialog = 9;
/**
 * @enum {number}
 */
export const TransitionToNativeView = 10;
/**
 * @enum {number}
 *
 * @deprecated Use CloseWebViewV2 instead.
 */
export const CloseWebView = 11;
/**
 * @enum {number}
 */
export const LaunchImportContact = 12;
/**
 * @enum {number}
 */
export const LaunchBrowser = 13;
/**
 * @enum {number}
 */
export const CloseWebViewV2 = 14;
/**
 * @enum {number}
 */
export const TerminateAppSession = 15;
/**
 * @enum {number}
 */
export const LaunchNewContext = 16;
/**
 * @enum {number}
 */
export const PersistAppSession = 17;
/**
 * @enum {number}
 */
export const EnablePushNotifications = 18;
/**
 * @enum {number}
 */
export const EnableBluetooth = 19;
/**
 * @enum {number}
 */
export const CheckBluetoothPermissions = 20;
/**
 * @enum {number}
 */
export const StartBleAdvertising = 21;
/**
 * @enum {number}
 */
export const StopBleAdvertising = 22;
