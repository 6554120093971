import BinaryInput, { RADIO } from './binary-input.js';

/**
 * @classdesc
 * The RadioInput class can be used for radio groups. This is a group of options where only one selection can be made at a time.
 */
export default class RadioInput extends BinaryInput {
    get type() {
        return RADIO;
    }
}
