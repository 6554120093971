// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { guidFor } from '@ember/object/internals';
import { isDestroyed } from '@adc/ember-utils/utils/ember-helpers';
import { once } from '@ember/runloop';
import { logMissingAbstractMember } from '@adc/ember-utils/utils/debug';

/**
 * Recalculates buttons for the native toolbar.
 */
function recalculateNativeToolbar() {
    let backButtons = [],
        contextButtons = [],
        wasBackOrCancel = false;

    const { buttonsList } = this;

    buttonsList.forEach((button) => {
        const { type } = button;

        switch (type) {
            case this.nativeBridge.getButtonTypeBack():
            case this.nativeBridge.getButtonTypeCancel():
                // Check if back or cancel already existed.
                if (wasBackOrCancel) {
                    // Probably still let the new button be inserted but log an error.
                    console.error(
                        `Native toolbar back or cancel button already existed for modal, title=${
                            this.title
                        }, toolbar=${JSON.stringify(buttonsList)}`
                    );
                }

                backButtons.insertAt(0, button);

                wasBackOrCancel = true;
                break;
            case this.nativeBridge.getButtonTypeConfirm():
                contextButtons.insertAt(0, button);
                break;
            default:
                contextButtons.pushObject(button);
                break;
        }
    });

    // If there is no close button defined, then add one because a modal needs to have a close button.
    if (!wasBackOrCancel) {
        this.handleNoNativeBackOrCancelButton({
            contextButtons,
            backButtons
        });
    }

    this.nativeBridge.setToolbarButtons({
        id: this.nativeToolbarId,
        backButtons,
        contextButtons
    });
}

/**
 * @classdesc
 * Base class for components that handle a layer in a view. For example a layer on a route, or a modal.
 * Handles some base functionality for communicating with a Native App.
 */
// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/require-tagless-components
export default class BaseContentLayerComponent extends Component {
    @service nativeBridge;

    /**
     * List of buttons for a native toolbar.
     *
     * @type {Array<NativeButtonType>}
     */
    buttonsList = [];

    /**
     * Can the component utilize App native buttons?
     *
     * @type {boolean}
     */
    @alias('nativeBridge.doesSupportToolbarButtons')
    canHaveNativeButtons;

    /**
     * Id that will uniquely represent this component in the native toolbars stack.
     *
     * @type {String}
     */
    @computed('elementId')
    get nativeToolbarId() {
        return this.elementId || guidFor(this);
    }

    /**
     * Triggers the update of native toolbar.
     */
    triggerNativeToolbarUpdate() {
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line @adc/ember/no-send
        this.send('updateNativeToolbarButtons');
    }

    /**
     * Called when there is no back or cancel button already present in the buttonsList collection when toolbar buttons are constructed.
     *
     * @param {{contextButtons: Array<NativeButtonType>, backButtons: Array<NativeButtonType> }} data Buttons to be used as context buttons (on the right side).
     */
    handleNoNativeBackOrCancelButton(data) {
        logMissingAbstractMember(this, '"handleNoNativeBackOrCancelButton" method', data);
    }

    willDestroy(...args) {
        this.nativeBridge.removeToolbarButtons(this.nativeToolbarId);

        super.willDestroy(...args);
    }

    /**
     * Updates native toolbar buttons with buttons that were passed in.
     *
     * @param {Array<NativeButtonType>} buttons Buttons list to add to the toolbar.
     */
    @action updateNativeToolbarButtons(buttons = []) {
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line @adc/ember/no-is-destroyed
        if (isDestroyed(this) || !this.canHaveNativeButtons) {
            return;
        }

        const { buttonsList } = this;

        // Remove any instance of buttons with the same componentId.
        this.actions.disposeNativeButtons.call(
            this,
            buttons.map(({ componentId }) => componentId),
            true
        );

        buttonsList.pushObjects(buttons);

        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        once(this, recalculateNativeToolbar);
    }

    /**
     * Disposes buttons from toolbar with the defined componentIds.
     *
     * @param {Array<number>} componentIds List of component ids referring to buttons that should be removed.
     * @param {boolean} doNotRecalculateToolbar Should the toolbar not be recalculate right now?
     */
    @action disposeNativeButtons(componentIds, doNotRecalculateToolbar) {
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line @adc/ember/no-is-destroyed
        if (isDestroyed(this) || !this.canHaveNativeButtons) {
            return;
        }

        const { buttonsList } = this;

        componentIds.forEach((id) => buttonsList.removeObjects(buttonsList.filterBy('componentId', id)));

        if (!doNotRecalculateToolbar) {
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            once(this, recalculateNativeToolbar);
        }
    }
}
