import SameSite from './samesite-enum';

export default abstract class Cookie {
    name: string | undefined;

    value: string | undefined;

    expirationDate: Date | undefined;

    domain: string | undefined;

    sameSite: SameSite | undefined;

    /**
     * Is the cookie secure?
     */
    isSecure(): boolean {
        return this.sameSite === SameSite.None;
    }

    /**
     * Is user accepting cookies?
     */
    abstract isAllowed(consentCookieValue: string | null): boolean;

    protected constructor(
        namePrefix: string,
        name: string,
        value?: string,
        expirationDate?: Date,
        sameSite?: SameSite,
        domain?: string
    ) {
        if (name && name.trim() !== '') {
            this.name = `${namePrefix}_${name.trim()}`;

            this.value = value;
            this.expirationDate = expirationDate;
            this.sameSite = sameSite ? sameSite : SameSite.Strict;
            this.domain = domain;
        }
    }
}
