/**
 * Helper function that returns a Promise that passes down argument to an async method.
 */
export default function wrapWithPromise<T>(
    fn: (...args: any[]) => Promise<T>,
    ...args: any[]
): Promise<T> {
    return new Promise((resolve) => {
        fn(...args).then((result) => resolve(result));
    });
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Helper function that returns a Promise that passes down argument to an async method.
         */
        'wrap-with-promise': typeof wrapWithPromise;
    }
}
