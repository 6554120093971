import Component from '@glimmer/component';
import { action } from '@ember/object';

import type { ModalModel } from '../../../../services/modals.ts';
import type { SimpleDialogSignature } from '../simple/simple-dialog';
import type { SafeString } from 'handlebars';

export interface InfoDialogModel
    extends ModalModel,
        Pick<SimpleDialogSignature['Args'], 'title' | 'buttons' | 'disableHeaderClose' | 'disableFooterClose'> {
    /** The text to display in the info dialog. */
    info: string | SafeString;
    /** Optional SVG to display in the info dialog. */
    svg?: string;
    /** Optional CSS class applied to info dialog element. */
    css?: string;
    /** Optional bool to allow the modal to resize on mobile screens. */
    resizableOnMobile?: boolean;
}

export interface InfoDialogSignature {
    Element: HTMLDivElement;
    Args: {
        model: InfoDialogModel;
    };
}

/**
 * @classdesc
 * A simple info dialog for displaying a string (or HTML) with custom buttons and title.
 */
export default class InfoDialog extends Component<InfoDialogSignature> {
    @action updateScrollIndicator(el: HTMLDivElement): void {
        const dialog = el.closest('.simple-dialog-modal');
        if (dialog) {
            dialog.classList.toggle('has-scroll-indicator', el.scrollHeight > el.offsetHeight);
        }
    }

    @action handleScroll(evt: MouseEvent & { target: HTMLDivElement }): void {
        const el = evt.target;
        el.classList.toggle('scrolled', !!el.scrollTop);
    }
}
