/**
 * Utility helper module for browser related code.
 * @module
 */

import { Promise as EmberPromise } from 'rsvp';

// region Cookies

/**
 * Expire cookie based on the cookie name.
 *
 * @param name The name of the cookie that will be changed.
 */
export function expireCookie(name: string): void {
    const date = new Date();
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

    document.cookie = `${name}=''; expires=${date.toUTCString()}; path=/`;
}

/**
 * Returns a single cookie value.
 *
 * @param cookieName The name of the cookie value to retrieve.
 */
export function getCookie(cookieName: string): string {
    return (document.cookie || '').split('; ').reduce((value, cookie) => {
        // Extract name and all values (since value may contain equals signs).
        const [name, ...cookieValue] = cookie.split('=');

        // Return cookie values if this is the anti-forgery token.
        return name === cookieName ? cookieValue.join('=') : value;
    }, '');
}

// endregion

// region DOM Helpers

/**
 * Add or remove specified class from the body element.
 *
 * @param className
 * @param add - When true, the class will be added, when false the class will be removed.
 */
export function toggleClassOnBody(className: string, add: boolean): void {
    toggleClassOnElement(document.body, className, add);
}

/**
 * Add or remove specified class from a specific element.
 *
 * @param element
 * @param className
 * @param add - When true, the class will be added, when false the class will be removed.
 */
export function toggleClassOnElement(element: HTMLElement, className: string, add: boolean): void {
    // Cannot use toggle with a forced value, because IE does not support forced value.
    element.classList[add ? 'add' : 'remove'](className);
}

// endregion

interface ScrollIntoViewIfNeededOptions {
    centerIfNeeded?: boolean;
    padding?: number;
    animate?: boolean;
}

type Area = {
    left: number;
    top: number;
    width: number;
    height: number;
    right: number;
    bottom: number;
    translate(x: number, y: number): Area;
    relativeFromTo(lhs: HTMLElement, rhs: HTMLElement): Area;
};

/**
 * Scrolls an element into view if it is not in the current viewport.
 *
 * @note Taken from https://github.com/stipsan/scroll-into-view-if-needed . I did not want to just import it because I wanted to modify it a little.
 *
 * @param elem
 * @param [options={}]
 * @param finalElement - Used for stopping recursion.
 */
export function scrollIntoViewIfNeeded(
    elem: HTMLElement & {
        scrollIntoViewIfNeeded?: (centerIfNeeded?: boolean) => void;
    },
    options: ScrollIntoViewIfNeededOptions = {},
    finalElement?: HTMLElement
): void {
    // Webkit has this implemented natively, so use that function it if it available.
    if (elem.scrollIntoViewIfNeeded) {
        elem.scrollIntoViewIfNeeded(options.centerIfNeeded);

        return;
    }

    const { centerIfNeeded, padding = 0, animate } = options;

    if (!elem) {
        throw new Error('Element is required in scrollIntoViewIfNeeded');
    }

    function withinBounds(value: number, min: number, max: number, extent: number): number {
        if (false === centerIfNeeded || (max <= value + extent && value <= min + extent)) {
            return Math.min(max, Math.max(min, value));
        }

        return (min + max) / 2;
    }

    function makeArea(left: number, top: number, width: number, height: number): Area {
        return {
            left,
            top,
            width,
            height,
            right: left + width,
            bottom: top + height,
            translate(x: number, y: number): Area {
                return makeArea(x + left, y + top, width, height);
            },
            relativeFromTo(lhs: HTMLElement, rhs: HTMLElement): Area {
                let newLeft = left,
                    newTop = top;

                let tempLHS = lhs.offsetParent as HTMLElement,
                    tempRHS = rhs.offsetParent as HTMLElement;

                if (tempLHS === tempRHS) {
                    return area;
                }

                for (; tempLHS; tempLHS = tempLHS.offsetParent as HTMLElement) {
                    newLeft += tempLHS.offsetLeft + tempLHS.clientLeft;
                    newTop += tempLHS.offsetTop + tempLHS.clientTop;
                }

                for (; tempRHS; tempRHS = tempRHS.offsetParent as HTMLElement) {
                    newLeft -= tempRHS.offsetLeft + tempRHS.clientLeft;
                    newTop -= tempRHS.offsetTop + tempRHS.clientTop;
                }

                return makeArea(newLeft, newTop, width, height);
            }
        };
    }

    const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = elem;

    let parent,
        area = makeArea(
            offsetLeft - padding,
            offsetTop - padding,
            offsetWidth + padding * 2,
            offsetHeight + padding * 2
        );

    while ((parent = elem.parentNode) instanceof HTMLElement && elem !== finalElement) {
        const clientLeft = parent.offsetLeft + parent.clientLeft;
        const clientTop = parent.offsetTop + parent.clientTop;

        // Make area relative to parent's client area.
        area = area.relativeFromTo(elem, parent).translate(-clientLeft, -clientTop);

        const scrollLeft = withinBounds(
            parent.scrollLeft,
            area.right - parent.clientWidth,
            area.left,
            parent.clientWidth
        );

        const scrollTop = withinBounds(
            parent.scrollTop,
            area.bottom - parent.clientHeight,
            area.top,
            parent.clientHeight
        );

        if (animate) {
            // TODO: Figure out how to animate things.
            // animate(parent, {
            //     scrollLeft: scrollLeft,
            //     scrollTop: scrollTop
            // }, options)
        } else {
            parent.scrollLeft = scrollLeft;
            parent.scrollTop = scrollTop;
        }

        // Determine actual scroll amount by reading back scroll properties.
        area = area.translate(clientLeft - parent.scrollLeft, clientTop - parent.scrollTop);
        elem = parent;
    }
}

/**
 * Loads script that gets immediately executed. Resolves once script has been loaded.
 */
export function loadScript(scriptUrl: string): Promise<void> {
    return new EmberPromise((resolve, reject) => {
        const scriptEl = document.createElement('script');

        scriptEl.src = scriptUrl;

        scriptEl.onload = () => {
            // Remove script element from the body.
            scriptEl.parentNode?.removeChild(scriptEl);

            // Resolve promise.
            resolve();
        };

        scriptEl.onerror = reject;

        // Finally add the script to the body so that it can get loaded up.
        document.body.appendChild(scriptEl);
    });
}

/**
 * Determines if the browser is running on a mobile device.
 */
export function isMobileBrowser(): boolean {
    const userAgent = navigator.userAgent;

    /*
    iPad outputs same platform type as a macbook since iOS 13.
    Thus, we now have a secondary check where we confirm that it is MacIntel with multiple touch point since
    iPad is touchscreen whereas a macbook is not.
    https://stackoverflow.com/questions/58019463/how-to-detect-device-name-in-safari-on-ios-13-while-it-doesnt-show-the-correct/58064481#58064481
    */
    return (
        ((/ipad|iphone|ipod/i.test(userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
            (!('MSStream' in window) || !window.MSStream)) ||
        /android|webos|blackberry|iemobile|mpera mini/i.test(userAgent)
    );
}
