import templateOnlyComponent from '@ember/component/template-only';

interface BaseInsetGroupArgs {
    /** The title for the inset group. If excluded, the inset group will be title-less. */
    title?: string;
}

interface ClickableIconInsetGroupArgs extends BaseInsetGroupArgs {
    /** Triggered when the user clicks the icon button. */
    onIconClick: VoidFunction;
    /** The icon button to show. */
    icon: string;
    /** The text describing the button. */
    iconText: string;
}

interface NonClickableIconInsertGroupArgs extends BaseInsetGroupArgs {
    /** The icon button to show. */
    icon?: string;
    /** The text describing the button. */
    iconText?: string;
    /** Triggered when the user clicks the icon button. */
    onIconClick?: undefined;
}

export interface InsetGroupSignature {
    Element: HTMLDivElement;
    Args: ClickableIconInsetGroupArgs | NonClickableIconInsertGroupArgs;
    Blocks: {
        default: [];
        'title-contents': [];
    };
}

const InsetGroup = templateOnlyComponent<InsetGroupSignature>();
export default InsetGroup;
