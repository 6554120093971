import { addWeakListener, removeListener } from './event-listeners.ts';
import { isDestroyed } from './ember-helpers.ts';
import { isFunction } from './general.ts';
import { schedule } from '@ember/runloop';

/**
 * Utility module for accessibility code.
 *
 * @module
 */

export interface FocusSettings {
    scopeSelector: string;
    escapeCallback: () => void;
    treatArrowsAsTabEvents: boolean;
    oldListenerId: string;
}

// region Key Codes

/**
 * The key code for the shift key.
 */
export const SHIFT_KEY = 16;

/**
 * The code for the left shift key.
 */
export const SHIFT_LEFT_CODE = 'ShiftLeft';

/**
 * The code for the right shift key.
 */
export const SHIFT_RIGHT_CODE = 'ShiftRight';

/**
 * The key code for the control key.
 */
export const CONTROL_KEY = 17;

/**
 * The code for the left control key.
 */
export const CONTROL_LEFT_CODE = 'ControlLeft';

/**
 * The code for the right control key.
 */
export const CONTROL_RIGHT_CODE = 'ControlRight';

/**
 * The key code for the space key.
 */
export const SPACE_KEY = 32;

/**
 * The code for the space key.
 */
export const SPACE_CODE = 'Space';

/**
 * The key code for the enter key.
 */
export const ENTER_KEY = 13;

/**
 * The code for the enter key.
 */
export const ENTER_CODE = 'Enter';

/**
 * The code for the numpad enter key.
 */
export const NUMPAD_ENTER_CODE = 'NumpadEnter';

/**
 * The key code for the escape key.
 */
export const ESCAPE_KEY = 27;

/**
 * The code for the escape key.
 */
export const ESCAPE_CODE = 'Escape';

/**
 * The key code for the tab key.
 */
export const TAB_KEY = 9;

/**
 * The code for the tab key.
 */
export const TAB_CODE = 'Tab';

/**
 * The key code for the down-arrow key.
 */
export const DOWN_ARROW_KEY = 40;

/**
 * The code for the down-arrow key.
 */
export const ARROW_DOWN_CODE = 'ArrowDown';

/**
 * The key code for the up-arrow key.
 */
export const UP_ARROW_KEY = 38;

/**
 * The code for the up-arrow key.
 */
export const ARROW_UP_CODE = 'ArrowUp';

/**
 * The key code for the left-arrow key.
 */
export const LEFT_ARROW_KEY = 37;

/**
 * The code for the left-arrow key.
 */
export const ARROW_LEFT_CODE = 'ArrowLeft';

/**
 * The key code for the right-arrow key.
 */
export const RIGHT_ARROW_KEY = 39;

/**
 * The code for the right-arrow key.
 */
export const ARROW_RIGHT_CODE = 'ArrowRight';

/**
 * The key code for the Home key.
 */
export const HOME_KEY = 36;

/**
 * The code for the Home key.
 */
export const HOME_CODE = 'Home';

/**
 * The key code for the End key.
 */
export const END_KEY = 35;

/**
 * The code for the End key.
 */
export const END_CODE = 'End';

/**
 * The key code for the Backspace key.
 */
export const BACKSPACE_KEY = 8;

/**
 * The code for the Backspace key.
 */
export const BACKSPACE_CODE = 'Backspace';

/**
 * The key code for the Equals key.
 */
export const EQUALS_KEY = 187;

/**
 * The code for the Equals key.
 */
export const EQUAL_CODE = 'Equal';

/**
 * The key code for the left-bracket key.
 */
export const LEFT_BRACKET_KEY = 219;

/**
 * The code for the left-bracket key.
 */
export const BRACKET_LEFT_CODE = 'BracketLeft';

/**
 * The key code for the right-bracket key.
 */
export const RIGHT_BRACKET_KEY = 221;

/**
 * The code for the right-bracket key.
 */
export const BRACKET_RIGHT_CODE = 'BracketRight';

/**
 * The key code for the b key.
 */
export const B_KEY = 66;

/**
 * The code for the b key.
 */
export const B_CODE = 'KeyB';

/**
 * The key code for the c key.
 */
export const C_KEY = 67;

/**
 * The code for the c key.
 */
export const C_CODE = 'KeyC';

/**
 * The key code for the d key.
 */
export const D_KEY = 68;

/**
 * The code for the d key.
 */
export const D_CODE = 'KeyD';

/**
 * The key code for the e key.
 */
export const E_KEY = 69;

/**
 * The code for the e key.
 */
export const E_CODE = 'KeyE';

/**
 * The key code for the f key.
 */
export const F_KEY = 70;

/**
 * The code for the f key.
 */
export const F_CODE = 'KeyF';

/**
 * The key code for the n key.
 */
export const N_KEY = 78;

/**
 * The code for the n key.
 */
export const N_CODE = 'KeyN';

/**
 * The key code for the s key.
 */
export const S_KEY = 83;

/**
 * The code for the s key.
 */
export const S_CODE = 'KeyS';

/**
 * The key code for the t key.
 */
export const T_KEY = 84;

/**
 * The code for the t key.
 */
export const T_CODE = 'KeyT';

// endregion

// region Utility Functions

/**
 * Checks if the given key code is for Enter or Space
 *
 * @param code - the code of the key
 */
export function isEnterOrSpaceCode(code: string): boolean {
    return code === ENTER_CODE || code === SPACE_CODE || code === NUMPAD_ENTER_CODE;
}

/**
 * Checks if the given key code is for arrow right.
 *
 * @param code - the code of the key
 */
export function isArrowRightCode(code: string): boolean {
    return code === ARROW_RIGHT_CODE;
}

/**
 * Checks if the given key code is for arrow left.
 *
 * @param code - the code of the key
 */
export function isArrowLeftCode(code: string): boolean {
    return code === ARROW_LEFT_CODE;
}

/**
 * Checks if the given key code is for arrow down.
 *
 * @param code - the code of the key
 */
export function isArrowDownCode(code: string): boolean {
    return code === ARROW_DOWN_CODE;
}

/**
 * Checks if the given key code is for arrow up.
 *
 * @param code - the code of the key
 */
export function isArrowUpCode(code: string): boolean {
    return code === ARROW_UP_CODE;
}

/**
 * Checks if the given key code is for arrows.
 *
 * @param code - the code of the key
 */
export function isArrowCode(code: string): boolean {
    return isArrowDownCode(code) || isArrowUpCode(code) || isArrowLeftCode(code) || isArrowRightCode(code);
}

/**
 * Checks if the given key code is for tab.
 *
 * @param code - the code of the key
 */
export function isTabCode(code: string): boolean {
    return code === TAB_CODE;
}

/**
 * Checks if the given key code is for enter.
 *
 * @param code - the code of the key
 */
export function isEnterCode(code: string): boolean {
    return code === ENTER_CODE || code === NUMPAD_ENTER_CODE;
}

/**
 * Checks if the given key code is for space.
 *
 * @param code - the code of the key
 */
export function isSpaceCode(code: string): boolean {
    return code === SPACE_CODE;
}

/**
 * Checks if the given key code is for escape.
 *
 * @param code - the code of the key
 */
export function isEscapeCode(code: string): boolean {
    return code === ESCAPE_CODE;
}

/**
 * Checks if the given key code is for home.
 *
 * @param code - the code of the key
 */
export function isHomeCode(code: string): boolean {
    return code === HOME_CODE;
}

/**
 * Checks if the given key code is for backspace.
 *
 * @param code - the code of the key
 */
export function isBackspaceCode(code: string): boolean {
    return code === BACKSPACE_CODE;
}

/**
 * Returns if the node handles some keyboard input by default.
 */
export function nodeHandlesKeyboardInput(node: Node): boolean {
    return ['INPUT', 'TEXTAREA', 'SELECT'].includes(node.nodeName);
}

/**
 * Returns if the node handles some space or enter input by default.
 */
export function nodeHandlesSpaceOrEnterInput(node: Node): boolean {
    return ['A', 'BUTTON', 'INPUT', 'TEXTAREA', 'VIDEO', 'SELECT'].includes(node.nodeName);
}

// endregion

// region Trap Focus Listener

/**
 * Creates a key listener to only tab between elements that live within the given DOM scope, which will be destroyed when the passed context is destroyed.
 *
 * @param scopeSelector - The CSS selector that scopes which elements can be focused.
 * @param escapeCallback - A callback method to call if the escape key is pressed.
 * @param treatArrowsAsTabEvents - Should the up/down arrow keys be treated as tab/shift+tab events?
 * @param oldListenerId - An optional listener ID to remove if we are creating a new one.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function addWeakTrapFocusListener(
    destroyable: any,
    { scopeSelector, escapeCallback, treatArrowsAsTabEvents, oldListenerId }: FocusSettings
): string | undefined {
    if (isDestroyed(destroyable)) {
        return undefined;
    }

    const element = document.querySelector(scopeSelector);

    if (oldListenerId) {
        removeListener(oldListenerId);
    }

    return addWeakListener(destroyable, element ?? window, 'keydown', (event: KeyboardEvent) =>
        trapFocus(event, scopeSelector, escapeCallback, treatArrowsAsTabEvents)
    );
}

/**
 * First tab is set to TRUE until the second TAB key is pressed
 */
let isFirstTabPressInTrapFocusListener = true;

/**
 * Removes the listener with the given ID.
 */
export function removeTrapFocusListener(listenerId: string): void {
    removeListener(listenerId);
    isFirstTabPressInTrapFocusListener = true;
}

/**
 * Ensures only the elements within the scopeSelector can be tabbed between.
 *
 * @param event - The keyboard event to evaluate.
 * @param scopeSelector - The CSS selector that scopes which elements can be accessed by the tab key.
 * @param escapeCallback - A callback method to call if the escape key is pressed.
 * @param treatArrowsAsTabEvents - Should the up/down arrow keys be treated as tab/shift+tab events?
 */
function trapFocus(
    event: KeyboardEvent,
    scopeSelector: string,
    escapeCallback: () => any,
    treatArrowsAsTabEvents: boolean
) {
    // window.event is available on Firefox from version 63, in previous versions it is not supported.
    // In other browsers and higher versions on Firefox the default window.event is taken and that's why for the Firefox versions where is not supported we need to pass the event.

    if (event.code === TAB_CODE) {
        changeElementInFocus(scopeSelector, event.shiftKey, event);
        return;
    }

    if (treatArrowsAsTabEvents && [ARROW_UP_CODE, ARROW_DOWN_CODE].includes(event.code)) {
        changeElementInFocus(scopeSelector, event.code === ARROW_UP_CODE, event);
        return;
    }

    if (event.code === ESCAPE_CODE && isFunction(escapeCallback)) {
        escapeCallback();
    }
}

/**
 * Check if element is visible or not (hidden from css)
 *
 * @public
 *
 * @param el - element that needs to be checked
 */
export function isVisible(el: Element): boolean {
    const { width, height } = el.getBoundingClientRect();

    return width !== 0 && height !== 0;
}

/**
 * Get all focusable elements from a specific container
 */
export function getFocusableElements(baseElement: Element): Element[] {
    return Array.from(baseElement.querySelectorAll('a, button, object, input, iframe, select, [tabindex]')).filter(
        (item) => item.getAttribute('tabindex') !== '-1' && isVisible(item)
    );
}

/**
 * Cycles through the tabbed elements and selects the next/previous one based on the keyboard event.
 *
 * @param {Object} focusSettings - The settings used to determine which element to focus.
 * @param {String} focusSettings.scopeSelector - The CSS selector that scopes which elements can be focused.
 * @param {String} focusSettings.shouldFocusPreviousElement - Determines the direction for the newly focused item (next or previous).
 *
 * @public
 */
function changeElementInFocus(scopeSelector: string, shouldFocusPreviousElement: boolean, event: KeyboardEvent) {
    const { code } = event,
        baseElement = window.document.body.querySelector(scopeSelector),
        // Some of the elements might have tabindex="-1" and we need to remove these from the list
        focusElements = getFocusableElements(baseElement as Element),
        currentIndex = Array.prototype.indexOf.call(focusElements, document.activeElement),
        elementCount = focusElements.length,
        lastElementIndex = elementCount - 1,
        listContainer = baseElement?.querySelector('ul, ol, [role="list"]');

    let listItems;

    if (listContainer) {
        listItems = getFocusableElements(listContainer);
    }

    if (elementCount) {
        let newIndex = currentIndex + (shouldFocusPreviousElement ? -1 : 1);

        // Ensure the edge cases wrap back around to focus the correct element.
        if (shouldFocusPreviousElement) {
            if (currentIndex === 0) {
                newIndex = lastElementIndex;
            }
        } else if (currentIndex === lastElementIndex) {
            newIndex = 0;
        }

        // If TAB is pressed for the first time we need to focus the element with aria-selected="true".
        if (isFirstTabPressInTrapFocusListener) {
            for (let i = 0; i < elementCount; i++) {
                if (focusElements[i].getAttribute('aria-selected') === 'true') {
                    newIndex = i;
                    break;
                }
            }

            if (focusElements[newIndex]) {
                if (
                    focusElements[newIndex].getAttribute('aria-selected') === 'true' &&
                    focusElements[newIndex] === document.activeElement
                ) {
                    const step = newIndex + (shouldFocusPreviousElement ? -1 : 1);

                    newIndex = newIndex === lastElementIndex ? 0 : step;
                }
            }
        }

        isFirstTabPressInTrapFocusListener = false;

        if (listItems && isArrowCode(code)) {
            const listItemsLastIndex = listItems.length - 1;

            if (isArrowDownCode(code) && listItems.indexOf(focusElements[currentIndex]) === listItemsLastIndex) {
                newIndex = focusElements.indexOf(listItems[0]);
            }

            if (isArrowUpCode(code)) {
                newIndex = currentIndex - 1;

                if (listItems.indexOf(focusElements[currentIndex]) === 0) {
                    newIndex = focusElements.indexOf(listItems[listItemsLastIndex]);
                }
            }
        }

        if (focusElements[newIndex]) {
            (focusElements[newIndex] as HTMLInputElement).focus();
        }

        event.preventDefault();
    }
}

/**
 * Used for activating a focus trap -- typically used when tab indices are set to -1
 * initially, and the focus trap must be activated for elements to be tabbable.
 *
 * @param targetClass - contains the class for whichever section is controlled by the focus trap.
 * @param updateTrapFocusHandler - should update the trap focus property on the caller and schedule focus on first focusable element in target if needed.
 * @param getElementToFocus - gets the HTML Element to focus on after activating focus trap.
 */
export function activateFocusTrap(
    event: KeyboardEvent,
    target: HTMLElement,
    targetClass: string,
    updateTrapFocusHandler: (state: boolean) => void,
    getElementToFocus: () => HTMLElement | null
): void {
    if (target.classList.contains(targetClass)) {
        event.preventDefault();
        event.stopPropagation();

        const { code } = event;

        if (isEnterOrSpaceCode(code)) {
            updateTrapFocusHandler(true);

            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            schedule('afterRender', () => {
                const focusTarget = getElementToFocus();
                if (focusTarget) {
                    const [firstElement] = getFocusableElements(focusTarget);
                    (firstElement as HTMLElement)?.focus();
                }
            });
        } else if (isEscapeCode(code)) {
            updateTrapFocusHandler(false);
        }
    }
}

// endregion
