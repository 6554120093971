import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { PasswordInputSignature } from './password-input';

type PasswordInputSignatureArgs = PasswordInputSignature['Args'];

type PasswordStrengthData = {
    strength: number;
    suggestions: string[];
};

enum PasswordStrengthPrefixes {
    weak = 1,
    fair,
    good,
    strong
}

export interface ChangePasswordSignature {
    Element: HTMLElement;
    Args: Pick<PasswordInputSignatureArgs, 'showErrorIcon'> & {
        /** Text to update the new password field. */
        newPassword?: string;
        /** The error tooltip text for the new password. */
        newPasswordError?: PasswordInputSignatureArgs['errorMessage'];
        /** Text to update the current password field. */
        currentPassword?: string;
        /** The error tooltip text for the current password. */
        currentPasswordError?: PasswordInputSignatureArgs['errorMessage'];
        /** The error tooltip text for the confirmation password. If not supplied, the default passwordConfirmationError message will be used. */
        confirmationPasswordError?: PasswordInputSignatureArgs['errorMessage'];
        /** Indicates the current password needs to be entered before changing it will be allowed. */
        isCurrentPasswordRequired?: boolean;
        /** The password confirmation text. */
        passwordConfirmation?: string;
        /** The required length of any new password. */
        requiredLength?: number;
        /** Number of numeric characters required for password. */
        numbersRequired: number;
        /** Number of special characters required for password. */
        symbolsRequired: number;
        /** Number of standard characters required for password. */
        lettersRequired: number;
        /** Data about password strength and suggestions. */
        passwordStrengthData?: Promise<PasswordStrengthData> | PasswordStrengthData;
        /** An array of strings that contain the default password tips that should be shown if no suggestions pertaining to the new password are available. */
        mostCommonPasswordTips?: string[];
        /** Triggered when user enters new password. */
        onChangeNewPassword: (val: string) => void;
        /** Triggered when user enters new password confirmation. */
        onChangePasswordConfirmation: (val: string) => void;
        /** An action defined by the consumer to retrieve the password strength for the server to be sent to the change password component. */
        getPasswordStrengthAction?: (val: string) => void;
        /** Triggered when users enters current password. */
        onCurrentPasswordChange: PasswordInputSignatureArgs['value-change'];
    };
}

/**
 * @classdesc
 * Component can be used for changing passwords.
 */
export default class ChangePassword extends Component<ChangePasswordSignature> {
    @service declare intl: ADCIntlService;

    /**
     * The value for the current password needed for change authorization.
     */
    @tracked _pwd = '';
    get currentPassword(): string {
        return this._pwd;
    }
    set currentPassword(v: string) {
        this._pwd = v;
    }

    /**
     * The value for the new password being saved.
     */
    @tracked _newPwd = '';
    get newPassword(): string {
        return this._newPwd;
    }
    set newPassword(v: string) {
        this._newPwd = v;
    }

    /**
     * The value for the confirming the new password being saved.
     */
    @tracked _pwdConf = '';
    get passwordConfirmation(): string {
        return this._pwdConf;
    }
    set passwordConfirmation(v: string) {
        this._pwdConf = v;
    }

    /**
     * Is used to keep track of the open state of the password suggestions
     * dropdown for the aria-expanded attribute.
     */
    @tracked
    isSuggestionsDropdownExpanded = false;

    /**
     * The number of characters total required for the password.
     */
    @computed('args.requiredLength')
    get requiredLength(): number {
        return this.args.requiredLength ?? 7;
    }

    /**
     * Error message shown over the password confirmation if it doesn't match the new password value.
     */
    get passwordConfirmationError(): string {
        const { newPassword, passwordConfirmation } = this,
            { confirmationPasswordError } = this.args;

        return (
            confirmationPasswordError ??
            (newPassword && passwordConfirmation && newPassword !== passwordConfirmation
                ? this.intl.t('@adc/ui-components.passwordConfirmationError')
                : this.internalPasswordError)
        );
    }

    /**
     * Set the internal password confirmation error tooltip
     */
    set passwordConfirmationError(error: string) {
        this.internalPasswordError = error;
    }

    /**
     * Internal password confirmation error tooltip
     */
    private internalPasswordError = '';

    /**
     * Returns a string indicating the password strength key.
     */
    @computed('passwordStrengthInt')
    get passwordStrengthKey(): Promise<string> {
        return (async () => {
            const passwordStrengthInt = await this.passwordStrengthInt;
            return passwordStrengthInt ? PasswordStrengthPrefixes[passwordStrengthInt] : 'empty';
        })();
    }

    /**
     * Returns the integer corresponding to how strong the
     * potential password is.
     */
    @computed('args.passwordStrengthData')
    get passwordStrengthInt(): Promise<number | undefined> {
        return (async () => {
            const passwordStrength = (await (this.args.passwordStrengthData ?? ({} as PasswordStrengthData))).strength;
            return isNaN(passwordStrength) ? undefined : passwordStrength;
        })();
    }

    /**
     * The key used to get the correct password strength for concat.
     */
    @computed('passwordStrengthKey', 'args.passwordStrengthData')
    get passwordStrengthTranslated(): Promise<string> {
        return (async () => {
            const key = await this.passwordStrengthKey;
            return key !== 'empty' ? this.intl.t(`@adc/ui-components.${key}PasswordStrengthText`) : '';
        })();
    }

    /**
     * Returns the visual password strength boxes class names to
     * use for each box depending on the strength level of the current
     * password attempting to be updated.
     */
    @computed('passwordStrengthInt')
    get strengthBarBoxes(): Promise<
        Array<{
            class: string;
        }>
    > {
        return (async () => {
            const strengthBoxes = [];
            const passwordStrengthInt = await this.passwordStrengthInt;

            for (let i = 0; i < 4; i++) {
                strengthBoxes.push({
                    class:
                        // Maps the password strength integer to the enum to figure out
                        // which css class should be used to give the strength box's their
                        // color (weak, fair, good or strong).
                        i < (passwordStrengthInt ?? 0)
                            ? `strength-bar-cell ${
                                  passwordStrengthInt ? PasswordStrengthPrefixes[passwordStrengthInt] : ''
                              }`
                            : 'strength-bar-cell'
                });
            }

            return strengthBoxes;
        })();
    }

    /**
     * Returns a list of current password requirements and whether the have been met or not.
     */
    get requirements(): {
        key: string;
        isMet: boolean;
        minimumCount: number;
    }[] {
        const password = this.newPassword || '';
        const numberMatches = password.match(/\d/g);
        const symbolMatches = password.match(/[-!@#$%^&*()_+|~=`{}[\]:";'<>?,.\\/]/g);
        const letterMatches = password.match(/[a-zA-Z]/g);

        return [
            ['numbersRequired', (numberMatches ?? []).length, this.args.numbersRequired],
            ['symbolsRequired', (symbolMatches ?? []).length, this.args.symbolsRequired],
            ['lettersRequired', (letterMatches ?? []).length, this.args.lettersRequired],
            ['requiredLength', password.length, this.requiredLength]
        ]
            .map(([key, lengthCriteria, minimumCount]) => ({
                key: String(key),
                isMet: lengthCriteria >= minimumCount,
                minimumCount: Number(minimumCount)
            }))
            .filter((requirement) => requirement.minimumCount > 0);
    }

    /**
     * Uses the getPasswordStrengthAction passed by the consumer of the component to determine the strength of the new password value.
     */
    @action updateNewPassword(passwordValue: string): void {
        this.newPassword = passwordValue;
        this.args.onChangeNewPassword(passwordValue);

        if (this.args.getPasswordStrengthAction) {
            this.args.getPasswordStrengthAction(passwordValue);
        }
    }

    /**
     * Updates the local and consumer's passwordConfirmation
     */
    @action updateConfirmPassword(passwordValue: string): void {
        this.passwordConfirmation = passwordValue;
        this.args.onChangePasswordConfirmation(passwordValue);
    }

    /**
     * Update a given password field value
     */
    @action updateField(fieldKey: 'newPassword' | 'currentPassword' | 'passwordConfirmation'): void {
        this[fieldKey] = this.args[fieldKey] ?? '';
    }
}
