import Component from '@glimmer/component';
import { once } from '@ember/runloop';
import { action } from '@ember/object';
import { isPromise } from '@adc/ember-utils/utils/rsvp';
import DropdownActionItem from '../utils/dropdown-action-item.js';
import { tracked } from '@glimmer/tracking';
import { or } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { guidFor } from '@ember/object/internals';
import { modifier } from 'ember-modifier';
import { addWeakListener, removeListener } from '@adc/ember-utils/utils/event-listeners';

import type { ComponentLike, WithBoundArgs } from '@glint/template';
import type { DropdownActionsResponsiveHeaderSignature } from './dropdown-actions/responsive-header';
import type { BaseDropDownWrapperSignature } from './base-dropdown/wrapper';
import type { Task } from 'ember-concurrency';

type BaseDropDownWrapperSignatureArgs = BaseDropDownWrapperSignature['Args'];

/**
 * Turns the items array/promise into component internal items. If an items promise is received it activated the dropdown loading state until the items are resolved and saved internally.
 */
function initializeInternalItems(this: DropdownActions): void {
    const { items = [] } = this.args;

    if (items) {
        // Is items not a promise?
        if (!isPromise(items)) {
            // No promise, so update immediately.
            return updateInternalItems.call(this, items);
        }

        this._isLoading = true;
        (items as Promise<DropdownActionItem[]>).then(updateInternalItems.bind(this));
    }
}

/**
 * Handles the internal items conversion and loading of the provided items. Makes sure items are instances of DropdownActionItem.
 */
function updateInternalItems(this: DropdownActions, items: DropdownActionItem[]): void {
    // Make sure all items are Ember objects instances of DropdownActionItem
    const itemsInternal = items.map((item) => {
        if (item instanceof DropdownActionItem) {
            return item;
        }

        return DropdownActionItem.create(item);
    });

    this.itemsInternal = itemsInternal;
    this._isLoading = false;
}

export interface DropdownActionsSignature {
    Element: HTMLDivElement;
    Args: Pick<BaseDropDownWrapperSignatureArgs, 'matchTriggerElementWidth' | 'on-dropdown-close'> &
        Pick<DropdownActionsResponsiveHeaderSignature['Args'], 'title'> & {
            /** The action items to render. */
            items?: DropdownActionItem[] | Promise<DropdownActionItem[]>;
            /** Called when the trigger is clicked, explicitly return false if the menu should not open. */
            validateOpen?: () => Promise<boolean> | boolean;
            /** Indicates we should show a spinner instead of the trigger. */
            replaceTriggerWithLoadingSpinner?: boolean;
            /** Indicates we should not close the popup when an item is selected. */
            keepOpenAfterItemTriggered?: boolean;
            /** Optional CSS class added to the popover element. */
            dropdownActionsClass?: BaseDropDownWrapperSignatureArgs['popoverClass'];
            /** The popper placement value. */
            placement?: BaseDropDownWrapperSignatureArgs['popoverPlacement'];
            /** The popover minimum width, in pixels. */
            minWidth?: BaseDropDownWrapperSignatureArgs['popoverMinWidth'];
            /** The popover max width, in pixels. */
            maxWidth?: BaseDropDownWrapperSignatureArgs['popoverMaxWidth'];
        };
    Blocks: {
        default: [DropdownActions['trigger']];
        'responsive-header'?: [
            WithBoundArgs<ComponentLike<DropdownActionsResponsiveHeaderSignature>, 'dropdownWrapper' | 'on-click'>
        ];
    };
}

/**
 * @classdesc
 * Displays dropdown list of "action items" that can be clicked to trigger specified actions.
 */
export default class DropdownActions extends Component<DropdownActionsSignature> {
    listenerId?: string;

    /**
     * Indicates whether or not the dropdown is open.
     */
    @tracked declare _isOpen: boolean;
    set isOpen(v: boolean) {
        this._isOpen = v;
    }
    get isOpen(): boolean {
        return this._isOpen ?? false;
    }

    /**
     * Used internally to indicate that the dropdown items can't be shown at the moment but they are getting prepared. If true, the
     * dropdown shows a temporary loading spinner.
     */
    @tracked _isLoading = false;

    @or('_isLoading', 'itemClicked.isRunning')
    declare isLoading: boolean;

    /**
     * Array that holds the resolved items to be rendered in the dropdown body.
     */
    @tracked _itemsInternal: DropdownActionItem[] | null = null;
    set itemsInternal(v: DropdownActionItem[] | null) {
        this._itemsInternal = v;
    }
    get itemsInternal(): DropdownActionItem[] | null {
        return this._itemsInternal;
    }

    triggerId = `dropdown-actions-trigger-${guidFor(this)}`;

    /**
     * Modifier attached to the trigger to open the dropdown actions (onclick).
     */
    trigger = modifier<{ Element: Element }>((element: HTMLElement) => {
        element.setAttribute('id', this.triggerId);
        element.setAttribute('has-popup', 'true');

        const id = (this.listenerId = addWeakListener(this, element, 'click', async (evt: Event) => {
            evt.stopPropagation();

            if (!this.isOpen) {
                if ((await this.args.validateOpen?.()) === false) {
                    return;
                }
            }

            this.toggleDropdown();
        }));

        return () => removeListener(id);
    });

    /**
     * Handles action list item click, by sending the associated action together with its arguments, up the DOM tree.
     */
    itemClicked: Task<void, [DropdownActionItem]> = task(async (item: DropdownActionItem) => {
        const { disabled, action, actionArguments } = item;

        if (disabled || !action) {
            return;
        }

        if (typeof action !== 'function') {
            console.error(`Action '${action}' is not a function.`);
            return;
        }

        const result = action(...(actionArguments || []));

        // Close the dropdown if the action does not return a promise and it shouldn't be kept open after the action is triggered or
        // when the action returns a promise but the loading spinner should replace the trigger button and should not appear in the dropdown.
        if (
            (!this.args.keepOpenAfterItemTriggered && !isPromise(result)) ||
            (isPromise(result) && this.args.replaceTriggerWithLoadingSpinner)
        ) {
            this.isOpen = false;
        }

        await result;
    });

    /**
     * Toggles between dropdown open/close states.
     *
     * @note Triggered when the dropdown header is clicked.
     */
    @action toggleDropdown(): void {
        this.isOpen = !this.isOpen;
    }

    /**
     * Sets up internal items based on whether items passed in is a promise or not.
     */
    @action initializeItems(): void {
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        once<DropdownActions, typeof initializeInternalItems>(this, initializeInternalItems);
    }

    /**
     * Clears trigger listener.
     */
    @action clearTriggerListener(): void {
        const { listenerId } = this;
        if (listenerId) {
            removeListener(listenerId);
        }
    }
}
