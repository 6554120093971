import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import type ModalService from '../../../../services/modals.ts';
import type { ModalModel } from '../../../../services/modals.ts';
import type { EditConfirmationModalSignature } from '../edit/edit-confirmations';

export interface ConfirmDeleteModel extends ModalModel {
    title: EditConfirmationModalSignature['Args']['title'];
    message: string;
    delete: VoidFunction;
}

export interface ConfirmDeleteDialogSignature {
    Element: EditConfirmationModalSignature['Element'];
    Args: {
        /** The `ConfirmDeleteModel`. */
        model: ConfirmDeleteModel;
    };
}

/**
 * @classdesc
 * A dialog to confirm deleting of an item
 */
export default class ConfirmDeleteDialog extends Component<ConfirmDeleteDialogSignature> {
    @service declare modals: ModalService;
}
