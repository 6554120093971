import Service from '@ember/service';
import CookieService from '@adc/cookie-manager/build/cookie-service';
import EssentialCookie from '@adc/cookie-manager/build/essential-cookie';

import type Cookie from '@adc/cookie-manager/build/cookie';

export default class CookieManager extends Service {
    /**
     * Base cookie service.
     */
    cookieService: CookieService;

    constructor() {
        super();

        this.cookieService = new CookieService();
    }

    /**
     * Gets the cookie value.
     */
    getCookie(cookie: Cookie): string | null {
        return this.cookieService.getCookie(cookie);
    }

    /**
     * Updates / Sets a cookie.
     */
    setCookie(cookie: Cookie): boolean {
        return this.cookieService.setCookie(cookie);
    }

    /**
     * Deletes a cookie.
     */
    deleteCookie(cookie: Cookie): boolean {
        return this.cookieService.deleteCookie(cookie);
    }

    /**
     * Check the truthy/falsy state for a cookie.
     */
    hasTruthyCookieValue(cookie: Cookie): boolean {
        return this.cookieService.hasTruthyCookieValue(cookie);
    }

    /**
     * Gets the essential cookie value.
     */
    getEssentialCookie(cookieName: string): string | null {
        return this.cookieService.getCookie(new EssentialCookie(cookieName));
    }

    /**
     * Check the truthy/falsy state for an essential cookie.
     */
    hasEssentialCookieTruthyValue(cookieName: string): boolean {
        return this.cookieService.hasTruthyCookieValue(new EssentialCookie(cookieName));
    }

    /**
     * Deletes the essential cookie.
     */
    deleteEssentialCookie(cookieName: string): boolean {
        return this.cookieService.deleteCookie(new EssentialCookie(cookieName));
    }

    /**
     * Updates / Sets an essential cookie.
     *
     * @param cookieName The name of the essential cookie.
     * @param cookieValue The value of the essential cookie.
     * @param expirationDate The expiration date of the essential cookie. If undefined, the cookie will expire at the
     *  end of the session.
     */
    setEssentialCookie(cookieName: string, cookieValue: string, expirationDate?: Date | undefined): boolean {
        return this.cookieService.setCookie(new EssentialCookie(cookieName, cookieValue, expirationDate));
    }
}
