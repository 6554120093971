import templateOnlyComponent from '@ember/component/template-only';

import type { SafeString } from 'handlebars';

export interface FormGroupSignature {
    Element: HTMLDivElement;
    Args: {
        /** Indicates there is a form input validation error. */
        error?: any;
        /** Indicates the form group should be inline with the input, instead of stacked. */
        inline?: boolean;
        /** Indicates the input is required. */
        required?: boolean;
        /** The label text for the input. */
        label?: string | SafeString;
        /** Optional CSS class applied tot he label element. */
        labelClass?: string;
        /** The description text for the input. */
        labelDescription?: string | SafeString;
        /** Indicates the format group should be text-align to the right. */
        labelTextRight?: boolean;
        /** Input ID value so the label/input are accessible (strongly encouraged). */
        formElementId?: string;
    };
    Blocks: {
        default: [];
    };
}

const FormGroup = templateOnlyComponent<FormGroupSignature>();
export default FormGroup;
