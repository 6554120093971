import BasePikaday from './date-time-picker/base-pikaday.js';
import { action, setProperties } from '@ember/object';

/**
 * Calendar picker control.
 */
export default class PikadayInputless extends BasePikaday {
    @action initPikaday(el) {
        setProperties(this, {
            field: el.querySelector('.ember-pikaday-input'),
            pikadayContainer: el.querySelector('.ember-pikaday-container')
        });

        this.setupPikaday();
    }

    /** @override */
    onPikadayOpen() {}

    /** @override */
    onPikadayClose() {}
}
