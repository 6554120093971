// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { computed } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import { isDestroyed } from '@adc/ember-utils/utils/ember-helpers';
import { convertAutoDestroyToDuration } from '../error-tooltip.ts';

// region Constants
/**
 * Name of the error message clearing action
 *
 * @type {string}
 *
 * @static
 * @memberof components.common.BaseInput
 */
export const CLEAR_ERROR_MESSAGE_ACTION = 'clearErrorMessage';

/**
 * Name of the action when value changes
 *
 * @note This is still being used as an export in other addons
 *
 * @type {string}
 *
 * @static
 * @memberof components.common.BaseInput
 */
export const VALUE_CHANGE_ACTION = 'value-change';

// endregion

// region Component Helpers

/**
 * <p>Returns the correct value for a boolean html attribute.</p>
 * <strong>Note:</strong> Returns undefined if value is non-truthy, otherwise returns true
 *
 * @param propertyName
 * @returns {boolean}
 *
 * @private
 * @instance
 * @memberof components.common.BaseInput
 */
function booleanHtmlAttributeValue(propertyName) {
    return this[propertyName] ? true : undefined;
}

/**
 * Clears the error message
 *
 * @private
 * @instance
 * @memberof components.common.BaseInput
 */
function clearErrorMessageTrigger() {
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line @adc/ember/no-is-destroyed
    if (!isDestroyed(this)) {
        this.send(CLEAR_ERROR_MESSAGE_ACTION);
    }
}

/**
 * Schedules clearing of error message
 *
 * @public
 * @instance
 * @memberof components.common.BaseInput
 */
export function clearErrorMessage() {
    // Clear error message
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/no-runloop
    scheduleOnce('afterRender', this, clearErrorMessageTrigger);
}
// endregion

/**
 * <p>Mixin that extracts some common properties/attributes and functions of ADC inputs.</p>
 *
 * @class BaseInput
 * @extends Ember.Component
 * @memberof components.common
 */
// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-classes, ember/require-tagless-components
const BaseInput = Component.extend(
    /** @lends components.common.BaseInput*/ {
        // region Error Messages

        /**
         * Specifies error message to be displayed as a tooltip on the input.
         *
         * @type {string|undefined}
         * @default undefined
         */
        errorMessage: undefined,

        /**
         * The number of seconds to wait before closing the error tooltip.
         *
         * @type {number}
         */
        autoDestroy: undefined,

        /**
         * Converts the autoDestroy value to be used as the ErrorTooltip components duration property.
         */
        errorDuration: computed('autoDestroy', function () {
            return convertAutoDestroyToDuration(this.autoDestroy);
        }),

        /**
         * Location for error tooltip.
         *
         * @type {String}
         */
        errorTooltipPlace: undefined,

        // endregion

        // region Disabling and visibility

        /**
         * <p>Attribute for triggering required visibility of the input.</p>
         *
         * @type {boolean}
         * @default false
         */
        required: false,

        /**
         * <p>Attribute for triggering disabled visibility of the input.</p>
         *
         * @type {boolean}
         * @default false
         */
        disabled: false,

        /**
         * <p>Attribute for triggering readonly visibility of the input.</p>
         *
         * @type {boolean}
         * @default false
         */
        readonly: false,

        /**
         * <p>Returns the correct attribute for required the input element.</p>
         * If required is false, this should return undefined
         *
         * @function
         * @returns {boolean}
         */
        isRequired: computed('required', function () {
            return booleanHtmlAttributeValue.call(this, 'required');
        }).readOnly(),

        /**
         * <p>Returns the correct attribute for readonly the input element.</p>
         * If readonly is false, this should return undefined
         *
         * @function
         * @returns {boolean}
         */
        isReadonly: computed('readonly', function () {
            return booleanHtmlAttributeValue.call(this, 'readonly');
        }).readOnly(),

        /**
         * <p>Returns the correct attribute for disabled the input element.</p>
         * If disabled is false, this should return undefined
         *
         * @function
         * @returns {boolean}
         */
        isDisabled: computed('disabled', function () {
            return booleanHtmlAttributeValue.call(this, 'disabled');
        }).readOnly(),

        // endregion

        /** @ignore **/
        // eslint-disable-next-line ember/no-actions-hash
        actions: /** @lends components.common.BaseInput*/ {
            /**
             * Clears the error message, which in turn hides the tooltip
             */
            clearErrorMessage() {
                // It could happen that by the time this action is received this is already destroyed.
                // Do not copy this deprecated usage. If you see this, please fix it
                // eslint-disable-next-line @adc/ember/no-is-destroyed
                if (!isDestroyed(this)) {
                    this.set('errorMessage', undefined);
                }
            }
        }
    }
);

export default BaseInput;
