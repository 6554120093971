import Modifier from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';
import { debounce as runloopDebounce, cancel } from '@ember/runloop';

import type { EmberRunTimer } from '@ember/runloop/types';
import type { ArgsFor } from 'ember-modifier';

type DOMElement = Element | SVGElement;

type Handler = (el: Element, observer: ResizeObserver) => void;

type Options = {
    box: 'content-box' | 'device-pixel-content-box' | 'border-box';
};

interface DidResizeModifierSignature {
    Element: DOMElement;
    Args: {
        Positional: [Handler];
        Named: {
            debounce?: number;
            options?: Options;
        };
    };
}

export default class DidResizeModifier extends Modifier<DidResizeModifierSignature> {
    // Public API
    declare domElement?: DOMElement;
    declare handler: Handler;
    declare options: Options;
    debounce = 0;
    debounceId?: EmberRunTimer;

    // Private API
    declare static observer: ResizeObserver;
    declare static handlers: WeakMap<DOMElement, Handler>;

    constructor(owner: any, args: ArgsFor<DidResizeModifierSignature>) {
        super(owner, args);

        if (!('ResizeObserver' in window)) {
            return;
        }

        if (!DidResizeModifier.observer) {
            DidResizeModifier.handlers = new WeakMap<DOMElement, Handler>();
            DidResizeModifier.observer = new ResizeObserver(
                (entries, observer) => {
                    window.requestAnimationFrame(() => {
                        for (const entry of entries) {
                            const handler = DidResizeModifier.handlers.get(
                                entry.target
                            );
                            handler?.(entry.target, observer);
                        }
                    });
                }
            );
        }

        registerDestructor(this, unobserve);
    }

    modify(
        element: DOMElement,
        [handler, options]: [Handler, Options?],
        { debounce }: { debounce?: number }
    ) {
        unobserve(this);
        this.domElement = element;
        this.debounce = debounce ?? 0;

        // Save arguments for when we need them.
        this.handler = handler;
        this.options = options ?? this.options;

        this.observe(element);
    }

    observe(element: DOMElement) {
        if (DidResizeModifier.observer) {
            this.addHandler(element);
            DidResizeModifier.observer.observe(element, this.options);
        }
    }

    addHandler(element: DOMElement) {
        DidResizeModifier.handlers.set(
            element,
            (el: Element, observer: ResizeObserver) => {
                if (this.debounce !== 0) {
                    // Do not copy this deprecated usage. If you see this, please fix it
                    // eslint-disable-next-line ember/no-runloop
                    this.debounceId = runloopDebounce(
                        element,
                        this.handler,
                        el,
                        observer,
                        this.debounce
                    );
                } else {
                    this.handler(el, observer);
                }
            }
        );
    }

    removeHandler(element: DOMElement) {
        DidResizeModifier.handlers.delete(element);
    }
}

/**
 *
 * @param {DidResizeModifier} instance
 */
function unobserve(instance: DidResizeModifier) {
    if (instance.domElement && DidResizeModifier.observer) {
        if (instance.debounceId) {
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            cancel(instance.debounceId);
        }
        DidResizeModifier.observer.unobserve(instance.domElement);
        instance.removeHandler(instance.domElement);
        delete instance.domElement;
    }
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Calls the passed function whenever the modified element is resized, using debounce if a debounce period is supplied.
         * @note Uses a global instance of ResizeObserver.
         *
         * @example {{did-resize this.onResizeFunction}}
         */
        'did-resize': typeof DidResizeModifier;
    }
}
