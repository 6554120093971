import templateOnlyComponent from '@ember/component/template-only';

export interface ModalInsetOverlaySignature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}

const ModalInsetOverlay = templateOnlyComponent<ModalInsetOverlaySignature>();
export default ModalInsetOverlay;
