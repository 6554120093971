/**
 * Utility module for general JS related code.
 * @module
 */

/**
 * Returns true if the given variable is a function.
 *
 * @param functionToCheck - The variable to check is a function.
 *
 * @deprecated Use `typeof functionToCheck === 'function'` instead.
 */
export function isFunction(functionToCheck: () => any): boolean {
    return functionToCheck && typeof functionToCheck === 'function';
}
