// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { isFunction } from '@adc/ember-utils/utils/general';
import { inject as service } from '@ember/service';
import layout from '../templates/components/list-row-item.hbs';
import { ActionItem, DeleteActionItem, EditActionItem } from '../utils/action-item.js';
import { isEnterOrSpaceCode } from '@adc/ember-utils/utils/a11y';

// region Constants

/**
 * The name of the action property to be called in order to received the action items list.
 *
 * @type {String}
 * @static
 * @memberof components.ListRowItem
 */
const ACTIONS_ITEMS_GETTER = 'getActionItems';

// endregion

// region Caching.

/**
 * The translated default text of EditActionItem.
 *
 * @type {String}
 * @static
 * @memberof components.ListRowItem
 */
let editText = undefined;

/**
 * The translated default text of DeleteActionItem.
 *
 * @type {String}
 * @static
 * @memberof components.ListRowItem
 */
let deleteText = undefined;

// endregion

// region Helper Methods

/**
 * Ensures the action items have the necessary properties required for use in the list-row and they have the desired order.
 *
 * @param {Array<ActionItem>} actionItems - The action items we are validating.
 * @returns {Array<ActionItem>}
 *
 * @static
 * @memberof components.ListRowItem
 */
function validateActionItems(actionItems) {
    let deleteAction = undefined;
    actionItems = actionItems.filter((action) => {
        if (!action) {
            return false;
        }

        if (!(action instanceof ActionItem)) {
            console.error(`list-row-item: The item ${action} must be an instance of ActionItem.`);
            return false;
        }

        // If action is not visible, do not add it to the list.
        if (!action.visible) {
            return false;
        }

        if (action instanceof DeleteActionItem) {
            // Add delete text and icon title if it was not defined.
            action.text = action.text || deleteText;
            action.iconTitle = action.iconTitle || deleteText;

            // Cache delete action and do not include it in the array because we will append it.
            deleteAction = action;
            return false;
        }

        if (action instanceof EditActionItem) {
            // Add edit text and icon title if it was not defined.
            action.text = action.text || editText;
            action.iconTitle = action.iconTitle || editText;
        }

        if (!action.icon || !action.text) {
            console.error(`list-row-item: The item ${action} must have an icon and text.`);
            return false;
        }

        return true;
    });

    // Add deleteAction to the end if it was defined.
    if (deleteAction) {
        actionItems.push(deleteAction);
    }

    return actionItems;
}

/**
 * Load the action items and set them for use within the template/dropdown
 *
 * @static
 * @memberof components.ListRowItem
 */
function setActionItems() {
    const actions = this[ACTIONS_ITEMS_GETTER];

    if (!actions || !isFunction(actions)) {
        return false;
    }

    this.set('actionItemsCollection', validateActionItems(actions()));
}

// endregion

// region Component

/**
 * A flexible container containing content, alongside tools with associated actions.
 *
 * @class ListRowItem
 * @extends Ember.Component
 * @memberof components
 */
// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-classes, ember/require-tagless-components
export default Component.extend(
    /** @lends components.ListRowItem# **/ {
        intl: service(),

        // region Property Overrides

        /** @override */
        layout: layout,

        /** @override */
        classNames: ['list-row-item'],

        /** @override */
        classNameBindings: ['compact', 'highlightRow'],

        attributeBindings: ['dataTestId:data-test-list-row-item'],

        /** @override */
        init() {
            this._super(...arguments);

            if (!editText) {
                // Cache edit text.
                editText = this.intl.t('@adc/ui-components.edit');
            }

            if (!deleteText) {
                // Cache delete text.
                deleteText = this.intl.t('@adc/ui-components.delete');
            }

            setActionItems.call(this);
        },

        // endregion

        // region Properties

        /**
         * Should the list-row be highlighted on hover?
         *
         * @type {boolean}
         */
        highlightRow: true,

        /**
         * Indicates whether or not the dropdown actions trigger should be disabled.
         *
         * @type {boolean}
         */
        areActionsDisabled: false,

        /**
         * Whether to reset the dropdown action items.
         *
         * @type {boolean}
         */
        resetActionItems: false,

        /**
         * Whether to reset the dropdown action items.
         *
         * @type {boolean}
         */
        showEllipsis: true,

        // endregion

        // region Computed Properties

        /**
         *  The title to be used for the tools dropdown. This property is translated locally for i18n support.
         *
         *  @function
         *  @returns {String}
         */
        actionsTitle: computed(function () {
            return this.intl.t('@adc/ui-components.dropdownTitle');
        }),

        // endregion

        /**
         * Listener for keyDown events.
         *
         * @param {KeyboardEvent} event
         */
        keyDown(event) {
            if (isEnterOrSpaceCode(event.code) && event.target.classList.contains('list-row-content')) {
                this.onContentClick();
            }
        },
        // region Actions

        callSetActionItems: action(function () {
            setActionItems.call(this);
        }),

        /**
         *  Load the toolset into the dropdown actions
         */
        validateActionItemsOpen: action(function () {
            // If the model wasn't loaded during initialization or if we need to reset action items, set the actions when the ellipsis is clicked
            if (!this.actionItemsCollection?.length || this.resetActionItems) {
                setActionItems.call(this);
            }

            return true;
        }),

        /**
         *  The action to execute on content click.
         */
        onContentClick: action(function () {
            const action = this['on-content-click'];

            if (!action) {
                return;
            }

            if (!isFunction(action)) {
                console.error(`list-row-item: action '${action}' is not a function.`);
                return false;
            }

            action();
        })

        // endregion
    }
);

// endregion
