import templateOnlyComponent from '@ember/component/template-only';

export interface BinaryInputLabelSignature {
    Element: HTMLDivElement;
    Args: {
        isYieldedComponent?: boolean;
        shouldYield?: boolean;
        icon?: string;
        iconTitle?: string;
        iconDesc?: string;
        iconIsHiddenForAccessibility?: boolean;
        label: string;
    };
    Blocks: {
        default: [];
    };
}
const BinaryInputLabel = templateOnlyComponent<BinaryInputLabelSignature>();
export default BinaryInputLabel;
