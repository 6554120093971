import Component from '@glimmer/component';
import { intlPath } from '@adc/i18n/path';
import { action } from '@ember/object';

import type ResponsiveTableCellSignature from './cell-signature.ts';

/**
 * @classdesc
 * Displays a contextual table header cell.
 */
@intlPath({ module: '@adc/ui-components', path: 'responsive-table/table-header-cell' })
export default class TableHeaderCell<T> extends Component<ResponsiveTableCellSignature<T>> {
    private getSortValue(defaultValue: string): string {
        const { sort, canSort = false } = this.args;

        // Is this column NOT sortable OR is it not sorted?
        if (!canSort || sort === undefined) {
            return defaultValue;
        }

        // Sorted, so return sort direction.
        return sort ? 'ascending' : 'descending';
    }

    /**
     * The sort direction for this column.
     */
    get cssSort(): string {
        return this.getSortValue('');
    }

    /**
     * The aria sort value for this column.
     */
    get ariaSort(): string {
        return this.getSortValue('none');
    }

    /**
     * The title of the sort link.
     */
    get sortKey(): string {
        return `${this.cssSort || 'descending'}Sort`;
    }

    @action sort(): void {
        this.args.sortChanged?.();
    }
}
