import EmberRoute from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { once } from '@ember/runloop';

/**
 * Updates the titles for the current route.
 *
 * @private
 * @instance
 * @memberof routes.AdcBaseRoute
 */
export function updateRouteInfo() {
    // Assume there is no parent route path.
    let { queryParams, routeItem, routeName, fullRouteName } = this;

    // If the routeName is undefined, use the fullRouteName.
    if (routeName !== fullRouteName) {
        routeName = fullRouteName;
    }

    // Push new route data to the service.
    this.contextManager.setCurrentRouteData(
        {
            routeItem,
            routeName,
            queryParams,
            route: this,
            pageTitle: this.getRouteItemTitle()
        },
        this.wasReplaceTransition
    );
}

/**
 * Classes to define the max width of the content on a page.
 *
 * @static
 *
 * @type {string}
 */
export const PAGE_MAX_WIDTH_MEDIUM = 'page-max-width-md';

/**
 * Returns a query parameter configuration object.
 *
 * @param {String} [type='number'] Optional query parameter type.
 *
 * @returns {{refreshModel: boolean, replace: boolean, type: String}}
 *
 * @memberof @adc/app-infrastructure/routes/base-route
 */
export function getQueryParam(type = 'number') {
    return {
        refreshModel: true,
        replace: true,
        type
    };
}

/**
 * @classdesc
 * Base route class that every route should extend from.
 *
 * @abstract
 * @class routes.AdcBaseRoute
 * @extends Ember.Route
 */
// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-classes
export default EmberRoute.extend(
    /** @lends routes.AdcBaseRoute */ {
        // region Services

        contextManager: service(),
        performanceMonitor: service(),
        intl: service(),

        // endregion

        // region Route Info

        /**
         * Defines the id of this route. When defined, the routes.AdcBaseRoute can do a lot of work behind the scenes.
         * However, for some routes this won't be relevant or possible. For those, the canUserAccess function must be overridden.
         *
         * Note: This value corresponds to the CustomerPageUrlEnum.
         *
         * @type {number|undefined}
         */
        routeId: undefined,

        /**
         * RouteItem associated with this route.
         *
         * @type {Model}
         */
        get routeItem() {
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line @adc/ember/no-context-manager-get-route
            return this._routeItem ?? this.contextManager.getRouteItem(this.routeId);
        },

        set routeItem(value) {
            this._routeItem = value;
        },

        /**
         * Returns the route item title.
         *
         * @returns {String}
         */
        getRouteItemTitle() {
            // Use static page title from i18n.
            return this.routeItem?.pageTitle || this.intl.tc(this, 'pageTitle');
        },

        /**
         * Sends information about the current route to the application route.
         */
        sendRouteInfoToApp() {
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            once(this, updateRouteInfo);
        },

        // endregion

        // region Query Parameter serialization

        /** @override */
        serializeQueryParam(value, urlKey, defaultValueType) {
            if (defaultValueType === 'date' && value && value instanceof Date) {
                return value.toISOString();
            }

            return this._super(...arguments);
        },

        /** @override */
        deserializeQueryParam(value, urlKey, defaultValueType) {
            if (defaultValueType === 'date' && value && !(value instanceof Date)) {
                return new Date(value);
            }

            return this._super(...arguments);
        },

        // endregion

        // region Page Width

        /**
         * The name of the class to be added in order to define the max width of the content on a page.
         *
         * @function
         * @type {string}
         */
        pageMaxWidth: computed('isMediumWidthPage', function () {
            return this.isMediumWidthPage ? PAGE_MAX_WIDTH_MEDIUM : '';
        }),

        /**
         * Flag indicating whether or not the page content max width class should be added.
         *
         * @type {boolean}
         */
        isMediumWidthPage: false,

        // endregion

        // region Hooks

        beforeModel(transition) {
            // We use transition.targetName because we are not yet in the destination route.  Also the router service does not yet have the routeName defined.
            this.performanceMonitor.markRouteInitialized(transition.targetName, transition);

            // We shouldn't use kpi threshold metrics unless we separately activate it for a route.
            this.performanceMonitor.setUseKpiThresholdMetrics(false);
        },

        // endregion

        // region Actions

        /** @ignore */
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-actions-hash
        actions: /** @lends BaseRoute# */ {
            didTransition() {
                // Update application router with route information.
                this.sendRouteInfoToApp();
            }
        }

        // endregion
    }
);
