import templateOnlyComponent from '@ember/component/template-only';

export interface BinaryInputContentSignature {
    Element: HTMLDivElement;
    Args: {
        showAlways?: boolean;
        isItemSelected?: boolean;
    };
    Blocks: {
        default: [];
    };
}
const BinaryInputContent = templateOnlyComponent<BinaryInputContentSignature>();
export default BinaryInputContent;
