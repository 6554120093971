import Model from '@ember-data/model';
import { get } from '@ember/object';
import { pluralize } from 'ember-inflector';
import RSVP from 'rsvp';

// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type ModelRegistry from 'ember-data/types/registries/model';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type AdapterRegistry from 'ember-data/types/registries/adapter';
import type Store from '@ember-data/store';
import type AjaxService from '@adc/ajax/services/adc-ajax';
import type BaseAdapter from '../adapters/base-adapter.ts';

interface RelationshipInfo {
    kind: 'belongsTo' | 'hasMany';
}

/**
 * Creates new model with defaults provided by the server.  If a modelId is specified, then that will be the ID used for the new model.
 *
 * @param ajax The ADC Ajax service.
 * @param store The ember-data store.
 * @param modelName The name of the model to create.
 * @param [params] Parameters passed to the server.
 * @param [modelId] id of the newly created model if we want to specify it.
 * @param [defaultProperties] properties we want the new model to be initialized with.
 */
export async function createRecordWithServerDefaults<T extends BaseModel>(
    ajax: AjaxService,
    store: Store,
    modelName: keyof ModelRegistry,
    params: Record<string, unknown> = {},
    modelId: number | string = -1,
    defaultProperties: Partial<T> = {}
): Promise<T> {
    const defaultModel = await ajax.apiRequest<{
        data: {
            attributes: Partial<T>;
        };
    }>(`${pluralize(String(modelName))}/getModelDefaultData`, undefined, params, 'POST');

    return store.createRecord(modelName, {
        ...(modelId === -1 ? {} : { id: modelId }),
        ...(defaultModel.data?.attributes ?? {}),
        ...defaultProperties
    });
}

/**
 * @classdesc
 * BaseModel class with some functionality that can be overridden.
 */
export default class BaseModel extends Model {
    /**
     * Should we attempt to rollback relationships on the model when rollbackAttributes() is called?
     */
    get shouldRollbackRelationships(): boolean {
        return false;
    }

    /**
     * Can this model be rolled back?
     */
    canBeRolledBack(): boolean {
        // Model cannot be rolled back if it is already destroyed.
        // eslint-disable-next-line @adc/ember/no-is-destroyed
        return !(this.isDestroyed || this.isDestroying);
    }

    /** @override */
    reload(options?: { adapterOptions?: object | undefined }): RSVP.Promise<this> {
        // Attempting to reload the record while it is in an empty state will trigger an error in Ember.data.
        // eslint-disable-next-line ember/no-get
        if (get(this, 'isEmpty')) {
            console.warn('Record will not be reloaded while in state root.empty.');
            return RSVP.reject();
        }

        // Defer to base.
        return super.reload(options);
    }

    /**
     * Rolls back the model.
     *
     * For more complex models, this should be overridden to also roll back child models.
     *
     * @warning If you are overriding this method, you MUST make sure you first check whether the model canBeRolledBack.
     */
    rollback(): void {
        if (this.canBeRolledBack()) {
            this.rollbackAttributes();
        }
    }

    /**
     * Returns the model name of this model.
     */
    getModelName(): keyof ModelRegistry {
        return String(this).match(/@model:(.*)::/)?.[1] ?? '';
    }

    /**
     * Submits custom api call.
     *
     * @param apiMethod - Method name to call on the controller.
     * @param [httpVerb=POST] - HTTP verb to use for the api call.
     * @param [data] - Additional data to pass in the post request.
     */
    submitCustomApiCall<T>(apiMethod: string, httpVerb = 'POST', data?: any): Promise<T> {
        return (
            this.store.adapterFor(this.getModelName() as keyof AdapterRegistry) as unknown as BaseAdapter
        ).submitCustomApiCall(this, apiMethod, httpVerb, data);
    }

    /**
     * Overrides native function to include rollback of relationships
     *
     * @override
     */
    rollbackAttributes() {
        super.rollbackAttributes();

        if (this.shouldRollbackRelationships) {
            this.rollbackRelationships();
        }
    }

    /**
     * Returns the list of records for a specified relationship property on this model.
     */
    private getRelationshipRecords(name: Exclude<keyof this, keyof Model>, { kind }: RelationshipInfo): Model[] {
        if (kind === 'belongsTo') {
            const value = this.belongsTo(name).value();
            return value ? [value] : [];
        }

        return (this.hasMany(name).value() ?? []) as Model[];
    }

    /**
     * Rollbacks all the record of the specified relationship property of this model.
     */
    private rollbackRelationship(name: Exclude<keyof this, keyof Model>, relDescriptor: RelationshipInfo): void {
        this.getRelationshipRecords(name, relDescriptor).forEach((record) => {
            // Call rollback for the records which inherit from base-model class.
            if (record instanceof BaseModel) {
                record.rollback();
                return;
            }

            // Call rollbackAttributes from the ember model class otherwise.
            record.rollbackAttributes();
        });
    }

    /**
     * Rolls back all the fetched relationships on this model.
     */
    rollbackRelationships(): void {
        this.eachRelationship(this.rollbackRelationship, this);
    }
}
