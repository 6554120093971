import templateOnlyComponent from '@ember/component/template-only';

import type { ChecklistTreeInfo } from '../multi-select';

export interface DropdownMultiSelectTreeWrapperSignature {
    Element: HTMLElement;
    Args: {
        /** Information about the rendered tree. */
        tree: ChecklistTreeInfo;
    };
    Blocks: {
        default: [];
    };
}
const DropdownMultiSelectTreeWrapper = templateOnlyComponent<DropdownMultiSelectTreeWrapperSignature>();
export default DropdownMultiSelectTreeWrapper;
