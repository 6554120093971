import Component from '@glimmer/component';
import PageViewContainer from './page-view/container.ts';
import { action } from '@ember/object';
import { service } from '@ember/service';

import type { ComponentLike, WithBoundArgs } from '@glint/template';
import type { NativeBridgeSignature } from './native-bridge';
import type { PageViewContainerSignature } from './page-view/container';
import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type NativeBridgeService from '@adc/app-infrastructure/services/native-bridge';
import type PerformanceMonitorService from '@adc/app-infrastructure/services/performance-monitor';
import type RouterService from '@ember/routing/router-service';

type PageViewApi<M> = {
    /** The unresolved `@model`. */
    model: PageViewSignature<M>['Args']['model'];
    /** An object with native bridge button methods. */
    buttons: NativeBridgeSignature['Blocks']['default'][0];
    /** The host application navigation API. */
    navigation: NativeBridgeSignature['Blocks']['default'][1];
};

export interface PageViewSignature<M> {
    Element: NativeBridgeSignature['Element'];
    Args: Pick<PageViewContainerSignature<M>['Args'], 'model' | 'dataLoaded'> & {
        /** Number of containers to render in the current page-view.
         * Passed in when there is more than 1 container to load. */
        numOfContainersToRender?: number;
        /** Indicates if this page view will utilize the kpi threshild metric system */
        useKpiThresholdMetrics?: boolean;
    };
    Blocks: {
        /** Space for a toolbar, rendered at the top of the `<PageView />` element. */
        toolbar: [
            /** The `<PageView />` api (with model, buttons and navigation properties). */
            PageViewApi<M>
        ];
        default: [
            /** The `<PageView::Container />` component. */
            WithBoundArgs<ComponentLike<PageViewContainerSignature<M>>, 'model' | 'dataLoaded' | 'markEndOfRendering'>,
            /** The `<PageView />` api (with model, buttons and navigation properties). */
            PageViewApi<M>
        ];
    };
}

type NoBackParameters = Parameters<NativeBridgeSignature['Args']['handleNoNativeBackOrCancelButton']>;

/**
 * This helps in tracking the rendering of multiple containers in a route. It increments a count of rendered containers and
 * checks if it equals the total number of containers to be rendered. If they are equal, it marks that the last container
 * has been loaded by calling markLastRender from PerformanceMonitorService. This function is useful for performance monitoring,
 * especially when a route consists of multiple containers that need to be rendered.
 * @param containersRendered Current number of rendered contianers.
 * @param numOfContainersToRender Expected total containers to render.
 * @param performanceMonitor The current instance of the performance monitor service.
 * @returns New count of containers rendered.
 */
export function markLastContainerRendered(
    containersRendered: number,
    numOfContainersToRender: number,
    performanceMonitor: PerformanceMonitorService
): number {
    // If this is called we will increment the count because this can only be called once a container has rendered.
    containersRendered++;

    // If the counts are the same, mark that we've loaded the last contianer.
    if (containersRendered === numOfContainersToRender) {
        performanceMonitor.markLastRender();
    }

    return containersRendered;
}

/**
 * @classdesc
 * A component for rendering as the root element in each route, that contains code for resolving the model, and showing loading progress, error state and no results.
 */
export default class PageView<M> extends Component<PageViewSignature<M>> {
    @service declare intl: ADCIntlService;
    @service declare nativeBridge: NativeBridgeService;
    @service declare performanceMonitor: PerformanceMonitorService;
    @service declare router: RouterService;

    containerComp = PageViewContainer<M>;

    constructor(owner: unknown, args: PageViewSignature<M>['Args']) {
        super(owner, args);

        // We want to use kpi threshold metrics for page views.
        this.setKpiMetricUsage();
    }

    /**
     * Keeps track of the number of containers that have been rendered.
     */
    containersRendered = 0;

    /** Determines whether the rendering process for this page view
     * has completed and marks it using the performance monitor service.
     * This action is passed into each container and fires when that container
     * is finished rendering. Once every container has rendered, then we
     * call the performance monitor to mark the last render point.
     */
    @action
    markEndOfRendering(): void {
        const numOfContainersToRender = this.args.numOfContainersToRender ?? 1;

        // Attempt to mark the last container as rendered and return the new count.
        this.containersRendered = markLastContainerRendered(
            this.containersRendered,
            numOfContainersToRender,
            this.performanceMonitor
        );
    }

    /*
     * Determines whether the page view utilizes the KPI threshold metric tracking feature.
     */
    @action
    private setKpiMetricUsage(): void {
        this.performanceMonitor.setUseKpiThresholdMetrics(this.args.useKpiThresholdMetrics ?? false);
    }

    /**
     * Notifies the performance monitor service that the route view has been rendered.
     */
    @action sendRouteViewRendered(): void {
        this.performanceMonitor.markRouteViewRendered(this.router.currentRouteName);
    }

    @action handleNoNativeBackOrCancelButton(
        { backButtons }: NoBackParameters[0],
        navigationApi: NoBackParameters[1]
    ): void {
        // Is there no route to go back to?
        if (!navigationApi || navigationApi.getVisitedRoutesStackLength() <= 1) {
            return;
        }

        const backText = this.intl.t('generic.back');

        backButtons.unshift({
            componentId: 'back',
            text: backText,
            description: backText,
            type: this.nativeBridge.getButtonTypeBack(),
            action: () => navigationApi.transitionToPreviousRoute()
        });
    }
}
