// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { guidFor } from '@ember/object/internals';
import { once } from '@ember/runloop';
import { notEmpty } from '@ember/object/computed';
import { dasherize } from '@ember/string';
import { action, computed, set } from '@ember/object';
import { isRtl, DIR_CHANGED_EVENT_KEY } from '@adc/ember-utils/utils/html-dir-helpers';
import { isDestroyed } from '@adc/ember-utils/utils/ember-helpers';
import { A } from '@ember/array';
import { isTestEnvironment } from '../../utils/general.ts';
import { ESCAPE_CODE, addWeakTrapFocusListener, removeTrapFocusListener } from '@adc/ember-utils/utils/a11y';
import { tracked } from '@glimmer/tracking';
import Popper from 'popper.js';
import { htmlSafe } from '@ember/template';

// region RTL Support

/**
 * Constants for placement strings.
 *
 * @note
 * These are only the placement options that are used in this file and do not reflect all that are possible for a popover.
 */
const LEFT = 'left',
    RIGHT = 'right',
    DEFAULT_PLACEMENT = 'bottom';

/**
 * Helper variable to track if the popover was already focused.
 *
 * @type {Boolean}
 */
let focusSet = false;

// endregion

// region Popper Helpers

/**
 * Returns the HTML element the popper should be anchored to.
 *
 * @returns {Element}
 */
export function getAnchorElement() {
    let { anchorSelector } = this;

    if (isTestEnvironment.call(this)) {
        // For tests, we append the popover to the #ember-testing container
        // so that ember-native-dom-helpers can find and operate on it.
        anchorSelector = '#ember-testing';
    }

    return typeof anchorSelector === 'string' ? document.querySelector(anchorSelector) : anchorSelector;
}

/**
 * Returns the options to use when creating the popper.
 *
 * @param {HTMLElement} el The popover element.
 *
 * @returns {{}}
 */
function getPopperOptions(el) {
    const { placement, boundariesElement = 'viewport' } = this;

    return {
        onCreate: () => {
            const onCreateAction = this['on-create'];

            if (onCreateAction) {
                onCreateAction(el);
            }
        },
        placement,
        modifiers: {
            flip: {
                boundariesElement
            },
            arrow: {
                element: '.arrow'
            },
            preventOverflow: {
                boundariesElement,
                escapeWithReference: false
            }
        }
    };
}

/**
 * Creates a new Popper.js instance with the content of this component, and then saves its reference on the context.
 *
 * @param {HTMLElement} el The popper element.
 */
function createAndSetPopper(el) {
    const anchorElement = getAnchorElement.call(this);
    if (!anchorElement) {
        console.error('No anchorSelector defined for popover');
        return;
    }

    // Creates a popper instance with the content of this component.
    set(this, 'popper', new Popper(anchorElement, el, getPopperOptions.call(this, el)));

    this.dom.addListener(this, document, 'transitionend', updatePopper.bind(this));
}

/**
 * Destroys the existing Popper.js instance if a reference is found, and then removes the reference from context.
 *
 * @returns undefined
 */
function cleanupAndRemovePopper() {
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line @adc/ember/no-is-destroyed
    if (isDestroyed(this) || !this.popper) {
        return;
    }

    this.popper.destroy();
    set(this, 'popper', null);
}

/**
 * Schedules an aspect update on the existing Popper.js instance, if a reference is found.
 *
 * @returns undefined
 */
function updatePopper() {
    if (this.popper) {
        this.popper.scheduleUpdate();
    }
}

/**
 * Handler function for the togglePopper observer. It identifies the moment and does the switch between rendering with Popper.js and direct render.
 *
 * @param {HTMLElement} el The popper element.
 */
function handleTogglePopper(el) {
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line @adc/ember/no-is-destroyed
    if (isDestroyed(this)) {
        return;
    }

    const { isRenderedWithPopper, shouldRenderWithPopper } = this;

    if (isRenderedWithPopper && !shouldRenderWithPopper) {
        cleanupAndRemovePopper.call(this);
    } else if (!isRenderedWithPopper && shouldRenderWithPopper) {
        createAndSetPopper.call(this, el);
    }
}

// endregion

// region Component

/**
 * @classdesc
 *
 * Wrapper for the actual popover
 *
 * @note This could eventually be made into its own addon as a base-popover and we could use it in tooltips as well.
 */
export default class PopoverWrapper extends Component {
    tagName = '';

    // region Service

    @service media;
    @service dom;

    // endregion

    // region Component state

    domId = '';

    /**
     * This property will enable arrows to work like TAB or SHIFT + TAB
     *
     * @type {boolean}
     */
    treatArrowsAsTabEvents = true;

    /**
     * An optional CSS selector for the element that should have focus after render.
     *
     * @type {string}
     */
    focusSelector = '';

    /**
     * The last focused element before the popover was opened.
     *
     * @type {Object}
     * @ignore
     */
    lastFocusedElement;

    /**
     * Width for the popover.
     *
     * @type {undefined|number}
     */
    width;

    /**
     * Maximum width for the popover.
     *
     * @type {undefined|number}
     */
    maxWidth;

    /**
     * Maximum height for the popover.
     *
     * @type {undefined|number}
     */
    maxHeight;

    /**
     * Minimum width for the popover.
     *
     * @type {undefined|number}
     */
    minWidth;

    /**
     * Minimum height for the popover.
     *
     * @type {undefined|number}
     */
    minHeight;

    /**
     * Placement of the popover.
     *
     * @type {String}
     */
    placement = DEFAULT_PLACEMENT;

    /**
     * When the popover for this is rendered, we trigger a focus event on the popover.  Should we prevent the page from scrolling when this focus is triggered.
     *
     * @type {Boolean}
     */
    preventScroll = false;

    /**
     * Is the element focusable?
     *
     * @type {boolean}
     */
    @tracked isFocusable = false;

    /**
     * Optional passed function to indicate the popover is handling close detection and wants to close.
     *
     * @function
     */
    willClose() {}

    // endregion

    // region Hooks

    /** @override */
    constructor() {
        super(...arguments);

        this.lastFocusedElement = document.activeElement;

        const rtlPlacement = () => {
            const { placement = DEFAULT_PLACEMENT } = this;

            // Make necessary conversions for RTL.
            if (isRtl()) {
                [
                    [LEFT, RIGHT],
                    [RIGHT, LEFT]
                ].some(([check, replacement]) => {
                    if (placement.includes(check)) {
                        this.placement = placement.replace(check, replacement);
                        return true;
                    }

                    return false;
                });
            }
        };

        this.dom.addListener(this, document, DIR_CHANGED_EVENT_KEY, rtlPlacement);

        rtlPlacement();

        this.domId = this.id || guidFor(this);
    }

    /** @override */
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdate() {
        focusSet = false;

        super.didUpdate();
    }

    /**
     * Called to initialize the popover element.
     *
     * @param {HTMLElement} el
     */
    @action initPopup(el) {
        this.trapFocusListener = addWeakTrapFocusListener(this, {
            scopeSelector: '.popover-menu',
            escapeCallback: () => {},
            treatArrowsAsTabEvents: this.treatArrowsAsTabEvents,
            oldListenerId: this.trapFocusListener
        });

        if (this.shouldRenderWithPopper) {
            createAndSetPopper.call(this, el);
        }
    }

    /** @override */
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/no-component-lifecycle-hooks
    didRender() {
        if (this.isRenderedWithPopper) {
            updatePopper.call(this);
        }

        const focusOptions = { preventScroll: this.preventScroll };

        if (this.isFocusable && !focusSet) {
            document.querySelector(`#${this.domId}`).focus(focusOptions);
        }

        if (this.focusSelector && !focusSet) {
            const elementToFocus = document.querySelector(this.focusSelector);

            if (elementToFocus) {
                elementToFocus.focus(focusOptions);
            }
        }

        return super.didRender();
    }

    /**
     * Cleanup and remove Popper object.
     *
     * @override
     */
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement() {
        cleanupAndRemovePopper.call(this);

        focusSet = false;
        removeTrapFocusListener(this.trapFocusListener);

        super.willDestroyElement(...arguments);
    }

    // endregion

    // region Computed Properties

    /**
     * Calculates css (max/min) height and (max/min) width style. This property is ignored when not rendering with Popper.js.
     *
     * NOTE: Avoid watching 'isRenderedWithPopper'. Changing the styles while the popper container is rendered in the page causes horizontal positioning issues: Wrongly calculated horizontal center and content flickering at initial render due to quick horizontal position correction.
     */
    @computed('{width,maxWidth,maxHeight,minWidth,minHeight}', 'shouldRenderWithPopper')
    get style() {
        // Ignore custom width and height if not rendering within a popper.
        if (!this.shouldRenderWithPopper) {
            return null;
        }

        return htmlSafe(
            A(
                ['width', 'maxHeight', 'maxWidth', 'minWidth', 'minHeight'].map((key) => {
                    // Dasherize property name.
                    let property = dasherize(key);

                    const value = parseInt(this[key], 10);
                    return window.isNaN(value) ? undefined : `${property}:${value}px;`;
                })
            )
                .compact()
                .join('')
        );
    }

    /**
     * Whether or not the component should be render with Popper.js.
     *
     * @type {boolean} - true if the component should be rendered with Popper.js.
     */
    @computed('media.isMobile', 'directRenderOnMobile')
    get shouldRenderWithPopper() {
        return this.media.isMobile ? !this.directRenderOnMobile : true;
    }

    /**
     * Whether or not the component is currently rendered with Popper.js.
     *
     * @type {boolean} - true if the component is already rendered with Popper.js.
     */
    @notEmpty('popper')
    isRenderedWithPopper;

    // endregion

    // region Actions

    /**
     * Called to switch from Popper.js to direct render and vice-versa. It also executes the necessary transition logic.
     *
     * @param {HTMLElement} el The popper element.
     */
    @action togglePopper(el) {
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        once(this, handleTogglePopper, el);
    }

    /**
     * Triggers an additional updatePopper request, besides didRender hook, when style attribute value changes.
     *
     * @note This is used as a bug fix for the wrong popper horizontal positioning bug, which happens when styles change dynamically the time the popper component is already in the page.
     */
    @action extraPopperUpdate() {
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        once(this, updatePopper);
    }

    /**
     * Listener for keyDown events.
     *
     * @param {KeyboardEvent} event
     */
    @action keyDownOnElement(event) {
        // Prevents exiting weak trap focus of popover using tab/enter when there are no inner focusable components
        if (event.target?.id === this.domId && event.code !== ESCAPE_CODE) {
            event.preventDefault();
            return;
        }

        focusSet = true;

        // Does user want to close this popup?
        if (event.code === ESCAPE_CODE) {
            const { lastFocusedElement } = this;
            if (lastFocusedElement) {
                lastFocusedElement.focus();
            }

            this['on-close']();
        }
    }

    /**
     * Called when the user clicks outside the popup.  The willClose method will be called if it was provided.
     */
    @action clickOutsidePopup(event) {
        const path = event.path || (event.composedPath && event.composedPath());

        // When a user clicks the anchorSelector (the trigger), the popover toggles its visibility.  Therefore we want to ignore clicking outside of the popup
        // here when the user clicks on the anchorSelector/trigger, otherwise it would cause a double toggle (and the popover would remain open).
        if (path.filter((path) => `#${path.id}` === this.anchorSelector).length) {
            return;
        }

        this.willClose();
    }

    // endregion
}

// endregion
