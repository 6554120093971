import IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import getTranslation from '../utils/get-translation.ts';
import { formatDateTz, formatTimeTz, isSameDayTz } from '../utils/dates.ts';
import { IntlErrorCode } from '@formatjs/intl';

import type { IntlContext } from '../utils/get-translation';

type BaseArgs = Parameters<IntlService['addTranslations']>;

export default class ADCIntlService extends IntlService {
    /**
     * Shortcut for settings locale, translations, and timezone.
     */
    initialize(locale: BaseArgs[0], translations?: BaseArgs[1], timeZone?: string): void {
        this.setLocale(locale);

        if (translations) {
            this.addTranslations(locale, translations);
        }

        if (timeZone) {
            this.timeZone = timeZone;
        }
    }

    /**
     * An optional global timezone.
     */
    @tracked timeZone = '';

    /** @override */
    onIntlError(error: Error & { code: string }): void {
        // We ignore MISSING_DATA due to lack of support for eu-es & is-is in Chromium browsers
        // We ignore MISSING_TRANSLATION to maintain functionality with the original service method as it is handled elsewhere
        if (error.code === IntlErrorCode.MISSING_DATA || error.code === IntlErrorCode.MISSING_TRANSLATION) {
            return;
        }

        throw error;
    }

    /**
     * Formats a date/time, using the optional global timezone if no timezone was supplied in the options.
     */
    formatDateTz(
        value: Parameters<typeof formatDateTz>[1],
        options: Parameters<typeof formatDateTz>[2]
    ): ReturnType<typeof formatDateTz> {
        return formatDateTz(this, value, options);
    }

    /**
     * Formats a date/time, using the optional global timezone if no timezone was supplied in the options.
     */
    formatTimeTz(
        value: Parameters<typeof formatTimeTz>[1],
        options: Parameters<typeof formatTimeTz>[2]
    ): ReturnType<typeof formatTimeTz> {
        return formatTimeTz(this, value, options);
    }

    /**
     * Returns the translation at the key value, relative to the passed context.
     *
     * @example
     * // en-US.yml
     * components:
     *     "my-component":
     *         successMessage: "It worked!"
     *         confirmDelete: "Are you sure you want to delete \"{item}\"?"
     *
     * // components/my-component/component.js
     * this.intl.tc(this, 'successMessage');
     *
     * this.intl.tc(this, 'confirmDelete', {
     *     item: 'My Item'
     * });
     */
    tc(
        context: IntlContext,
        key: string,
        options?: Parameters<typeof getTranslation>[3]
    ): ReturnType<typeof getTranslation> {
        return getTranslation(this, context, key, options);
    }

    /**
     * Checks if the two dates are the same day in the timezone set for this service
     * @param date1
     * @param date2
     * @returns True if the two dates are on the same day in the timezone set for this service; otherwise, false
     */
    isSameDay(date1: Parameters<typeof isSameDayTz>[1], date2: Parameters<typeof isSameDayTz>[2]): boolean {
        return isSameDayTz(this, date1, date2);
    }

    /**
     * Checks if the date is tomorrow in the timezone set for this service
     * @param date The date that you want to check is tomorrow
     * @returns True if the date is tomorrow in the timezone set for this service, otherwise, false
     */
    isTomorrow(date: Parameters<typeof isSameDayTz>[1]): boolean {
        const today = new Date(),
            oneDayAhead = today.setDate(today.getDate() + 1);
        return isSameDayTz(this, date, oneDayAhead);
    }

    /**
     * Checks if the date is today in the timezone set for this service
     * @param date The date that you want to check is today
     * @returns True if the date is today in the timezone set for this service, otherwise, false
     */
    isToday(date: Parameters<typeof isSameDayTz>[1]): boolean {
        return isSameDayTz(this, date, new Date());
    }

    /**
     * Checks if the date is today in the timezone set for this service
     * @param date The date that you want to check is yesterday
     * @returns True if the date is yesterday in the timezone set for this service, otherwise, false
     */
    isYesterday(date: Parameters<typeof isSameDayTz>[1]): boolean {
        const today = new Date(),
            oneDayBehind = today.setDate(today.getDate() - 1);
        return isSameDayTz(this, date, oneDayBehind);
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
    interface Registry {
        'adc-intl': ADCIntlService;
    }
}
