import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

import type ModalService from '../../../../services/modals.ts';
import type { SimpleDialogSignature } from '../../dialog-modals/simple/simple-dialog';

export interface EditConfirmationModalSignature {
    Element: HTMLDivElement;
    Args: Pick<SimpleDialogSignature['Args'], 'title'> & {
        /** Text for the confirm button. */
        buttonText: string;
        /** Triggered when the user interacts with the confirm button. */
        editConfirmed: () => boolean | Promise<any> | void;
        /** Optional CSS class for the confirm button. */
        buttonCss?: string;
        /** Called when the user interacts with the cancel button. */
        editCancelled?: () => any;
    };
    Blocks: {
        default: [];
    };
}

/**
 * @classdesc
 * Base modal for making confirmation modals such as rename or delete
 */
export default class EditConfirmationModal extends Component<EditConfirmationModalSignature> {
    @service declare modals: ModalService;

    /**
     * Message to display to user if an error is encountered during operation
     */
    @tracked errorMessage = '';

    @action async confirmAction(): Promise<boolean> {
        try {
            const result = await this.args.editConfirmed();
            if (result === false) {
                return Promise.reject();
            }

            return true;
        } catch (ex) {
            this.errorMessage = ex;
            return false;
        }
    }
}
