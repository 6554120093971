import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';
import { debounce } from '@ember/runloop';

/**
 * Small interface to define the structure of a keyword suggestion.
 */
export interface KeywordSuggestion {
    displayName: string;
    value: string;
}

/**
 * Returns the user-provided keyword in the search string, if one exists.
 */
function getKeywordInSearchString(searchString?: string): string | null {
    // Look for a desired keyword in the search string.
    const regex = /^[^=:]*(?=[=:]\s)/,
        matches = searchString?.match(regex);

    // If we found one, clean it up and return it.
    return matches ? matches[0].toLowerCase().trim() : null;
}

/**
 * Returns the keyword that is exactly matched by the user-defined keyword in the search string, if appropriate.
 */
function getMatchedKeyword(
    searchString?: string,
    keywordOptions?: Array<KeywordSuggestion>
): KeywordSuggestion | undefined {
    // If the user-provided string does not include a potential keyword,
    // then there's definitely not going to be a match.
    const keywordInSearchString = getKeywordInSearchString(searchString);
    if (!keywordInSearchString) {
        return undefined;
    }

    // Try to find a keyword in the provided suggestions that exactly matches the one in the search string.
    return keywordOptions?.find((keyword) => keyword.displayName.toLowerCase() === keywordInSearchString);
}

/**
 * Returns the version of the search string that we should display within the keyword suggestions popup.
 * This can be different from the current search string if the search string includes a keyword.
 */
function getSuggestedSearchString(searchString?: string, keywordOptions?: KeywordSuggestion[]): string {
    return (
        (getMatchedKeyword(searchString, keywordOptions)
            ? // Remove the keyword suggestion and any trailing whitespace from the search string.
              searchString?.replace(/[^=:]*[=:]\s/, '').trim()
            : // Return the original string.
              searchString?.trim()) ?? ''
    );
}

export interface KeywordSearchSignature {
    Element: HTMLInputElement;
    Args: {
        /** Represents the options for keyword suggestions. */
        keywordOptions?: KeywordSuggestion[];
        /** The keyword object to apply. */
        keyword?: KeywordSuggestion | Promise<KeywordSuggestion>;
        /** The search string to apply. */
        searchString?: string;
        /** Triggered when the search string changes. */
        applySearchFn: (searchString: string, keyword: KeywordSuggestion | null | undefined) => void;
        /** Optional CSS class applied to this element. */
        suggestionClass?: string;
    };
}

/**
 * @classdesc
 * A search bar that provides keyword suggestions.
 */
export default class KeywordSearch extends Component<KeywordSearchSignature> {
    // region Properties

    /**
     * Whether the popover for suggestions is open.
     */
    @tracked
    showKeywordSuggestionPopover = false;

    get displayName(): Promise<string> {
        return (async () => {
            const keyword = await this.args.keyword;
            return keyword?.displayName ?? '';
        })();
    }

    /**
     * Contains a list of all keyword suggestions that are matched by the input string.
     * For example, if the search string is "Name: Headquarters",
     * this would return both the "Name:" and "Company Name:" keyword suggestions.
     */
    @computed('args.{keywordOptions,searchString}')
    get matchedKeywordSuggestions(): Array<KeywordSuggestion> | null {
        const keywordInSearchString = getKeywordInSearchString(this.args.searchString);
        if (keywordInSearchString !== null) {
            // The user-provided search string includes a potential keyword. Attempt to find keyword suggestions that match.
            const matchingKeywords = this.args.keywordOptions?.filter((keywordOption) =>
                keywordOption.displayName.toLowerCase().includes(keywordInSearchString)
            );

            // If at least one keyword matches the keyword in the search string, return that.
            if (matchingKeywords?.length) {
                return matchingKeywords;
            }
        }

        // No matching suggestions were found, so return null.
        return null;
    }

    /**
     * Represents the list of keywords to display in the popup, given the current search string.
     * Note that this should account for any keywords already present in the current search string.
     * For example, if the search string is "Name: Headquarters",
     * then this should only return the "Name:" and "Company name:" keyword suggestions.
     */
    @computed('args.keywordOptions', 'matchedKeywordSuggestions')
    get suggestedKeywords(): KeywordSuggestion[] {
        // No valid keyword was found in the search string, so return all the options.
        return this.matchedKeywordSuggestions ?? this.args.keywordOptions ?? [];
    }

    /**
     * Gets the suggested search string based on the current input.
     */
    @computed('args.{keywordOptions,searchString}')
    get suggestedSearchString(): string {
        return getSuggestedSearchString(this.args.searchString, this.args.keywordOptions);
    }

    // endregion

    // region Actions

    /**
     * Updates the selected keyword and applies it to the search function.
     */
    @action
    applySuggestedKeyword(keyword: KeywordSuggestion): void {
        // Reset the suggestion popover visibility.
        this.showKeywordSuggestionPopover = false;

        // Now apply the search with the new values.
        this.applySearch(this.suggestedSearchString, keyword);
    }

    /**
     * Updates and applies the search string.
     */
    @action
    updateSearchString(event: InputEvent & { target: HTMLInputElement }): void {
        // Show the keyword suggestion popover.
        this.showKeywordSuggestionPopover = true;

        // Now apply the search function.
        this.applySearch(event.target.value, this.args.keyword);
    }

    /**
     * Applies key-based input functions.
     */
    @action
    async keyDownEventListener(event: KeyboardEvent & { target: HTMLInputElement }): Promise<void> {
        // If the user pressed escape, then we want to clear the keyword and close the popup.
        if (event.code === 'Escape') {
            await this.applySearch('', undefined, true);
            this.showKeywordSuggestionPopover = false;
        }

        // If the user is pressing enter, then they're presumably trying to apply a search.
        // However, we should have already applied the search, so instead, just close the popover.
        else if (event.code === 'Enter') {
            this.showKeywordSuggestionPopover = false;
        }

        // If the user pressed the down arrow key, then we want to re-open the popup.
        else if (event.code === 'ArrowDown') {
            // If the popup is already open, set focus on the first suggestion.
            if (this.showKeywordSuggestionPopover) {
                const elementToFocus: HTMLElement | null = document.querySelector(
                    '.keyword-suggestions button.keyword-suggestion:nth-of-type(1)'
                );
                if (elementToFocus) {
                    elementToFocus.focus();
                }
            }

            // If the popup is not already open, then open it.
            else {
                this.showKeywordSuggestionPopover = true;
            }
        }

        // If the user pressed the up arrow key with the popover window open, then we want to select the last suggestion.
        else if (event.code === 'ArrowUp' && this.showKeywordSuggestionPopover) {
            const elementToFocus: HTMLElement | null = document.querySelector(
                '.keyword-suggestions button.keyword-suggestion:last-of-type'
            );
            if (elementToFocus) {
                elementToFocus.focus();
            }
        }

        // If the user is pressing backspace at the start of the text input, then remove the keyword.
        else if (this.args.keyword && event.code === 'Backspace' && event.target.selectionStart === 0) {
            const searchString = event.target.value;

            // Reapply the search.
            await this.applySearch(searchString);
        }
    }

    /**
     * Applies the current search strings.
     */
    async applySearch(
        searchString: string,
        keyword?: KeywordSuggestion | Promise<KeywordSuggestion>,
        skipDebounceTime?: boolean
    ): Promise<void> {
        const matchedKeyword = getMatchedKeyword(searchString, this.args.keywordOptions);

        // If there's a specific keyword to apply, search on that.
        if (keyword) {
            keyword = await keyword;
        }

        // If there's no keyword applied, but there is one in the search string, then intelligently apply that keyword.
        else if (matchedKeyword) {
            searchString = getSuggestedSearchString(searchString, this.args.keywordOptions);
            keyword = matchedKeyword;
        }

        if (skipDebounceTime) {
            this.args.applySearchFn(searchString, keyword ?? null);
        } else {
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            debounce(this, this.args.applySearchFn, searchString, keyword, 200);
        }
    }

    // endregion
}
