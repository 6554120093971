import Component from '@glimmer/component';

export interface DrillThroughItemSignature {
    Element: HTMLButtonElement;
    Args: {
        /** Optional icon rendered in left of title. */
        icon?: string;
        /** Optional icon rendered in right of title (defaults to "chevron-right"). */
        drillIcon?: string;
        /** Hide optional drill icon on the right of title (defaults to false). */
        hideDrillIcon?: boolean;
        /** Optional title icon title. */
        iconTitle?: string;
        /** Optional title icon description. */
        iconDesc?: string;
        /** Indicates the title icon is hidden from a11y. */
        iconIsHiddenForAccessibility?: boolean;
        /*** Optional title text. */
        title?: string;
        /** Optional sub description rendered below title. */
        subdescription?: string;
        /** The item description to be rendered on the right, close to the drill icon. */
        descriptionRight?: string;
        /** Indicates this item should have a light gray border at the bottom. */
        borderBottom?: boolean;
        /** Triggered when the user interacts with the drill button. */
        'on-click'?: () => any;
    };
    Blocks: {
        /** The main content of the drill through component, but ignored if the `@title` was supplied. */
        content: [];
        /** The footer of the drill through component. */
        footer: [];
    };
}

/**
 * @classdesc
 * This is a contextual component with two different sections: content and footer. It helps arrange the content according to the "drill through" design requirements.
 */
export default class DrillThroughItem extends Component<DrillThroughItemSignature> {
    /**
     * Should the component render a light gray border at the bottom?
     */
    get borderBottom(): boolean {
        return this.args.borderBottom ?? true;
    }

    /**
     * Returns True if we should force ltr direction for subdescription. The criteria is that the content is a phone number.
     */
    get forceLtrForSubDescription(): boolean {
        return /^[-()+ .]?(?:[0-9]+[-()+ .]+)+[0-9]+$/.test(this.args.subdescription ?? '');
    }
}
