import Service from '@ember/service';
import { classify, dasherize } from '@ember/string';

interface ColorDescriptorObject {
    light: string;
    dark: string;
    highContrast: string;
    darkHighContrast: string;
    lightText: string;
    highContrastText: string;
}

/**
 * Class to be added on the body to activate the dark mode.
 */
export const DARK_MODE_CLASS = 'dark';

/**
 * Class to be added on the body to activate the high-contrast mode.
 */
export const HIGH_CONTRAST_MODE_CLASS = 'hc';

/**
 * High Contrast mode enum
 *
 * @private
 * @memberof AccessibilityService
 */
const HIGH_CONTRAST = 0;

/**
 * Dark mode enum
 *
 * @private
 * @memberof AccessibilityService
 */
const DARK_MODE = 1;

/**
 * Updates the root custom variables.
 *
 * @private
 * @memberof AccessibilityService
 */
function updateRootVariable(color: ColorDescriptorObject, type: string) {
    if (color) {
        const rootStyle = document.documentElement.style;

        [
            ['light', ''],
            ['highContrast', 'hc'],
            ['dark', 'dark'],
            ['darkHighContrast', 'dark-hc']
        ].forEach(([key, css]: [keyof ColorDescriptorObject, string]) => {
            const description = dasherize(classify(css) + classify(type)),
                code = color[key];

            if (code) {
                rootStyle.setProperty(`--${description}-color`, code);
            }

            if (key === 'light' || key === 'highContrast') {
                const textColor = color[`${key}Text`];
                if (textColor) {
                    rootStyle.setProperty(`--${description}-text-color`, textColor);
                }
            }
        });
    }
}

/**
 * Generates the corresponding class names for the given list of display mode features.
 *
 * @private
 * @memberof AccessibilityService
 */
function getDisplayModeClasses(featuresList: Array<number> = []): Array<string> {
    const list: string[] = [];

    featuresList.forEach((feature) => {
        switch (feature) {
            case DARK_MODE:
                list.push(DARK_MODE_CLASS);
                break;
            case HIGH_CONTRAST:
                list.push(HIGH_CONTRAST_MODE_CLASS);
                break;
            default:
                break;
        }
    });

    return list;
}

/**
 * @classdesc
 * A service for accessibility.
 */
export default class AccessibilityService extends Service {
    /**
     * The last focused element on the page.
     */
    private lastFocusedElement?: HTMLElement;

    /**
     * Caches the focused element.
     */
    saveFocusedElement(): void {
        this.lastFocusedElement = document.activeElement as HTMLElement;
    }

    /**
     * Restores the focus on the cached element.
     */
    restoreFocus(): void {
        this.lastFocusedElement?.focus();
    }

    /**
     * Sets the focus on the specified element.
     */
    focusElement(selector: string, context: Element | Document = document): boolean {
        const element = context.querySelector(selector) as HTMLElement;
        if (element) {
            element.focus();
            return true;
        }

        return false;
    }

    /**
     * Updates the custom variables with the dealer colors.
     */
    updateDealerColors(
        headerColor: ColorDescriptorObject,
        navColor: ColorDescriptorObject,
        highlightColor: ColorDescriptorObject
    ): void {
        updateRootVariable(headerColor, 'header');
        updateRootVariable(navColor, 'nav-highlight');
        updateRootVariable(highlightColor, 'highlight');
    }

    /**
     * Activates dark and/or high-contrast for the entire app.
     */
    activateDisplayMode(featuresList: Array<number>): void {
        if (!featuresList) {
            return;
        }

        const displayModeClasses = getDisplayModeClasses(featuresList);

        displayModeClasses.forEach((className) => {
            document.body.classList.add(className);
        });
    }
}
