import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { computed, action } from '@ember/object';
import DropdownSelectItem, { SELECTED, UNSELECTED } from '../utils/dropdown-select-item.js';
import { inject as service } from '@ember/service';

import type ADCIntlService from '@adc/i18n/services/adc-intl';

export interface SegmentedTimeModel {
    hour: number;
    min: number;
}

/**
 * Default configuration for time picker if no placeholder is provided
 * @private
 */
const DefaultConfig: SegmentedTimeModel = {
    hour: 0,
    min: 0
};

/**
 * Build a list of dropdown select items for a given number value range and interval
 * @private
 */
function buildDropdownOptions(
    minValue: number,
    maxValue: number,
    interval: number,
    comparisonValue: number,
    isMinute = false
): DropdownSelectItem[] {
    const valueList = [];
    for (let value = minValue; value <= maxValue; value += interval) {
        valueList.push(value);
    }

    return valueList.map((value) =>
        DropdownSelectItem.create({
            name: isMinute ? String(value).padStart(2, '0') : String(value),
            value: String(value),
            state: comparisonValue === value ? SELECTED : UNSELECTED
        })
    );
}

export interface SegmentedTimePickerSignature {
    Element: HTMLDivElement;
    Args: {
        /** The minimum possible minute value to display. */
        minMinute?: number;
        /** The maximum possible minute value to display. */
        maxMinute?: number;
        /** The interval which to increment the minute options. */
        minuteInterval?: number;
        /** The method to fire when a value is changed, allowing the parent to handle changes. */
        valueChange: (timeModel: SegmentedTimeModel, ...args: unknown[]) => void;
        /** The value of the time picker to initialize it or update it */
        value?: SegmentedTimeModel;
    };
}

/**
 * Time picker broken up into 3 segments: hour, minute, period
 * @classdesc
 */
export default class SegmentedTimePicker extends Component<SegmentedTimePickerSignature> {
    @service declare intl: ADCIntlService;

    constructor(owner: unknown, args: SegmentedTimePickerSignature['Args']) {
        super(owner, args);

        const { min, hour } = args.value ?? DefaultConfig;

        this.currentHour = this.getHourWithMeridiem(hour);
        this.currentMinute = min;
        this.isCurrentAm = hour < 12;
    }

    /**
     * The currently selected hour value
     */
    @tracked
    currentHour: number;

    /**
     * The currently selected minute value
     */
    @tracked
    currentMinute: number;

    /**
     * Whether or not the current period is set to 'AM', for use only with meridiem displays
     */
    @tracked
    isCurrentAm: boolean;

    /**
     * Get the hour factoring in if we are using meridiem time
     */
    private getHourWithMeridiem(hour: number): number {
        return hour === 0 && this.useMeridiem ? 12 : hour > 12 && this.useMeridiem ? hour - 12 : hour;
    }

    /**
     * Whether or not the format uses am/pm
     */
    get useMeridiem(): boolean {
        return !this.intl
            .formatTimeTz(new Date('2020-06-04T13:00:00.000Z'), {
                hour: 'numeric',
                minute: '2-digit',
                timeZone: 'UTC'
            })
            .includes('13');
    }

    /**
     * Get the dropdown list items for the possible hours
     */
    @computed('args.{maxHour,minHour}', 'currentHour', 'useMeridiem')
    get hourOptions(): DropdownSelectItem[] {
        return buildDropdownOptions(this.useMeridiem ? 1 : 0, this.useMeridiem ? 12 : 23, 1, this.currentHour);
    }

    /**
     * Get the dropdown list items for the possible minutes
     */
    @computed('args.{maxMinute,minMinute,minuteInterval}', 'currentMinute')
    get minuteOptions(): DropdownSelectItem[] {
        return buildDropdownOptions(
            this.args.minMinute ?? 0,
            this.args.maxMinute ?? 60,
            this.args.minuteInterval ?? 1,
            this.currentMinute,
            true
        );
    }

    /**
     * Get the dropdown list items for the possible periods
     */
    @computed('isCurrentAm')
    get periodOptions(): DropdownSelectItem[] {
        return [
            ['am', this.isCurrentAm],
            ['pm', !this.isCurrentAm]
        ].map(([value, state]: [string, boolean]) =>
            DropdownSelectItem.create({
                name: this.intl.t(`@adc/ui-components.${value}`),
                value,
                state: state ? SELECTED : UNSELECTED
            })
        );
    }

    /**
     * Get the current time configuration
     */
    @computed('currentHour', 'currentMinute', 'isCurrentAm', 'useMeridiem')
    get currentTimeModel(): SegmentedTimeModel {
        let { currentHour } = this;
        if (currentHour === 12 && this.isCurrentAm && this.useMeridiem) {
            currentHour = 0;
        }

        return {
            hour: this.useMeridiem && !this.isCurrentAm && currentHour != 12 ? currentHour + 12 : currentHour,
            min: this.currentMinute
        };
    }

    /**
     * Set the current minute or hour and pass the current time config to the passed valueChange method
     */
    @action
    changeTimeValue(key: 'currentHour' | 'currentMinute', newValue: string): void {
        this[key] = Number(newValue);
        this.args.valueChange(this.currentTimeModel);
    }

    /**
     * Set the current period and pass the current time config to the passed valueChange method
     */
    @action
    changePeriod(newPeriod: string): void {
        this.isCurrentAm = newPeriod === 'am';
        this.args.valueChange(this.currentTimeModel);
    }

    /**
     * Update the current time when the passed value is updated
     */
    @action
    updateCurrentTimeByPassedValue(): void {
        const hour = this.args.value?.hour ?? this.currentHour;

        this.isCurrentAm = hour < 12;
        this.currentHour = this.getHourWithMeridiem(hour);
        this.currentMinute = this.args.value?.min ?? this.currentMinute;
    }
}
