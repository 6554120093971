import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import { or } from '@ember/object/computed';
import { assert } from '@ember/debug';
import { toggleBodyScroll } from '../utils/general.ts';
import { isEscapeCode } from '@adc/ember-utils/utils/a11y';
import { A } from '@ember/array';

import type DomService from '@adc/ember-utils/services/dom';
import type ModalService from '../services/modals.ts';
import type { ButtonSimpleSignature } from './button/simple';

export interface ActionSheetButton {
    text: string;
    fn: VoidFunction;
    disabled?: boolean;
    color?: ButtonSimpleSignature['Args']['buttonColor'];
}

export interface ActionSheetSignature {
    Element: HTMLDivElement;
    Args: {
        /** Indicates the action sheet is open. */
        isActive: boolean;
        /** Triggered when the user wants to close the action sheet. */
        close(): void;
        /** Optional title for the action sheet. */
        title?: string;
        /** Optional URI for the image to be displayed at the bottom of the action sheet. */
        imageSrc?: string;
        /** Alt text for the bottom image (must be provided if the image src is).  */
        imageAlt?: string;
        /** Collection of buttons to render at the bottom of the body of the action sheet. When docked the buttons will appear stacked, 100% width, with primary buttons sorted to the top. When not docked the buttons will appear at the bottom, right aligned, with primary buttons sorted to the right. */
        buttons?: ActionSheetButton[] | Promise<ActionSheetButton[]>;
    };
    Blocks: {
        /** Optional block for rendering a trigger element. */
        trigger: [];
        /** Will render into the action sheet header, but only if a `@title` was not provided. */
        header: [];
        /** Will render into a space just below the action sheet header. */
        subheader: [];
        /** The body of the action sheet. */
        default: [];
        /** Will render below the body. */
        footer: [];
    };
}

/**
 * @classdesc
 * A popup container with a customizable title, sub-header, footer and content. Renders as a popup on large screens, and docked to the bottom of the screen with a max height slightly shorter than the screen on small screens. The main content will scroll if it overflows the action sheet.
 *
 * @see https://bitbucket.corp.adcinternal.com/projects/FALCOR/repos/adc-web-frontend/browse/addons/adc-ui-components/package/doc/action-sheet.md
 */
export default class ActionSheet extends Component<ActionSheetSignature> {
    @service declare media: ADCResponsiveMediaService;
    @service declare modals: ModalService;
    @service declare dom: DomService;

    @tracked isFocusTrapActive = false;

    @notEmpty('modals.modalsToRender')
    declare areAnyModalsOpen: boolean;

    curtain?: Element | null;
    closeListener?: string;

    focusTrapOptions = {
        allowOutsideClick: true,
        escapeDeactivates: false
    };

    @or('media.isMobile', 'media.isTabletPortrait')
    declare isDocked: boolean;

    @computed('args.buttons.[]', 'isDocked')
    get buttons(): Promise<ActionSheetButton[]> {
        return (async () => {
            const all = A((await this.args.buttons) ?? []),
                buttons = [...all.filterBy('color', 'primary'), ...all.rejectBy('color', 'primary')];

            return this.isDocked ? buttons : buttons.reverse();
        })();
    }

    /**
     * Called when the action sheet element is inserted.
     */
    @action opened(el: Element): void {
        // Cache curtain so we can access in closed.
        this.curtain = this.toggleCurtain(true, el);

        // Is the sheet NOT docked?
        if (!this.isDocked) {
            // Need to set focus trap here because element will not transition.
            this.isFocusTrapActive = true;

            const { close } = this.args;
            this.closeListener = this.dom.addListener(this, el, 'keyup', (evt: KeyboardEvent & { target: Element }) => {
                if (isEscapeCode(evt.code) && evt.target.tagName.toLowerCase() !== 'input') {
                    close();
                }
            });
        }
    }

    /**
     * Called when the action sheet element is about to be removed.
     */
    @action closed(): void {
        const { closeListener } = this;
        if (closeListener) {
            this.dom.removeListener(this, closeListener);
        }

        this.toggleCurtain(false);
        this.isFocusTrapActive = false;
        this.curtain = null;
    }

    private toggleCurtain(isOpen: boolean, el = this.curtain): Element {
        assert('[@adc/ui-components] Action sheet element not found', el);

        toggleBodyScroll(isOpen);

        const curtain = el.closest('.action-sheet-host')?.querySelector('.curtain');
        assert(
            '[@adc/ui-components] Action sheet found no host or curtain, are you sure the action-sheet-host is rendered?',
            curtain
        );

        curtain.classList.toggle('is-open', isOpen);
        return curtain;
    }
}
