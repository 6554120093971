import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ModalService from '../services/modals.ts';
import { action, computed } from '@ember/object';
import { addWeakListener } from '@adc/ember-utils/utils/event-listeners';

export interface ModalsHostSignature {
    Element: HTMLDivElement;
}

/**
 * @classdesc
 *
 * Component designed to handle rendering of modals
 *
 */
export default class ModalsHost extends Component<ModalsHostSignature> {
    @service declare modals: ModalService;

    /**
     * option for focus-trap modifier
     */
    focusTrapOptions = {
        escapeDeactivates: false,
        allowOutsideClick: true,
        initialFocus: this.focusSelector,
        preventScroll: true
    };

    constructor(owner: unknown, args: any) {
        super(owner, args);

        addWeakListener(this, document, 'keyup', this.modalKeyTrigger);
    }

    /**
     * Return modal model's focusSelector if it has one
     */
    @computed('modals.modalsToRender')
    get focusSelector(): string | undefined {
        const modals = this.modals.modalsToRender;
        if (!modals.lastObject?.model) {
            return;
        }

        return modals.lastObject.model.focusSelector;
    }

    /**
     * Close the modal
     */
    @action
    modalBackdropTrigger(): void {
        if (!this.modals.modalsToRender.lastObject?.model?.disableBackdropClose) {
            this.modals.closeModal();
        }
    }

    /**
     * Handle keyup events
     */
    @action
    modalKeyTrigger(event: KeyboardEvent): void {
        const { code, target } = event;
        if (code === 'Escape' && (target as HTMLElement).tagName !== 'INPUT') {
            this.modalBackdropTrigger();
        }
    }
}
