import BaseWebsocketMessage, { WebsocketMessageType } from './base.ts';

/**
 * @classdesc
 * Message class for SerializedMonitoringEvent websocket message
 */
export default class MonitoringEventWebsocketMessage extends BaseWebsocketMessage {
    constructor(unitId: string, deviceId: string, eventType: string, correlatedEventId: string) {
        super(unitId, deviceId);

        Object.assign(this, {
            subtype: eventType,
            subvalue: correlatedEventId
        });
    }

    type = WebsocketMessageType.monitoringEvent;
}
