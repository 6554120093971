import { isNumberInRange, normalizeNumberToRange } from '../../utils/common.ts';

import type { NumericRange } from '../type-utils';

export type Hue = NumericRange<0, 360>;
export type Saturation = NumericRange<0, 100>;
export type Lightness = NumericRange<50, 100>;
export type Warmth = NumericRange<0, 100>;

export interface Color {
    hue: Hue;
    baseHue?: Hue;
    saturation: Saturation;
    lightness: Lightness;
    percentWarmth?: Warmth;
}

interface LightSettings {
    color: Color;
    percentWarmthRange: { min: Warmth; max: Warmth };
}

/**
 * HSL values for the Cold, Warm and Neutral lights. The user selected color will be an interpolation between those.
 */
const warmLight: LightSettings = {
        color: { hue: 36.28 as Hue, saturation: 100, lightness: 66.27 as Lightness },
        percentWarmthRange: { min: 0, max: 40 }
    },
    neutralLight: LightSettings = {
        color: { hue: 0, saturation: 100, lightness: 100 },
        percentWarmthRange: { min: 40, max: 60 }
    },
    coldLight: LightSettings = {
        color: { hue: 213.05 as Hue, saturation: 100, lightness: 76.86 as Lightness },
        percentWarmthRange: { min: 60, max: 100 }
    };

/**
 * Gradient value for the color temperature gradient.
 */
export const TEMPERATURES_GRADIENT_VALUE = `${getHSLCss(warmLight.color)} 0%,${getHSLCss(
    neutralLight.color
)} 40%,${getHSLCss(neutralLight.color)} 60%,${getHSLCss(coldLight.color)} 100%`;

/**
 * Generates a linear gradient string in the given direction and with the specified value.
 */
export function fnGradient(direction: string, value: string): string {
    return `linear-gradient(to ${direction}, ${value})`;
}

/**
 * Calculates a new hue value using the offset and the optional base hue, wrapping as the value leaves the 0 - 360 range.
 */
export function constrainHueValue(offset: number, baseHue: Hue = 0): Hue {
    return ((baseHue + (offset % 360) + 360) % 360) as Hue;
}

/**
 * Outputs a value between leftValue and rightValue base on x.
 */
export function linearInterpolation<T = number>(x: number, leftValue: number, rightValue: number): T {
    return (leftValue + x * (rightValue - leftValue)) as T;
}

/**
 * Converts the passed HEX color string to an HSL color object.
 * @note adapted from https://css-tricks.com/converting-color-spaces-in-javascript/
 */
export function hexToHSL(hex: string): Color {
    // Convert hex to RGB first
    let r: any = 0,
        g: any = 0,
        b: any = 0;

    if (hex.length === 4) {
        r = '0x' + hex[1] + hex[1];
        g = '0x' + hex[2] + hex[2];
        b = '0x' + hex[3] + hex[3];
    } else if (hex.length === 7) {
        r = '0x' + hex[1] + hex[2];
        g = '0x' + hex[3] + hex[4];
        b = '0x' + hex[5] + hex[6];
    } else {
        throw `Invalid HEX format "${hex}"`;
    }

    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;

    const cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin;
    let hue: Hue, saturation: Saturation, lightness: Lightness;

    if (delta === 0) {
        hue = 0;
    } else if (cmax === r) {
        hue = (((g - b) / delta) % 6) as Hue;
    } else if (cmax === g) {
        hue = ((b - r) / delta + 2) as Hue;
    } else {
        hue = ((r - g) / delta + 4) as Hue;
    }

    hue = ((Math.round(hue * 60) + 360) % 360) as Hue;

    lightness = ((cmax + cmin) / 2) as Lightness;
    saturation = (delta === 0 ? 0 : delta / (1 - Math.abs(2 * lightness - 1))) as Saturation;
    saturation = +(saturation * 100).toFixed(1) as Saturation;
    lightness = +(lightness * 100).toFixed(1) as Lightness;

    return { hue, saturation, lightness };
}

/**
 * Receives the hslColor object and returns the css string describing the color in hsl format.
 */
export function getHSLCss(hslColor: any = {}): string {
    return `hsl(${hslColor.hue}, ${hslColor.saturation}%, ${hslColor.lightness}%)`;
}

/**
 * Converts the passed HSL color object to a HEX color string.
 * @note adapted from https://css-tricks.com/converting-color-spaces-in-javascript/
 */
export function hslToHex(hslColor: Color): string {
    const { hue } = hslColor;
    let { saturation, lightness } = hslColor;

    saturation /= 100;
    lightness /= 100;

    const c = (1 - Math.abs(2 * lightness - 1)) * saturation,
        x = c * (1 - Math.abs(((hue / 60) % 2) - 1)),
        m = lightness - c / 2;
    let r = 0,
        g = 0,
        b = 0;

    if (0 <= hue && hue < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (60 <= hue && hue < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (120 <= hue && hue < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (180 <= hue && hue < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (240 <= hue && hue < 300) {
        r = x;
        g = 0;
        b = c;
    } else if (300 <= hue && hue < 360) {
        r = c;
        g = 0;
        b = x;
    }

    return [r, g, b].reduce((hex, v) => {
        // Convert channel to hex, padding with zero (if necessary).
        return hex + ('00' + Math.round((v + m) * 255).toString(16)).substr(-2);
    }, '#');
}

/**
 * Determines the corresponding hsl color for the given percentWarmth value.
 */
export function hslFromPercentWarmth(percentWarmth: Warmth): Color {
    /**
     * The logic for the 3 hue ranges (blue, white and yellow).
     * Each object contains a matching function which tells if the percentWarmth is in the hue range,
     * and a function which does the math and return the hsl color for the percentWarmth value.
     */
    const hueRanges = [
        // Warm Light Range
        {
            includes: (percentWarmth: Warmth) => isNumberInRange(percentWarmth, warmLight.percentWarmthRange),
            getColorForPercentWarmth: (percentWarmth: Warmth) => {
                // Keeps the cold light hue and saturation, and interpolates between the white and warm light lightness.
                const interpolatedLightness = linearInterpolation<Lightness>(
                    normalizeNumberToRange(percentWarmth, warmLight.percentWarmthRange) as number,
                    warmLight.color.lightness,
                    neutralLight.color.lightness
                );

                return { ...warmLight.color, lightness: interpolatedLightness };
            }
        },
        // Neutral Light Range
        {
            includes: (percentWarmth: Warmth) => isNumberInRange(percentWarmth, neutralLight.percentWarmthRange),
            getColorForPercentWarmth: () => neutralLight.color
        },
        // Cold Light Range
        {
            includes: (percentWarmth: Warmth) => isNumberInRange(percentWarmth, coldLight.percentWarmthRange, true),
            getColorForPercentWarmth: (percentWarmth: Warmth) => {
                // Keeps the cold light hue and saturation, and interpolates between the cold and white light lightness.
                const interpolatedLightness = linearInterpolation<Lightness>(
                    normalizeNumberToRange(percentWarmth, coldLight.percentWarmthRange) as number,
                    neutralLight.color.lightness,
                    coldLight.color.lightness
                );

                return { ...coldLight.color, lightness: interpolatedLightness };
            }
        }
    ];
    const hueRange = hueRanges.find((range) => range.includes(percentWarmth));

    if (!hueRange) {
        return neutralLight.color;
    }

    return hueRange.getColorForPercentWarmth(percentWarmth);
}

/**
 * Returns the passed kelvin scale color value to a HEX color string.
 */
export function percentWarmthToHexString(percentWarmth: Warmth): string {
    return hslToHex(hslFromPercentWarmth(percentWarmth));
}
