import templateOnlyComponent from '@ember/component/template-only';

export interface DropdownActionsResponsiveHeaderSignature {
    Args: {
        /** The default yield from the popover menu (header, body, footer). */
        dropdownWrapper: any;
        /** The popover menu header text. */
        title?: string;
        /** Triggers when the popover header is clicked. */
        'on-click': VoidFunction;
        /** The icon to show in popover header (defaults to "close"). */
        icon?: string;
        /** The icon title. */
        iconTitle?: string;
        /** The icon description. */
        iconDesc?: string;
        /** Indicates the icon is hidden for a11y purposes. */
        iconIsHiddenForAccessibility?: boolean;
    };
    Blocks: {
        default: [];
    };
}

const DropdownActionsResponsiveHeader = templateOnlyComponent<DropdownActionsResponsiveHeaderSignature>();
export default DropdownActionsResponsiveHeader;
