import BaseModel from './base-model.ts';
import { attr, hasMany } from '@ember-data/model';

import type MicroApiEndpoint from './micro-api-endpoint.ts';

/**
 * @classdesc
 * Holds Micro API endpoint data items and token version
 */
export default class MicroApiDataItem extends BaseModel {
    /**
     * List of avialable MicroAPI endpoints
     */
    @hasMany('micro-api-endpoint', { async: false, inverse: null })
    declare microApiEndpoints: MicroApiEndpoint[];

    /**
     * The version of the MicroAPI token being used
     */
    @attr('string')
    declare microApiTokenVer: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'micro-api-data-item': MicroApiDataItem;
    }
}
