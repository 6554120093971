
import { importSync as i } from '@embroider/macros';
let d = window.define;
d("ember-get-config/index", function(){ return i("ember-get-config/index");});
d("ember-intl/-private/error-types", function(){ return i("ember-intl/-private/error-types");});
d("ember-intl/-private/formatters/-base", function(){ return i("ember-intl/-private/formatters/-base");});
d("ember-intl/-private/formatters/format-date", function(){ return i("ember-intl/-private/formatters/format-date");});
d("ember-intl/-private/formatters/format-list", function(){ return i("ember-intl/-private/formatters/format-list");});
d("ember-intl/-private/formatters/format-message", function(){ return i("ember-intl/-private/formatters/format-message");});
d("ember-intl/-private/formatters/format-number", function(){ return i("ember-intl/-private/formatters/format-number");});
d("ember-intl/-private/formatters/format-relative", function(){ return i("ember-intl/-private/formatters/format-relative");});
d("ember-intl/-private/formatters/format-time", function(){ return i("ember-intl/-private/formatters/format-time");});
d("ember-intl/-private/formatters/index", function(){ return i("ember-intl/-private/formatters/index");});
d("ember-intl/-private/utils/empty-object", function(){ return i("ember-intl/-private/utils/empty-object");});
d("ember-intl/-private/utils/flatten", function(){ return i("ember-intl/-private/utils/flatten");});
d("ember-intl/-private/utils/get-dom", function(){ return i("ember-intl/-private/utils/get-dom");});
d("ember-intl/-private/utils/hydrate", function(){ return i("ember-intl/-private/utils/hydrate");});
d("ember-intl/-private/utils/is-array-equal", function(){ return i("ember-intl/-private/utils/is-array-equal");});
d("ember-intl/-private/utils/missing-message", function(){ return i("ember-intl/-private/utils/missing-message");});
d("ember-intl/-private/utils/normalize-locale", function(){ return i("ember-intl/-private/utils/normalize-locale");});
d("ember-intl/-private/utils/parse", function(){ return i("ember-intl/-private/utils/parse");});
d("ember-intl/helpers/format-date", function(){ return i("ember-intl/helpers/format-date");});
d("ember-intl/helpers/format-list", function(){ return i("ember-intl/helpers/format-list");});
d("ember-intl/helpers/format-message", function(){ return i("ember-intl/helpers/format-message");});
d("ember-intl/helpers/format-number", function(){ return i("ember-intl/helpers/format-number");});
d("ember-intl/helpers/format-relative", function(){ return i("ember-intl/helpers/format-relative");});
d("ember-intl/helpers/format-time", function(){ return i("ember-intl/helpers/format-time");});
d("ember-intl/helpers/t", function(){ return i("ember-intl/helpers/t");});
d("ember-intl/index", function(){ return i("ember-intl/index");});
d("ember-intl/macros/index", function(){ return i("ember-intl/macros/index");});
d("ember-intl/macros/intl", function(){ return i("ember-intl/macros/intl");});
d("ember-intl/macros/t", function(){ return i("ember-intl/macros/t");});
d("ember-intl/services/intl", function(){ return i("ember-intl/services/intl");});
d("ember-intl/template-registry", function(){ return i("ember-intl/template-registry");});
d("ember-intl/translations", function(){ return i("ember-intl/translations");});
import "ember-get-config/-embroider-implicit-modules.js";
import "ember-intl/-embroider-implicit-modules.js";
