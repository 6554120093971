import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { A } from '@ember/array';
import { camelize } from '@ember/string';
import { isHTMLSafe } from '@ember/template';
import { assert } from '@ember/debug';
import { hasHTML } from '../utils/general.ts';

import type { SafeString } from 'handlebars';
import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { SvgSymbolSignature } from '@adc/svg-system/components/svg-symbol';
import type { InternalOwner } from '@ember/-internals/owner';

type NotificationType = 'info' | 'success' | 'warning' | 'error' | 'session-ended' | 'custom';
export type AddNotificationMethodType = 'addInfo' | 'addSuccess' | 'addWarning' | 'addError' | 'addSessionEnded';

export interface AppNotificationButton {
    text: string;
    icon?: string;
    iconTitle?: string;
    iconDesc?: string;
    iconIsHiddenForAccessibility?: boolean;
    iconOnly?: boolean;
    cssClass?: string;
    noBackground?: boolean;
    elementToFocus?: HTMLElement;
    action?: (buttonAnimation?: Promise<unknown>) => any;
}

export interface AppNotification {
    type: NotificationType;
    icon: string;
    text: string | SafeString;
    hidden: boolean;
    iconTitle?: string;
    iconFillColor?: SvgSymbolSignature['Args']['fillColor'];
    persistent?: boolean;
    autoCloseDuration?: number;
    iconDesc?: string;
    iconIsHiddenForAccessibility?: boolean;
    buttons?: AppNotificationButton[];
    cssClass?: string;
    elementToFocus?: HTMLElement;
}

type NotificationManagerConfig = Record<NotificationType, Partial<AppNotification>>;

/**
 * Creates a notification type.
 *
 * @private
 */
function createNotificationType(
    this: NotificationManager,
    type: NotificationType,
    icon: string,
    persistent = false,
    autoCloseDuration?: number
): AppNotification {
    // Create default notification config.
    const n = {
            type,
            icon,
            iconTitle: this.intl.t(`@adc/ui-components.${camelize(type)}`),
            persistent,
            autoCloseDuration
        },
        owner = getOwner(this),
        config = (owner && (owner as InternalOwner).resolveRegistration('config:environment')) ?? {};

    if ('notificationManager' in config) {
        Object.assign(n, (config.notificationManager as NotificationManagerConfig)[type] ?? {});
    }

    return n as AppNotification;
}

/**
 * Adds notification from predefined notification type.
 *
 * @private
 */
function addNotificationFromType(
    this: NotificationManager,
    text: string | SafeString,
    notificationType: AppNotification,
    autoCloseDurationOverride?: number,
    elementToFocus?: HTMLElement
): AppNotification | undefined {
    if (autoCloseDurationOverride !== undefined && isNaN(autoCloseDurationOverride)) {
        autoCloseDurationOverride = undefined;
    }

    if (elementToFocus && !(elementToFocus instanceof Element)) {
        elementToFocus = undefined;
    }

    return addNotification.call(this, text, {
        ...notificationType,
        autoCloseDuration:
            autoCloseDurationOverride !== undefined ? autoCloseDurationOverride : notificationType.autoCloseDuration,
        hidden: false,
        elementToFocus
    });
}

/**
 * Deletes any duplicates of the given notification.
 *
 * @private
 */
function returnDedupedNotifications(this: NotificationManager, newNotification: AppNotification): AppNotification[] {
    const { text, type } = newNotification;

    if (text && type) {
        // Remove the duplicate notifications. Non-mutating returns a new array
        return this.notifications.reject((notification) => {
            // Note: have to compare SafeStrings here so using string property.
            return notification.text.toString() === text.toString() && notification.type === type;
        });
    }
    return this.notifications;
}

/**
 * Adds a notification to the notification manager by triggering a trigger.
 *
 * @private
 */
function addNotification(
    this: NotificationManager,
    text: string | SafeString,
    appNotification: Omit<AppNotification, 'text'>
): AppNotification | undefined {
    // Are notifications currently muted?
    if (this.isMuted) {
        // Is this DEV environment?
        const owner = getOwner(this),
            config = (owner && (owner as InternalOwner).resolveRegistration('config:environment')) ?? {};

        if ('environment' in config && config.environment === 'development') {
            // Log the muted notification.
            console.log(`Muted ${appNotification.type} notification: ${text}`);
        }

        // Do not add the notification.
        return;
    }

    if (!isHTMLSafe(text) && hasHTML(text)) {
        const msg =
            '[@adc/ui-components] The notification text contains HTML characters but is not HTML safe.  Please mark text as safe, but only if it does not include any user entered data.';
        console.log(msg);
        console.log('failing text:', text);
        assert(msg, false);
    }

    const newNotification: AppNotification = {
        ...appNotification,
        text,
        // Convert false to 0ms to keep notification open until dismissed.
        autoCloseDuration: appNotification.autoCloseDuration ?? 0
    };

    // If there are duplicates that already exist, remove them.
    // Add the notification to the array.
    this.notifications = A([...returnDedupedNotifications.call(this, newNotification), newNotification]);
    return newNotification;
}

/**
 * @classdesc
 * Service that manages adding/removing notifications for an application.
 */
export default class NotificationManager extends Service {
    @service declare intl: ADCIntlService;

    // region Notification Types

    private get infoType(): AppNotification {
        return createNotificationType.call(this, 'info', 'info');
    }

    private get successType(): AppNotification {
        return createNotificationType.call(this, 'success', 'circle-o-check', true, 5000);
    }

    private get warningType(): AppNotification {
        return createNotificationType.call(this, 'warning', 'warning');
    }

    private get errorType(): AppNotification {
        return createNotificationType.call(this, 'error', 'issue');
    }

    private get sessionEndedType(): AppNotification {
        return createNotificationType.call(this, 'session-ended', 'issue', true, 0);
    }

    // endregion

    // region Properties

    /**
     * A list of currently displayed notifications.
     */
    @tracked notifications: ReturnType<typeof A<AppNotification>> = A<AppNotification>([]);

    /**
     * Determines if the notifications should be ignored.
     *
     * @note If the session is ended, this flag should be set to false using the addSessionEnded() method so no new notifications will be added.
     * New notifications are likely errors caused by the lack of session, and we don't want multiple of these to display during the logout process.
     */
    @tracked isMuted = false;

    // endregion

    // region Add/Remove Notification Methods

    /**
     * Adds an 'info' notification to the notification manager.
     */
    @action addInfo(
        text: string | SafeString,
        autoCloseDurationOverride?: number,
        elementToFocus?: HTMLElement
    ): AppNotification | undefined {
        return addNotificationFromType.call(this, text, this.infoType, autoCloseDurationOverride, elementToFocus);
    }

    /**
     * Adds a 'success' notification to the notification manager.
     */
    @action addSuccess(
        text: string | SafeString,
        autoCloseDurationOverride?: number,
        elementToFocus?: HTMLElement
    ): AppNotification | undefined {
        return addNotificationFromType.call(this, text, this.successType, autoCloseDurationOverride, elementToFocus);
    }

    /**
     * Adds a 'warning' notification to the notification manager.
     */
    @action addWarning(
        text: string | SafeString,
        autoCloseDurationOverride?: number,
        elementToFocus?: HTMLElement
    ): AppNotification | undefined {
        return addNotificationFromType.call(this, text, this.warningType, autoCloseDurationOverride, elementToFocus);
    }

    /**
     * Adds an 'error' notification to the notification manager.
     */
    @action addError(
        text: string | SafeString,
        autoCloseDurationOverride?: number,
        elementToFocus?: HTMLElement
    ): AppNotification | undefined {
        return addNotificationFromType.call(this, text, this.errorType, autoCloseDurationOverride, elementToFocus);
    }

    /**
     * Adds a notification telling the user that their session has ended.
     *
     * @note This sets a flag that does not allow any more notifications to be added.
     */
    @action addSessionEnded(
        text: string | SafeString,
        autoCloseDurationOverride?: number,
        elementToFocus?: HTMLElement
    ): void {
        addNotificationFromType.call(this, text, this.sessionEndedType, autoCloseDurationOverride, elementToFocus);

        // Mute notifications so service knows not to show any more application notifications.
        this.mute();
    }

    /**
     * Adds a notification to the notification manager.
     *
     * @param text - Text to show in the notification.
     * @param icon - Icon for the notification.
     * @param buttons - Buttons to render in the notification
     * @param [autoCloseDuration] - How long should the notification stay open? (in ms)
     * @param [hidden] - Determines if the notification persists is currently shown or hidden.
     * @param [persistent] - Determines if the notification persists through route transitions or is cleared.
     * @param [cssClass] - Css class to be added to the notification.
     * @param [iconTitle] - The icon's title for the notification.
     * @param [iconDesc] - The icon's description for the notification.
     * @param [iconIsHiddenForAccessibility] - Is the icon for the notification hidden for screen readers.
     * @param [elementToFocus] - The element to focus after the notification is closed.
     */
    @action addNotification(
        text: string | SafeString,
        icon: string,
        buttons?: AppNotificationButton[],
        autoCloseDuration?: number,
        persistent?: boolean,
        hidden?: boolean,
        cssClass?: string,
        iconTitle?: string,
        iconDesc?: string,
        iconIsHiddenForAccessibility?: boolean,
        elementToFocus?: HTMLElement
    ): AppNotification | undefined {
        return addNotification.call(this, text, {
            icon,
            type: 'custom',
            buttons,
            autoCloseDuration,
            persistent,
            hidden: !!hidden,
            cssClass,
            iconTitle,
            iconDesc,
            iconIsHiddenForAccessibility,
            elementToFocus
        });
    }

    /**
     * Removes the given notification from the list of notifications.
     */
    @action removeNotification(notification: AppNotification): void {
        this.notifications.removeObject(notification);
    }

    /**
     * Clears any notifications that are NOT persistent.
     */
    @action clearNonPersistentNotifications(): void {
        this.notifications = A<AppNotification>(this.notifications.rejectBy('persistent', false));
    }

    /**
     * Clears all notifications.
     */
    @action clearNotifications(): void {
        this.notifications = A<AppNotification>([]);
    }

    // endregion

    // region Methods to Update Properties

    /**
     * Hides any currently shown notifications.
     */
    @action hideNotifications(): void {
        this.notifications.setEach('hidden', true);
    }

    /**
     * Shows any currently hidden notifications.
     */
    @action showNotifications(): void {
        this.notifications.setEach('hidden', false);
    }

    /**
     * Mutes (i.e. ignores) notifications so they do not render on screen.
     */
    @action mute(): void {
        this.isMuted = true;
    }

    /**
     * Disabled notification muting.
     */
    @action unMute(): void {
        this.isMuted = false;
    }

    // endregion
}
