import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import getTranslation from '../utils/get-translation.ts';

import type ADCIntlService from '../services/adc-intl.ts';
import type { IntlContext, IntlTextOptions } from '../utils/get-translation.ts';

export interface TcSignature {
    Args: {
        Positional: [IntlContext, string];
        Named?: IntlTextOptions;
    };
    Return: ReturnType<typeof getTranslation>;
}

/**
 * @classdesc
 * Translation helper that allows specifying context.
 * More sophisticated version of the translation lookup, allows passing in context.
 * The benefit is that if the context is passed in, then the namespace of the context is used to prepend before key.
 */
export default class TcHelper extends Helper<TcSignature> {
    @service declare intl: ADCIntlService;

    constructor(properties?: object) {
        super(properties);

        // @ts-expect-error: Property 'onLocaleChanged' is private and only accessible within class 'IntlService'.  This is how the native `t` helper works as well.
        this.intl.onLocaleChanged(this.recompute, this);
    }

    compute([context, key]: [IntlContext, string], named?: IntlTextOptions): ReturnType<typeof getTranslation> {
        return getTranslation(this.intl, context, key, named);
    }
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Returns the translation at the provided key, relative to the passed context.
         */
        tc: typeof TcHelper;
    }
}
