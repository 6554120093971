import BasePikaday from './date-time-picker/base-pikaday.js';
import { action, set } from '@ember/object';
import { isEmpty } from '@ember/utils';

/**
 * @classdesc
 * Text input control that will open the calendar picker on click, then host the selected date as text.
 */
export default class PikadayInputComponent extends BasePikaday {
    /** @override */
    onPikadayOpen() {
        this.onOpen();
    }

    /** @override */
    onPikadayClose() {
        if (this.pikaday.getDate() === null || isEmpty(this.field.value)) {
            set(this, 'value', null);
            this.onSelection(null);
        }

        this.onClose();
    }

    @action initPikaday(el) {
        set(this, 'field', el);
        this.setupPikaday();

        // Update configuration.
        this.setMinDate();
        this.setMaxDate();
        this.setPikadayDate();

        if (this.options) {
            this.updateOptions();
        }

        this.hidePikaday();
    }

    @action hidePikaday() {
        if (this.disabled) {
            this.pikaday?.hide();
        }
    }
}
