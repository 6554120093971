import Component from '@glimmer/component';
import { set, computed, action } from '@ember/object';
import { once } from '@ember/runloop';
import { A } from '@ember/array';
import { isEnterCode } from '@adc/ember-utils/utils/a11y';

import type { CheckboxTreeItemSignature } from './checkbox-tree/tree-item';

type CheckboxTreeItemSignatureArgs = CheckboxTreeItemSignature['Args'];
type CheckboxTreeItem = CheckboxTreeItemSignatureArgs['item'];

export interface CheckboxTreeSignature {
    Element: HTMLUListElement;
    Args: Pick<CheckboxTreeItemSignatureArgs, 'on-action-execute' | 'showGroupSeparator' | 'hideSubitems'> & {
        /** The items to render. */
        items: CheckboxTreeItem[];
        /** Optional prefix added to the `@index` argument of each checkbox tree item. */
        idPrefix?: string;
        /** Triggered when tree item selection state changes. */
        'items-changed'?: (items: CheckboxTreeItem[]) => void;
        /** Indicates the UL element is focusable (key up enter will expand/collapse tree). */
        isFocusable?: boolean;
    };
    Blocks: {
        default: [];
    };
}

/**
 * @classdesc
 * A component for rendering a tree hierarchy of checkbox items.
 *
 * This component specifically acts as the middle man between the consumer and the rendering of the tree.
 *
 * @note There is no internal copy of "items", so this component actually manipulates the
 *       data passed into it. This was the cleanest way to keep track of external changes,
 *       while ensuring computed properties still fire correctly. Complications
 *       can arise with computed-properties when you make deep copies of the objects
 *       they observe, because the copy of the item changed, not the original item the observer
 *       is bound to.
 */
export default class CheckboxTree extends Component<CheckboxTreeSignature> {
    /**
     * Should the element be focusable?
     */
    get isFocusable(): boolean {
        return this.args.isFocusable ?? true;
    }

    /**
     * Should the list items be focusable or not?
     */
    @computed('args.items.[]')
    get areListItemsFocusable(): boolean {
        return this.args.items?.length > 1;
    }

    // endregion

    // region Actions

    /**
     * Collapses items if user hits the enter key.
     */
    @action handleKeyUp(event: KeyboardEvent & { target: HTMLElement }): void {
        if (isEnterCode(event.code)) {
            const checkbox = event.target.closest('li.detailed-checkbox');
            if (!checkbox) {
                return;
            }

            const item = A(this.args.items).findBy('value', (checkbox as HTMLElement).dataset.value);
            if (item?.isCollapsible) {
                set(item, 'isCollapsed', !item.isCollapsed);
            }
        }
    }

    /**
     * Updates the "items-changed" action for passed items.
     */
    @action updateItemAction(): void {
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        once<CheckboxTree, typeof storeActionOnRootItems>(this, storeActionOnRootItems);
    }

    // endregion
}

/**
 * The name of the action used for notifying the consuming code that the state of the items has changed.
 * @public
 */
export const ITEMS_CHANGED_ACTION = 'items-changed';

/**
 * Stores the "items-changed" action that was provided by the consumer on the root items of the tree.
 *
 * @note When the items change state, the root items are the last to
 *       update and they will trigger this action.
 *
 * @private
 */
function storeActionOnRootItems(this: CheckboxTree) {
    const itemsChangedAction = this.args[ITEMS_CHANGED_ACTION];
    if (itemsChangedAction) {
        const { items } = this.args;
        A(items).setEach(ITEMS_CHANGED_ACTION, () => itemsChangedAction(items));
    }
}
