import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPromise } from '@adc/ember-utils/utils/rsvp';
import { isFunction } from '@adc/ember-utils/utils/general';
import { Promise as EmberPromise } from 'rsvp';
import { tracked } from '@glimmer/tracking';
import { scheduleOnce } from '@ember/runloop';

import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { TextInputSignature } from './text-input';

type TextInputSignatureArgs = TextInputSignature['Args'];

/**
 * Action name constants to be triggered from inside this component.
 */
const GENERATE_PIN_ACTION = 'get-random-pin',
    PIN_CHANGED_ACTION = 'value-change';

interface PinInputSignature {
    Element: TextInputSignature['Element'];
    Args: Pick<TextInputSignatureArgs, 'errorMessage' | 'errorTooltipPlace' | 'autoDestroy'> & {
        /** The pin code input value. */
        value?: string;
        /** Flag to specify whether to show the pin code as it is, or hide it with asterisk icons. */
        isCodeVisible?: boolean;
        /** Should only numbers be allowed as input? */
        onlyAllowNumbers?: boolean;
        /** The minimum pin code length. */
        minPinLength?: number;
        /** The maximum pin code length. */
        maxPinLength?: number;
        /** Indicates the input and the generate pin button are disabled. */
        disabled?: boolean;
        /** Indicates the pin-input component is disabled. */
        disablePinInput?: boolean;
        /** Indicates the generate pin button is disabled. */
        disableGeneratePinButton?: boolean;
        /** Optional text for the generate button (defaults to generate). */
        buttonLabel?: string;
        /** Triggered when the user interacts with the generate code button. */
        [GENERATE_PIN_ACTION]?: () => any;
        /** Triggered when the pin value was changed. */
        [PIN_CHANGED_ACTION]?: (value?: string) => any;
        /** Text to be displayed above the input field. */
        description?: string;
        /** Class name for the pin input container. */
        containerClass?: string;
    };
}

/**
 * @classdesc
 * The purpose of this component is to show or read a pin code from the user.
 */
export default class PinInput extends Component<PinInputSignature> {
    // region Services

    @service declare intl: ADCIntlService;

    // endregion

    constructor(owner: unknown, args: PinInputSignature['Args']) {
        super(owner, args);

        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        scheduleOnce('afterRender', this, this.onExternalValueChange);
    }

    // region Internal Properties

    /**
     * The internal value of the pin input.
     */
    @tracked internalValue?: string;

    /**
     * Whether or not the code should be hidden with asterisks.
     *
     * @note When set to false, 4 asterisks are shown regardless of the value property.
     */
    get isCodeVisible(): boolean {
        return this.args.isCodeVisible ?? true;
    }

    /**
     * Should only numbers be allowed as input?
     */
    get onlyAllowNumbers(): boolean {
        return this.args.onlyAllowNumbers ?? true;
    }

    // endregion

    // region Computed Properties

    /**
     * Generates the string to be used as a placeholder for the input field.
     */
    @computed('args.minPinLength')
    get placeholder(): string {
        const { minPinLength } = this.args,
            placeholderDigitsNumber: number = minPinLength && minPinLength > 0 ? minPinLength : 4;
        let placeholderString = '';

        for (let count = 0; count < placeholderDigitsNumber; count++) {
            placeholderString += '-';
        }

        return placeholderString;
    }

    /**
     * Text value to be shown on generate random code button. This property is translated locally for i18n support.
     */
    @computed('args.buttonLabel')
    get buttonLabelInternal(): string {
        return this.args.buttonLabel || this.intl.t('@adc/ui-components.generate');
    }

    // endregion

    /**
     * Calls the provided <GENERATE_PIN_ACTION> action and updates the value with the result. If the function returns a promise, the "Generate Button" styling will update according the promise status.
     *
     * @return {undefined|Promise}
     */
    @action
    generateRandomPin(): void {
        const generatePinMethod = this.args[GENERATE_PIN_ACTION];

        if (generatePinMethod && isFunction(generatePinMethod)) {
            const generateMethodResult = generatePinMethod(),
                resultPromise = isPromise(generateMethodResult)
                    ? generateMethodResult
                    : EmberPromise.resolve(generateMethodResult);

            resultPromise.then((newValue: string) => this.debouncedValueChange(newValue));

            return resultPromise;
        }
    }

    /**
     * Validates the new value of the input and calls the "value-change" action.
     *
     * @param newValue
     */
    @action
    debouncedValueChange(newValue: string): void {
        const onChange = this.args[PIN_CHANGED_ACTION];

        this.internalValue = newValue;

        if (onChange && isFunction(onChange)) {
            onChange(this.internalValue);
        }
    }

    /**
     * Updates the internal value when the external value changes.
     */
    @action
    onExternalValueChange(): void {
        const { value } = this.args;

        this.internalValue = value;
    }
}
