import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import type { TextInputSignature } from './text-input.ts';

export interface PasswordInputSignature {
    Element: TextInputSignature['Element'];
    Args: Pick<
        TextInputSignature['Args'],
        'value' | 'value-change' | 'errorMessage' | 'showErrorIcon' | 'containerClass'
    > & { onClick?: VoidFunction };
}

/**
 * @classdesc
 * Component designed for a password input.
 */
export default class PasswordInputComponent extends Component<PasswordInputSignature> {
    /**
     * Is the password masked?
     */
    @tracked isPasswordMasked = true;
}
