import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isDestroyed } from '@adc/ember-utils/utils/ember-helpers';

import type { AppNotification } from '../services/notification-manager';
import type NotificationManagerService from '../services/notification-manager';

export interface NotificationManagerSignature {
    Element: HTMLDivElement;
    Args: {
        /** Indicates automated tests are running, which will disable notification closing delay to speed up tests. */
        isTesting?: boolean;
        /** Indicates all notifications should be hidden. */
        isHidden?: boolean;
        /** Optional element to focus on after notification closes. */
        lastFocusedElement?: HTMLElement;
    };
}

/**
 * @classdesc
 * A container for the number of notifications that might be displayed within an application.
 */
export default class NotificationManager extends Component<NotificationManagerSignature> {
    @service declare notificationManager: NotificationManagerService;

    /**
     * Tells the notification-manager service to remove the given notification.
     *
     * @param notification - The notification to close.
     * @param [elementToFocus] - The default element to focus on.
     */
    @action closeNotification(notification: AppNotification, elementToFocus?: HTMLElement): void {
        const element = notification.elementToFocus ?? elementToFocus;
        if (element) {
            element.focus();
        }

        if (!isDestroyed(notification)) {
            this.notificationManager.removeNotification(notification);
        }
    }
}
