import BaseWebsocketMessage, { WebsocketMessageType } from './base.ts';

/**
 * @classdesc
 * Message class for SerializedPropertyChange websocket message
 */
export default class PropertyChangeWebsocketMessage extends BaseWebsocketMessage {
    constructor(unitId: string, deviceId: string, property: string, propertyValue: string) {
        super(unitId, deviceId);

        Object.assign(this, {
            subtype: property,
            value: propertyValue
        });
    }

    type = WebsocketMessageType.propertyChange;
}
