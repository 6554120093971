import { registerDestructor } from '@ember/destroyable';

const listenerCache = 'appEventListeners';

type ListenerConfig = {
    id: string;
    element?: Node | Window | Document;
    event: string;
    fnEvent?: EventListener;
    eventOptions: AddEventListenerOptions;
    initialized: number;
};

declare global {
    interface Window {
        [listenerCache]: Record<string, ListenerConfig>;
    }
}

/**
 * Returns unix timestamp in seconds. Don't care about the timezone here so this is ok.
 */
function getTimestamp(): number {
    return Math.round(new Date().getTime() / 1000);
}

/**
 * Schedules garbage collection run.
 */
function runGarbageCollection(): void {
    // Run GC in 20 seconds.
    setTimeout(() => {
        const callback = window.requestIdleCallback || window.requestAnimationFrame;

        callback(() => {
            const listeners = getEventListenersObject(),
                keys = Object.keys(listeners),
                count = keys.length,
                timestamp = getTimestamp();

            for (let i = 0; i < count; i++) {
                const { id, element, initialized } = listeners[keys[i]];

                // Do not check the listener if it was initialized less than 30 seconds ago.
                if (!initialized || timestamp < initialized + 30) {
                    continue;
                }

                if (!element || element === window || element === document) {
                    // Do nothing if the element is window or document, because we cannot collect those.
                    continue;
                }

                // If the element is still in the DOM, it is all good.
                if (document.body.contains(element as Node)) {
                    continue;
                }

                // We should garbage collect, remove listener.
                console.warn('Garbage collecting listener for element no longer in DOM', element);

                removeListener(id);
            }

            // Call yourself again.
            runGarbageCollection();
        });
    }, 20000);
}

/**
 * Gets event listeners object.
 */
function getEventListenersObject(): Record<string, ListenerConfig> {
    // Ensure that event listeners object is instantiated.
    if (!window[listenerCache]) {
        window[listenerCache] = {} as Record<string, ListenerConfig>;

        // Initialize garbage collection in case we mess stuff up.
        runGarbageCollection();
    }

    return window[listenerCache];
}

/**
 * Returns a unique id for a new event.
 */
function generateEventId(): string {
    const { crypto } = window,
        values = new Uint32Array(4);

    crypto.getRandomValues(values);

    const id = Array.from(values).join('-');

    // Sanity check to make sure that the id is ok.
    // Not including a stop clause, because the probability of us not getting it right on one or two tries is very low.
    if (getEventListenersObject()[id]) {
        return generateEventId();
    }

    return id;
}

/**
 * Adds new event listener to element with specified properties, that will be destroyed automatically when the passed context is destroyed.
 *
 * @param element
 * @param event - Event which should trigger function.
 * @param fn - Function to call when event triggers.
 * @param isOnce - Should the event be triggered at most once?
 * @param capture - Should capture method be used?
 * @param passive - Should the event be captured passively?
 *
 * @returns The id of the event so that it can be referenced for removal.
 */
export function addWeakListener(
    destroyable: any,
    element: Node | Window | Document,
    event: string,
    fn: EventListener,
    isOnce = false,
    capture = false,
    passive?: boolean
): string {
    // Passive events should be captured passively, if possible.
    if (passive === undefined && ['scroll', 'touchmove', 'touchstart', 'touchend', 'resize'].includes(event)) {
        passive = true;
    }

    // Generate id for this listener.
    const id = generateEventId();

    // Create options for the event listener.
    const eventOptions: AddEventListenerOptions = {
        capture,
        passive
    };

    if (isOnce) {
        eventOptions.once = true;
    }

    // If the event should be handled only once, we need to alter the calling function a bit so that we can remove the even after it was called once.
    const fnEvent = isOnce
        ? (evt: Event) => {
              // Remove listener.
              removeListener(id);

              // Call original function.
              return fn(evt);
          }
        : fn;

    // Add listener to the element.
    element.addEventListener(event, fnEvent, eventOptions);

    // Add everything to the events cache.
    getEventListenersObject()[id] = {
        id,
        element,
        event,
        fnEvent,
        eventOptions,
        initialized: getTimestamp()
    };

    // Register destructor so listener is removed when context is destroyed.
    registerDestructor(destroyable, () => removeListener(id));

    return id;
}

/**
 * Removes event listener associated with the specified id.
 */
export function removeListener(id: string): void {
    let data = getEventListenersObject()[id];
    if (!data) {
        // Listener was probably already removed.
        return;
    }

    // Remove listener.
    data.element?.removeEventListener(data.event, data.fnEvent as EventListener, data.eventOptions);

    // Clean up.
    delete data.element;
    delete data.fnEvent;
    data = {} as ListenerConfig;

    delete getEventListenersObject()[id];
}
