import Service from '@ember/service';
import { A } from '@ember/array';
import { registerDestructor } from '@ember/destroyable';
import { addWeakListener, removeListener } from '../utils/event-listeners.ts';

const CACHE_NAME = '_adcDomListeners',
    ON_CLOSE_CLICK_LISTENER_KEY = '_adcCloseOnClickListenerId';

/**
 * @classdesc
 * A service for adding/removing/maintaining DOM listeners.
 */
export default class DomService extends Service {
    /**
     * Adds new event listener and caches for automatic clearing during tear down.
     *
     * @param destroyable - The object that requested the listener.
     * @param element - The element to attach the listener to.
     * @param event - Event which should trigger function.
     * @param fn - Function to call when event triggers.
     * @param isOnce - Should the event be triggered at most once?
     * @param capture - Should capture method be used?
     * @param passive - Should the event be captured passively?
     */
    addListener(
        destroyable: any,
        element: Node | Window | Document,
        event: string,
        fn: (evt: Event) => void,
        isOnce?: boolean,
        capture?: boolean,
        passive?: boolean
    ): string {
        destroyable[CACHE_NAME] = destroyable[CACHE_NAME] ?? A([]);
        const id = destroyable[CACHE_NAME].pushObject(
            addWeakListener(destroyable, element, event, fn, isOnce, capture, passive)
        );

        // Listen for destroy.
        registerDestructor(destroyable, () => this.removeListener(destroyable, id));

        return id;
    }

    /**
     * Removes listener with specified id.
     *
     * @param destroyable - The object that requested the listener.
     * @param id The ID of the listener to remove.
     */
    removeListener(destroyable: any, id: string): void {
        // Remove the registered listener.
        removeListener(id);

        // Remove the id from the local listeners holder.
        destroyable[CACHE_NAME]?.removeObject(id);
    }

    /**
     * Cleans up all listeners.
     *
     * @param destroyable - The object that requested the listeners.
     */
    removeAllListeners(destroyable: any): void {
        const domListeners = destroyable[CACHE_NAME];

        if (domListeners) {
            // Clear DOM listeners.
            domListeners.forEach(removeListener);

            // Clear the listeners array.
            domListeners.clear();
            destroyable[CACHE_NAME] = undefined;
        }
    }

    /**
     * Clears the close listener event handler (if it exists).
     *
     * @param destroyable - The object that requested the listener.
     */
    clearCloseClickListener(destroyable: any): void {
        const id = destroyable[ON_CLOSE_CLICK_LISTENER_KEY];
        if (id) {
            // Clear the listener id so that we know if we need to reattach it again.
            this.removeListener(destroyable, id);
            delete destroyable[ON_CLOSE_CLICK_LISTENER_KEY];
        }
    }

    /**
     * Attaches a click event listener to the entire document which calls the passed function on execution.
     *
     * @param destroyable - The object that requested the listener.
     * @param fnClose A handler for when the element should be closed.
     * @param fnTestElement An optional function for testing if the close action should proceed.
     */
    attachCloseHandler(destroyable: any, fnClose: () => void, fnTestElement?: (evt: Event) => boolean): void {
        // Does the listener already exist?
        if (destroyable[ON_CLOSE_CLICK_LISTENER_KEY]) {
            // Nothing to do.
            return;
        }

        // Create click handler.
        const fnClick = (evt: Event) => {
            // Is there a test function AND does it indicate this should be ignored?
            if (fnTestElement && fnTestElement(evt)) {
                // Ignore this click.
                return;
            }

            // Remove event listener (fires one time only).
            this.clearCloseClickListener(destroyable);

            // Execute close function.
            fnClose();
        };

        // Attach event listener and clear on destroy.
        destroyable[ON_CLOSE_CLICK_LISTENER_KEY] = this.addListener(
            destroyable,
            document,
            'click',
            fnClick,
            false,
            true
        );
        registerDestructor(destroyable, this.clearCloseClickListener.bind(this, destroyable));
    }

    /**
     * Determines if an event occurred within the passed element.
     *
     * @param element The element to test.
     * @param evt The click event.
     * @param selector A CSS selector for the element to test.
     */
    verifyCloseTarget(element: HTMLElement, evt: Event, selector: string): boolean {
        const parent = element.querySelector(selector);
        return parent?.contains((evt.target || evt.srcElement) as Node) ?? false;
    }
}
