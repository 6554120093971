import Button from './button-adc.js';
import { inject as service } from '@ember/service';
import { computed, observer } from '@ember/object';
import { alias } from '@ember/object/computed';
import { once } from '@ember/runloop';

/**
 * Publishes the native button if the functionality is available.
 *
 * @private
 * @instance
 * @memberof components.NativeButton
 */
function publishNativeButton() {
    if (!this.isRenderedNative) {
        return;
    }

    // Get button's properties.
    const {
        elementId,
        text,
        disabled,
        icon,
        responsiveText,
        isAddItemButton,
        type,
        title,
        updateNativeToolbarButtons,
        isPromisePending
    } = this;

    const action = this['on-click'];

    if (disabled || !action) {
        disposeNativeButton.call(this);
        return;
    }

    if (!updateNativeToolbarButtons) {
        console.error('updateNativeToolbarButtons action not defined on NativeButtons component.');
        return;
    }

    // Publish button.
    updateNativeToolbarButtons([
        {
            componentId: elementId,
            // For text always use responsive text; if this is an add button then use "Add" text over text property.
            text: responsiveText || (isAddItemButton ? this.intl.t('@adc/ui-components.add') : text) || title,
            icon,
            type: isAddItemButton ? this.nativeBridge.getButtonTypeAdd() : type || '',
            isPromisePending,
            // We are just clicking the button because that will emulate how the whole button behaves.
            // This provides consistent behavior between web and native.
            action: () => this.element.click()
        }
    ]);
}

/**
 * Disposes this button from the native button collection.
 *
 * @private
 * @instance
 * @memberof components.NativeButton
 */
function disposeNativeButton() {
    if (!this.nativeBridge.doesSupportToolbarButtons) {
        return;
    }

    if (!this.disposeNativeButtons) {
        console.error('disposeNativeButtons action not defined on NativeButton component');
    }

    this.disposeNativeButtons([this.elementId]);
}

/**
 * @classdesc
 *
 * Button that will be displayed as native button if the native bridge supports it.
 *
 * @class NativeButton
 * @extends Button
 * @memberof components
 */
export default Button.extend(
    /** @lends components.NativeButton# */ {
        nativeBridge: service(),
        intl: service(),

        /** @override */
        attributeBindings: ['shouldHide:hidden'],

        /**
         * The element should not be visible if the bridge supports native buttons.
         *
         * @function
         * @returns {boolean}
         */
        shouldHide: alias('isRenderedNative'),

        /**
         * Is the button actually rendered natively?
         *
         * @function
         * @returns {boolean}
         */
        isRenderedNative: alias('nativeBridge.doesSupportToolbarButtons'),

        /**
         * Is this button used for triggering a screen for adding an item?
         *
         * @type {boolean}
         */
        isAddItemButton: false,

        /** @override */
        noCallbackDelay: computed('isRenderedNative', function () {
            // If button is not rendered native just default to base, otherwise make sure there is no delay.
            return !this.isRenderedNative ? this._super(...arguments) : true;
        }),

        /** @override */
        init() {
            this._super(...arguments);

            this.propertiesChanged();
        },

        /**
         * Triggered when any of the properties changes that would change a native button.
         *
         * @function
         */
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-observers
        propertiesChanged: observer(
            'text',
            'disabled',
            'icon',
            'responsiveText',
            'isAddItemButton',
            'on-click',
            'isRenderedNative',
            'promiseStateClass',
            function () {
                // Do not copy this deprecated usage. If you see this, please fix it
                // eslint-disable-next-line ember/no-runloop
                once(this, publishNativeButton);
            }
        ),

        /** @override */
        willDestroy() {
            disposeNativeButton.call(this);

            return this._super(...arguments);
        }
    }
);
