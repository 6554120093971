import templateOnlyComponent from '@ember/component/template-only';

import type { TextInputSignature } from './text-input';

export interface SearchInputSignature {
    Element: TextInputSignature['Element'];
    Args: Pick<TextInputSignature['Args'], 'value' | 'value-change' | 'containerClass'> & {
        /** Indicates the search input should be disabled. */
        disabled?: boolean;
        /** The `aria-label` for the search input (defaults to "search"). */
        ariaLabel?: string;
    };
}
const SearchInput = templateOnlyComponent<SearchInputSignature>();
export default SearchInput;
