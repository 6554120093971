import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import { singularize } from 'ember-inflector';
import CommonSerializer from './common-serializer.ts';

/**
 * For classes that have properties that are abstract types that need to be deserialized as polymorphic,
 * this serializer will include those properties using the relations as "data" implementation mentioned here:
 * https://www.mediasuite.co.nz/blog/managing-relations-ember-data-json-api/
 *
 * @class serializers.PolymorphicSerializer
 * @extends serializers.CommonSerializer
 * @mixes DS.EmbeddedRecordsMixin
 */
export default CommonSerializer.extend(
    EmbeddedRecordsMixin,
    /** @lends serializers.PolymorphicSerializer# */ {
        isEmbeddedRecordsMixinCompatible: true,

        /** @override */
        payloadKeyFromModelName(modelName) {
            return singularize(modelName);
        },

        /** @override **/
        serialize(snapshot, options = {}) {
            // Make sure ID is included.
            options.includeId = true;

            const json = this._super(snapshot, options).data;

            // Following the data implementation specified here: https://www.mediasuite.co.nz/blog/managing-relations-ember-data-json-api/
            json.relationships = {};

            snapshot.eachRelationship((key) => {
                let relationship = (json.relationships[key] = {});
                relationship.data = json[key];
            });

            return json;
        }
    }
);
