
import { importSync as i } from '@embroider/macros';
let d = window.define;
d("@ember-data/adapter/-private/build-url-mixin", function(){ return i("@ember-data/adapter/-private/build-url-mixin");});
d("@ember-data/adapter/-private/fastboot-interface", function(){ return i("@ember-data/adapter/-private/fastboot-interface");});
d("@ember-data/adapter/-private/index", function(){ return i("@ember-data/adapter/-private/index");});
d("@ember-data/adapter/-private/utils/continue-on-reject", function(){ return i("@ember-data/adapter/-private/utils/continue-on-reject");});
d("@ember-data/adapter/-private/utils/determine-body-promise", function(){ return i("@ember-data/adapter/-private/utils/determine-body-promise");});
d("@ember-data/adapter/-private/utils/fetch", function(){ return i("@ember-data/adapter/-private/utils/fetch");});
d("@ember-data/adapter/-private/utils/parse-response-headers", function(){ return i("@ember-data/adapter/-private/utils/parse-response-headers");});
d("@ember-data/adapter/-private/utils/serialize-into-hash", function(){ return i("@ember-data/adapter/-private/utils/serialize-into-hash");});
d("@ember-data/adapter/-private/utils/serialize-query-params", function(){ return i("@ember-data/adapter/-private/utils/serialize-query-params");});
d("@ember-data/adapter/error", function(){ return i("@ember-data/adapter/error");});
d("@ember-data/adapter/index", function(){ return i("@ember-data/adapter/index");});
d("@ember-data/adapter/json-api", function(){ return i("@ember-data/adapter/json-api");});
d("@ember-data/adapter/rest", function(){ return i("@ember-data/adapter/rest");});
d("@ember-data/model/-private/attr", function(){ return i("@ember-data/model/-private/attr");});
d("@ember-data/model/-private/belongs-to", function(){ return i("@ember-data/model/-private/belongs-to");});
d("@ember-data/model/-private/diff-array", function(){ return i("@ember-data/model/-private/diff-array");});
d("@ember-data/model/-private/errors", function(){ return i("@ember-data/model/-private/errors");});
d("@ember-data/model/-private/has-many", function(){ return i("@ember-data/model/-private/has-many");});
d("@ember-data/model/-private/index", function(){ return i("@ember-data/model/-private/index");});
d("@ember-data/model/-private/legacy-data-fetch", function(){ return i("@ember-data/model/-private/legacy-data-fetch");});
d("@ember-data/model/-private/legacy-data-utils", function(){ return i("@ember-data/model/-private/legacy-data-utils");});
d("@ember-data/model/-private/legacy-relationships-support", function(){ return i("@ember-data/model/-private/legacy-relationships-support");});
d("@ember-data/model/-private/many-array", function(){ return i("@ember-data/model/-private/many-array");});
d("@ember-data/model/-private/model-for-mixin", function(){ return i("@ember-data/model/-private/model-for-mixin");});
d("@ember-data/model/-private/model", function(){ return i("@ember-data/model/-private/model");});
d("@ember-data/model/-private/notify-changes", function(){ return i("@ember-data/model/-private/notify-changes");});
d("@ember-data/model/-private/promise-belongs-to", function(){ return i("@ember-data/model/-private/promise-belongs-to");});
d("@ember-data/model/-private/promise-many-array", function(){ return i("@ember-data/model/-private/promise-many-array");});
d("@ember-data/model/-private/record-state", function(){ return i("@ember-data/model/-private/record-state");});
d("@ember-data/model/-private/references/belongs-to", function(){ return i("@ember-data/model/-private/references/belongs-to");});
d("@ember-data/model/-private/references/has-many", function(){ return i("@ember-data/model/-private/references/has-many");});
d("@ember-data/model/-private/relationship-meta", function(){ return i("@ember-data/model/-private/relationship-meta");});
d("@ember-data/model/-private/util", function(){ return i("@ember-data/model/-private/util");});
d("@ember-data/model/index", function(){ return i("@ember-data/model/index");});
d("@ember-data/serializer/-private/embedded-records-mixin", function(){ return i("@ember-data/serializer/-private/embedded-records-mixin");});
d("@ember-data/serializer/-private/index", function(){ return i("@ember-data/serializer/-private/index");});
d("@ember-data/serializer/-private/transforms/boolean", function(){ return i("@ember-data/serializer/-private/transforms/boolean");});
d("@ember-data/serializer/-private/transforms/date", function(){ return i("@ember-data/serializer/-private/transforms/date");});
d("@ember-data/serializer/-private/transforms/number", function(){ return i("@ember-data/serializer/-private/transforms/number");});
d("@ember-data/serializer/-private/transforms/string", function(){ return i("@ember-data/serializer/-private/transforms/string");});
d("@ember-data/serializer/-private/transforms/transform", function(){ return i("@ember-data/serializer/-private/transforms/transform");});
d("@ember-data/serializer/-private/utils", function(){ return i("@ember-data/serializer/-private/utils");});
d("@ember-data/serializer/index", function(){ return i("@ember-data/serializer/index");});
d("@ember-data/serializer/json-api", function(){ return i("@ember-data/serializer/json-api");});
d("@ember-data/serializer/json", function(){ return i("@ember-data/serializer/json");});
d("@ember-data/serializer/rest", function(){ return i("@ember-data/serializer/rest");});
d("@ember-data/serializer/transform", function(){ return i("@ember-data/serializer/transform");});
d("@ember/render-modifiers/modifiers/did-insert", function(){ return i("@ember/render-modifiers/modifiers/did-insert");});
d("@ember/render-modifiers/modifiers/did-update", function(){ return i("@ember/render-modifiers/modifiers/did-update");});
d("@ember/render-modifiers/modifiers/will-destroy", function(){ return i("@ember/render-modifiers/modifiers/will-destroy");});
d("@sentry/ember/index", function(){ return i("@sentry/ember/index");});
d("@sentry/ember/instance-initializers/sentry-performance", function(){ return i("@sentry/ember/instance-initializers/sentry-performance");});
d("@sentry/ember/types", function(){ return i("@sentry/ember/types");});
d("ember-inflector/index", function(){ return i("ember-inflector/index");});
d("ember-inflector/lib/helpers/pluralize", function(){ return i("ember-inflector/lib/helpers/pluralize");});
d("ember-inflector/lib/helpers/singularize", function(){ return i("ember-inflector/lib/helpers/singularize");});
d("ember-inflector/lib/system", function(){ return i("ember-inflector/lib/system");});
d("ember-inflector/lib/system/inflections", function(){ return i("ember-inflector/lib/system/inflections");});
d("ember-inflector/lib/system/inflector", function(){ return i("ember-inflector/lib/system/inflector");});
d("ember-inflector/lib/system/string", function(){ return i("ember-inflector/lib/system/string");});
d("ember-inflector/lib/utils/make-helper", function(){ return i("ember-inflector/lib/utils/make-helper");});
d("ember-data/-private/core", function(){ return i("ember-data/-private/core");});
d("ember-data/-private/index", function(){ return i("ember-data/-private/index");});
d("ember-data/adapter", function(){ return i("ember-data/adapter");});
d("ember-data/adapters/errors", function(){ return i("ember-data/adapters/errors");});
d("ember-data/adapters/json-api", function(){ return i("ember-data/adapters/json-api");});
d("ember-data/adapters/rest", function(){ return i("ember-data/adapters/rest");});
d("ember-data/attr", function(){ return i("ember-data/attr");});
d("ember-data/index", function(){ return i("ember-data/index");});
d("ember-data/model", function(){ return i("ember-data/model");});
d("ember-data/relationships", function(){ return i("ember-data/relationships");});
d("ember-data/serializer", function(){ return i("ember-data/serializer");});
d("ember-data/serializers/embedded-records-mixin", function(){ return i("ember-data/serializers/embedded-records-mixin");});
d("ember-data/serializers/json-api", function(){ return i("ember-data/serializers/json-api");});
d("ember-data/serializers/json", function(){ return i("ember-data/serializers/json");});
d("ember-data/serializers/rest", function(){ return i("ember-data/serializers/rest");});
d("ember-data/setup-container", function(){ return i("ember-data/setup-container");});
d("ember-data/store", function(){ return i("ember-data/store");});
d("ember-data/transform", function(){ return i("ember-data/transform");});
import "@adc/ajax/-embroider-implicit-modules.js";
import "@adc/ember-utils/-embroider-implicit-modules.js";
import "@adc/i18n/-embroider-implicit-modules.js";
import "ember-concurrency/-embroider-implicit-modules.js";
import "ember-lifeline/-embroider-implicit-modules.js";
import "@ember-data/adapter/-embroider-implicit-modules.js";
import "@ember-data/model/-embroider-implicit-modules.js";
import "@ember-data/serializer/-embroider-implicit-modules.js";
import "@ember/render-modifiers/-embroider-implicit-modules.js";
import "@sentry/ember/-embroider-implicit-modules.js";
import "ember-inflector/-embroider-implicit-modules.js";
import "ember-data/-embroider-implicit-modules.js";
