import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { A } from '@ember/array';

import type { CommonInputErrorTooltipArgs } from '../error-tooltip';
import type { SafeString } from 'handlebars';

type ITEM_STATE = boolean | 'mixed';
export type BinaryListOrientation = 'vertical' | 'horizontal';

export class BinaryListItem<T = object> {
    @tracked label: string | SafeString = '';
    @tracked state: ITEM_STATE = false;
    @tracked icon?: string;
    @tracked css?: string;
    @tracked disabled?: boolean;
    @tracked color?: string;
    props: T;

    constructor(args: Partial<BinaryListItem<T>>) {
        Object.assign(this, args ?? {});
        this.props = (args.props ?? {}) as T;
    }
}

export interface SimpleBinaryListSignature<T extends BinaryListItem> {
    Element: HTMLUListElement;
    Args: CommonInputErrorTooltipArgs & {
        /** The `BinaryListItem` collection to render. */
        items: T[] | Promise<T[]>;
        /** The binary input type. */
        type?: 'checkbox' | 'radio';
        /** The orientation of the list (defaults to vertical). */
        orientation?: 'vertical' | 'horizontal';
        /** Triggered when the user changes the state of the binary list items. */
        onchange?: (items: T[]) => void;
        /** Triggered when the user changes the state of a binary list item. */
        onItemChange?: (item: BinaryListItem) => void;
        /** Indicates all binary inputs in list should be disabled.  */
        disabled?: boolean;
        /** Indicates label text for each binary input should wrap to a new line. */
        wrap?: boolean;
        /** Indicates all binary inputs in list should be reversed. */
        reverse?: boolean;
        /** Indicates the `content` block should be hidden if the parent binary control state is not `checked`.  */
        hideUnselectedContent?: boolean;
        /** Passed in if it should not be possible to tab and focus on the list items */
        itemsAreNotFocusable?: boolean;
    };
    Blocks: {
        /** Renders within the label element for the input element. */
        label: [T];
        /** Renders in it's own `<div />` element below the label/input elements. */
        content: [T];
    };
}

export default class SimpleBinaryList<T extends BinaryListItem> extends Component<SimpleBinaryListSignature<T>> {
    @action async listItemChanged(item: BinaryListItem, evt: InputEvent): Promise<void> {
        const items = await this.args.items,
            { type } = this.args;

        // Is this a radio list?
        if (type === 'radio') {
            // Clear all other selections.
            A(items).setEach('state', false);
        }

        item.state = (evt.target as HTMLInputElement).checked;

        const { onchange, onItemChange } = this.args;
        if (onchange) {
            onchange(A(items));
        }
        if (onItemChange) {
            onItemChange(item);
        }
    }
}
