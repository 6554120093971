import BaseSchedule from './views/base-schedule.ts';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import { MINUTES_IN_DAY, MINUTES_IN_HOUR } from '@adc/ember-utils/constants/time';
import { NINE_AM, TWELVE_PM, FIVE_PM } from './schedule-hour-labels.ts';

import type { BaseScheduleSignature } from './views/base-schedule';
import type { SafeString } from 'handlebars';
import type { ScheduleElement } from './views/types';

export interface ScheduleViewCompactSignature extends BaseScheduleSignature {
    Args: BaseScheduleSignature['Args'] & {
        /** The text to display when the timetable is 24/7. */
        allTimesText?: string;
        /** Optional bar CSS class used to set color (will use light gray if not present). */
        color?: 'red' | 'orange' | 'yellow' | 'green' | 'blue' | 'purple' | 'greenblue';
        /** The text to display when the timetable is empty. */
        emptyScheduleText?: string;
        /** Called to modify the timetable when the grid is clicked. */
        editTimetable?: VoidFunction;
        /** Optional CSS class for inactive schedules. Lower opacity for inactive plans */
        isInactiveSchedule?: boolean;
    };
}

/**
 * Displays a vertical schedule grid.
 */
export default class ScheduleViewCompact extends BaseSchedule<ScheduleViewCompactSignature> {
    /**
     * The text to display when the whole day is selected.
     */
    get allTimesText(): string {
        return this.args.allTimesText ?? '';
    }

    get editTimetable(): VoidFunction {
        return this.args.editTimetable ?? (() => {});
    }

    /**
     * Is the schedule empty?
     */
    @computed('planSchedules.[]')
    get isScheduleEmpty(): boolean {
        return (this.planSchedules ?? []).every((planSchedule) => !planSchedule.combinedSchedules.length);
    }

    /**
     * Array of string styles that specify the position for lines to be drawn horizontally across the schedule.
     */
    get hourLinePositions(): SafeString[] {
        return [NINE_AM, TWELVE_PM, FIVE_PM].map((hour) =>
            htmlSafe(`top:${(hour * MINUTES_IN_HOUR * 100) / MINUTES_IN_DAY}%`)
        );
    }

    /**
     * Schedule to be rendered into the grid
     */
    @computed('allTimesText', 'planSchedules.[]')
    get scheduleElements(): ScheduleElement[] {
        return this.getScheduleElements(true, this.allTimesText);
    }

    /**
     * Returns the time blocks for each day on the grid.
     */
    get gridDays(): { day: number; label: string; planNameLabel: string; classes: string; style: SafeString }[][] {
        return this.scheduleElements.map(({ schedules }) => {
            const [s] = schedules;
            return s?.timeBlocks;
        });
    }
}
