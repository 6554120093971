import templateOnlyComponent from '@ember/component/template-only';

import type { SafeString } from 'handlebars';

export interface FieldsetHeaderSignature {
    Element: HTMLElement;
    Args: {
        /** The text to be shown in the fieldset header. */
        text?: string | SafeString;
    };
    Blocks: {
        default: [];
    };
}

const FieldsetHeader = templateOnlyComponent<FieldsetHeaderSignature>();
export default FieldsetHeader;
