/**
 * @module
 */
/**
 * @enum {number}
 */
export const NotEnabled = 0;
/**
 * @enum {number}
 */
export const Authenticator = 1;
/**
 * @enum {number}
 */
export const SMS = 2;
/**
 * @enum {number}
 */
export const Email = 4;
