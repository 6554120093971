import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { intlPath } from '@adc/i18n/path';

import type ADCIntlService from '@adc/i18n/services/adc-intl';

/**
 * Allowed sizes for the spinner
 */
const ALLOWED_SIZES: ('small' | 'medium' | 'large' | 'x-large')[] = ['small', 'medium', 'large', 'x-large'];

export interface SpinnerSvgSignature {
    Element: SVGElement;
    Args: {
        /** Indicates the spinner is spinning (defaults to `false`). */
        spinning?: boolean;
        /** Optional spinner size. */
        size?: 'small' | 'medium' | 'large' | 'x-large';
        /** Optional title rendered into a `<title />` element. */
        title?: string;
        /** Optional description rendered into a `<desc />` element. */
        desc?: string;
    };
}

/**
 * @classdesc
 * Component for showing a spinner.
 */
@intlPath({ module: '@adc/ui-components', path: 'spinner-svg' })
export default class SpinnerSvg extends Component<SpinnerSvgSignature> {
    @service declare intl: ADCIntlService;

    /**
     * Indicates that the spinner is spinning (defaults to `true`).
     */
    get spinning(): boolean {
        return this.args.spinning ?? true;
    }

    /**
     * Computed CSS class for size.
     */
    @computed('args.size')
    get size(): string {
        const { size = 'medium' } = this.args;
        return size === 'medium' || ALLOWED_SIZES.indexOf(size) < 0 ? '' : size;
    }

    /**
     * If the title was not specified, use a default title.
     */
    @computed('args.title')
    get iconTitle(): string {
        const { title } = this.args;

        if (isEmpty(title)) {
            return this.intl.tc(this, 'loading');
        }

        return title as string;
    }

    /**
     * The aria-label of the icon, if there is one.
     */
    @computed('iconTitle', 'args.desc')
    get ariaLabel(): string {
        return [this.iconTitle, this.args.desc].filter((el) => !!el).join(' ');
    }

    // endregion
}
