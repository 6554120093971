import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { assert } from '@ember/debug';
import { isPresent } from '@ember/utils';
import config from 'ember-get-config';

// eslint-disable-next-line @typescript-eslint/ban-types
type i18nContextConstructor<T extends {} = {}> = new (...args: any[]) => T;

const PATH_REG_EXP = new RegExp('^<(?:[^:]+)@(.*)>$');

function resolvePath<T>(context: T, path?: string): string {
    if (context instanceof Component) {
        assert(
            '[@adc/i18n] Use of the @intlPath decorator on a glimmer component requires that the path parameter be passed',
            isPresent(path)
        );

        return `component:${path}::${guidFor(context)}`;
    }

    const value = String(context),
        m = value.match(PATH_REG_EXP);

    assert(`[@adc/i18n] Unable to calculate intl path from context toString value (${value})`, !!m);

    return (m && m[1]) ?? '';
}

/**
 * Class decorator for adding support for `tc` method/helper in glimmer components and addons/engines.
 *
 * @example
 * import Component from '@glimmer/component';
 * import { intlPath } from '@adc/i18n/path';
 *
 * ‎@intlPath({ module: '@adc/ui-components' path: 'path/to/component/foo' })
 * export default class Foo extends Component {
 *     ...
 * }
 */
export function intlPath({
    module,
    path
}: {
    path?: string;
    module?: string;
}): (context: i18nContextConstructor) => void {
    const { modulePrefix } = config;

    assert(
        `[@adc/i18n] The module value passed to the @intlPath decorator is identical to the application module prefix (${modulePrefix})`,
        module !== modulePrefix
    );

    return function (context: i18nContextConstructor): void {
        context.prototype.getIntlPath = function () {
            return `<${module ?? modulePrefix}@${resolvePath(this, path)}>`;
        };
    };
}
