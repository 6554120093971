import {
    NotLoggedIn,
    InvalidAntiForgeryToken,
    ValidationError,
    ProcessingError,
    NoPermissionForEmber,
    ServerProcessingError,
    ServiceUnavailable
} from '../enums/AjaxResponseHttpCode.ts';

const NotFound = 404;

export class AjaxError {
    code: number;
    type: string;
    name: string;
    title: string;
    message: string;
    shouldBeLogged: boolean;
    isUnauthorizedAccess: boolean;
    isValidationError: boolean;
    isProcessingError: boolean;
    isIncorrectSite: boolean;

    constructor(
        code: number,
        type: string,
        title = '',
        message = '',
        shouldBeLogged = false,
        isUnauthorizedAccess = false,
        isValidationError = false,
        isProcessingError = false,
        isIncorrectSite = false
    ) {
        this.code = code;
        this.type = type;
        // Name is the same as type, but some places use type and some use name. This simplifies logic in those instances.
        this.name = type;
        this.title = title;
        this.message = message;
        this.shouldBeLogged = shouldBeLogged;
        this.isUnauthorizedAccess = isUnauthorizedAccess;
        this.isValidationError = isValidationError;
        this.isProcessingError = isProcessingError;
        this.isIncorrectSite = isIncorrectSite;
    }
}

function getErrorDetails(): Array<
    [number, string, string?, string?, boolean?, boolean?, boolean?, boolean?, boolean?]
> {
    return [
        [NotLoggedIn, 'NotLoggedIn', 'User was not logged in.', 'Unauthorized access', false, true],
        [
            InvalidAntiForgeryToken,
            'InvalidAntiForgeryToken',
            'Attempted access with invalid token.',
            'Unauthorized access',
            false,
            true
        ],
        [NotFound, 'NotFound', 'WebApi Controller endpoint not found.', undefined, true],
        [ValidationError, 'ValidationError', undefined, 'Validation error', false, false, true],
        [ProcessingError, 'ProcessingError', undefined, 'Processing error', false, false, false, true],
        [
            NoPermissionForEmber,
            'NoPermissionForEmber',
            'No access for site version.',
            'Incorrect access',
            false,
            false,
            false,
            false,
            true
        ],
        [ServerProcessingError, 'ServerProcessingError', undefined, 'Server processing error'],
        [ServiceUnavailable, 'ServiceUnvailable', undefined, 'Service Unvailable']
    ];
}

/**
 * Returns error with the specified error status code.
 */
export function getErrorWithStatusCode(statusCode: number): AjaxError | undefined {
    const args = getErrorDetails().find((args) => args[0] === statusCode);
    return args && new AjaxError(...args);
}

/**
 * Returns error status code for specified error type.
 */
function getStatusCodeForErrorType(errorType: string): number | undefined {
    return getErrorDetails().find((args) => args[1] === errorType)?.[0];
}

/**
 * Should an error with the specified status code not be logged by client side error logging?
 */
export function shouldNotLogErrorWithStatusCode(statusCode: number): boolean {
    const error = getErrorWithStatusCode(statusCode);

    // Error should not be logged if the error is in the collection and it is marked as not to be logged.
    return !!(error && !error.shouldBeLogged);
}

/**
 * Should an error with the specified errorType not be logged by client side error logging?
 */
export function shouldNotLogErrorWithType(errorType: string): boolean {
    return shouldNotLogErrorWithStatusCode(getStatusCodeForErrorType(errorType) as number);
}

/**
 * Checks to see if Error is a no network error
 */
export function isNoNetworkError(): boolean {
    return !window.navigator.onLine;
}
