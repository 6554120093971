import templateOnlyComponent from '@ember/component/template-only';

export interface SimpleListCircleSignature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}

const SimpleListCircle = templateOnlyComponent<SimpleListCircleSignature>();
export default SimpleListCircle;
