import JSONAPISerializer from '@ember-data/serializer/json-api';

/**
 * Common serializer attributes to share between ADC's serializer implementations.
 */
export default class CommonSerializer extends JSONAPISerializer {
    /** @override **/
    keyForAttribute(key: string): string {
        // Return non-dasherized key.
        return key;
    }

    /** @override **/
    keyForRelationship(key: string): string {
        return key;
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
    export default interface SerializerRegistry {
        'common-serializer': CommonSerializer;
    }
}
