import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { formatTimeTz } from '../utils/dates.ts';

import type ADCIntlService from '../services/adc-intl.ts';

type FormatTimeTzArgs = Parameters<typeof formatTimeTz>;
type FormatTimeTzValue = FormatTimeTzArgs[1];
type FormatTimeTzOptions = FormatTimeTzArgs[2];

export interface FormatTimeTzSignature {
    Args: {
        Positional: [FormatTimeTzValue];
        Named: FormatTimeTzOptions;
    };
    Return: string;
}

/**
 * @classdesc
 * Format a time, using the intl service global timezone if no explicit timezone is passed.
 */
export default class FormatTimeTzHelper extends Helper<FormatTimeTzSignature> {
    @service declare intl: ADCIntlService;

    compute([value]: [FormatTimeTzValue], options: FormatTimeTzOptions): ReturnType<typeof formatTimeTz> {
        return formatTimeTz(this.intl, value, options);
    }
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Returns the date formatted to the specified options, using the globally specified timezone value.
         */
        'format-time-tz': typeof FormatTimeTzHelper;
    }
}
