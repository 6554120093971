import { isPresent } from '@ember/utils';

export default function isPresentHelper(...args: any[]): boolean {
    return args.every((v) => isPresent(v));
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Returns a boolean indicating if all of the passed values are considered present by ember.
         *
         * @link https://api.emberjs.com/ember/3.28/functions/@ember%2Futils/isPresent
         * @example {{#if (is-present @item.details)}}
         */
        'is-present': typeof isPresentHelper;
    }
}
