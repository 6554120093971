import templateOnlyComponent from '@ember/component/template-only';

import type { KeywordSearchSignature } from './keyword-search';

type KeywordSearchSignatureArgs = KeywordSearchSignature['Args'];

interface KeywordSearchToolbarArgs
    extends Pick<KeywordSearchSignature['Args'], 'suggestionClass' | 'keywordOptions' | 'keyword' | 'searchString'> {
    /** Triggered when the search string changes. */
    searchValueChange: KeywordSearchSignatureArgs['applySearchFn'];
    /** Indicates the search box should be hidden. */
    searchBoxHidden?: boolean;
    /** Indicates the search box should be disabled. */
    searchBoxDisabled?: boolean;
    /** Optional placeholder value for the search box. */
    searchPlaceholder?: string;
}

export interface KeywordSearchToolbarSignature {
    Element: HTMLDivElement;
    Args: KeywordSearchToolbarArgs;
    Blocks: {
        default: [];
    };
}

const KeywordSearchToolbar = templateOnlyComponent<KeywordSearchToolbarSignature>();
export default KeywordSearchToolbar;
