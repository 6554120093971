export default function coalesce(...args: any[]): any | undefined {
    return args.reduce((value, p) => value || p, undefined);
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Returns the first "truthy" value.
         *
         * @example <p>{{coalesce @text (t 'text')}}</p>
         */
        coalesce: typeof coalesce;
    }
}
