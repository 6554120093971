import BaseContentLayerComponent from '../components/content-layer/base-content-layer-component.js';
import { inject as service } from '@ember/service';
import { computed, get, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { getOwner } from '@ember/owner';
import { markLastContainerRendered } from './page-view.ts';

/**
 * The #app-content element.
 *
 * @type {HTMLElement|undefined}
 */
let appContentEl = undefined;

/**
 * Get the #app-content element.
 *
 * @note We should figure out how to do this better.
 *
 * @returns {HTMLElement|undefined}
 */
function getAppContentEl() {
    if (!appContentEl) {
        appContentEl = document.getElementById('app-content');
    }

    return appContentEl;
}

/**
 * Class names.
 */
const ROUTE_VIEW_CLASS = 'route-view',
    USE_FLEXBOX_CLASS = 'use-flexbox';

/**
 * Mapping of property to class name for adding to app-content.
 *
 * @type {{useFlexbox: string, useRouteViewClass: string}}
 */
const PROPERTY_CLASS_MAP = {
    useFlexbox: USE_FLEXBOX_CLASS,
    useRouteViewClass: ROUTE_VIEW_CLASS
};

/**
 * List of allowed container width classes.
 */
const MAX_WIDTH_CLASSES = ['sm', 'md', 'lg'];

/**
 * @classdesc
 * Root component for all route views. Handles a bunch of stuff.
 */
export default class RouteView extends BaseContentLayerComponent {
    @service nativeBridge;
    @service performanceMonitor;
    @service router;
    @service intl;

    tagName = '';

    @tracked toolbarComponent;

    get navigationApi() {
        return getOwner(this).lookup('route-view:main');
    }

    /**
     * Toolbar component to render.
     *
     * @type {String}
     */
    get toolbar() {
        return this.toolbarComponent ?? 'search-toolbar';
    }

    /**
     * Should the route-view set the parent #app-content element to use flex box?
     *
     * @note This is used only in one place, we should probably get rid of it in favor of using the new route-view components.
     *
     * @type {boolean}
     */
    useFlexbox = false;

    /**
     * Should the app-content use a route-view class that standardizes all paddings + margins?
     *
     * @type {Boolean}
     */
    useRouteViewClass = false;

    /**
     * Max width for the view-container.
     *
     * @type {String | undefined}
     */
    containerMaxWidth;

    /**
     * Keeps track of the number of containers that have been rendered.
     */
    containersRendered = 0;

    /** Determines whether the rendering process for this route view
     * has completed and marks it using the performance monitor service.
     * This action is passed into each container and fires when that container
     * is finished rendering. Once every container has rendered, then we
     * call the performance monitor to mark the last render point.
     */
    @action
    markEndOfRendering() {
        // If numOfContainersToRender wasn't passed in, then we assume there
        // is only one contianer to render.
        const numOfContainersToRender = this.numOfContainersToRender ?? 1;

        // Attempt to mark the last container as rendered and return the new count.
        this.containersRendered = markLastContainerRendered(
            this.containersRendered,
            numOfContainersToRender,
            this.performanceMonitor
        );
    }

    /*
     * Determines whether the route view utilizes the KPI threshold metric tracking feature.
     */
    @action
    setKpiMetricUsage() {
        this.performanceMonitor.setUseKpiThresholdMetrics(this.useKpiThresholdMetrics ?? false);
    }

    /**
     * Generates class size for the max width of the container.
     *
     * @returns {String | undefined}
     */
    @computed('containerMaxWidth')
    get containerMaxWidthClass() {
        const maxWidth = this.containerMaxWidth;

        return MAX_WIDTH_CLASSES.includes(maxWidth) ? maxWidth : undefined;
    }

    /**
     * @note Init of a new route runs AFTER willClearRender of the previous one. However Init of a new route runs BEFORE didDestroyElement of previous route.
     *
     * @override
     */
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/classic-decorator-hooks
    init(...args) {
        super.init(...args);

        // We want to use kpi threshold metrics for route views.
        this.setKpiMetricUsage();

        const el = getAppContentEl.call(this);

        if (el) {
            const classesToAdd = Object.keys(PROPERTY_CLASS_MAP)
                .map((key) => (get(this, key) ? PROPERTY_CLASS_MAP[key] : undefined))
                .compact();

            el.classList.add(...classesToAdd);
        }

        const maxWidth = this.containerMaxWidth;

        if (maxWidth && !MAX_WIDTH_CLASSES.includes(maxWidth)) {
            console.error(`Incorrect maxWidth defined=${maxWidth}, only ${MAX_WIDTH_CLASSES.join(', ')} are allowed`);
        }

        this.triggerNativeToolbarUpdate();
    }

    /** @override */
    didInsertElement(...args) {
        super.didInsertElement(...args);

        this.performanceMonitor.markRouteViewRendered(this.router.currentRouteName);
    }

    /** @override */
    handleNoNativeBackOrCancelButton({ backButtons }) {
        const { navigationApi } = this;

        // If there is no back button already defined, check if there is a "go back" route and if so then add that as a back button.
        if (navigationApi.get('getVisitedRoutesStackLength')() <= 1) {
            return;
        }

        const backText = this.intl.t('generic.back');

        backButtons.insertAt(0, {
            text: backText,
            description: backText,
            type: this.nativeBridge.getButtonTypeBack(),
            action: () => navigationApi.get('transitionToPreviousRoute')()
        });
    }

    /**
     * @override
     */
    willClearRender(...args) {
        const el = getAppContentEl.call(this);

        if (el) {
            el.classList.remove(...Object.keys(PROPERTY_CLASS_MAP).map((key) => PROPERTY_CLASS_MAP[key]));
        }

        super.willClearRender(...args);
    }
}
