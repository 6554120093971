// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { reads, or, and } from '@ember/object/computed';
import { action, computed, get, set } from '@ember/object';
import { schedule, scheduleOnce } from '@ember/runloop';
import { getAnchorElement } from './popover/popover-wrapper.js';
import { inject as service } from '@ember/service';
import { isTestEnvironment } from '../utils/general.ts';

/**
 * A CSS class name applied to the popover if it is being displayed in a modal.
 *
 * @type {String}
 */
const IN_MODAL_CLASS = 'in-modal';

/**
 * A CSS class name applied to the popover if it is being displayed on a mobile screen.
 *
 * @type {String}
 */
const ON_MOBILE_CLASS = 'on-mobile';

/**
 * A CSS class name applied to the popover to be displayed stretched on the entire screen when on mobile.
 *
 * @type {String}
 */
const FULL_SCREEN_ON_MOBILE_CLASS = 'full-screen';

/**
 * A CSS class name applied to the popover to be used as a namespace for the mobile screen specific classes, to tell the difference when the popover content was
 * rendered directly or with Popper.js.
 *
 * @type {String}
 */
const DIRECT_RENDER_ON_MOBILE_CLASS = 'direct-render';

// region Helper Methods

/**
 * Handle opening and closing of the popover
 */
function handleOpenClose() {
    const { isOpen } = this;
    if (isOpen) {
        if (!getAnchorElement.call(this)) {
            console.error('No element found for popover anchor selector', this.anchorSelector);
            return;
        }
    }

    set(this, 'showPopover', isOpen);

    // Trigger the action if there is a handler defined.
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/no-get
    get(this, isOpen ? 'on-open' : 'on-close')?.();
}

/**
 * Takes a list of [ testCase, className ] pairs and returns a string of all classNames where the testCase passed.
 * TODO: In the future, import this function from @adc/ember-utils instead, if it's available.
 *
 * @param {Array<{testCase: boolean, className: String}>} classList - An array of testCase/className pairs.
 *
 * @returns {String}
 */
function buildClassListString(classList) {
    const classes = classList.reduce((appliedClasses, [shouldAddClass, className]) => {
        if (shouldAddClass) {
            appliedClasses.push(className);
        }

        return appliedClasses;
    }, []);

    return classes.join(' ');
}

// endregion

/**
 * @classdesc
 *
 * A popover component for displaying other components.
 *
 * The popover positioning is done by [Popper.js]{@link https://popper.js.org}.
 */
export default class PopoverMenu extends Component {
    // region Services

    @service media;

    // endregion

    // region Property Overrides

    /** @override */
    tagName = '';

    /**
     * This property will enable arrows to work like TAB or SHIFT + TAB
     *
     * @type {boolean}
     */
    treatArrowsAsTabEvents = false;

    // endregion

    // region Hooks

    /** @override */
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/classic-decorator-hooks
    init(...args) {
        super.init(...args);

        if (!this.anchorSelector) {
            console.error('anchorSelector for popover must be defined');
            return;
        }

        // Should the popup be open already?
        if (this.isOpen) {
            // Display popover during the next run cycle.
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            schedule('afterRender', this, handleOpenClose);
        }
    }

    // endregion

    // region Properties

    /**
     * A HtmlElement or document.querySelectorAll selector for finding the element that anchors this popover.
     *
     * @type {String|HTMLElement}
     */
    anchorSelector = '';

    /**
     * CSS class added to the popover element.
     *
     * @type {String}
     */
    popoverClass = '';

    /**
     * When the popover for this is rendered, we trigger a focus event on the popover.  Should we prevent the page from scrolling when this focus is triggered.
     *
     * @type {Boolean}
     */
    preventScroll = false;

    /**
     * The element which will define the boundaries of the popover position,
     * the popover will never be placed outside of the defined boundaries
     *
     * @type {String|HTMLElement}
     */
    boundariesElement = 'viewport';

    /**
     * Displays a loading indicator instead of the yield when true.
     *
     * @type {boolean}
     */
    isLoading = false;

    /**
     * Indicates the popover is open.
     *
     * @type {boolean}
     */
    isOpen = false;

    /**
     * Does the popover have a backdrop on large screens?
     *
     * @type {boolean}
     */
    renderBackdropOnLargeScreen = true;

    /**
     * Should the popover close when the backdrop is clicked?
     *
     * @type {boolean}
     */
    closeOnBackdropClick = true;

    /**
     * Indicates the popup should not use a backdrop, so users can interact with the background AND close the popover in one action.
     *
     * @type {boolean}
     */
    useImprovedCloseOnClick = false;

    /**
     * Whether or not to consider to directly render on mobile screens. Without Popper.js.
     *
     * NOTE: This property should be overridden by components which need their content to stay rendered inside Popper. Like the tool-tip component.
     */
    directRenderOnMobile = true;

    /**
     * Should the popover render full screen on mobile devices?
     *
     * @type {boolean}
     */
    fullScreenOnMobile = false;

    // endregion

    // region Computed Properties

    /**
     * Indicates the popover will be hosted within a modal.
     */
    @computed()
    get inModal() {
        const modal = document.body.querySelector('.modal');
        return !!modal?.contains(getAnchorElement.call(this));
    }

    /**
     * Returns the element that the backdrop should be appended to.
     *
     * @type {Node}
     */
    @computed()
    get backdropDestinationElement() {
        const { body } = window.document;

        if (isTestEnvironment.call(this)) {
            // For tests, we append the popover to the #ember-testing container
            // so that @ember/test-helpers can find and operate on it.
            return body.querySelector('#ember-testing');
        }

        return body;
    }

    /**
     * Find a DOM node to which the popover should be appended.
     *
     * This should be an offset parent of the last parent that is capable of being scrolled.
     *
     * @type {Element}
     */
    get popoverDestinationElement() {
        let bodyEl = window.document.body,
            goodParent = bodyEl;

        if (isTestEnvironment.call(this)) {
            // For tests, we append the popover to the #ember-testing container
            // so that @ember/test-helpers can find and operate on it.
            return bodyEl.querySelector('#ember-testing');
        }

        let { parentNode } = getAnchorElement.call(this),
            overflowStyles = ['overflow-x', 'overflow-y', 'overflow'],
            forbiddenStyleValue = ['auto', 'scroll', 'hidden'];

        while (parentNode !== bodyEl) {
            let style = getComputedStyle(parentNode);

            if (overflowStyles.some((key) => forbiddenStyleValue.some((value) => style[key] === value))) {
                // Move to the next available attachment element
                goodParent = parentNode = parentNode.offsetParent || bodyEl;
            } else {
                // Move to the next parent
                parentNode = parentNode.parentNode;
            }
        }

        // If the final element to append is position fixed then just use body as the append to element
        if (getComputedStyle(goodParent).position === 'fixed') {
            goodParent = bodyEl;
        }

        return goodParent;
    }

    /**
     * Observes opening and closing of the popover and triggers relevant actions if they were defined.
     */
    @action openStateChanged() {
        // Run this just once after render, otherwise it causes issues with dropdown-menu
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        scheduleOnce('afterRender', this, handleOpenClose);
    }

    /**
     * Class to be applied to the backdrop.
     *
     * @type {String}
     */
    @computed('inModal', 'isMobile')
    get popoverBackdropClass() {
        const classList = [
            [true, 'popover-backdrop'],
            [this.inModal, IN_MODAL_CLASS],
            [this.isMobile, ON_MOBILE_CLASS]
        ];

        return buildClassListString(classList);
    }

    /**
     *  Class to be applied on popover.
     *
     *  @type {String}
     */
    @computed('isMobile', 'fullScreenOnMobile', 'directRenderOnMobile', 'inModal', 'popoverClass')
    get classForPopover() {
        const classList = [
            [this.isMobile, ON_MOBILE_CLASS],
            [this.isMobile && this.fullScreenOnMobile, FULL_SCREEN_ON_MOBILE_CLASS],
            [this.directRenderOnMobile, DIRECT_RENDER_ON_MOBILE_CLASS],
            [this.inModal, IN_MODAL_CLASS],
            [this.popoverClass, this.popoverClass]
        ];

        return buildClassListString(classList);
    }

    /**
     * Defines an reads for media.isMobile so isMobile can be reached directly.
     *
     * @type {boolean}
     */
    @reads('media.isMobile')
    isMobile;

    /**
     * Indicates we will be rendering a backdrop.
     *
     * @type {boolean}
     */
    @or('renderBackdropOnLargeScreen', 'isMobile')
    usesBackdrop;

    /**
     * Does the popover have a backdrop?
     *
     * @type {boolean}
     */
    @computed('useImprovedCloseOnClick', 'usesBackdrop')
    get hasBackdrop() {
        return !this.useImprovedCloseOnClick && this.usesBackdrop;
    }

    /**
     * Indicates the popover should handle close as opposed to a local backdrop element.
     *
     * @type {boolean}
     */
    @and('useImprovedCloseOnClick', 'usesBackdrop')
    enhancedCloseOnClick;

    // endregion

    // region Actions

    /** @ignore */
    @action willClose() {
        if (this.enhancedCloseOnClick) {
            this.popoverBackdropClicked();
        }
    }

    /**
     * Toggles the popover
     */
    @action togglePopover() {
        set(this, 'isOpen', !this.isOpen);
    }

    /**
     * Closes the popover
     */
    @action closePopover() {
        set(this, 'isOpen', false);
    }

    /**
     * When the backdrop of the popover is clicked send the backdrop-clicked
     * action if it exists and then send the closePopover action
     */
    @action popoverBackdropClicked() {
        // If there is a handler, send the action.
        this['on-backdrop-click']?.();

        if (this.closeOnBackdropClick) {
            this.closePopover();
        }
    }

    // endregion
}
