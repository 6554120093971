/**
 * Ember related helpers.
 *
 * @module
 */

import { get } from '@ember/object';

/**
 * Tests the passed Ember.Object to see if it has been destroyed, or is in the process of being destroyed.
 */
export function isDestroyed(object: unknown): boolean {
    return ['isDestroyed', 'isDestroying'].some((name) => get(object || [], name as keyof typeof object));
}

/**
 * Extracts properties from the passed object and returns their values in an array.
 */
export function getObjProperties<T, K extends keyof T>(obj: T, ...names: K[]): T[K][] {
    return names.map((name) => get(obj, name) as T[K]);
}

/**
 * Takes a list of [ testCase, className ] pairs and returns a string of all classNames where the testCase passed.
 *
 * @param {Array<{testCase: boolean, String:className}>} classList - An array of testCase/className pairs.
 *
 * @returns {String}
 */
export function buildClassListString(classList: (boolean | string)[][]): string {
    const classes = classList.reduce((appliedClasses, [shouldAddClass, className]) => {
        if (shouldAddClass) {
            appliedClasses.push(className);
        }

        return appliedClasses;
    }, []);

    return classes.join(' ');
}
