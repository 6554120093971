import Component from '@glimmer/component';

import type { PageViewSignature } from '../page-view';
import type { PageViewContainerSignature } from '../page-view/container';
import type { PageViewDeleteSignature } from './delete';

interface PageViewDetailsSignature<M> {
    Element: PageViewSignature<M>['Element'];
    Args: Pick<PageViewContainerSignature<M>['Args'], 'model' | 'dataLoaded'> & {
        /** Information about the delete operation. */
        deleteModel?: PageViewDeleteSignature['Args']['model'];
        /** Optional CSS class applied to the `<PageView::Container />` component. */
        cssContainer?: string;
    };
    Blocks: {
        default: [PageViewContainerSignature<M>['Blocks']['default'][0], PageViewSignature<M>['Blocks']['default'][1]];
    };
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class PageViewDetails<M> extends Component<PageViewDetailsSignature<M>> {}
