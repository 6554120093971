import { SELECTED } from '../utils/dropdown-select-item.js';

export default function isItemSelected(state: number): boolean {
    return state === SELECTED;
}

export default interface Registry {
    /**
     * Should the item be selected in the dropdown?
     * @todo I don't think this is in use anymore.
     */
    'is-item-selected': typeof isItemSelected;
}
