import { getOwner } from '@ember/owner';

import type { InternalOwner } from '@ember/-internals/owner';

export function isTestEnvironment(this: object): boolean {
    const owner = getOwner(this),
        env = owner ? (owner as InternalOwner).resolveRegistration('config:environment') : {};

    return (env && 'environment' in env && env.environment === 'test') || false;
}
