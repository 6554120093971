import { resolve } from 'rsvp';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { getOwner } from '@ember/owner';
import Component from '@glimmer/component';

import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type NativeBridgeService from '@adc/app-infrastructure/services/native-bridge';
import type { NativeButton } from '@adc/app-infrastructure/services/native-bridge';
import type { NativeButtonSignature } from './native-button';
import type { InternalOwner } from '@ember/-internals/owner';

type NativeButtonArgs = NativeButtonSignature['Args'];

export interface ActionButtonsSignature {
    Element: HTMLDivElement;
    Args: Pick<NativeButtonArgs, 'updateNativeToolbarButtons' | 'disposeNativeButtons'> & {
        /** Optional confirm button text (defaults to "OK"). */
        confirmButtonText?: NativeButtonArgs['text'];
        /** Indicates the confirm button is visible (defaults to `true`). */
        confirmButtonVisible?: boolean;
        /** Indicates the confirm button should be disabled. */
        confirmButtonDisabled?: boolean;
        /** Optional cancel button text (defaults to "CANCEL"). */
        cancelButtonText?: NativeButtonArgs['text'];
        /** Indicates the cancel button is visible (defaults to `true`). */
        cancelButtonVisible?: boolean;
        /** Indicates the cancel button should be disabled. */
        cancelButtonDisabled?: boolean;
        /** Indicates application should be redirected to the previous route after use clicks on either of the buttons */
        shouldGoBackAfterAction?: boolean;
        /** Triggered when the user interacts with the confirm button. */
        'confirm-action'?: (...params: any[]) => void;
        /** Triggered when the user interacts with the cancel button. */
        'cancel-action'?: (...params: any[]) => void;
    };
}

/**
 * @classdesc
 *
 * Component for displaying Cancel and Confirm buttons in a controlled and unified way.
 */
export default class ActionButtons extends Component<ActionButtonsSignature> {
    @service declare nativeBridge: NativeBridgeService;
    @service declare intl: ADCIntlService;

    // region Confirm button.
    /**
     * Should confirm button be visible?
     */
    @computed('args.confirmButtonVisible')
    get confirmButtonVisible(): boolean {
        return this.args.confirmButtonVisible ?? true;
    }

    /**
     * Native type for the confirm button.
     */
    get confirmButtonNativeType(): NativeButton['type'] {
        return this.nativeBridge.getButtonTypeConfirm();
    }

    // endregion

    // region Cancel button.

    /**
     * Should cancel button be visible?
     */
    @computed('args.cancelButtonVisible')
    get cancelButtonVisible(): boolean {
        return this.args.cancelButtonVisible ?? true;
    }

    /**
     * Native type for the cancel button.
     */
    @computed('args.shouldGoBackAfterAction')
    get cancelButtonNativeType(): NativeButton['type'] {
        const { nativeBridge } = this;
        return this.args.shouldGoBackAfterAction
            ? nativeBridge.getButtonTypeBack()
            : nativeBridge.getButtonTypeCancel();
    }

    // endregion

    /**
     * Action that is triggered when the cancel button is clicked.
     */
    @alias('nativeBridge.doesSupportToolbarButtons')
    declare shouldHide: boolean;

    /**
     * Action that is triggered when the confirm button is clicked.
     */
    @action
    confirmAction(...params: any[]): void {
        return this.callAction('confirm-action', params);
    }

    /**
     * Action that is triggered when the cancel button is clicked.
     */
    @action
    cancelAction(...params: any[]): void {
        return this.callAction('cancel-action', params);
    }

    /**
     * Calls the specified action and returns its result.
     */
    private callAction(actionName: 'cancel-action' | 'confirm-action', params: any[]): void {
        const { [actionName]: action, shouldGoBackAfterAction } = this.args,
            delayedHandler = (params ?? [])[0],
            result = action?.(...params);

        // If we want to transition back after action:
        //  - If there was action, wait for the delayedHandler (params[0]) to resolve
        //      - Otherwise wait for the action promise.
        //  - Transition to the previous page after one of those things resolves.
        if (shouldGoBackAfterAction) {
            // Only use delayed handler if action was defined. Otherwise it might wait forever on the delayed handler,
            // for example if this is just a cancel button that should transition to the previous route.
            resolve((action && delayedHandler) || result).then(() => {
                const owner = getOwner(this);
                if (owner) {
                    const api = ((owner as InternalOwner).lookup('@adc/ui-components:appValues') ?? {}) as {
                        navigationApi?: { transitionToPreviousRoute: VoidFunction };
                    };
                    api.navigationApi?.transitionToPreviousRoute?.();
                }
            });
        }

        return result;
    }
}
