import { tracked } from '@glimmer/tracking';

import type { SafeString } from '@ember/template/-private/handlebars';

/**
 * A helper class to render list item elements of a simple-list components.
 */
export default class SimpleListItem<T = object> {
    @tracked css?: string;
    @tracked icon?: string;
    @tracked description?: string | SafeString;
    @tracked details?: string | SafeString;
    @tracked value?: string | SafeString;
    @tracked props: T;

    constructor(args: Partial<SimpleListItem<T>>) {
        Object.assign(this, args);
        this.props = (args.props ?? {}) as T;
    }
}
