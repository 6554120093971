/**
 * @module
 */
/**
 * @enum {number}
 */
export const None = 0;
/**
 * @enum {number}
 */
export const EnterpriseToSingleSystem = 1;
/**
 * @enum {number}
 */
export const SingleSystemToEnterprise = 2;
/**
 * @enum {number}
 */
export const EnterpriseToGroup = 3;
/**
 * @enum {number}
 */
export const GroupToEnterprise = 4;
