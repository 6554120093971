import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import type ModalService from '../services/modals';

type MethodParameters = Parameters<ModalService['showConfirmationDialog']>;

export interface ShowConfirmationModalSignature {
    Args: {
        Named: {
            title: MethodParameters[0];
            desc: MethodParameters[1];
            confirmButtonProperties?: MethodParameters[2];
            cancelButtonProperties?: MethodParameters[3];
            cssClass?: MethodParameters[4];
        };
    };
    Return: () => ReturnType<ModalService['showConfirmationDialog']>;
}

/**
 * Returns a function that when executed will open the confirmation dialog.
 */
export default class ShowConfirmationModal extends Helper<ShowConfirmationModalSignature> {
    @service declare modals: ModalService;

    compute(
        _: never[],
        {
            title,
            desc,
            confirmButtonProperties,
            cancelButtonProperties,
            cssClass
        }: {
            title: MethodParameters[0];
            desc: MethodParameters[1];
            confirmButtonProperties?: MethodParameters[2];
            cancelButtonProperties?: MethodParameters[3];
            cssClass?: MethodParameters[4];
        }
    ): () => ReturnType<ModalService['showConfirmationDialog']> {
        return () =>
            this.modals.showConfirmationDialog(title, desc, confirmButtonProperties, cancelButtonProperties, cssClass);
    }
}
