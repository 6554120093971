import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { equal } from '@ember/object/computed';
import { timeout, task } from 'ember-concurrency';
import { getSimpleButtonStyle } from './simple.ts';
import { computed } from '@ember/object';

import type { ButtonSimpleSignature } from './simple';
import type { ButtonIconSignature } from './icon';
import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { Task } from 'ember-concurrency';

export enum EnumAsyncState {
    idle,
    pending,
    success,
    failure
}

interface ButtonAsyncCommonArgs {
    /** A required function called when the button is clicked.  If a promise is returned this button will show the status of that button (rejects or success). */
    buttonAction: (buttonAnimation?: Promise<boolean>, e?: MouseEvent) => Promise<any> | any;
    /** Indicates the `buttonAnimation` promise should be resolved immediately instead of waiting (700ms for success, 3000ms for failure). */
    noDelay?: boolean;
    /** Hack used for native buttons, please don't use this. */
    updateAsyncState?: (value: EnumAsyncState) => void;
}

interface ButtonAsyncSimpleArgs extends Omit<ButtonSimpleSignature['Args'], 'buttonAction'>, ButtonAsyncCommonArgs {
    icon?: undefined;
    iconOnly?: undefined;
}

export interface ButtonAsyncSignature {
    Element: ButtonIconSignature['Element'];
    Args: (Omit<ButtonIconSignature['Args'], 'buttonAction'> & ButtonAsyncCommonArgs) | ButtonAsyncSimpleArgs;
    Blocks: ButtonIconSignature['Blocks'];
}

/**
 * A button (icon or simple) that displays async state from promise.
 */
export default class ButtonAsync extends Component<ButtonAsyncSignature> {
    @service declare intl: ADCIntlService;

    get simpleButtonStyle(): ReturnType<typeof getSimpleButtonStyle> {
        return getSimpleButtonStyle(this.args.buttonStyle);
    }
    /**
     * The current async promise state.
     */
    @tracked asyncState = EnumAsyncState.idle;

    /**
     * Indicates the async promise is currently pending.
     */
    @equal('asyncState', EnumAsyncState.pending)
    declare isPending: boolean;

    /**
     * Indicates the async promise has completed (success or failure).
     */
    get isFinished(): boolean {
        const { asyncState } = this;
        return asyncState === EnumAsyncState.success || asyncState === EnumAsyncState.failure;
    }

    @computed('isPending', 'isFinished', 'asyncType')
    get ariaLabel(): string {
        return this.isPending
            ? this.intl.t('@adc/ui-components.loading')
            : this.isFinished
            ? this.intl.t(`@adc/ui-components.${this.asyncType}`)
            : '';
    }

    /**
     * The textual representation of the current async state.
     */
    get asyncType(): string {
        return {
            [EnumAsyncState.idle]: '',
            [EnumAsyncState.pending]: 'pending',
            [EnumAsyncState.success]: 'success',
            [EnumAsyncState.failure]: 'warning'
        }[this.asyncState];
    }

    /**
     * A task to animate promise state changes based on promise returned from `onClick` argument.
     */
    executeButtonAction: Task<void, [MouseEvent]> = task({ drop: true }, async (e: MouseEvent) => {
        const { buttonAction } = this.args;
        if (!buttonAction) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let buttonAnimation = (_isSuccess: boolean) => {},
            isPromise = false;

        try {
            // Execute button action function.
            const p = buttonAction(
                new Promise((r) => (buttonAnimation = r as unknown as (_isSuccess: boolean) => void)),
                e
            );

            // Is the return value a promise?
            if (p && typeof p.then === 'function') {
                isPromise = true;
                this.asyncState = EnumAsyncState.pending;
                await p;
                this.asyncState = EnumAsyncState.success;
            }
        } catch (ex) {
            if (isPromise) {
                this.asyncState = EnumAsyncState.failure;
            }
        } finally {
            if (isPromise) {
                const isSuccess = this.asyncState === EnumAsyncState.success;

                if (!this.args.noDelay) {
                    await timeout(isSuccess ? 700 : 3000);
                }

                this.asyncState = EnumAsyncState.idle;

                // Pass success/fail state out with button animation.  Considered using promise
                // reject/resolve instead, but unhandled rejects raise application errors.
                buttonAnimation(isSuccess);
            }
        }
    });
}
