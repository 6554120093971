import templateOnlyComponent from '@ember/component/template-only';

export interface HeaderContentSignature {
    Element: HTMLDivElement;
    Args: {
        /** The header items to render. */
        items?: { css?: string; text: string }[];
    };
    Blocks: {
        default: [];
    };
}

const RouteViewViewContainerHeaderContent = templateOnlyComponent<HeaderContentSignature>();
export default RouteViewViewContainerHeaderContent;
