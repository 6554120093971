import Mixin from '@ember/object/mixin';
import { PAGE_MAX_WIDTH_MEDIUM } from '../utils.ts';

/**
 * @classdesc
 *
 * Sets medium width for page rendering.
 *
 * @mixin IsMediumWidthPage
 * @extends Mixin
 * @memberof routes.mixins
 */
// TODO: This ignore is an existing deprecation that must be refactored, do not copy this usage
// eslint-disable-next-line ember/no-new-mixins
const IsMediumWidthPage = Mixin.create(
    /** @lends routes.mixins.IsMediumWidthPage# */ {
        /**
         * @override
         */
        pageMaxWidth: PAGE_MAX_WIDTH_MEDIUM
    }
);

export default IsMediumWidthPage;
