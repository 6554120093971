import Component from '@glimmer/component';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { equal } from '@ember/object/computed';
import { timeout, task } from 'ember-concurrency';

import type { SimpleBinarySignature } from '../simple-binary';
import type { Task } from 'ember-concurrency';

type AsyncState = 'idle' | 'pending' | 'success' | 'failure';

export interface AsyncToggleSignature {
    Element: SimpleBinarySignature['Element'];
    Args: Pick<
        SimpleBinarySignature['Args'],
        'checked' | 'errorMessage' | 'errorDuration' | 'errorTooltipPlace' | 'icon' | 'label' | 'css'
    > & {
        /** Triggered when the async state is "pending". */
        onChange: (state: boolean) => Promise<unknown>;
    };
}

export default class AsyncToggle extends Component<AsyncToggleSignature> {
    @tracked state: AsyncState = 'idle';

    get css(): string {
        const { state } = this;

        return A(['async-toggle', `async-${state}`, state === 'failure' ? 'has-error' : undefined, this.args.css])
            .compact()
            .join(' ');
    }

    @equal('state', 'pending')
    declare isPending: boolean;

    animateState: Task<void, [MouseEvent]> = task(
        { drop: true },
        async (evt: MouseEvent & { target: HTMLInputElement }) => {
            evt.preventDefault();

            this.state = 'idle';

            try {
                this.state = 'pending';
                await this.args.onChange(!!evt.target.checked);
                this.state = 'success';
            } catch {
                this.state = 'failure';
            } finally {
                await timeout(this.state === 'success' ? 700 : 3000);

                this.state = 'idle';
            }
        }
    );
}
