import templateOnlyComponent from '@ember/component/template-only';

import type { ModalHeaderSignature } from '../../base/header/modal-header';
import type { ModalFooterSignature } from '../../base/footer/modal-footer';

type ModalHeaderArgs = ModalHeaderSignature['Args'];
type ModalFooterArgs = ModalFooterSignature['Args'];

export interface SimpleDialogSignature {
    Element: HTMLDivElement;
    Args: Pick<ModalHeaderArgs, 'title'> & {
        /** Indicates the header close button should not be visible. */
        disableHeaderClose?: ModalHeaderArgs['disableCloseButton'];
        /** The buttons to render in the modal footer. */
        buttons?: ModalFooterArgs['buttons'];
        /** Indicates the close button should not be added to the collection of footer buttons. */
        disableFooterClose?: ModalFooterArgs['disableCloseButton'];
        /** Optional text for the automatic close button. */
        footerCloseText?: ModalFooterArgs['closeButtonText'];
    };
    Blocks: {
        default: [];
    };
}

const SimpleDialog = templateOnlyComponent<SimpleDialogSignature>();
export default SimpleDialog;
