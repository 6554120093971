import templateOnlyComponent from '@ember/component/template-only';

export interface PopoverHeaderSignature {
    Element: HTMLElement;
    Args: {
        /** The text to render in the popover header. */
        title?: string;
    };
    Blocks: {
        default: [];
    };
}

const PopoverHeader = templateOnlyComponent<PopoverHeaderSignature>();
export default PopoverHeader;
