import PopoverMenu from './popover-menu.js';

/**
 * @classdesc
 *
 * A popover component without a backdrop that is styled as a tooltip.
 *
 * The popover positioning is done by [Popper.js]{@link https://popper.js.org}
 */
export default class Tooltip extends PopoverMenu {
    // region Property Overrides

    /** @override */
    placement = 'top';

    /** @override */
    popoverClass = 'tool-tip default';

    /** @override */
    minHeight = 0;

    /** @override */
    minWidth = 0;

    /** @override */
    hasBackdrop = false;

    /** @override */
    fullScreenOnMobile = false;

    /** @override */
    directRenderOnMobile = false;

    // endregion
}
