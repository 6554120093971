import Service from '@ember/service';
import { getErrorWithStatusCode, isNoNetworkError } from '../utils/errors.ts';
import { computed } from '@ember/object';
import { getOwner } from '@ember/owner';
import Error from '@ember/error';
import { getCookie } from '@adc/ember-utils/utils/browser-helpers';
import { TwoFactorAuthenticationRequired } from '../enums/AjaxResponseHttpCode.ts';
import isBefore from 'date-fns/isBefore';

import type { InternalOwner } from '@ember/-internals/owner';

// #region Content Constants

const APPLICATION_JSON = 'application/json;charset=utf-8';
const APPLICATION_JSONAPI = 'application/vnd.api+json;charset=utf-8';
const APPLICATION_FORM = 'application/x-www-form-urlencoded;charset=utf-8';

// #endregion

// #region httpVerbs

/**
 * POST httpVerb enum for using in requests.
 *
 * @memberof AdcAjaxService
 */
export const POST = 'POST';

/**
 * POST httpVerb enum for using in requests.
 *
 * @memberof AdcAjaxService
 */
export const PUT = 'PUT';

/**
 * GET httpVerb enum for using in requests.
 *
 * @memberof AdcAjaxService
 */
export const GET = 'GET';

// #endregion

interface AppConfig {
    apiBaseUrl: string;
    antiForgeryCookieName: string;
    requestJsonApiResponse: boolean;
}

interface AjaxHeaders {
    'content-type': string;
    accept?: string;
    ajaxrequestuniquekey?: string;
}

interface ApiErrorSource {
    pointer: string;
}

interface MicroApiData {
    included: MicroApiEndpointData[];
    data: {
        attributes: {
            microApiTokenVer: string;
        };
    }[];
}

interface MicroApiEndpointData {
    id: number;
    attributes: {
        baseApiEndpoint: string;
        encodedJwtToken: string;
        jwtExpirationDate: string;
    };
}

export interface ApiError {
    code: number;
    detail: string;
    source: ApiErrorSource;
}

interface SerializableObject {
    serialize(options?: { includeId?: boolean | undefined }): object;
}

export type AjaxRequestBody = BodyInit | Record<string, unknown> | SerializableObject | unknown[] | null;

// #region data and content types

/**
 * Type enum for JSONAPI request.
 *
 * @memberof AdcAjaxService
 */
export const JSONAPI_REQUEST: Partial<RequestInit> = {
    headers: {
        'content-type': APPLICATION_JSON,
        accept: APPLICATION_JSONAPI
    }
};

/**
 * Type enum for JSON_REQUEST request.
 *
 * @memberof AdcAjaxService
 */
export const JSON_REQUEST: Partial<RequestInit> = {
    headers: {
        'content-type': APPLICATION_JSON,
        accept: APPLICATION_JSON
    }
};

/**
 * Type enum for URL_ENCODED request.
 *
 * @memberof AdcAjaxService
 */
export const URL_ENCODED: Partial<RequestInit> = {
    headers: {
        'content-type': APPLICATION_FORM,
        accept: APPLICATION_JSON
    }
};

/**
 * Type enum for a plain text request.
 *
 * @memberof AdcAjaxService
 */
export const CONTENT_TYPE_PLAIN: Partial<RequestInit> = {
    headers: {
        'content-type': 'text/plain;charset=utf-8'
    }
};

// #endregion

class EmberAjaxError extends Error {
    code: number;
    type: string;
    name: string;
    errors: ApiError[];

    constructor(message: string, code: number, type: string, errors: ApiError[]) {
        super(message);

        this.code = code;
        this.type = this.name = type;
        this.errors = errors;
    }
}

export type TEmberAjaxError = InstanceType<typeof EmberAjaxError>;

/**
 * Gets data from fetch response.
 */
async function parseResponse(response: Response, method: string): Promise<Record<string, unknown>> {
    let data = await response.text();

    try {
        data = JSON.parse(data);
    } catch (error) {
        if (!(error instanceof SyntaxError)) {
            throw error;
        }

        const { ok, status } = response;

        if (ok && (status === 204 || status === 205 || method === 'HEAD')) {
            return { data: null };
        }
    }

    return data as unknown as Record<string, unknown>;
}

/**
 * @classdesc
 * The Ajax service configured to work with the CustomerDotNet/WebApi project.
 */
export default class AdcAjaxService extends Service {
    /**
     * Cached list of MicroApi endpoints and the token version
     */
    microApiData?: MicroApiData;

    /**
     * The Ember app environment config.
     */
    get environmentConfig(): AppConfig {
        const owner = getOwner(this);
        // We provide a default value because unit tests do not have a config.
        return (owner ? (owner as InternalOwner).resolveRegistration('config:environment') : {}) as AppConfig;
    }

    // #region Ajax Config Params

    /**
     * Defines headers for all requests. This includes the authenticationHeaders
     */
    get defaultHeaders(): Partial<AjaxHeaders> {
        // The cookie name set in the env config.
        const envAntiForgeryCookieName = this.environmentConfig.antiForgeryCookieName,
            /* The cookie name we will actually use. If the env cookie was set to an empty string, we do not include an anti forgery cookie */
            antiForgeryCookieName = envAntiForgeryCookieName === '' ? undefined : envAntiForgeryCookieName || 'afg';

        // Default accept headers to fix issues with older Firefox.  We explicitly set the content-type to match the `fetch` default to avoid any ambiguity or breaking changes
        // when we leave fetch for the next great thing.
        const headers = {
            ...{ accept: '*/*' },
            ...CONTENT_TYPE_PLAIN.headers
        } as AjaxHeaders;

        // Add anti-forgery token if it was defined in application settings.
        if (antiForgeryCookieName) {
            // We use all lower case since this is how it ends up hitting the server anyway.
            headers.ajaxrequestuniquekey = getCookie(antiForgeryCookieName);
        }

        return headers;
    }

    /**
     * The base URL that should be included on all calls to apiRequest.
     */
    @computed('environmentConfig.apiBaseUrl')
    get apiBaseUrl(): string {
        // Get the host App's config environment variable.
        return this.environmentConfig.apiBaseUrl ?? '';
    }

    // #endregion

    /**
     * Send request to a WebApi endpoint.
     */
    async apiRequest<T>(
        apiEndpoint: string,
        optionsOrType: RequestInit = {},
        data?: AjaxRequestBody,
        httpVerb = GET,
        useJsonApiFormat?: boolean
    ): Promise<T> {
        // We use a copy of the options passed in.  Otherwise we would be mutating the original options, which could be one of our enums like JSON_REQUEST.
        const newOptions = { ...optionsOrType };

        // The following block's logic is a little hard to digest. Our intended outcome is:
        // If a user has no ENV setting and does not provide useJsonApiFormat, it will default to true and use JSON API.
        // If a user has an ENV setting and does not provide useJsonApiFormat, it will use the ENV setting.
        // If a user provides a useJsonApiFormat value, it will use that no matter what the ENV setting is.
        const envUseJsonApi = this.environmentConfig.requestJsonApiResponse ?? true;

        // These are the headers that this method sets.
        const defaultHeaders = {
            ...this.defaultHeaders,
            ...(useJsonApiFormat ?? envUseJsonApi ? JSONAPI_REQUEST.headers : {})
        };

        // We then merge defaultHeaders in with any headers provided by the caller.
        newOptions.headers = {
            ...defaultHeaders,
            ...newOptions.headers
        };

        // If apiEndpoint has a leading '/', we don't want to add our own.
        const joinChar = apiEndpoint[0] === '/' ? '' : '/';

        return this.request<T>(`${this.apiBaseUrl}${joinChar}${apiEndpoint}`, newOptions, data, httpVerb, true);
    }

    /**
     * Send a request to a MicroApi endpoint given the endpoint enum & path.
     */
    async microApiRequest<T>(
        microApiEnum: number,
        apiEndpoint: string,
        data?: AjaxRequestBody,
        httpVerb = GET
    ): Promise<T> {
        if (!this.microApiData) {
            const microApiDataRaw = await this.apiRequest<MicroApiData>('microApiDataItems', {}, null, GET, true);
            this.microApiData = microApiDataRaw;
        }

        const endpointData = this.microApiData?.included.find(
            (item: MicroApiEndpointData) => Number(item.id) === microApiEnum
        )?.attributes;

        if (!endpointData) {
            throw 'Micro API endpoint not found';
        }

        const now = new Date();
        // We want to give 60 seconds of padding before expiration time to not hit any race conditions
        if (isBefore(new Date(endpointData.jwtExpirationDate), now.setSeconds(now.getSeconds() - 60))) {
            this.microApiData = undefined;
            return await this.microApiRequest(microApiEnum, apiEndpoint, data, httpVerb);
        }

        const { pathname, search } = window.location,
            headers = {
                Accept: 'application/vnd.api+json',
                Authorization: `Bearer ${endpointData.encodedJwtToken}`,
                SourcePath: pathname,
                SourceQueryParams: search,
                TokenVersion: this.microApiData?.data?.[0]?.attributes?.microApiTokenVer ?? '',
                'content-type': 'application/vnd.api+json'
            };

        // Avoid automatic route prefixing
        const subEndpoint = `${endpointData.baseApiEndpoint}/${apiEndpoint}`,
            finalEndpoint = subEndpoint.startsWith('https://') ? subEndpoint : `https://${subEndpoint}`;

        return this.request<T>(finalEndpoint, { headers }, data, httpVerb, false);
    }

    /**
     * Serializes the passed request body.
     */
    private serializeBody(data: AjaxRequestBody): BodyInit | null | undefined {
        if (data === null || typeof data !== 'object') {
            return data;
        }

        if ('serialize' in data && typeof data.serialize === 'function') {
            data = data.serialize();
        }

        return JSON.stringify(data);
    }

    /**
     * Ajax Request method to be used when not accessing api resources.
     */
    async request<T>(
        url: string,
        optionsOrType: RequestInit = {},
        data?: AjaxRequestBody,
        httpVerb = GET,
        includeHeaders = true
    ): Promise<T> {
        try {
            // We use a copy of the options passed in.  Otherwise we would be mutating the original options, which could be one of our enums like JSON_REQUEST.
            const newOptions = { ...optionsOrType },
                defaultHeaders = includeHeaders ? this.defaultHeaders : {};

            // Build the options. Note that user provided options take priority over others.
            newOptions.headers = {
                ...defaultHeaders,
                ...newOptions.headers
            };

            // if the user passed in the method in the option object, we give that priority over the function parameter.
            newOptions.method = newOptions.method || httpVerb;

            if (data) {
                newOptions.body = this.serializeBody(data);
            }

            const response = await fetch(url, newOptions);

            const { status } = response,
                { method } = newOptions,
                result = await parseResponse(response, method);

            // If everything was ok or 2FA is required, just resolve the result and return.
            if (response.ok || status == TwoFactorAuthenticationRequired) {
                return result as unknown as T;
            }

            // Things went bad, create an Error and throw it.
            const errorData = getErrorWithStatusCode(status);

            if (!errorData) {
                throw new Error(`Unhandled HTTP Error Code: ${status}`);
            }

            const { code, message, type, title } = errorData;
            throw new EmberAjaxError(
                `${message || title}, url: ${decodeURIComponent(url)}, method: ${method}`,
                code,
                type,
                (result.errors || []) as ApiError[]
            );
        } catch (e) {
            // We don't want to throw error to sentry if it's just no network connection issue.
            if (!this.processNoNetworkError()) {
                throw e;
            }

            return {
                data: null
            } as unknown as T;
        }
    }

    /**
     * Simple request method which attempts to nullify most of our customization on the request method.
     */
    async simpleRequest<T>(
        url: string,
        optionsOrType?: RequestInit,
        data?: AjaxRequestBody,
        httpVerb?: string
    ): Promise<T> {
        return this.request<T>(url, optionsOrType, data, httpVerb, false);
    }

    /**
     * This logic is extracted for testing purposes. To my knowledge, there is currently not a way to mock a network error.
     */
    processNoNetworkError(): boolean {
        if (isNoNetworkError()) {
            this.noInternetConnection();
            return true;
        }

        return false;
    }

    /**
     * This method is used to indicate when there is a network connection error. It can be overridden in your project to handle this error however you wish.
     */
    noInternetConnection(): unknown | void {
        return null;
    }
}
