import Component from '@glimmer/component';

import type { SafeString } from 'handlebars';

export interface AsyncButtonIconSignature {
    Element: HTMLDivElement;
    Args: {
        /** Whether to show "success" or "warning" (all other values ignored). */
        icon: 'success' | 'warning' | string;
        /** Optional CSS class for the SVG element. */
        iconCss?: string;
        /** Optional title rendered into the SVG `<title />` element. */
        title?: string | SafeString;
    };
}

/**
 * @classdesc
 * A component to provide hardcoded svg icons for button-adc.
 */
export default class AsyncButtonIcon extends Component<AsyncButtonIconSignature> {
    get d(): string {
        const { icon } = this.args;
        if (icon === 'success') {
            return 'M19.776 5L8.84 15.935l-5.426-5.424L2 11.925l6.84 6.84.707-.709.707-.705L21.19 6.413z';
        }

        if (icon === 'warning') {
            return 'M11 15h2V5h-2v10zm0 4h2v-2h-2v2zm1-17C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2z';
        }

        return '';
    }
}
