import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { assert } from '@ember/debug';
import { task } from 'ember-concurrency';
import { htmlSafe } from '@ember/template';

import type { Task } from 'ember-concurrency';

/**
 * A helper function for getting a CSS selector for the card that represents the given card Id.
 */
export const getSpriteSelector = 'svg-sprite-id';

export interface SvgSpriteLoaderSignature {
    Element: HTMLDivElement;
    Args: {
        /** The path the server folder that hosts that sprites. */
        spriteRootPath: string;
        /** The collection of sprites to load. */
        spritePaths?: string[];
    };
}

/**
 * @classdesc
 * A component for requesting and rendering a collection of SVG sprites.
 */
export default class SvgSpriteLoader extends Component<SvgSpriteLoaderSignature> {
    /**
     * The retrieved sprite markup.
     */
    @tracked spriteMarkup: ReturnType<typeof htmlSafe>[] = [];

    /**
     * Requests required SVG sprites during initialization.
     */
    loadSprites: Task<void, never> = task({ drop: true }, async () => {
        // Initialize SVG sprite collection.
        const { spriteRootPath, spritePaths } = this.args;

        assert('[@adc/svg-system] The spriteRootPath must be specified.', spriteRootPath);

        // Are there any sprite paths.
        if (spritePaths?.length) {
            // Request all sprites.
            const spritesData: string[] = await Promise.all(
                spritePaths.map(async (name) => {
                    const spriteUrl = `${spriteRootPath}/${name}.xml`;

                    try {
                        const response = await window.fetch(spriteUrl, {
                                headers: {
                                    'Content-Type': 'xml',
                                    Accept: 'text/xml'
                                },
                                cache: 'force-cache'
                            }),
                            text = await response.text();

                        // filter out the undefined data and strip the xml tags.
                        return (text?.replace(/<\?xml[\s\S]*?\?>/, '') ?? '').replace(
                            /{{svg-sprites-id}}/,
                            getSpriteSelector
                        );
                    } catch (err: any) {
                        console.error(`Error fetching SVG sprite from ${spriteUrl}. ${err.code} - ${err.message}`);
                        return '';
                    }
                })
            );
            this.spriteMarkup = spritesData.filter((d) => !!d).map((d) => htmlSafe(d));
        }
    });
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * A component for requesting and rendering a collection of SVG sprites.
         */
        SvgSpriteLoader: typeof SvgSpriteLoader;
    }
}
