import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { assert } from '@ember/debug';
import { A } from '@ember/array';

import type { ModalHeaderSignature } from '../../base/header/modal-header';
import type { ModalFooterSignature } from '../../base/footer/modal-footer';

type SelectionFn = () => unknown;

/**
 * The base class for NavItem as well as the concrete class for NavItem sub items.
 */
export class SubItem {
    @tracked description = '';
    @tracked selected = false;
    onSelection: () => unknown;
    data: any = {};

    constructor(fn: SelectionFn, props: Partial<Exclude<SubItem, 'onSelection'>> = {}) {
        assert(
            '[@adc/ui-components] The `onSelection` function must be passed to the <NavDialog /> item constructor',
            fn
        );

        this.onSelection = fn;
        this.description = props.description ?? '';
        this.selected = props.selected ?? false;
        this.data = props.data ?? {};
    }
}

/**
 * The source for navigation items in the navigation dialog.
 */
export class NavItem extends SubItem {
    @tracked expanded = false;
    subItems: SubItem[] = [];

    constructor(fn: SelectionFn, props: Partial<Exclude<NavItem, 'onSelection'>> = {}) {
        super(fn, props);

        this.expanded = props.expanded ?? false;
        this.subItems = props.subItems ?? [];
    }
}

type ModalHeaderArgs = ModalHeaderSignature['Args'];
type ModalFooterArgs = ModalFooterSignature['Args'];

interface NavDialogArgs extends Pick<ModalHeaderArgs, 'title'>, Pick<ModalFooterArgs, 'buttons'> {
    /** The navigation items to render. */
    navItems: NavItem[];
    /** Indicates the header close button should not be visible. */
    disableHeaderClose?: ModalHeaderArgs['disableCloseButton'];
    /** Triggered when the user interacts with the close button. */
    customHeaderClose?: ModalHeaderArgs['customClose'];
    /** Indicates the close button should not be added to the collection of footer buttons. */
    disableFooterClose?: ModalFooterArgs['disableCloseButton'];
    /** Optional text for the automatic close button. */
    footerCloseText?: ModalFooterArgs['closeButtonText'];
}

export interface NavigationDialogSignature {
    Element: HTMLDivElement;
    Args: NavDialogArgs;
    Blocks: {
        /** The navigation item section (right side). */
        'nav-item': [NavItem];
        /** A sub nav item within the navigation section (not currently in use). */
        'sub-nav-item': [SubItem];
        /** The body of the modal. */
        default: [];
    };
}

/**
 * @classdesc
 * A navigation dialog
 */
export default class NavigationDialog extends Component<NavigationDialogSignature> {
    /**
     * Is the nav visible on a mobile device?
     */
    @tracked showNavMobile = false;

    /**
     * Array of navigational items for the nav pane.
     */
    @computed('args.navItems')
    get navigationItems(): ReturnType<typeof A<NavItem>> {
        return A<NavItem>(this.args.navItems ?? []);
    }

    @action initSelectedItem(): void {
        const { navigationItems } = this;
        if (!navigationItems.findBy('selected')) {
            const firstItem = navigationItems.firstObject;
            if (firstItem) {
                this.selectItem(firstItem);
            }
        }
    }

    /**
     * Called from the template when the user selects a nav item.
     */
    @action selectItem(selectedItem: SubItem): boolean {
        const items = this.navigationItems;

        if (selectedItem instanceof NavItem) {
            // Reset all items and sub item values.
            items.forEach((item) => {
                item.selected = item.expanded = false;
                A(item.subItems).setEach('selected', false);
            });

            selectedItem.selected = true;

            const [firstItem] = selectedItem.subItems;
            if (firstItem) {
                selectedItem.expanded = true;
                firstItem.selected = true;
                firstItem.onSelection();
            } else {
                selectedItem.onSelection();
            }
        } else {
            const { subItems = [] } = items.find((item) => item.subItems.includes(selectedItem)) ?? {};
            A(subItems).setEach('selected', false);
            selectedItem.selected = true;
            selectedItem.onSelection();
        }

        return false;
    }

    @action updateNavListCss(el: HTMLUListElement): void {
        const nav = el.parentElement;
        if (nav) {
            nav.classList.toggle('has-list-indicator', el.scrollHeight !== el.clientHeight);
        }
    }
}
