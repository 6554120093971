import templateOnlyComponent from '@ember/component/template-only';

export interface ErrorContentSignature {
    Args: {
        /** Indicates the route view model promise rejected. */
        isRejected?: boolean;
        /** Optional error text to display. */
        errorText?: string;
        /** Triggered when this component is done rendering. */
        markEndOfRendering: () => void;
    };
    Blocks: {
        /** Yields the error text. */
        default: [
            {
                errorText?: ErrorContentSignature['Args']['errorText'];
            }
        ];
    };
}

const RouteViewContentErrorContent = templateOnlyComponent<ErrorContentSignature>();
export default RouteViewContentErrorContent;
