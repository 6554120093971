import { HOURS_IN_DAY } from '@adc/ember-utils/constants/time';

export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const MILLISECONDS_IN_SECOND = 1000;
export const MILLISECONDS_IN_MINUTE = SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;
export const MILLISECONDS_IN_HOUR = MINUTES_IN_HOUR * MILLISECONDS_IN_MINUTE;
export const HALF_A_DAY_IN_MILLISECONDS = (HOURS_IN_DAY / 2) * MILLISECONDS_IN_HOUR;
export const HALF_HOURS_IN_DAY = HOURS_IN_DAY * 2;

/**
 * Checks the given value to determine if it may be time value or not.
 */
export function isValidTimeValue(timeValue: number): boolean {
    return !isNaN(timeValue) && timeValue !== null && timeValue >= 0;
}

/**
 * Tests to see if a native input type is supported.
 */
function testInputType(type: string): boolean {
    const input = document.createElement('input'),
        value = 'a';

    input.setAttribute('type', type);
    input.setAttribute('value', value);

    return input.value !== value;
}

/**
 * Indicates that native date input elements are supported.
 */
export const isNativeDateInputSupported = testInputType('date');

/**
 * Indicates that native time input elements are supported.
 */
export const isNativeTimeInputSupported = testInputType('time');
