import templateOnlyComponent from '@ember/component/template-only';

import type { BinaryInputItem } from '../binary-input';

export interface BaseBinaryInputSignature {
    Args: {
        items: BinaryInputItem[];
        itemClass?: string;
        disabled?: boolean;
        tabindex?: number;
        ariaRole?: string;
    };
    Blocks: {
        default: [BinaryInputItem];
    };
}
const BaseBinaryInput = templateOnlyComponent<BaseBinaryInputSignature>();
export default BaseBinaryInput;
