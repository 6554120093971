import Service from '@ember/service';

export interface AppEnvironment {
    environment: string;
    modulePrefix: string;
    podModulePrefix: string;
    locationType: string;
    rootURL: string;
    APP: Record<string, unknown>;
}

/**
 * @classdesc
 * A service for storing/accessing the environment variable.
 */
export default class EnvSettingsService extends Service {
    private envSettings?: AppEnvironment;

    /**
     * Sets the environment.
     */
    setEnvironment<T extends AppEnvironment>(env: T): void {
        if (!this.envSettings) {
            this.envSettings = env;
        }
    }

    /**
     * Returns the environment.
     */
    getEnvironment<T extends AppEnvironment>(): T | undefined {
        if (!this.envSettings) {
            console.error('[@adc/app-infrastructure] Cannot retrieve the environment variable because it was not set.');
            return;
        }

        return this.envSettings as T;
    }

    /**
     * Helper function for checking if environment is of a specific type.
     */
    private isEnvironmentOfType(value: string): boolean {
        if (!this.envSettings) {
            console.error(
                '[@adc/app-infrastructure] Cannot specify the environment type because the environment variable was not set.'
            );
        }

        return this.envSettings?.environment === value ?? false;
    }

    /**
     * Determines if the application is running in the development environment.
     */
    isDevelopmentEnvironment(): boolean {
        return this.isEnvironmentOfType('development');
    }

    /**
     * Determines if the application is running in the test environment.
     */
    isTestEnvironment(): boolean {
        return this.isEnvironmentOfType('test');
    }

    /**
     * Determines if the application is running in the production environment.
     */
    isProductionEnvironment(): boolean {
        return this.isEnvironmentOfType('production');
    }
}
