import Component from '@glimmer/component';
import { action } from '@ember/object';

import type { SystemSelectItem } from '../system-select';

export interface SystemSelectListItemSignature {
    Element: HTMLLIElement;
    Args: {
        /** The system item to render. */
        item: SystemSelectItem;
        /** The current index of this item (used for data-index attribute). */
        index?: number;
        /** The index of the current parent item (used for data-index attribute). */
        parentIndex?: number;
        /** Trigger when the user clicks the rendered item. */
        itemClicked: () => void;
        /** Optional tooltip for the item name element.  */
        tooltipText?: string;
    };
}

/**
 * @classdesc
 * Items list for the dropdown menu component.
 */
export default class SystemSelectListItem extends Component<SystemSelectListItemSignature> {
    /**
     * Toggles visibility of this item's sub-items.
     */
    @action toggleSubItems(): void {
        const { item } = this.args;

        // The showSubItems property is used to alter the icon indicating whether the sub-items are collapsed or expanded.
        item.showSubItems = !item.showSubItems;

        // Loop over all the sub-items and toggle their visibility (unless they are selected).
        item.subItems?.forEach((subItem: SystemSelectItem) => {
            if (!subItem.selected) {
                subItem.show = !subItem.show;
            }
        });
    }

    /**
     * Called when the item is clicked.
     */
    @action itemClicked(): void {
        const { item } = this.args,
            fn = item?.disabled ? undefined : item?.action;

        if (fn) {
            fn();
            this.args.itemClicked();
        }
    }
}
