/**
 * Utility helper module for math- or number-related code.
 *
 * @module
 */

/**
 * Force number to be within a range.
 *
 * @param n Number to be put into a range.
 * @param min Minimum value n can be.
 * @param max Maximum value n can be.
 *
 * @returns n if between min and max, min if n < min, max if n > max.
 */
export function constrainRange(n: number, min: number, max: number): number {
    return Math.min(Math.max(n, min), max);
}

/**
 * Determines if z exists between a and b.
 * Also allows for an epsilon buffer value, helpful when comparing floating-point values. Eps is by default 0.
 *
 * @param z Number to be checked.
 * @param a First range value.
 * @param b Second range value.
 * @param eps Small epsilon buffer value used when comparing values with < and >.
 *
 * @returns True if z is between a and b with a buffer, false otherwise.
 */
export function isBetween(z: number, a: number, b: number, eps = 0): boolean {
    const [left, right] = [a, b].sort((a, b) => a - b);
    return left - eps <= z && z <= right + eps;
}
