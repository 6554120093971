import Service, { inject as service } from '@ember/service';
import { computed, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { GET } from '@adc/ajax/services/adc-ajax';
import { loadScript } from '@adc/ember-utils/utils/browser-helpers';
import { isRtl } from '@adc/ember-utils/utils/html-dir-helpers';
import { defaultIsoCode } from '../models/locale.ts';
import { getOwner } from '@ember/owner';
import setDay from 'date-fns/setDay';
import setHours from 'date-fns/setHours';
import startOfHour from 'date-fns/startOfHour';
import setMonth from 'date-fns/setMonth';

import type EnvSettingsService from './env-settings.ts';
import type AdcAjaxService from '@adc/ajax/services/adc-ajax';
import type LocaleModel from '../models/locale.ts';
import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type ContextManager from './context-manager.ts';
import type EmberObject from '@ember/object';

const MONTHS = Array.from({ length: 12 }, (_, idx) => startOfHour(setHours(setMonth(new Date(), idx), 12)));

type TranslationsPayload = {
    language: string;
    translations: Record<string, string>;
};

/**
 * Returns promise that resolves as translations collection, from cache if available, based on the passed culture ID.
 *
 * @private
 */
function getTranslations(
    this: LocaleService,
    cultureId: string,
    translations?: Record<string, string>
): Promise<TranslationsPayload> {
    if (translations) {
        return Promise.resolve({
            language: cultureId,
            translations
        });
    }

    return this.ajax.apiRequest<TranslationsPayload>(
        `translations/${cultureId}/${this.envSettings.isDevelopmentEnvironment()}`,
        undefined,
        null,
        GET,
        false
    );
}

/**
 * @classdesc
 * A service for retrieving locale translations.
 */
export default class LocaleService extends Service {
    @service declare ajax: AdcAjaxService;
    @service declare intl: ADCIntlService;
    @service declare envSettings: EnvSettingsService;
    @service declare contextManager: ContextManager;

    /**
     * Is the site being displayed RTL?
     */
    @computed('selectedLocale')
    get isRtl(): boolean {
        return isRtl();
    }

    /**
     * The current system time zone.
     */
    @alias('contextManager.identityModel.timezone')
    declare timeZone: string;

    /**
     * Does the week start on sunday for the current Locale?
     */
    weekStartsOnSunday = true;

    /**
     * Refreshes the application in order to refresh the culture.
     *
     * @TODO: Make this happen automatically whenever the culture is changed.
     */
    refreshCulture(): void {
        this.contextManager.transitionToUrl('');
    }

    /**
     * Updates the application locale and downloads required translations.
     *
     * @note If no translations are found on the server, english (en) translations are loaded as a fallback.
     */
    async changeLocale(locale: Pick<LocaleModel, 'cultureId' | 'isoCode' | 'weekStartsOnSunday'>): Promise<void> {
        // Get required services.
        const { intl } = this,
            owner = getOwner(this) as ReturnType<typeof getOwner> & {
                translations: Record<string, Record<string, string>>;
            },
            cache = ((app) => (app.translations = app.translations ?? {}))(owner),
            { cultureId, isoCode, weekStartsOnSunday } = locale;

        this.weekStartsOnSunday = weekStartsOnSunday;

        // Predefine language and translations.
        let language = defaultIsoCode,
            translations;

        try {
            // Combine requests into one promise.
            const [data] = await Promise.all([
                // Get translations.
                getTranslations.call(this, String(cultureId), cache[cultureId]),

                // Request Intl polyfill script if necessary.
                // All of the other polyfills are loaded from index.html so that they are ready before any code is executed.
                window.Intl
                    ? Promise.resolve()
                    : loadScript(
                          `https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?version=3.25.1&features=Intl.~locale.${isoCode}`
                      )
            ]);

            // Have to put parentheses around this statement for it to build.
            ({ language, translations } = data);

            if (translations) {
                // Did we NOT get the requested locale back?
                if (language !== isoCode) {
                    // Log failure.
                    console.warn(`Did not find translations for ${isoCode}; falling back to ${language}`);
                }

                // Cache language
                cache[language] = translations;
            }
        } catch (e) {
            // Do nothing. We are driving throwing of error of of null translations variable.
        }

        // Update application translations and locale.
        intl.addTranslations(language, translations || {});
        intl.setLocale(language);

        // Update intl service with timezone for date formatting.
        const { timeZone } = this;

        // Using set here to prevent an exception from intl.timeZone not being
        // marked as 'tracked' when coming from certain routes.
        set(intl, 'timeZone', timeZone);

        // Update timeZone for ui-components.
        const uiComponents = owner.lookup('@adc/ui-components:appValues') as { timeZone: string };
        if (uiComponents) {
            uiComponents.timeZone = timeZone;
        }

        // Is there a "defaults" object for this addon?
        const defaults = owner.lookup('pikaday:main') as EmberObject;
        if (defaults) {
            // Always include time zone.
            const props: {
                timeZone: string;
                firstDay: number;
                i18n?: Record<string, any>;
            } = {
                timeZone,
                firstDay: weekStartsOnSunday ? 0 : 1
            };

            const WEEKDAYS = Array.from({ length: 7 }, (_, idx) => setDay(new Date(), idx));

            // Add translated descriptions.
            props.i18n = {
                previousMonth: intl.t('generic.calendar.previousMonth'),
                nextMonth: intl.t('generic.calendar.nextMonth'),
                // Do not copy this deprecated usage. If you see this, please fix it
                // eslint-disable-next-line @adc/ember/require-tz-functions
                months: MONTHS.map((d) => intl.formatDate(d, { month: 'long' })),
                // Do not copy this deprecated usage. If you see this, please fix it
                // eslint-disable-next-line @adc/ember/require-tz-functions
                weekdays: WEEKDAYS.map((d) => intl.formatDate(d, { weekday: 'long' })),
                weekdaysShort: WEEKDAYS.map((d) =>
                    // Do not copy this deprecated usage. If you see this, please fix it
                    // eslint-disable-next-line @adc/ember/require-tz-functions
                    intl.formatDate(d, { weekday: language === 'he-IL' ? 'narrow' : 'short' })
                )
            };

            // Pass defaults.
            defaults.setProperties(props);
        }

        // If we did not get translations then throw an error.
        if (translations === null) {
            throw new Error('Getting translations failed.');
        }
    }
}
