import EmberObject from '@ember/object';

export function initialize(application) {
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/no-classic-classes
    application.register('@adc/ui-components:appValues', EmberObject.extend({}), { singleton: true });
}

export default {
    name: '@adc/ui-components-initializer',
    initialize
};
