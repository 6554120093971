/**
 * Utility helper module for RSVP shortcuts and objects.
 *
 * @module
 */

import { Promise as EmberPromise } from 'rsvp';

/**
 * Returns a promise that will be resolved with the given data object.
 *
 * @deprecated Please use the native Promise.resolve() instead.
 */
export function RSVPResolve<T>(data: T): ReturnType<typeof EmberPromise.resolve<T>> {
    return EmberPromise.resolve(data);
}

/**
 * Returns a promise that will be rejected with the given error object.
 *
 * @deprecated Please use the native Promise.reject() instead.
 */
export function RSVPReject<T>(error: T): EmberPromise<never> {
    return EmberPromise.reject(error);
}

/**
 * Creates a compound RSVPPromiseType object that contains the promise itself and shortcuts to resolve and reject the promise.
 *
 * @deprecated This is hacky, and nobody understands it, please use the native Promise instead.
 */
export function RSVPPromise<T>(): {
    promise: EmberPromise<T>;
    resolve: (value?: T) => void;
    reject: (reason?: any) => void;
} {
    const fn = () => {};
    let resolve: (value?: T) => void = fn,
        reject: (reason?: any) => void = fn;

    const promise = new EmberPromise<T>((fnResolve, fnReject) => {
        resolve = fnResolve;
        reject = fnReject;
    });

    return {
        promise,
        resolve,
        reject
    };
}

/**
 * Checks if the passed in object is a promise.
 *
 * @deprecated This is a pretty naive test and just checks if the object has a .then property.
 */
export function isPromise(object?: any): boolean {
    return !!object?.then;
}
