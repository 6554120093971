import { isPresent } from '@ember/utils';
import EventWebsocketMessage from './event.ts';
import MonitoringEventWebsocketMessage from './monitoringevent.ts';
import PropertyChangeWebsocketMessage from './propertychange.ts';
import GeofenceCrossingWebsocketMessage from './geofencecrossing.ts';
import StatusUpdateWebsocketMessage from './statusupdate.ts';

import type { IncomingMessage } from '../../services/websocket.ts';
import type BaseWebsocketMessage from './base.ts';

/**
 * Helper function for Ember.NativeArray to determine if a variable's type is not undefined.
 * Differs from isPresent in that it returns true for empty strings, boolean false, etc.
 */
function isDefined(variable: string | undefined): boolean {
    return variable !== undefined;
}

/**
 * Websocket message factory function.
 * Takes raw websocket data and outputs an internal websocket message object
 */
export function getWebsocketMessage(data: IncomingMessage): BaseWebsocketMessage | undefined {
    const { UnitId: unitId } = data,
        deviceId = unitId + '-' + data.DeviceId;

    if ([unitId, deviceId].every(isPresent)) {
        const {
            EventType: eventType,
            EventValue: eventValue,
            EventDateUtc: eventDateUtc,
            QstringForExtraData: qStringForExtraData,
            CorrelatedEventId: correlatedEventId,
            Property: property,
            PropertyValue: propertyValue,
            FenceId: fenceId,
            IsInsideNow: isInsideNow,
            NewState: newState,
            FlagMask: flagMask
        } = data;

        if ([eventType, eventValue, qStringForExtraData, eventDateUtc].every(isDefined)) {
            return new EventWebsocketMessage(
                unitId,
                deviceId,
                eventType,
                eventValue,
                qStringForExtraData,
                eventDateUtc
            );
        }

        if ([eventType, correlatedEventId].every(isDefined)) {
            return new MonitoringEventWebsocketMessage(unitId, deviceId, eventType, correlatedEventId);
        }

        if ([property, propertyValue].every(isDefined)) {
            return new PropertyChangeWebsocketMessage(unitId, deviceId, property, propertyValue);
        }

        if ([fenceId, isInsideNow].every(isDefined)) {
            return new GeofenceCrossingWebsocketMessage(unitId, deviceId, fenceId, isInsideNow);
        }

        if ([newState, flagMask].every(isDefined)) {
            return new StatusUpdateWebsocketMessage(unitId, deviceId, newState, flagMask);
        }

        return undefined;
    }

    return undefined;
}
