/**
 * Utility helper module for HTML direction attribute related code.
 * @module
 */

// region Constants

/**
 * The attribute value used for indicating an RTL language.
 */
export const RTL_STRING = 'rtl';

/**
 * The name of our custom event that is fired whenever the HTML element's "dir" attribute changes.
 */
export const DIR_CHANGED_EVENT_KEY = 'htmlDirChanged';

// endregion

// region DOM Helpers

/**
 * Is the page displayed using a RTL language format?
 *
 * @note By default, we assume the browser is LTR, unless a dir='rtl' attribute exists on the HTML element.
 */
export function isRtl(): boolean {
    return document.documentElement.attributes.getNamedItem('dir')?.value === RTL_STRING;
}

// endregion

// region Mutation Observer

/**
 * Creates the mutation observer that watches for any changes to the HTML element's "dir" attribute.
 *
 * @note We start the observer immediately, even though nothing is subscribed to it yet.
 *       This should be low impact in terms of performance.
 */
(function createHtmlDirectionObserver(): void {
    const directionChangedEvent = document.createEvent('Event');

    directionChangedEvent.initEvent(DIR_CHANGED_EVENT_KEY, true, true);

    const directionObserver = new MutationObserver((mutations) => {
        if (mutations.some((mutation) => mutation.attributeName === 'dir')) {
            document.dispatchEvent(directionChangedEvent);
        }
    });

    directionObserver.observe(document.documentElement, { attributes: true });
})();

// endregion
