/**
 * @module
 */
/**
 * @enum {number}
 */
export const Other = 0;
/**
 * @enum {number}
 */
export const DesktopWeb = 1;
/**
 * @enum {number}
 */
export const MobileWeb = 2;
/**
 * @enum {number}
 */
export const MobileApp = 3;
/**
 * @enum {number}
 */
export const SmartTV = 4;
/**
 * @enum {number}
 */
export const VoiceDevice = 5;
/**
 * @enum {number}
 */
export const Tablet = 6;
