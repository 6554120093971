import { service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';

import type PerformanceMonitorService from '../services/performance-monitor.ts';

interface LastRenderMarkSignature {
    Args: {
        /** Triggered in the markEndOfRendering action of this component.
         * It allows us to run a separate process for marking the end of
         * rendering for a route.
         */
        markEndOfRendering?: () => void;
    };
}

export default class LastRenderMark extends Component<LastRenderMarkSignature> {
    @service declare performanceMonitor: PerformanceMonitorService;

    @action
    markEndOfRendering(): void {
        // If there isn't a markEndOfRendering function passed in, then we will call markLastRender here.
        (this.args.markEndOfRendering ?? this.performanceMonitor.markLastRender)();
    }
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        LastRenderMark: typeof LastRenderMark;
    }
}
