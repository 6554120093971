import templateOnlyComponent from '@ember/component/template-only';

export interface ActionSheetsConfirmSaveSignature {
    Args: {
        close: VoidFunction;
        confirm: VoidFunction;
        disabled?: boolean;
        confirmationDescription: string;
        isActive: boolean;
    };
    Element: HTMLDivElement;
}

const ActionSheetConfirmSave = templateOnlyComponent<ActionSheetsConfirmSaveSignature>();
export default ActionSheetConfirmSave;
