import templateOnlyComponent from '@ember/component/template-only';

const EmptyContainer = templateOnlyComponent();

export default EmptyContainer;

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        EmptyContainer: typeof EmptyContainer;
        'empty-container': typeof EmptyContainer;
    }
}
