import templateOnlyComponent from '@ember/component/template-only';

import type { BinaryListItem } from './list';

export interface SimpleBinaryButtonSignature {
    Element: HTMLButtonElement;
    Args: {
        /** The binary list item to render. */
        item: BinaryListItem<any>;
        /** Indicates the button should be disabled. */
        disabled?: boolean;
        /** Indicates the containing list is multi select. */
        isMultiSelect?: boolean;
    };
    Blocks: {
        default: [];
    };
}

const SimpleBinaryButton = templateOnlyComponent<SimpleBinaryButtonSignature>();
export default SimpleBinaryButton;
