import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { MINUTES_IN_DAY, MINUTES_IN_WEEK } from '@adc/ember-utils/constants/time';
import startOfYear from 'date-fns/startOfYear';
import setMinutes from 'date-fns/setMinutes';
import setDay from 'date-fns/setDay';

import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { TimeBlock } from './types';

/**
 * @classdesc
 * Base class for all addon views.
 */
export default class BaseView<T> extends Component<T> {
    @service declare intl: ADCIntlService;

    /**
     * Create the elements needed to render a time block to the time block, then call fnPushSchedule for each element created.
     */
    protected createElementsForTimeBlock(
        timeBlock: TimeBlock,
        allTimesText: string,
        fnPushSchedule: (
            index: number,
            label: string,
            classes: string,
            start: number,
            length: number,
            planNameLabel: string
        ) => void
    ): void {
        let { startMinutesLocal, endMinutesLocal } = timeBlock;

        // Get day number based on startMinutes from beginning of the week.
        const dayOfWeek = Math.floor(startMinutesLocal / MINUTES_IN_DAY),
            { title = '' } = timeBlock;
        const passedInClasses = timeBlock.classes ?? '';

        // Adjust minutes for day of week.
        startMinutesLocal -= MINUTES_IN_DAY * dayOfWeek;
        endMinutesLocal -= MINUTES_IN_DAY * dayOfWeek;

        // Calculate day span.
        const daySpan = Math.floor(endMinutesLocal / MINUTES_IN_DAY),
            [startValue, endValue] = [startMinutesLocal, endMinutesLocal].map((minutes) =>
                // Do not copy this deprecated usage. If you see this, please fix it
                // eslint-disable-next-line @adc/ember/require-tz-functions
                this.intl.formatTime(setMinutes(startOfYear(new Date()), minutes), {
                    hour: 'numeric',
                    minute: 'numeric'
                })
            );

        // Calculate the start point and length of the time block for the absolutely positioned time blocks.
        let start = (startMinutesLocal * 100) / MINUTES_IN_DAY,
            length = ((endMinutesLocal - startMinutesLocal) * 100) / MINUTES_IN_DAY;

        // Is there only 1 time block to create?
        if (daySpan === 0 || (daySpan === 1 && endMinutesLocal === MINUTES_IN_DAY)) {
            fnPushSchedule(dayOfWeek, `${startValue} - ${endValue}`, passedInClasses, start, length, title);
            return;
        }

        // Otherwise create all the time blocks we need to create.
        for (let i = 0; i <= daySpan; i++) {
            let elementTitle = '',
                label = '',
                classes = '';

            // Is this the first day?
            if (i === 0) {
                // Calculate weekday and add to label.
                // Do not copy this deprecated usage. If you see this, please fix it
                // eslint-disable-next-line @adc/ember/require-tz-functions
                const weekday = this.intl.formatDate(setDay(new Date(), (dayOfWeek + daySpan) % 7), {
                    weekday: 'long'
                });

                elementTitle = title;
                classes = 'start';
                length = ((MINUTES_IN_DAY - startMinutesLocal) * 100) / MINUTES_IN_DAY;
                label =
                    endMinutesLocal - startMinutesLocal === MINUTES_IN_WEEK
                        ? allTimesText
                        : `${startValue} - ${endValue} (${weekday})`;
            } else {
                start = 0;
                length = 100;
                classes = 'middle';

                // If we're on the second to last day in the span, and the schedule ends at midnight, this is the end block.
                if (i === daySpan - 1 && endMinutesLocal - (i + 1) * MINUTES_IN_DAY === 0) {
                    classes = 'end';
                }

                // If this is the last day.
                if (i === daySpan) {
                    length = ((endMinutesLocal - i * MINUTES_IN_DAY) * 100) / MINUTES_IN_DAY;
                    classes = 'end';

                    if (length === 0) {
                        break;
                    }
                }
            }

            fnPushSchedule(
                (dayOfWeek + i) % 7,
                label,
                `wrap-indicator-${classes} ${passedInClasses}`,
                start,
                length,
                elementTitle
            );
        }
    }
}
