import Component from '@glimmer/component';
import { action } from '@ember/object';
import { ESCAPE_CODE } from '@adc/ember-utils/utils/a11y';
import { timeout, task } from 'ember-concurrency';

import type { AppNotification, AppNotificationButton } from '../../services/notification-manager';
import type { Task } from 'ember-concurrency';

export interface ModalInsetOverlaySignature {
    Element: HTMLDivElement;
    Args: {
        /** The notification to render. */
        notification: AppNotification;
        /** Indicates notifications should close immediately (using to improve test performance). */
        isTesting?: boolean;
        /** Triggered when the user clicks the close button to clear the notification. */
        'close-notification': (notification: AppNotification, elementToFocus?: Element) => void;
    };
}

/**
 * @classdesc An individual notification that is displayed within the notification manager.
 */
export default class AppNotificationComponent extends Component<ModalInsetOverlaySignature> {
    /**
     * The last focused element before the notification was opened.
     */
    lastFocusedElement: Element | null;

    /** @override */
    constructor(owner: unknown, args: ModalInsetOverlaySignature['Args']) {
        super(owner, args);

        this.lastFocusedElement = document.activeElement;
    }

    /**
     * Closes the notification.
     */
    @action closeNotification(restoreFocus = false): void {
        const { lastFocusedElement } = this;
        this.args['close-notification'](
            this.args.notification,
            restoreFocus && lastFocusedElement ? lastFocusedElement : undefined
        );
    }

    /**
     * Closes the notification when the user hits the escape key.
     */
    @action keyUpHandler(event: KeyboardEvent): void {
        if (event.code === ESCAPE_CODE) {
            this.closeNotification(true);
        }
    }

    /**
     * Called when the notification is inserted so we can schedule animation and auto close.
     */
    notificationInserted: Task<void, [HTMLElement]> = task({ drop: true }, async (el: HTMLElement) => {
        // Let it render and change CSS so it fades in.
        await timeout(0);
        el.classList.add('in');

        const { autoCloseDuration } = this.args.notification,
            { isTesting } = this.args;

        if (isTesting || autoCloseDuration) {
            // Wait for close duration then clear CSS so it fades out.
            await timeout(isTesting ? 1 : (autoCloseDuration as number));
            el.classList.remove('in');

            // Wait a little bit then close the faded animation.
            await timeout(isTesting ? 1 : 300);
            this.closeNotification(false);
        } else {
            el.focus();
        }
    });

    /**
     * Button inside notification was clicked.
     */
    @action buttonClicked(
        fn: AppNotificationButton['action'],
        buttonAnimation: Promise<void>
    ): Promise<unknown> | unknown {
        // Get the return value from the callback.
        const returnValue = (fn || (() => {}))(buttonAnimation),
            isPromise = !!returnValue?.then;

        Promise.resolve(returnValue).then((result) => {
            // If the click handler explicitly returned false then do NOT close the notification.
            if (result !== false) {
                // Close the notification.
                (isPromise ? buttonAnimation : Promise.resolve()).then(() => this.closeNotification(true));
            }
        });

        // Return the value to the button addon so it can animate if needed.
        return returnValue;
    }
}
