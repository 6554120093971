import templateOnlyComponent from '@ember/component/template-only';

import type { WeekViewElements } from './week-view';

export interface WeekViewTimeBlockSignature {
    Element: HTMLElement;
    Args: {
        /** Information describing this time block. */
        timeBlock: WeekViewElements;
        /** The ID of the currently active time block. */
        activeTimeBlockId: number;
        /** Indicates we should display the label for this time block. */
        areTimeBlockLabelsVisible: boolean;
    };
}

const WeekViewTimeBlock = templateOnlyComponent<WeekViewTimeBlockSignature>();

export default WeekViewTimeBlock;
