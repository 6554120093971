import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import type ModalService from '../../../../services/modals.ts';

export interface ModalHeaderSignature {
    Element: HTMLDivElement;
    Args: {
        /** The modal header title. */
        title: string;
        /** The title element ID attribute value, used to label the modal with the title element, for a11y. */
        titleId?: string;
        /** Triggered when the user interacts with the close button. */
        customClose?: () => void;
        /** Indicates the header close button should not be visible. */
        disableCloseButton?: boolean;
    };
    Blocks: {
        default: [];
    };
}

/**
 * @classdesc
 * Header for modal component, contains title and close button
 */
export default class ModalHeader extends Component<ModalHeaderSignature> {
    @service declare modals: ModalService;
}
