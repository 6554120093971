import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { equal } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { getModalFooterButton } from '../../../modals/base/footer/modal-footer.ts';

import type ModalService from '../../../../services/modals.ts';
import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { ModalModel } from '../../../../services/modals.ts';
import type { SimpleDialogSignature } from '../simple/simple-dialog.ts';

type SimpleDialogSignatureArgs = SimpleDialogSignature['Args'];

export interface PromiseStep {
    bodyText: string;
    buttonText?: string;
    buttonCss?: string;
    displayCancel?: boolean;

    footerText?: string;
    footerImagePath?: string;
    footerImageAlt?: string;

    cancelOnClickFunction?: () => void;
    successOnClickFunction?: () => void;

    showError?: boolean;
    allowRetry?: boolean;
}

export interface PromiseDialogModel extends ModalModel, Pick<SimpleDialogSignatureArgs, 'title'> {
    promiseFn: () => Promise<unknown>;
    stepConfirm: PromiseStep;
    stepPromise: PromiseStep;
    stepSuccess: PromiseStep;
    stepFailure: PromiseStep;
    css?: string;
}

/**
 * Constants for the different possible states.
 */
enum State {
    Confirm,
    Promise,
    Success,
    Failure
}

export interface PromiseDialogSignature {
    Args: {
        /** The promise dialog model. */
        model: PromiseDialogModel;
    };
    Blocks: {
        default: [];
    };
}

/**
 * @classdesc
 * A simple dialog for handling data while a promise resolves
 */
export default class PromiseDialog extends Component<PromiseDialogSignature> {
    @service declare intl: ADCIntlService;
    @service declare modals: ModalService;

    /**
     * Current promise state
     */
    @tracked private state = State.Confirm;

    /**
     * Message to display to user if an error is encountered during operation
     */
    @tracked errorMessage = '';

    /**
     * Should a spinner be displayed to indicate a pending promise
     */
    @equal('state', State.Promise)
    declare showSpinner: boolean;

    /**
     * Get the icon type based on the promise state
     */
    @computed('state')
    get displayIconType(): string {
        const { state } = this;

        if (state === State.Success) {
            return 'circle-check';
        }

        if (state === State.Failure) {
            return 'circle-x';
        }

        return '';
    }

    /**
     * Return the current promise state step
     */
    @computed('args.model', 'state')
    get currentStateValues(): PromiseStep {
        const { model } = this.args;
        return {
            [State.Confirm]: model.stepConfirm,
            [State.Promise]: model.stepPromise,
            [State.Success]: model.stepSuccess,
            [State.Failure]: model.stepFailure
        }[this.state];
    }

    /**
     * Return the buttons for the footer.
     */
    @computed('currentStateValues', 'processPromise', 'state')
    get buttons(): SimpleDialogSignatureArgs['buttons'] {
        const buttons = [],
            { state, currentStateValues } = this,
            { cancelOnClickFunction } = currentStateValues,
            showSuccess = state === State.Success,
            showRetry = state === State.Failure && currentStateValues.allowRetry;

        if (!showSuccess && currentStateValues.displayCancel) {
            buttons.push(
                getModalFooterButton(
                    this.intl.t('@adc/ui-components.cancel'),
                    cancelOnClickFunction && ([State.Promise, State.Confirm].includes(state) || showRetry)
                        ? cancelOnClickFunction
                        : () => {
                              this.modals.closeModal();
                          }
                )
            );
        }

        // Display an action button for stepConfirm and stepSuccess, and for stepFailure if promise retry is allowed.
        const displayPromiseButton = state === State.Confirm || showRetry;

        if (displayPromiseButton || showSuccess) {
            buttons.push(
                getModalFooterButton(
                    currentStateValues.buttonText ?? '',
                    displayPromiseButton ? this.processPromise : currentStateValues.successOnClickFunction,
                    currentStateValues.buttonCss ?? ''
                )
            );
        }

        return buttons;
    }

    /**
     * Process the model's promise, changing state and handling errors
     */
    @action
    processPromise(): boolean {
        this.state = State.Promise;

        (async () => {
            const { model } = this.args;

            try {
                await model.promiseFn();
                this.state = State.Success;
            } catch (error) {
                this.state = State.Failure;

                if (model.stepFailure.showError) {
                    this.errorMessage = this.intl.t('@adc/ui-components.promiseFailed', { error });
                }
            }
        })();

        return false;
    }
}
