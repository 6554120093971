import Component from '@glimmer/component';

export interface FulfilledContentSignature<M> {
    Args: {
        /** Indicates that data is present. */
        hasData?: boolean;
        /** Indicates the route-view data loaded action was triggered.  */
        hasCalledDataLoadedAction?: boolean;
        /** The resolved data to yield.  */
        data?: M;
        /** Triggered when this component is done rendering. */
        markEndOfRendering: () => void;
    };
    Blocks: {
        /** Yields the resolved data. */
        default: [FulfilledContentSignature<M>['Args']['data']];
    };
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class RouteViewContentFulfilledContent<T> extends Component<FulfilledContentSignature<T>> {}
