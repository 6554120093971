import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { once } from '@ember/runloop';
import { guidFor } from '@ember/object/internals';
import { getOwner } from '@ember/owner';
import { isDestroyed } from '@adc/ember-utils/utils/ember-helpers';

import type NativeBridgeService from '@adc/app-infrastructure/services/native-bridge';
import type { ButtonNativeSignature, NativeButtonType } from './button/native';
import type { InternalOwner } from '@ember/-internals/owner';

type NativeButtonArgs = ButtonNativeSignature['Args'];

interface NavigationAPI {
    getVisitedRoutesStackLength(): number;
    transitionToPreviousRoute(): void;
}

export interface NativeBridgeSignature {
    Element: HTMLDivElement;
    Args: {
        /** Called when there is no back or cancel button already present in the `buttonsList` collection when toolbar buttons are constructed. */
        handleNoNativeBackOrCancelButton(
            data: {
                contextButtons: NativeButtonType[];
                backButtons: NativeButtonType[];
            },
            navigationApi: NavigationAPI | undefined
        ): void;
    };
    Blocks: {
        default: [
            /** An object with methods for adding/removing native bridge buttons. */
            {
                /** Updates native toolbar buttons with the passed buttons. */
                update: NativeButtonArgs['updateNativeToolbarButtons'];
                /** Disposes buttons from toolbar with the defined componentIds. */
                dispose: NativeButtonArgs['disposeNativeButtons'];
            },
            /** The application navigation API. */
            NavigationAPI | undefined
        ];
    };
}

/**
 * A component that provides a common interface for components to interact with the native bridge.
 */
export default class NativeBridgeComponent extends Component<NativeBridgeSignature> {
    @service declare nativeBridge: NativeBridgeService;

    /**
     * Current list of native toolbar buttons.
     */
    private buttonsList: NativeButtonType[] = [];

    /**
     * Recalculates buttons for the native toolbar.
     */
    private recalculateNativeToolbar(): void {
        const backButtons: NativeButtonType[] = [],
            contextButtons: NativeButtonType[] = [],
            { buttonsList } = this;

        let wasBackOrCancel = false;

        buttonsList.forEach((button) => {
            switch (button.type) {
                case this.nativeBridge.getButtonTypeBack():
                case this.nativeBridge.getButtonTypeCancel():
                    // Check if back or cancel already existed.
                    if (wasBackOrCancel) {
                        // Probably still let the new button be inserted but log an error.
                        console.error(
                            `Native toolbar back or cancel button already existed for modal, toolbar=${JSON.stringify(
                                buttonsList
                            )}`
                        );
                    }

                    backButtons.unshift(button);

                    wasBackOrCancel = true;
                    break;

                case this.nativeBridge.getButtonTypeConfirm():
                    contextButtons.unshift(button);
                    break;

                default:
                    contextButtons.push(button);
                    break;
            }
        });

        // If there is no close button defined, then add one because a modal needs to have a close button.
        if (!wasBackOrCancel) {
            this.args.handleNoNativeBackOrCancelButton(
                {
                    contextButtons,
                    backButtons
                },
                this.navigationApi
            );
        }

        this.nativeBridge.setToolbarButtons({
            id: this.nativeToolbarId,
            backButtons,
            contextButtons
        } as Parameters<NativeBridgeService['setToolbarButtons']>[0]);
    }

    /**
     * Id that will uniquely represent this component in the native toolbars stack.
     */
    get nativeToolbarId(): ReturnType<typeof guidFor> {
        return guidFor(this);
    }

    get navigationApi(): NavigationAPI | undefined {
        const owner = getOwner(this);
        if (owner) {
            const api = (owner as InternalOwner).lookup('route-view:main');
            if (api) {
                return api as NavigationAPI;
            }
        }

        return undefined;
    }

    @action removeToolbarButtons(): void {
        this.nativeBridge.removeToolbarButtons(this.nativeToolbarId);
    }

    /**
     * Updates native toolbar buttons with the passed buttons.
     */
    @action updateNativeToolbarButtons(buttons: NativeButtonType[] = []): void {
        if (isDestroyed(this) || !this.nativeBridge.doesSupportToolbarButtons) {
            return;
        }

        // Remove any instance of buttons with the same componentId.
        this.disposeNativeButtons(
            buttons.map(({ componentId }) => componentId),
            true
        );

        this.buttonsList.push(...buttons);

        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        once(this, this.recalculateNativeToolbar);
    }

    /**
     * Disposes buttons from toolbar with the defined componentIds.
     */
    @action disposeNativeButtons(componentIds: string[], doNotRecalculateToolbar?: boolean): void {
        if (isDestroyed(this) || !this.nativeBridge.doesSupportToolbarButtons) {
            return;
        }

        this.buttonsList = this.buttonsList.filter(({ componentId }) => !componentIds.includes(componentId));

        if (!doNotRecalculateToolbar) {
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line ember/no-runloop
            once(this, this.recalculateNativeToolbar);
        }
    }
}
