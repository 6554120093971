import Component from '@glimmer/component';
import { isPresent, isEmpty } from '@ember/utils';
import { assert } from '@ember/debug';
import { timeout, task } from 'ember-concurrency';
import parseGenerateConfig from '../utils/generate-popper.js';
import Popper from 'popper.js';

export type ErrorTooltipDirection = 'up' | 'down' | 'sibling';
import type { Task } from 'ember-concurrency';

/**
 * Converts the old HasErrorTooltip autoDestroy property value (seconds) to the new ErrorTooltip duration value (milliseconds).
 */
export function convertAutoDestroyToDuration(autoDestroy: unknown): number | undefined {
    if (isPresent(autoDestroy) && !isNaN(autoDestroy as number)) {
        return (autoDestroy as number) * 1000;
    }

    return undefined;
}

interface ErrorTooltipArgs {
    /** The error text. */
    text?: string;
    /** The Popper.js placement of the tooltip. */
    placement?:
        | 'auto-start'
        | 'auto'
        | 'auto-end'
        | 'top-start'
        | 'top'
        | 'top-end'
        | 'right-start'
        | 'right'
        | 'right-end'
        | 'bottom-end'
        | 'bottom'
        | 'bottom-start'
        | 'left-end'
        | 'left'
        | 'left-start';
    /** Optional CSS selector indicating which element to use for anchoring the tooltip. */
    selector?: string;
    /** The direction within the host element to look for the selector element: "down" uses `host.querySelector`, "up" uses `host.closest`, "sibling" uses `host.parentElement.querySelector` (defaults to "down"). */
    selectorDirection?: 'up' | 'down' | 'sibling';
    /** Optional number of milliseconds before closing tooltip (defaults to zero so tooltip won't close). */
    duration?: number;
}

export interface ErrorTooltipSignature {
    Element: HTMLDivElement;
    Args: ErrorTooltipArgs;
    Blocks: {
        default: [];
    };
}

export interface CommonInputErrorTooltipArgs {
    /** The text for the error tooltip. */
    errorMessage?: ErrorTooltipArgs['text'];
    /** The Popper.js placement of the tooltip. */
    errorTooltipPlace?: ErrorTooltipArgs['placement'];
    /** Optional number of milliseconds before closing tooltip (defaults to zero so tooltip won't close). */
    errorDuration?: ErrorTooltipArgs['duration'];
}

/**
 * A component for displaying a red error tooltip.
 */
export default class ErrorTooltip extends Component<ErrorTooltipSignature> {
    /**
     * The error tooltip instance.
     */
    tooltip: Popper | undefined;

    /**
     * Finds the element to use as the anchor for the error tooltip.
     */
    private getTooltipAnchor(el: HTMLDivElement): Element {
        const { selector = '', selectorDirection = 'down' } = this.args,
            defaultAnchor = (el.firstElementChild as Element) ?? el;

        if (isEmpty(selector)) {
            return defaultAnchor;
        }

        const anchor = (() => {
            if (selectorDirection === 'up') {
                return el.closest(selector);
            }

            return (selectorDirection === 'sibling' ? el.parentElement : el)?.querySelector(selector);
        })();

        assert(
            `[@adc/ui-components] ErrorTooltip target element not found for "${selector}" in direction "${selectorDirection}"`,
            anchor
        );

        return anchor ?? defaultAnchor;
    }

    /**
     * Creates (and caches) the error tooltip instance.
     */
    private createTooltip(el: HTMLDivElement): void {
        const { text, placement = 'auto' } = this.args;

        this.tooltip = new Popper(
            this.getTooltipAnchor(el),
            parseGenerateConfig({
                arrowClassNames: ['arrow'],
                classNames: ['tool-tip', 'tool-tip-error'],
                attributes: ['aria-live:assertive', 'role:alert', `aria-errormessage:${text}`],
                content: text,

                // If the reference element is within a modal, attach the tooltip to the modal.
                parent: el.closest('.modal') ?? window.document.body
            }),
            {
                placement,
                removeOnDestroy: true,
                modifiers: {
                    preventOverflow: {
                        escapeWithReference: false
                    }
                }
            }
        );
    }

    /**
     * Destroys and clears the error tooltip instance.
     */
    private destroyTooltip(): void {
        const { tooltip } = this;
        if (tooltip) {
            tooltip.destroy();
            this.tooltip = undefined;
        }
    }

    /**
     * Called to create or update the error tooltip instance.
     */
    updateTooltip: Task<void, [HTMLDivElement]> = task({ restartable: true }, async (el: HTMLDivElement) => {
        // Schedule tooltip update so render is complete and positioning will be correct.
        await timeout(0);

        this.destroyTooltip();

        const { duration = 0, text } = this.args;

        if (isPresent(text)) {
            this.createTooltip(el);

            if (duration > 0) {
                // Close the popover after a short time.
                await timeout(duration);
                this.destroyTooltip();
            }
        }
    });

    /** @override */
    willDestroy(): void {
        super.willDestroy();

        this.destroyTooltip();
    }
}
