interface Preset {
    id?: number;
    color?: {
        percentWarmth?: number;
    };
    label?: string;
    props?: Preset;
}

/**
 * Checks that the given property exists and has the given type on the given object. Logs console error if not.
 */
function ensureObjectHasPropertyOfType<T>(obj: T, propName: keyof T, type: string, message: string): boolean {
    if (typeof obj[propName] !== type) {
        console.error(message);

        return false;
    }

    return true;
}

/**
 * Checks that the given color preset config has the mandatory props for the analogous-color-picker color preset item to be generated,
 * logs error otherwise.
 */
export function isColorPresetConfigValid(
    preset: any,
    componentName = 'lightbulb-color-picker',
    extraChecks: (p?: Preset, c?: string) => boolean = () => true
): boolean {
    const propChecks = [
        ['id', 'number', `${componentName}: Missing "id" property on the preset with ID=${preset.id}`],
        ['labelName', 'string', `${componentName}: Missing "labelName" property on the preset with ID=${preset.id}`],
        [
            'backgroundImage',
            'string',
            `${componentName}: Missing "backgroundImage" property on the preset with ID=${preset.id}`
        ]
    ];

    return (
        propChecks.every((check) =>
            ensureObjectHasPropertyOfType<typeof preset>(preset, check[0], check[1], check[2])
        ) &&
        ensurePresetHasColor(preset, componentName) &&
        extraChecks(preset, componentName)
    );
}

/**
 * Checks that the given color preset has the mandatory props, logs error otherwise.
 */
export function isColorPresetValid(
    preset: any,
    componentName = 'lightbulb-color-picker',
    extraChecks: (p?: Preset, c?: string) => boolean = () => true
): boolean {
    return isPresetValid(preset, componentName, () => {
        const propChecks = [
            [
                'backgroundImage',
                'string',
                `${componentName}: Missing "backgroundImage" property on the preset with ID=${preset?.props?.id}`
            ]
        ];

        return (
            propChecks.every((check) =>
                ensureObjectHasPropertyOfType<typeof preset.props>(preset.props, check[0], check[1], check[2])
            ) &&
            ensurePresetHasColor(preset, componentName) &&
            extraChecks(preset, componentName)
        );
    });
}

/**
 * Checks that the preset has the color object defined as expected, logs error otherwise.
 */
function ensurePresetHasColor(preset: Preset, componentName: string): boolean {
    const context = preset.props ?? preset;

    if (
        !ensureObjectHasPropertyOfType<typeof context>(
            context,
            'color',
            'object',
            `${componentName}: Missing "color" object on the preset with ID=${context.id}`
        )
    ) {
        return false;
    }

    return ['hue', 'saturation', 'lightness'].every((propName) =>
        ensureObjectHasPropertyOfType<typeof context.color>(
            context.color,
            propName as keyof typeof context.color,
            'number',
            `${componentName}: Missing "${propName}" property on the color object of the preset with ID=${context.id}`
        )
    );
}

/**
 * Validates the given preset config for the props mandatory for the presets-picker component.
 * Logs console errors if something is missing.
 */
function isPresetValid(preset: Preset, componentName = 'presets-picker', extraChecks = () => true): boolean {
    return (
        ensureObjectHasPropertyOfType<typeof preset.props>(
            preset.props,
            'id' as keyof typeof preset.props,
            'number',
            `${componentName}: Missing "id" property on the preset object:`
        ) &&
        ensureObjectHasPropertyOfType<typeof preset>(
            preset,
            'label',
            'string',
            `${componentName}: Missing "label" property on the preset object wit ID=${preset.id}`
        ) &&
        extraChecks()
    );
}
