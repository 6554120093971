import Component from '@glimmer/component';

import type { SafeString } from 'handlebars';

export type InlineAlertType = 'info' | 'success' | 'warning' | 'danger';

export type InlineAlertPointerPlacement = 'top' | 'bottom';

// The button object that is rendered in the inline alert. Will need to pass in a text and an action to render properly.
export type InlineAlertButton = {
    icon?: string;
    text: string;
    action: () => any;
};

/**
 * The different possible types of messages, each colored differently.
 */
const TYPES: InlineAlertType[] = ['info', 'success', 'warning', 'danger'];

export interface InlineAlertSignature {
    Element: HTMLDivElement;
    Args: {
        /** The type of inline alert to show (defaults to 'info'). */
        type?: 'info' | 'success' | 'warning' | 'danger';
        /** Optional pointer type for inline alert to contain a top or bottom pointer, defaults to no pointer */
        pointerPlacement?: 'top' | 'bottom';
        /** Optional flag to hide the icon on the left side */
        hideLeftIcon?: boolean;
        /** Optional text to include within the inline alert (will not yield if present). */
        text?: string | SafeString;
        /** Optional title text to include within the inline alert */
        title?: string;
        /** Optional icon override for the left icon. If nothing is passed in, it will use the icon associated with the alert type */
        leftIcon?: string;
        /** The icon button, will need a text and an action to render properly.*/
        iconButton?: InlineAlertButton;
        /** The confirm button, will need a text and an action to render properly */
        confirmButton?: InlineAlertButton;
        /** The cancel button, will need a text and an action to render properly */
        cancelButton?: InlineAlertButton;
    };
    Blocks: {
        default: [];
    };
}

/**
 * An inline banner displayed with a configurable message, and including an icon and color indicative of the severity (info, success, warning or danger).
 */
export default class InlineAlert extends Component<InlineAlertSignature> {
    /**
     * Validated type.
     */
    get type(): InlineAlertType {
        const { type = TYPES[0] } = this.args;
        return TYPES.includes(type) ? type : TYPES[0];
    }

    get path(): 'inline-alert/info' | 'inline-alert/success' | 'inline-alert/warning' | 'inline-alert/danger' {
        return `inline-alert/${this.type}`;
    }
}
