import { action, computed } from '@ember/object';
import { isEmpty, isNone } from '@ember/utils';
import Component from '@glimmer/component';
import { getSpriteSelector } from './svg-sprite-loader.ts';
import config from 'ember-get-config';

import '../styles/svg.css';

export interface SvgSymbolSignature {
    Element: SVGElement;
    Args: {
        /** The name of the icon to render (minus the "icn-" prefix). */
        icon: string;
        /** An optional title for the icon, rendered in a `<title />` element. */
        title?: string;
        /** An optional description for the icon, rendered in a `<desc />` element. */
        desc?: string;
        /** Indicates whether the icon can receive focus (this is likely a mistake). */
        isFocusable?: boolean;
        /** Indicates the icon should be hidden from screen readers (defaults to `true` unless `title` or `description` are passed). */
        isHiddenForAccessibility?: boolean;
        /** An optional icon fill color (defaults to gray-80). */
        fillColor?:
            | 'red'
            | 'orange'
            | 'yellow'
            | 'lime'
            | 'green'
            | 'greenblue'
            | 'greencyan'
            | 'cyanblue'
            | 'blue'
            | 'navy'
            | 'purple'
            | 'pink'
            | 'gray-100'
            | 'gray-80'
            | 'gray-60'
            | 'gray-40'
            | 'gray-20'
            | 'gray-10'
            | 'gray-0';
        /** An optional path to the icon SVG sprite (unlikely to be used). */
        path?: string;
        /**
         * Please don't make SVG element clickable, use a button instead.
         * @deprecated
         */
        'on-click'?: () => void;
    };
}

/**
 * @classdesc
 * A simple component for showing SVG symbols from global SVG sprites.
 */
export default class SvgSymbol extends Component<SvgSymbolSignature> {
    /**
     * Called when the user clicks the SVG.
     */
    get clickHandler(): () => void {
        return this.args['on-click'] ?? (() => {});
    }

    /**
     * The path to the SVG sprite as indicated in the application configuration.  If path is not present in application configuration
     * it will be assumed that the sprite will be present in the application DOM.
     */
    @computed('args.path')
    get iconPath(): string {
        const { path } = this.args;

        if (path) {
            return path;
        }

        if (config) {
            return (config as { 'adc-svg-system'?: { path?: string } })['adc-svg-system']?.path ?? '';
        }

        return '';
    }

    /**
     * The aria-label of the icon, if has one.
     */
    @computed('args.{title,desc}')
    get ariaLabel(): string | undefined {
        const labelText = [this.args.title, this.args.desc].filter((el) => !!el).join(' ');
        return isEmpty(labelText) ? undefined : labelText;
    }

    /**
     * Indicate the svg icon should have aria-hidden equal to true.
     */
    @computed('ariaLabel', 'args.isHiddenForAccessibility')
    get hideForAccessibility(): string | undefined {
        return this.args.isHiddenForAccessibility || isEmpty(this.ariaLabel) ? 'true' : undefined;
    }

    /**
     * Indicates the icon is interactive.
     */
    @computed('hideForAccessibility', 'args.{isFocusable,on-click}')
    get isInteractive(): boolean {
        const { args } = this;
        return !isEmpty(args['on-click']) || (!!args.isFocusable && isNone(this.hideForAccessibility));
    }

    /**
     * A computed CSS class based on icon name and color.
     */
    @computed('args.{icon,fillColor}')
    get iconCss(): string {
        const { icon, fillColor = 'gray-80' } = this.args;
        return [`svg-${icon}`, `adc-${fillColor}-fill`].join(' ');
    }

    /**
     * Fix to support safari svg sprite icons.
     * When we use svg icons with a <def> tag they do not render in
     * safari or ios webviews when used with a <use> tag. This method
     * copies the content inside <symbol><defs></defs></symbol> onto
     * the root of the <svg> so that safari can render it properly.
     * @param element The <def> element
     */
    @action
    loadSvgDefs(element: SVGDefsElement): void {
        const { icon } = this.args;
        if (icon) {
            const defsElement = document.querySelector(`svg[id=${getSpriteSelector}] > symbol#icn-${icon} > defs`);
            if (defsElement) {
                element?.replaceWith(defsElement.cloneNode(true));
            }
        }
    }

    @computed('args.icon')
    get hasSvgDefs(): boolean {
        // try catch  is necessary if query selector doesn't exist in the DOM such as when running the unit tests. Selector should exist in the customer site.
        try {
            const icon = this.args.icon;
            if (icon) {
                const defsElement = document.querySelector(`svg[id=${getSpriteSelector}] > symbol#icn-${icon} > defs`);
                return !!defsElement;
            }
        } catch (_) {
            // do nothing
        }
        return false;
    }
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * A simple component for showing SVG symbols from global SVG sprites.
         */
        SvgSymbol: typeof SvgSymbol;
    }
}
