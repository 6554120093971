export interface ScanParameters {
    umask: number;
    icon: string;
    scanDescription: string;
}

export type SCAN_TYPE = 'qr' | 'bar' | 'both';

/**
 * Returns the scan parameters based on what was specified in the 'scanCodeType'
 */
export function getScanParameters(scanCodeType: SCAN_TYPE, scanDescription: string): ScanParameters {
    const [umask, icon] = {
        both: [0, 'QRcode-scanner'],
        qr: [1, 'QRcode-scanner'],
        bar: [2, 'barcode-scanner']
    }[scanCodeType] as [number, string];

    return {
        umask,
        icon,
        scanDescription
    };
}

/**
 * Returns the redirect link to access the qr/barcode scanner feature in customer mobile app.
 */
export function getLinkToCamera(textFieldId: string, scanParameters: ScanParameters, barcodeLocation: string): string {
    return `android-app://${barcodeLocation}B.AutoFocus=true;S.fieldName=${textFieldId};B.ScanType=1;B.ScanDesc=${scanParameters.scanDescription};B.ScanCodeType=${scanParameters.umask};end`;
}
