import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEnterCode } from '@adc/ember-utils/utils/a11y';

import type { ModalModel } from '../../../../services/modals.ts';
import type { EditConfirmationModalSignature } from '../edit/edit-confirmations';

export interface RenameDialogModel extends ModalModel {
    title: EditConfirmationModalSignature['Args']['title'];
    maxLength?: number;
    rename: (value: string) => boolean | Promise<boolean>;
    message?: string;
    output: { value: string; error?: string };
}

export interface RenameDialogSignature {
    Element: EditConfirmationModalSignature['Element'];
    Args: {
        /** The rename dialog model. */
        model: RenameDialogModel;
    };
}

/**
 * @classdesc
 * A dialog for renaming an item
 */
export default class RenameDialog extends Component<RenameDialogSignature> {
    /**
     * Stored value to replace model's value
     */
    cachedValue?: string;

    /**
     * Saves the cached value.
     */
    @action
    saveChanges(): boolean | Promise<boolean> {
        return this.args.model.rename(this.cachedValue ?? this.args.model.output.value);
    }

    /**
     * Updates the cached value.
     */
    @action
    changeInputValue(evt: KeyboardEvent & { target: HTMLInputElement }): void {
        this.cachedValue = evt.target.value ?? this.args.model.output.value;
    }

    /**
     * Handles keydown for escape and enter.
     */
    @action
    keyInput(evt: KeyboardEvent & { target: HTMLInputElement }): void {
        if (!isEnterCode(evt.code)) {
            return;
        }

        this.changeInputValue(evt);
        const closeBtn = evt.target
            .closest('.rename-dialog-modal')
            ?.querySelector<HTMLElement>('.ui-modal-footer .btn-color-primary');

        // Cache new value, then trigger save using button so progress is shown.
        if (closeBtn) {
            closeBtn.click();
        } else {
            this.saveChanges();
        }
    }
}
