import templateOnlyComponent from '@ember/component/template-only';

export interface SimpleListDetailsSignature {
    Element: HTMLDivElement;
    Args: {
        description: string;
        details?: string;
    };
    Blocks: {
        default: [];
    };
}

const SimpleListDetails = templateOnlyComponent<SimpleListDetailsSignature>();
export default SimpleListDetails;
