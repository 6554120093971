import templateOnlyComponent from '@ember/component/template-only';

export interface PopoverBodySignature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}

const PopoverBody = templateOnlyComponent<PopoverBodySignature>();
export default PopoverBody;
