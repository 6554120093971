import templateOnlyComponent from '@ember/component/template-only';

import type { SystemSelectItem } from '../system-select.ts';
import type { SystemSelectListItemSignature } from './list-item';

type SystemSelectListItemSignatureArgs = SystemSelectListItemSignature['Args'];

export interface SystemSelectItemsListSignature {
    Element: HTMLUListElement;
    Args: Pick<SystemSelectListItemSignatureArgs, 'index' | 'parentIndex' | 'itemClicked'> & {
        /** Optional max height (in pixels) of the items collection. */
        maxHeight?: number;
        /** Indicates the items collection is focusable (not sure why since it's not interactive). */
        isFocusable?: boolean;
        /** The system select items to render. */
        items?: SystemSelectItem[];
    };
}

const SystemSelectItemsList = templateOnlyComponent<SystemSelectItemsListSignature>();
export default SystemSelectItemsList;
