import templateOnlyComponent from '@ember/component/template-only';

import type { SearchToolbarSignature } from '../../search-toolbar';

type SearchToolbarSignatureArgs = SearchToolbarSignature['Args'];

export interface DropdownMultiSelectToolbarSignature {
    Element: HTMLDivElement;
    Args: Pick<SearchToolbarSignatureArgs, 'searchString'> & {
        /** Optional title for the mobile only view. */
        placeholder?: string;
        /** Trigger when the close button is interacted with. */
        closeDropdown: VoidFunction;
        /** Indicates to show the search toolbar. */
        hasSearch?: boolean;
        search: SearchToolbarSignatureArgs['searchValueChange'];
    };
}
const DropdownMultiSelectToolbar = templateOnlyComponent<DropdownMultiSelectToolbarSignature>();
export default DropdownMultiSelectToolbar;
