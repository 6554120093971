export default function math(
    op: string,
    ...args: number[]
): number | boolean | undefined {
    return {
        '+': args.reduce((a, b) => a + b),
        '-': args.reduce((a, b) => a - b),
        '*': args.reduce((a, b) => a * b),
        '/': args.reduce((a, b) => a / b),
        abs: Math.abs(args[0]),
        max: Math.max(...args),
        min: Math.min(...args),
        gt: !!(args[0] > args[1]),
        lt: !!(args[0] < args[1])
    }[op];
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Performs a number of math operations, including '+', '-', '*', '/', 'abs', 'max', 'min', 'gt' or 'lt'.
         *
         * @example <p>{{math '+' @homeTemp @value}}</p>
         */
        math: typeof math;
    }
}
