import templateOnlyComponent from '@ember/component/template-only';

export interface PopoverFooterSignature {
    Element: HTMLElement;
    Blocks: {
        default: [];
    };
}

const PopoverFooter = templateOnlyComponent<PopoverFooterSignature>();
export default PopoverFooter;
