/**
 * Represents the different possible states for a dimmer switch.
 */
export const UNKNOWN = 'unknown',
    ON = 'on',
    OFF = 'off';

export type DimmerState = typeof UNKNOWN | typeof ON | typeof OFF;

export interface DimmerArgs {
    dimPercentage: number;
    dimmerState: string;
}
