import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';

import type { ComponentLike, WithBoundArgs } from '@glint/template';
import type { ModalHeaderSignature } from './header/modal-header';
import type { ModalFooterSignature } from './footer/modal-footer';

export interface BaseModalSignature {
    Element: HTMLDivElement;
    Blocks: {
        /** The modal header component. */
        header: [WithBoundArgs<ComponentLike<ModalHeaderSignature>, 'titleId'>];
        /** The body of the modal. */
        default: [];
        /** The modal footer component. */
        footer: [ComponentLike<ModalFooterSignature>];
    };
}

/**
 * @classdesc
 * Base Modal component
 */
export default class BaseModal extends Component<BaseModalSignature> {
    get ariaLabelledBy(): string {
        return `${guidFor(this)}_header`;
    }
}
