// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';

/**
 * @classdesc
 *
 * A transform that allows boolean to have null values.
 */
export default class NullableBoolean extends DS.BooleanTransform {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    deserialize(serialized: boolean | null): boolean | null {
        // If the serialized value is null, then deserialize as null.
        if (serialized === null) {
            return null;
        }

        // Else wise deserialize it like a normal boolean.
        return super.deserialize(serialized, {});
    }

    serialize(deserialized: boolean | null): boolean | null {
        // If the deserialize value is null, then serialize as null.
        if (deserialized === null) {
            return null;
        }

        // Else wise serialize it like a normal boolean.
        return super.serialize(deserialized, {});
    }
}
