import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { timeout, task } from 'ember-concurrency';

import type { Task } from 'ember-concurrency';

export interface PendingContentSignature {
    Args: {
        /** Indicates the route-view model is still pending. */
        isPending?: boolean;
        /** The amount of milliseconds to delay the transition to fulfilled. */
        showDelay?: number;
    };
    Blocks: {
        default: [];
    };
}

/**
 * Component used for displaying "waiting" message to the end user.
 */
export default class PendingContent extends Component<PendingContentSignature> {
    /**
     * Show the content?
     */
    @tracked show = false;

    /**
     * Delay in ms after which this component is shown.
     */
    get showDelay(): number {
        return this.args.showDelay ?? 500;
    }

    /**
     * Handles showing/hiding changes.
     */
    showHideElement: Task<void, never> = task({ drop: true }, async () => {
        if (this.args.isPending) {
            await timeout(this.showDelay);
            this.show = this.args.isPending;
        }
    });
}
