import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { isDestroyed } from '@adc/ember-utils/utils/ember-helpers';
import { reads, empty, filterBy } from '@ember/object/computed';
import { computed } from '@ember/object';
import { once, scheduleOnce } from '@ember/runloop';
import DropdownSelectItem, { UNSELECTED, SELECTED } from '../../utils/dropdown-select-item.js';
import { A } from '@ember/array';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

import type { CommonInputErrorTooltipArgs } from '../error-tooltip';
import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { Task } from 'ember-concurrency';

const VALUE_CHANGE_ACTION = 'value-change';

/**
 * Update the selected item based on the given value.
 *
 * @param value - The value of the item that should be selected.
 * @param [shouldIgnoreAction=false] - Should the action notifying the user of a change in value not be sent?
 */
function updateSelectedValue(this: SingleSelectComponent, value: string, shouldIgnoreAction = false): void {
    if (this.resolvedItems.length) {
        this.selectItem(value, shouldIgnoreAction);
    }
}

/**
 * Toggles the error message visibility.
 */
function toggleErrorMessageVisibility(this: SingleSelectComponent, errorMessage?: string): void {
    if (!isDestroyed(this)) {
        this.hideErrorMessage = !errorMessage;
    }
}

export interface SingleSelectSignature {
    Element: HTMLSelectElement;
    Args: CommonInputErrorTooltipArgs & {
        /** Triggered when the user chooses a new value for the drop down. */
        [VALUE_CHANGE_ACTION]?: (value: string) => void;
        /** The currently selected value. */
        value?: string;
        /** The dropdown items to render. */
        items?: Promise<DropdownSelectItem[]> | DropdownSelectItem[];
        /** Optional ID value for the native select element (should be passed as HTML attribute instead). */
        selectId?: string;
        /** Text for the optional unselectable placeholder option for the native select element. */
        placeholder?: string;
        /** Indicates a selection value is required (should be passed as HTML attribute instead). */
        required?: boolean;
        /** Indicates the native select element should be disabled (should be passed as HTML attribute instead). */
        disabled?: boolean;
        /** Optional aria-label for the select element (should be passed as HTML attribute instead). */
        label?: string;
    };
}

/**
 * @classdesc
 * ADC's custom version of the native HTML select element. Only one item can be selected from this dropdown.
 *
 * @note This component should not be directly rendered in a template. Use dropdown-select with the
 *       "multiselect" property set to false in order to use this.
 */
export default class SingleSelectComponent extends Component<SingleSelectSignature> {
    @service declare intl: ADCIntlService;

    @tracked resolvedItems: DropdownSelectItem[] = [];

    /**
     * The dropdown select item enum value for SELECTED.
     */ // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dropdownSelectItemEnumSelected = SELECTED;

    /**
     * A task for resolving promise items.
     */
    resolveItems: Task<void, never> = task({ restartable: true }, async () => {
        const items = await this.args.items;
        this.resolvedItems = items ? [...items] : [];
    });

    /**
     * Should the error message be hidden?
     */
    @tracked hideErrorMessage = false;

    /**
     * The error message for the select.
     */
    get resolvedErrorMessage(): string | undefined {
        return this.hideErrorMessage ? '' : this.args.errorMessage;
    }

    /**
     * Are there no items?
     */
    @empty('resolvedItems')
    isEmpty!: boolean;

    /**
     * A collection of items that are selected in the dropdown.
     *
     * @note This should only contain one item for the DropdownSelectSingle.
     *       Warning: Never bind to this value from the outside. This should
     *       be used internally only.
     *
     * @ignore
     */
    @filterBy('resolvedItems', 'state', SELECTED)
    selectedItems!: DropdownSelectItem[];

    /**
     * The item that is selected in the dropdown-select.
     *
     * @note This property should only be used in the DropdownSelectSingle component.
     *       Warning: never bind to this value from the outside. This should be used
     *       internally only.
     */
    @reads('selectedItems.firstObject')
    selectedItem!: DropdownSelectItem;

    /**
     * The aria-label attribute to apply.  Use whats passed in if passed in, otherwise use default
     */
    @computed('args.label')
    get label(): string {
        return this.args.label || this.intl.t('@adc/ui-components.triggerSelect');
    }

    /**
     * Determines if the selected items have changed.
     */
    hasSelectionChanged(currentValue: string, previousValue?: string): boolean {
        try {
            // NOTE: The try/catch was added because there is a circular reference happening in items.
            return JSON.stringify(currentValue) !== JSON.stringify(previousValue);
        } catch (e) {
            return currentValue !== previousValue;
        }
    }

    /**
     * Triggers the value-change action if the selections inside the dropdown have changed.
     */
    saveSelectionChange(
        currentValue: string,
        previousValue?: string,
        valueChangeParameter: string = currentValue
    ): void {
        if (this.hasSelectionChanged(currentValue, previousValue)) {
            toggleErrorMessageVisibility.call(this);

            const valueChangeAction = this.args[VALUE_CHANGE_ACTION];

            if (valueChangeAction) {
                valueChangeAction(valueChangeParameter);
            }
        }
    }

    /**
     * Sets the specified item's state to selected.
     *
     * @param selectedValue - The value of the item that was selected.
     * @param [shouldIgnoreAction=false] - Should the action notifying the user of a change in value not be sent?
     */
    selectItem(selectedValue: string, shouldIgnoreAction = false): boolean {
        if (!selectedValue) {
            return false;
        }

        const flattenedItems = A<DropdownSelectItem>(
                this.resolvedItems
                    .map((item) => (item.hasSubitems ? item.subitems : item))
                    .reduce((acc, val) => acc.concat(val), [])
            ),
            previousItem = flattenedItems.findBy('state', SELECTED) ?? ({} as DropdownSelectItem),
            selectedItem = flattenedItems.findBy('value', selectedValue);

        if (!selectedItem) {
            return false;
        }

        // Ensure only the clicked item is selected.
        flattenedItems.setEach('state', UNSELECTED);
        set(selectedItem, 'state', SELECTED);

        if (!shouldIgnoreAction) {
            // Note: Native selects close when an item is selected.
            this.onDropdownClose(selectedItem.value, previousItem.value);
        }

        return true;
    }

    /**
     * Synchronizes the currently selected item with the current value.
     */
    @action synchronizeSelectedItem(): void {
        // Some parents do not utilize the 'value' arg, and just set the state of the options in the items array directly
        // This can confuse Ember, so to compensate, if this.args.value is undefined, we synchronize by
        //  finding the selected item in the array and 'select' it again to ensure Ember updates the UI
        const value = this.args.value ?? this.resolvedItems.find((x) => x.state)?.value ?? '';
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        once<SingleSelectComponent, typeof updateSelectedValue>(this, updateSelectedValue, value, true);
    }

    /**
     * Called when the user changes the selected item in the UI.
     */
    @action itemClicked(evt: Event): void {
        this.selectItem((evt.target as HTMLSelectElement).value);
    }

    /**
     * Calls the saveSelectionChange method.
     */
    @action onDropdownClose(currentValue: string, previousValue?: string, valueChangeParameter?: string): void {
        this.saveSelectionChange(currentValue, previousValue, valueChangeParameter);
    }

    /**
     * Triggers when the error message changes.
     */
    @action onErrorMessageChange(): void {
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        scheduleOnce<SingleSelectComponent, typeof toggleErrorMessageVisibility>(
            'afterRender',
            this,
            toggleErrorMessageVisibility,
            this.args.errorMessage
        );
    }

    /**
     * Sets the attributes on the option element.
     */
    @action updateOption(item: DropdownSelectItem, el: HTMLOptionElement): void {
        if (item.state === SELECTED) {
            el.setAttribute('selected', 'selected');
        } else {
            el.removeAttribute('selected');
        }
    }
}
