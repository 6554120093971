import Modifier from 'ember-modifier';
import { assert } from '@ember/debug';
import { runTask } from 'ember-lifeline';

export interface AutoFocusSignature {
    Element: HTMLElement;
    Args: {
        Positional: [string?];
    };
}

/**
 * @classdesc A template modifier for setting focus on the passed element.
 * @note If setting focus fails an assert will be raised.
 *
 * Usage: {{auto-focus}} or {{auto-focus '.my-focused-element'}}
 */

export default class AutoFocusModifier extends Modifier<AutoFocusSignature> {
    modify(element: HTMLElement, [selector]: [string]) {
        const elementToFocus = selector
            ? element.querySelector(selector)
            : element;
        runTask(
            this,
            () => {
                if (elementToFocus) {
                    if (elementToFocus.hasAttribute('disabled')) {
                        return;
                    }
                    (elementToFocus as HTMLElement).focus();
                }

                assert(
                    `The auto-focus template modifier failed, perhaps the passed element is not focusable ${elementToFocus}`,
                    document.activeElement === elementToFocus
                );
            },
            0
        );
    }
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Focuses on the modified element, or a child element if a selector is passed.
         *
         * @example <Button::Simple @text={{t 'generic.ok'}} {{auto-focus}} />
         */
        'auto-focus': typeof AutoFocusModifier;
    }
}
