import Helper from '@ember/component/helper';
import { getModalFooterButton } from '../components/modals/base/footer/modal-footer.ts';
import { isTestEnvironment } from '../utils/general.ts';

import type { ModalButtonConfig } from '../components/modals/base/footer/modal-footer';

export interface GetModalButtonSignature {
    Args: {
        Named: Partial<ModalButtonConfig> & {
            isPrimary?: boolean;
        };
    };
    Return: ModalButtonConfig & {
        toString?: () => string;
    };
}

/**
 * A template helper for generating modal footer button configuration objects (ModalButtonConfig).
 */
export default class GetModalButton extends Helper<GetModalButtonSignature> {
    compute(
        _: [],
        { text = '', action, css, disabled, isPrimary }: GetModalButtonSignature['Args']['Named']
    ): GetModalButtonSignature['Return'] {
        const cfg = getModalFooterButton(text, action, css, disabled, isPrimary);

        if (isTestEnvironment.call(this)) {
            cfg.toString = function () {
                return JSON.stringify(this);
            };
        }

        return cfg;
    }
}
