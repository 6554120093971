import { htmlSafe } from '@ember/template';

export default function safeHtml(value = ''): ReturnType<typeof htmlSafe> {
    return htmlSafe(value);
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Returns a HTML safe version of the passed string.
         *
         * @example <p>{{safe-html @model.info}}</p>
         */
        'safe-html': typeof safeHtml;
    }
}
