import Helper from '@ember/component/helper';
import { hash } from 'rsvp';

type AwaitedObject<T> = {
    [K in keyof T]: Awaited<T[K]>;
};

interface PromiseHashTrackedSignature<T> {
    Args: {
        Named: T;
    };
    Return: Promise<AwaitedObject<T>>;
}

export default class PromiseHashTrackedHelper<
    T extends Record<string, Promise<any> | any>
> extends Helper<PromiseHashTrackedSignature<T>> {
    compute(_: never[], obj: T): Promise<AwaitedObject<T>> {
        // Hack to fix https://github.com/emberjs/ember.js/issues/19277.
        Object.keys(obj).forEach((k) => obj[k]);
        return hash<AwaitedObject<T>>(obj);
    }
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Accepts any number of named promises, and returns a single promise that resolved into an object with all of those resolved promises as properties.
         *
         * @example @model={{promise-hash-tracked rule=@model.rule extraRuleOption=@model.extraRuleOption}}
         */
        'promise-hash-tracked': typeof PromiseHashTrackedHelper;
    }
}
