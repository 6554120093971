import BaseWebsocketMessage, { WebsocketMessageType } from './base.ts';

/**
 * @classdesc
 * Message class for SerializedGeofenceCrossing websocket message
 */
export default class GeofenceCrossingWebsocketMessage extends BaseWebsocketMessage {
    constructor(unitId: string, deviceId: string, fenceId: string, isInsideNow: string) {
        super(unitId, deviceId);

        Object.assign(this, {
            value: fenceId,
            subvalue: isInsideNow
        });
    }

    type = WebsocketMessageType.geofenceCrossing;
}
