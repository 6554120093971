/**
 * @module
 */
/**
 * @enum {number}
 */
export const PresenceStateApi = 1;
/**
 * @enum {number}
 */
export const DiscoverApi = 7;
/**
 * @enum {number}
 */
export const NotSet = -1;
/**
 * @enum {number}
 */
export const IntercomApi = 11;
/**
 * @enum {number}
 */
export const PointCentralCommunityApi = 16;

/**
 * @enum {number}
 */
export const MassNotificationsApi = 17;