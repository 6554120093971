import Component from '@glimmer/component';
import { isEnterOrSpaceCode } from '@adc/ember-utils/utils/a11y';
import { action } from '@ember/object';

import type DropdownActionItem from '../../utils/dropdown-action-item.ts';

export interface DropdownActionsListItemSignature {
    Element: HTMLLIElement;
    Args: {
        /** The drop down action to render. */
        item: DropdownActionItem;
        /** Triggered when the action item is clicked. */
        'on-item-click': (item: DropdownActionItem) => void;
        /** The index of this item. */
        index: number;
    };
}

/**
 * @classdesc
 * A list item component to be used inside an items list.
 */
export default class ListItemComponent extends Component<DropdownActionsListItemSignature> {
    /**
     * Executes action on 'enter' or 'space'.
     */
    @action onKeyUp(item: DropdownActionItem, e: KeyboardEvent): void {
        if (isEnterOrSpaceCode(e.code)) {
            e.preventDefault();

            this.args['on-item-click'](item);
        }
    }
}
