import { action, computed } from '@ember/object';
import { service } from '@ember/service';
import Service from '@ember/service';
import { A } from '@ember/array';
import { registerDestructor } from '@ember/destroyable';
import { task, timeout } from 'ember-concurrency';
import { isTestEnvironment } from '../utils/environment.ts';

import type AdcAjaxService from '@adc/ajax/services/adc-ajax';
import type ContextManager from './context-manager.ts';
import type Transition from '@ember/routing/transition';
import type RouterService from '@ember/routing/router-service';
import type { Task } from 'ember-concurrency';

// Mark Names
const CSS_PARSE_START = 'CSSParseStart';
const CSS_PARSE_END = 'CSSParseEnd';
const JS_PARSE_START = 'JSParseStart';
const JS_PARSE_END = 'JSParseEnd';
const TTI_END = 'TTIEnd';
const ROUTE_INITIALIZED = 'RouteInitialized';
const LAST_RENDER_MARK_RENDERED = 'LastRenderMarkRendered';
const CONTEXT_SWITCH_INITIALIZED = 'ContextSwitchInitialized';
const CONTEXT_SWITCH_RESOLVED = 'ContextSwitchResolved';

// Measurement Names
const TIME_TO_INTERACTIVE = 'TimeToInteractive';
const CSS_PARSE_TIME = 'CSSParseTime';
const JS_PARSE_TIME = 'JSParseTime';
const ROUTE_VIEW_RENDERED = 'RouteViewRendered';
const MODEL_RESOLVED = 'ModelResolved';
const CONTEXT_SWITCH_TIME = 'ContextSwitchTime';

// KPI Metric Contstants
const DEFAULT_KPI_THRESHOLD = 15000;
const KPI_API_ENDPOINT = 'swa/performance/metrics/recordKpiThresholdExceeded';

// Telling typescript that the isDisabled boolean might exist on Performance.
declare global {
    interface Performance {
        isDisabled?: boolean;
    }
}

/**
 * Wrapper for creating a browser performance measurement.
 */
export function createMeasurement(measurementName: string, startMark: string, endMark: string): void {
    if (!doAllMarksExist([startMark, endMark])) {
        console.info(`Route potentially has view-container and no route-view. Route=${window.location.pathname}`);
        return;
    }

    window.performance.measure(measurementName, startMark, endMark);
}

/**
 * returns true if all the marks exist in window performance array
 */
function doAllMarksExist(markNames: string[]): boolean {
    // metrics like 'requestStart' are valid and come from the performance timing api
    const browserReadOnlyTimes = getPerformanceTimingApiDefinedEntries(),
        allRecordedMarkNames = A(window.performance.getEntriesByType('mark')).mapBy('name'),
        allPossibleMarks = A(browserReadOnlyTimes.concat(allRecordedMarkNames));
    return markNames.every((markName) => allPossibleMarks.includes(markName));
}

/**
 * filters all the mark names and returns only the marks that have been created
 */
function filteredMarkNamesByCreated(markNames: string[]): string[] {
    const allRecordedMarkNames = A(window.performance.getEntriesByType('mark')).mapBy('name');
    return markNames.filter((markName) => allRecordedMarkNames.includes(markName));
}

/**
 * Gets all of the performance timing api defined metrics
 */
function getPerformanceTimingApiDefinedEntries(): string[] {
    // performance.timing generates an object that is not iterable
    // wrapping it in JSON.parse(JSON.stringify() ) makes the object iterable
    return Object.keys(JSON.parse(JSON.stringify(window.performance.timing)));
}

/**
 * Wrapper for creating a browser performance mark.
 */
export function createMark(markName: string): PerformanceMark {
    return window.performance.mark(markName);
}

/**
 * Clear performance marks for route view.
 */
function clearRouteViewMarks(routeName: string): void {
    clearMarks(
        `${ROUTE_INITIALIZED}-${routeName}`,
        `${ROUTE_VIEW_RENDERED}-${routeName}`,
        `${MODEL_RESOLVED}-${routeName}`,
        `${LAST_RENDER_MARK_RENDERED}-${routeName}`
    );
}

/**
 * Clear performance marks for context switches.
 */
function clearContextSwitchMarks(): void {
    clearMarks(CONTEXT_SWITCH_INITIALIZED, CONTEXT_SWITCH_RESOLVED);
}

/**
 * Clear performance marks for the given mark names.
 */
function clearMarks(...markNames: string[]): void {
    // Filter first to make sure mark exists before clearing
    const filtered = filteredMarkNamesByCreated(markNames);
    filtered.forEach((mark) => window.performance.clearMarks(mark));
}

/**
 * Returns a dictionary of the requested measurements.  Clears any measurements that are returned.
 * The value of the dictionary is the measurement value.
 */
export function getMeasurements(...measurementNames: string[]): Record<string, number | string> {
    const resultDictionary: Record<string, number> = {};
    window.performance
        .getEntriesByType('measure')
        .filter((performanceEntry) => measurementNames.includes(performanceEntry.name))
        .forEach((performanceEntry) => {
            resultDictionary[performanceEntry.name] = performanceEntry.duration;
            window.performance.clearMeasures(performanceEntry.name);
        });

    return resultDictionary;
}

/**
 * This function accepts a routeName (such as 'access-control.index') and converts it into a path format ('access-control/index').
 */
function convertRouteNameToPath(routeName: string): string {
    // We replace the '.' delimitter w/ '/' because '.' is used as the separator for different pieces of the metric description and we wouldn't be able to tell when the
    // route name started on ended.  We can't use '-' because it appears within the route.
    return routeName.replace(/[.]/g, '/');
}

/**
 * @classdesc A service for managing performance metrics
 * A standard javascript API is used in this class called Performance API including methods mark and measure.
 * Documentation of this API: https://developer.mozilla.org/en-US/docs/Web/API/Performance
 */
export default class PerformanceMonitorService extends Service {
    @service declare ajax: AdcAjaxService;
    @service declare contextManager: ContextManager;
    @service declare router: RouterService;

    constructor(...args: any[]) {
        super(...args);

        // We don't want this to run for every test. For now, it will only operate in non-test enviornments.
        if (!isTestEnvironment.call(this)) {
            this.router.on('routeDidChange', this.startKpiThresholdTask);
            registerDestructor(this, () => {
                this.router.off('routeDidChange', this.startKpiThresholdTask);
            });
        }
    }

    /**
     * Should the performance monitor send metrics to the server?
     * NOTE: often used to disable the service during integration tests.
     */
    @computed()
    get enabled(): boolean {
        // If window.performance doesn't have the methods we need, we add isDisabled=true in index.html.
        return !window.performance.isDisabled;
    }

    /**
     * Dictionary that tracks what route was active when a given metric was reported.
     * The key is the metric name and the value is the route.
     */
    routeViewMetricPathDictionary: Record<string, string> = {} as Record<string, string>;

    /**
     * Determines if we are going to use the kpi threshold metric tracking.
     * Should be determined on a route by route basis.
     */
    useKpiThresholdMetrics = false;

    /**
     * Holds the id of the context from which the context switch was initiated.
     */
    private contextSwitchPreviousId = '';

    /**
     * This task is triggered by a listener on routeDidChange.
     * If the task wakes up before the last component render mark is made,
     * we will log a count for that route, indicating that it took longer than
     * the threshold to load.
     * If the kpiThreshold argument is the default value of 15000ms,
     * we will want to record any routes that take longer than 5000ms, 10000ms, and 15000ms
     * without duplicating records.
     * A kpiThreshold can be set for testing purposes
     */
    kpiThresholdTask: Task<void, [number]> = task({ restartable: true }, async (kpiThreshold: number) => {
        let exceededThreshold = 0;
        const thresholdIncrement = kpiThreshold === 1 ? 1 : Math.floor(kpiThreshold / 3);

        for (let threshold = thresholdIncrement; threshold <= kpiThreshold; threshold += thresholdIncrement) {
            await timeout(thresholdIncrement);

            // If the last component has been rendered, do nothing.
            if (
                !this.useKpiThresholdMetrics ||
                doAllMarksExist([`${LAST_RENDER_MARK_RENDERED}-${this.router.currentRouteName}`])
            ) {
                break;
            }

            exceededThreshold = threshold;
        }

        // If an exceeded threshold was detected, send the request.
        if (exceededThreshold > 0) {
            const data = {
                routePath: convertRouteNameToPath(this.router.currentRouteName),
                kpiThreshold: exceededThreshold
            };

            this.ajax.apiRequest(KPI_API_ENDPOINT, {}, JSON.stringify(data), 'POST');
        }
    });

    /**
     * Send metrics in milliseconds of initial page rendering to server
     */
    private sendInitialMetricsToServer(source = ''): Promise<string> {
        if (!this.enabled) {
            return Promise.resolve('');
        }

        createMeasurement(CSS_PARSE_TIME, CSS_PARSE_START, CSS_PARSE_END);
        createMeasurement(JS_PARSE_TIME, JS_PARSE_START, JS_PARSE_END);
        // requestStart is a predefined browser mark.
        createMeasurement(TIME_TO_INTERACTIVE, 'requestStart', TTI_END);

        // We intentionally do not clear the marks here, as we only expect them to be created once during the entire app/page lifecycle.

        const data: Record<string, number | string> = getMeasurements(
            CSS_PARSE_TIME,
            JS_PARSE_TIME,
            TIME_TO_INTERACTIVE
        );
        data['source'] = source;

        return this.ajax.apiRequest('swa/performance/metrics/recordInitialLoad', {}, JSON.stringify(data), 'POST');
    }

    /**
     * Send metrics in milliseconds of routes that use route view to server
     * Only send metrics to server if routeView metrics come from same route.  Returns true if successful and false if the route data was bad.
     */
    private sendRouteViewMetricsToServer(routeName = ''): Promise<boolean> {
        if (!this.enabled) {
            return Promise.resolve(true);
        }

        // Grab the metrics which match the path for the routeInitialized metric.
        // In the case where multiple paths use the same route-view component (e.g. locations dashboards), it won't be rerendered,
        // so routeViewRendered will remain set to the previous route path. In that case, we won't record the routeViewRendered time.
        const validMetrics = [ROUTE_VIEW_RENDERED, MODEL_RESOLVED].filter(
            (metricName) =>
                this.routeViewMetricPathDictionary[metricName] === this.routeViewMetricPathDictionary[ROUTE_INITIALIZED]
        );
        let data;
        try {
            if (!validMetrics.length) {
                clearRouteViewMarks(routeName);
                return Promise.resolve(false);
            }

            // Record the routePath in our request body.
            const routePath = convertRouteNameToPath(this.routeViewMetricPathDictionary[ROUTE_INITIALIZED]);
            validMetrics.forEach((metricName) =>
                createMeasurement(metricName, `${ROUTE_INITIALIZED}-${routeName}`, `${metricName}-${routeName}`)
            );

            data = {
                ...getMeasurements(ROUTE_VIEW_RENDERED, MODEL_RESOLVED),
                routePath
            };
        } catch (err) {
            console.error('Error logging performance metrics.', err);
            return Promise.resolve(false);
        }

        // TODO JB: Update to get from the ENV config
        return this.ajax
            .apiRequest('swa/performance/metrics/recordRouteView', {}, JSON.stringify(data), 'POST')
            .then(() => true);
    }

    /**
     * Marks the point in which JS finishes parsing by creating a time stamp
     */
    markJSEnd(): void {
        createMark(JS_PARSE_END);
    }

    /**
     * Marks the point in which the page becomes interactive by creating a time stamp.
     * Also - flushes the initial load metrics to the server. Resolves after the metrics are sent to the server.
     */
    markTTIEnd(source = ''): Promise<string> {
        createMark(TTI_END);
        return this.sendInitialMetricsToServer(source);
    }

    /**
     * Marks the point in which the route is initialized by creating a time stamp
     */
    markRouteInitialized(routeName: string, transition: Transition): void {
        // This could get called for every route along the path and we only want to mark the route once (as early as possible.).
        // For example, with /users/1/contact-information/345, this will get called for users and users.contact-information.
        if (transition.data.routeMarkedAsInitialized) {
            return;
        }

        createMark(`${ROUTE_INITIALIZED}-${routeName}`);
        this.routeViewMetricPathDictionary[ROUTE_INITIALIZED] = routeName;

        transition.data.routeMarkedAsInitialized = true;
    }

    /**
     * Marks the point in which route view renders
     */
    markRouteViewRendered(routeName: string): void {
        createMark(`${ROUTE_VIEW_RENDERED}-${routeName}`);
        this.routeViewMetricPathDictionary[ROUTE_VIEW_RENDERED] = routeName;
    }

    /**
     * Marks the point in which the model resolves.  Also flushes the route metrics to the server.
     * Resolves when the request is finished.
     */
    markModelResolved(routeName: string): Promise<boolean> {
        createMark(`${MODEL_RESOLVED}-${routeName}`);
        this.routeViewMetricPathDictionary[MODEL_RESOLVED] = routeName;

        return this.sendRouteViewMetricsToServer(routeName);
    }

    /**
     * Marks that the LastRenderMark component has rendered. Which should be the last item to render on a route
     */
    @action
    markLastRender(): void {
        createMark(`${LAST_RENDER_MARK_RENDERED}-${this.router.currentRouteName}`);
    }

    /**
     * Marks the point in which we initialized the process for switching the current context.
     */
    markContextSwitchInitialized(contextId: string): void {
        createMark(CONTEXT_SWITCH_INITIALIZED);
        this.contextSwitchPreviousId = contextId;
    }

    /**
     * Marks the point in which we resolved the context switch.
     */
    async markContextSwitchResolved(contextId: string): Promise<boolean> {
        createMark(CONTEXT_SWITCH_RESOLVED);

        if (!this.enabled) {
            clearContextSwitchMarks();
            return false;
        }

        createMeasurement(CONTEXT_SWITCH_TIME, CONTEXT_SWITCH_INITIALIZED, CONTEXT_SWITCH_RESOLVED);
        const data = getMeasurements(CONTEXT_SWITCH_TIME);
        if (Object.keys(data).length === 0) {
            clearContextSwitchMarks();
            return false;
        }

        data.originalContextId = this.contextSwitchPreviousId;
        data.newContextId = contextId;
        clearContextSwitchMarks();
        await this.ajax.apiRequest('swa/performance/metrics/recordContextSwitch', {}, JSON.stringify(data), 'POST');
        return true;
    }

    /**
     * Sets a boolean indicating if we want to use the kpi threshold metrics.
     */
    setUseKpiThresholdMetrics(value: boolean): void {
        this.useKpiThresholdMetrics = value;
    }

    /**
     * This exists for the purpose of testing the KPI threshold metrics.
     */
    private getKpiThreshold(): number {
        const currentURL = this.router.currentURL,
            queryParams = new URL(currentURL, window.location.origin).searchParams,
            kpiThresholdParam = queryParams.get('kpiThreshold');
        let kpiThreshold = Number(kpiThresholdParam);

        // If kpiThreshold is not a number or doesn't exist, default to 15 seconds
        if (isNaN(kpiThreshold) || kpiThreshold <= 0) {
            kpiThreshold = DEFAULT_KPI_THRESHOLD;
        }

        return kpiThreshold;
    }

    /**
     * Starts the kpi threshold task and passes in the threshold.
     */
    @action
    private startKpiThresholdTask(): void {
        const routePath = this.router.currentRouteName,
            kpiThreshold = this.getKpiThreshold();

        // first we need to make sure we've cleared all previous marks for this route.
        window.performance.clearMarks(`${LAST_RENDER_MARK_RENDERED}-${routePath}`);

        this.kpiThresholdTask.perform(kpiThreshold);
    }
}
