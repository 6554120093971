import BaseWebsocketMessage, { WebsocketMessageType } from './base.ts';

/**
 * @classdesc
 * Message class for SerializedEvent websocket message
 */
export default class EventWebsocketMessage extends BaseWebsocketMessage {
    constructor(
        unitId: string,
        deviceId: string,
        eventType: string,
        eventValue: string,
        qStringForExtraData: string,
        eventDateUtc: string
    ) {
        super(unitId, deviceId);

        Object.assign(this, {
            subtype: eventType,
            value: eventValue,
            subvalue: qStringForExtraData,
            eventDateUtc
        });
    }

    type = WebsocketMessageType.event;

    /**
     * The timestamp of the event.
     */
    declare eventDateUtc: string;
}
