import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { MINUTES_IN_DAY } from '@adc/ember-utils/constants/time';
import startOfYear from 'date-fns/startOfYear';
import addMinutes from 'date-fns/addMinutes';

import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { SafeString } from 'handlebars';

const TWELVE_AM = 0;
export const NINE_AM = 9;
export const TWELVE_PM = 12;
export const FIVE_PM = 17;
const TEN_PM = 22;

export interface ScheduleHourLabelsSignature {
    Element: HTMLDivElement;
    Args: {
        /** Indicates the hours axis should be rendered on the vertical axis. */
        isVertical?: boolean;
        /** Indicates which hours should show labels. */
        hours?: number[];
    };
}

/**
 * Displays hour labels for a schedule.
 */
export default class ScheduleHourLabels extends Component<ScheduleHourLabelsSignature> {
    @service declare intl: ADCIntlService;

    /**
     * Labels for specific times on the schedule
     */
    @computed('args.{hours.[],isVertical}')
    get hourLabels(): { label: string; style: SafeString }[] {
        const start = startOfYear(new Date());

        // Create absolutely positioned labels for supplied hours.
        return (this.args.hours ?? [TWELVE_AM, NINE_AM, TWELVE_PM, FIVE_PM, TEN_PM])
            .map((hours) => hours * 60)
            .map((minutes) => ({
                // Do not copy this deprecated usage. If you see this, please fix it
                // eslint-disable-next-line @adc/ember/require-tz-functions
                label: this.intl.formatTime(addMinutes(start, minutes), {
                    hour: 'numeric'
                }),
                style: htmlSafe(`${this.args.isVertical ? 'top' : 'left'}:${(minutes * 100) / MINUTES_IN_DAY}%`)
            }));
    }
}
