import BaseView from './base-view.ts';
import { computed, get, set, setProperties } from '@ember/object';
import { MINUTES_IN_DAY, MINUTES_IN_WEEK, DAYS_IN_WEEK } from '@adc/ember-utils/constants/time';
import { htmlSafe } from '@ember/template';
import setDay from 'date-fns/setDay';

import type { TimeBlock, ScheduleObject, ScheduleElement, PlanSchedule } from './types';

/**
 * The properties that should be applied to set a time block to be 24/7.
 */
export const ALL_TIMES_PROPERTIES: TimeBlock = {
    day: 1,
    startMinutesLocal: MINUTES_IN_DAY,
    endMinutesLocal: MINUTES_IN_WEEK + MINUTES_IN_DAY
};

/**
 * Merge overlapping schedules.
 */
export function mergeSchedules(timeBlocks: TimeBlock[]): TimeBlock[] {
    // Check that there's at least one schedule
    if (!timeBlocks || timeBlocks.length <= 0) {
        return [];
    }

    // Sort the schedules by start time in the week or by the end time if the start times are the same
    timeBlocks = timeBlocks.sort(
        ({ startMinutesLocal: startA, endMinutesLocal: endA }, { startMinutesLocal: startB, endMinutesLocal: endB }) =>
            startA - startB || endA - endB
    );

    // Create a stack with the first schedule in it.
    const stack = [timeBlocks.shift() as TimeBlock];

    // Start from the next interval and merge if necessary.
    timeBlocks.forEach((schedule) => {
        const last = stack.slice().pop() as TimeBlock,
            { endMinutesLocal: lastEnd } = last,
            { startMinutesLocal: scheduleStart, endMinutesLocal: scheduleEnd } = schedule;

        // Does the current schedule NOT overlap with the latest from the stack?
        if (lastEnd < scheduleStart) {
            stack.push(schedule);

            // Is the current schedule later?
        } else if (lastEnd < scheduleEnd) {
            // Update last with new end time,
            set(last, 'endMinutesLocal', scheduleEnd);
        }
    });

    const last = stack.slice().pop() as TimeBlock;
    let [first] = stack,
        endMinutesLocal = last.endMinutesLocal;

    if (first) {
        // Does the last schedule in the stack leak into next week?
        if (stack.length > 1 && endMinutesLocal >= MINUTES_IN_WEEK) {
            while (stack.length > 1 && endMinutesLocal % MINUTES_IN_WEEK >= first.startMinutesLocal) {
                // Update the endMinutesLocal with the greater of the two ends of the schedules we're comparing.
                endMinutesLocal = Math.max(first.endMinutesLocal + MINUTES_IN_WEEK, endMinutesLocal);
                set(last, 'endMinutesLocal', endMinutesLocal);

                // Remove first schedule.
                stack.shift();

                // Update first.
                [first] = stack;
            }
        }

        // Is there only one schedule?
        if (stack.length === 1) {
            const { startMinutesLocal: start, endMinutesLocal: end } = first;

            // Does it cover all times?
            if (end % MINUTES_IN_WEEK >= start && end >= MINUTES_IN_WEEK) {
                // Reset to exactly one week.
                setProperties(first, ALL_TIMES_PROPERTIES);
            }
        }
    }

    return stack;
}

/**
 * Get a PlanSchedule from a ScheduleObject.
 */
export function getPlanSchedule(schedule: ScheduleObject): PlanSchedule {
    const { id = 0, colorName = 'black', timetables = [] } = schedule;
    return {
        planId: id,
        planColor: colorName,
        combinedSchedules: mergeSchedules(
            timetables.reduce(
                (timeBlocks, timetable) => [
                    ...timeBlocks,
                    ...(get(timetable, 'scheduleDictionary') ?? []).map(
                        ({ startMinutesLocal, endMinutesLocal, day }) => ({
                            // Convert start and end times to represent minutes from the beginning of the week
                            day,
                            startMinutesLocal: startMinutesLocal + MINUTES_IN_DAY * day,
                            endMinutesLocal: endMinutesLocal + MINUTES_IN_DAY * day
                        })
                    )
                ],
                []
            )
        )
    };
}

export interface BaseScheduleSignature {
    Element: HTMLDivElement;
    Args: {
        /** The array of ScheduleObjects to be rendered. */
        scheduleObjects?: ScheduleObject[];
    };
}

/**
 * @classdesc
 * Base class for all schedule components.
 */
export default class BaseSchedule<T extends BaseScheduleSignature = BaseScheduleSignature> extends BaseView<T> {
    /**
     * Creates a plan schedule.
     */
    protected getPlanSchedule(schedule: ScheduleObject): PlanSchedule {
        return getPlanSchedule(schedule);
    }

    /**
     * Adds time blocks to the schedule.
     */
    protected addTimeBlockToScheduleSlot(
        timeBlock: TimeBlock,
        schedules: ScheduleElement[],
        schedulesSlot: number,
        isGridVertical: boolean,
        allTimesText: string
    ): void {
        const dayOfWeek = Math.floor(timeBlock.startMinutesLocal / MINUTES_IN_DAY);

        this.createElementsForTimeBlock(
            timeBlock,
            allTimesText,
            (index, label, classes, start, length, planNameLabel) => {
                schedules[index].schedules[schedulesSlot].timeBlocks.push({
                    day: dayOfWeek,
                    label,
                    planNameLabel,
                    classes,
                    style: htmlSafe(
                        isGridVertical ? `top:${start}%;height:${length}%;` : `left:${start}%;width:${length}%;`
                    )
                });
            }
        );
    }

    /**
     * Creates and returns an empty schedule element collection.
     */
    protected getEmptyScheduleElements(): ScheduleElement[] {
        // Array of the 7 days of the week containing the string name for
        // the day of the week and an array of schedule elements
        const now = new Date();
        return Array.from({ length: DAYS_IN_WEEK }, (_, i) => ({
            // Do not copy this deprecated usage. If you see this, please fix it
            // eslint-disable-next-line @adc/ember/require-tz-functions
            dayOfWeek: this.intl.formatDate(setDay(now, i), {
                weekday: 'short'
            }),
            dayOfWeekNum: i,
            schedules: []
        }));
    }

    /**
     * Creates collection of schedule elements for the plan schedules.
     */
    protected getScheduleElements(isGridVertical: boolean, allTimesText = ''): ScheduleElement[] {
        const schedules = this.getEmptyScheduleElements();

        // For each plan schedule, create the block elements we need to render from their combinedSchedules property
        this.planSchedules.forEach(({ combinedSchedules, planColor }) => {
            if (combinedSchedules.length) {
                const [{ startMinutesLocal, endMinutesLocal }] = combinedSchedules;

                // Check if the schedule is all times if the user is showing the grid that only shows one access plan
                if (
                    !(
                        combinedSchedules.length === 1 &&
                        startMinutesLocal === MINUTES_IN_DAY &&
                        endMinutesLocal === MINUTES_IN_WEEK + MINUTES_IN_DAY
                    )
                ) {
                    const schedulesSlot = schedules[0].schedules.length;
                    schedules.forEach((schedule) =>
                        schedule.schedules.push({
                            planName: '',
                            planIcon: '',
                            planColor,
                            timeBlocks: []
                        })
                    );

                    combinedSchedules.forEach((schedule) => {
                        schedule.title = '';
                        this.addTimeBlockToScheduleSlot(
                            schedule,
                            schedules,
                            schedulesSlot,
                            isGridVertical,
                            allTimesText
                        );
                    });
                }
            }
        });

        // Remove Sunday from the front of the array and add it to the end
        // We want Monday to be the first weekday on the schedule
        schedules.push(schedules.shift() as ScheduleElement);

        return schedules;
    }

    /**
     * Array of plan schedules.
     */
    @computed('args.scheduleObjects.[]')
    get planSchedules(): PlanSchedule[] {
        return (this.args.scheduleObjects ?? []).map((s) => this.getPlanSchedule(s));
    }

    /**
     * Whether or not the schedule covers all times.
     */
    @computed('planSchedules.[]')
    get isScheduleAllTimes(): boolean {
        return this.planSchedules.some(({ combinedSchedules = [] }) => {
            if (combinedSchedules.length !== 1) {
                return false;
            }

            // Check if the schedule covers all times
            const [{ startMinutesLocal, endMinutesLocal }] = combinedSchedules;
            return startMinutesLocal === MINUTES_IN_DAY && endMinutesLocal === MINUTES_IN_WEEK + MINUTES_IN_DAY;
        });
    }
}
