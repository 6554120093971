import Component from '@glimmer/component';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { later } from '@ember/runloop';
import { addWeakListener, removeListener } from '@adc/ember-utils/utils/event-listeners';
import { constrainRange } from '@adc/ember-utils/utils/math';
import { isDestroyed } from '@adc/ember-utils/utils/ember-helpers';

import type { SafeString } from 'handlebars';

/**
 * The amount to multiply the scale value for every scroll zoom event.
 */
const ZOOM_AMT = 0.1;

/**
 * The amount a mouse wheel has to be scrolled in order to trigger a zoom event.
 */
const ZOOM_DELTA = 120;

/**
 * The maximum scale factor that things can be zoomed in
 */
const MAX_ZOOM_SCALE = 3;

/**
 * The percentage that we should pan the image to where the user's mouse is on zoom events when scrolling.
 */
const CENTERING_FACTOR_FOR_ZOOM_ON_SCROLL = 0.2;

/**
 * The percentage that we should pan the image to where the user's mouse is on zoom events when double clicking.
 */
const CENTERING_FACTOR_FOR_ZOOM_ON_DOUBLE_CLICK = 1;

/**
 * The amount that clicking on the zoom buttons zooms in or out.
 */
const ZOOM_BUTTON_AMOUNT = 250;

/**
 * The amount that double clicking zooms in.
 */
const ZOOM_DOUBLE_CLICK_AMOUNT = -500;

/**
 * The amount of zoom to apply to reset back to original position.  (this is just an arbitrarily set really large number)
 */
const ZOOM_AMOUNT_FOR_RESET = 99999;

/**
 * The amount that clicking on the pan buttons pans in the particular direction.
 */
const PAN_BUTTON_AMOUNT = 60;

type Limitations = {
    canPanLeft: boolean;
    canPanRight: boolean;
    canPanUp: boolean;
    canPanDown: boolean;
    canZoomIn: boolean;
    canZoomOut: boolean;
};

type Position = {
    top: number;
    left: number;
    scale: number;
};

/**
 * Actions that describe how we should move the image
 */
export type RelativeDirections = {
    in: number;
    out: number;
    reset: number;
    left: number;
    up: number;
    right: number;
    down: number;
};

export interface ZoomPanOverlaySignature {
    Element: HTMLDivElement;
    Args: {
        /** Indicates the ZPO is enabled (defaults to `true`). */
        isEnabled: boolean;
        /** Used so APO and the consuming application are speaking the same language. */
        relativeDirections: RelativeDirections;
        /** Called when the ZPO is changed to pass out the new style value. */
        zoomPanStyle: (style: SafeString) => void;
        /** When this component is initialized, we call this method to pass up a function so that the host component can make calls for panning and zooming. */
        sendDigitalZoomPanMethod: (digitalZoomPanMethod: (eventDirection: number) => void) => void;
        /** Tells the host component what the new zoom/pan limitations (ie can't zoom in any further) are. */
        zoomPanLimitationsUpdated: (limitations: Limitations, position: Position) => void;
        /** A CSS selector to get the host element.  */
        elementSelector: string | null;
    };
    Blocks: {
        default: [];
    };
}

export default class ZoomPanOverlay extends Component<ZoomPanOverlaySignature> {
    left = 0;
    top = 0;
    scale = 1;
    height = 0;
    width = 0;

    /**
     * Collection of mouse event handler to clear on destroy.
     *
     * @ignore
     */
    mouseEventHandlers?: string[];

    /**
     * If this component is fully initialized.  Meaning it knows its size properties and has passed its parent
     * component all the necessary methods/information
     */
    isInitialized = false;

    /**
     * Indicates whether pan/zoom are enabled.
     */
    get isEnabled(): boolean {
        return this.args.isEnabled ?? true;
    }

    /**
     * Cancel the passed event.
     */
    private cancelEvent(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * Returns the absolute top and left boundary positions.
     */
    private getBounds(): { boundsX: number; boundsY: number } {
        const { scale } = this;
        return {
            boundsX: (this.width / 2) * (scale - 1),
            boundsY: (this.height / 2) * (scale - 1)
        };
    }

    /**
     * Constrains the new requested left/top/scale values into the bounds and announces what the limitations are after this change.
     *
     * @param newLeft
     * @param newTop
     * @param newScale
     * @private
     */
    private constrainAndNotifyNewParams(newLeft: number, newTop: number, newScale: number) {
        this.scale = newScale;

        // Get bounds after changing the scale, because the new scale value will impact what getBounds returns.
        const { boundsX, boundsY } = this.getBounds();

        Object.assign(this, {
            left: constrainRange(newLeft, -boundsX, boundsX),
            top: constrainRange(newTop, -boundsY, boundsY)
        });

        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        later(() => {
            this.args.zoomPanStyle(
                htmlSafe(`transform: translate(${this.left}px, ${this.top}px) scale(${this.scale});`)
            );

            this.announceZoomPanLimitations();
        }, 0);
    }

    /**
     * Tells the parent component what the new zoom/pan limitations (ie can't zoom in any further) are.
     *
     * @private
     */
    private announceZoomPanLimitations() {
        const { left, top, scale } = this;
        const { boundsX, boundsY } = this.getBounds();

        this.args.zoomPanLimitationsUpdated &&
            this.args.zoomPanLimitationsUpdated(
                {
                    canPanLeft: left !== boundsX,
                    canPanRight: left !== -boundsX,
                    canPanUp: top !== boundsY,
                    canPanDown: top !== -boundsY,
                    canZoomIn: scale !== MAX_ZOOM_SCALE,
                    canZoomOut: scale !== 1
                },
                {
                    left,
                    top,
                    scale
                }
            );
    }

    /**
     * Sets the width and height properties of the element.
     */
    private setSizeValues(): void {
        if (isDestroyed(this)) {
            return;
        }

        const { elementSelector } = this.args;

        if (elementSelector === null) {
            return;
        }

        const element = document.querySelector(elementSelector) as HTMLElement;

        if (!element) {
            return;
        }

        const { offsetWidth, offsetHeight } = element;

        Object.assign(this, {
            width: offsetWidth,
            height: offsetHeight
        });
    }

    /**
     * Triggers a zoom event
     *
     * @param zoomAmount the amount to zoom in or out.  Negative numbers is zoom in, positive is zoom out
     * @param centeringFactorForZoom the percentage (0 to 1) to pan directly to the event location.  IE 0.5 means pan halfway from current location to event.
     * @param eventLocationX x value of mouse/event location.  if not passed in we assume the middle
     * @param eventLocationY y value of mouse/event location.  if not passed in we assume the middle
     * @private
     */
    private triggerZoom(
        zoomAmount: number,
        centeringFactorForZoom: number,
        eventLocationX?: number,
        eventLocationY?: number
    ) {
        if (!this.isEnabled || !this.isInitialized) {
            return;
        }

        const { width, height, left, top, scale } = this;

        const delta = zoomAmount / -ZOOM_DELTA,
            unboundedScale = scale * (1 + delta * ZOOM_AMT),
            newScale = Math.min(Math.max(unboundedScale, 1), MAX_ZOOM_SCALE);

        if (scale === newScale) {
            return;
        }

        if (newScale === 1) {
            return this.reset();
        }

        // Setting the new scale value before calling getBounds so that getBounds works off of the new scale info.
        this.scale = newScale;

        if (!eventLocationX || !eventLocationY) {
            eventLocationX = this.width / 2;
            eventLocationY = this.height / 2;
        }

        const mouseOffsetX = eventLocationX,
            mouseOffsetY = eventLocationY;

        let newLeft, newTop;

        // We want to pan towards where the users mouse is only if we are zooming in.
        if (delta > 0) {
            const mousePercentLeft = mouseOffsetX / width,
                mousePercentTop = mouseOffsetY / height,
                mouseTargetAsLeftValue = (mousePercentLeft - 0.5) * width,
                mouseTargetAsTopValue = (mousePercentTop - 0.5) * height;

            // When zooming in, we don't want to pan DIRECTLY to where the users mouse is, as this will cause the next zoom event triggered immediately after this one (zoom events get
            // triggered in bunches) to happen in a different location on the image, making for a difficult user experience.  Instead we apply CENTERING_FACTOR_FOR_ZOOM and only pan
            // partially in the direction of where the user's mouse is, making it easier for a user to zoom specifically into the part of the image they want to see.
            newLeft = left - mouseTargetAsLeftValue * centeringFactorForZoom;
            newTop = top - mouseTargetAsTopValue * centeringFactorForZoom;
        } else {
            newLeft = left;
            newTop = top;
        }

        this.constrainAndNotifyNewParams(newLeft, newTop, newScale);
    }

    /**
     * Triggers various initialization such as setting size values and sending this component's parent information and methods it can use
     */
    private initializeComponent(): void {
        const { sendDigitalZoomPanMethod } = this.args;

        this.setSizeValues();

        // When this component is initialized, we call this.args.sendDigitalZoomPanMethod and pass up a function so that the parent to this component
        // can make calls for panning and zooming in here
        sendDigitalZoomPanMethod &&
            sendDigitalZoomPanMethod((eventDirection?: number, position?: Position) => {
                const { relativeDirections } = this.args;

                if (eventDirection == null) {
                    if (position) {
                        this.constrainAndNotifyNewParams(position.left, position.top, position.scale);
                    }

                    return;
                }

                if (
                    [relativeDirections.in, relativeDirections.out, relativeDirections.reset].includes(eventDirection)
                ) {
                    let deltaY = 0;

                    switch (eventDirection) {
                        case relativeDirections.in:
                            deltaY = -ZOOM_BUTTON_AMOUNT;
                            break;
                        case relativeDirections.out:
                            deltaY = ZOOM_BUTTON_AMOUNT;
                            break;
                        case relativeDirections.reset:
                            deltaY = ZOOM_AMOUNT_FOR_RESET;
                            break;
                    }

                    this.triggerZoom(deltaY, CENTERING_FACTOR_FOR_ZOOM_ON_SCROLL);
                } else {
                    let { left, top } = this;

                    if (eventDirection == relativeDirections.right) {
                        left -= PAN_BUTTON_AMOUNT;
                    } else if (eventDirection == relativeDirections.down) {
                        top -= PAN_BUTTON_AMOUNT;
                    } else if (eventDirection == relativeDirections.left) {
                        left += PAN_BUTTON_AMOUNT;
                    } else {
                        top += PAN_BUTTON_AMOUNT;
                    }

                    this.constrainAndNotifyNewParams(left, top, this.scale);
                }
            });

        this.announceZoomPanLimitations();
        this.isInitialized = true;
    }

    /**
     * When this component's elementSelector args change, if its populated lets trigger initialization
     */
    @action initializeIfReady(): void {
        // much of initialization depends on having an elementSelector in the args, and often this selector isn't immediately available.
        // on svr-timeline for instance, the elementSelector isn't populated until the user clicks PLAY
        if (this.args.elementSelector && !this.isInitialized) {
            this.initializeComponent();
        }
    }

    /**
     * The window resize listener
     *
     * @ignore
     */
    @action resizeListener(): void {
        this.setSizeValues();
    }

    /**
     * Rests pan and zoom values.
     */
    @action reset(): void {
        this.constrainAndNotifyNewParams(0, 0, 1);
    }

    /**
     * Zooms the target.
     */
    @action zoom(event: WheelEvent): void {
        this.cancelEvent(event);

        this.triggerZoom(event.deltaY, CENTERING_FACTOR_FOR_ZOOM_ON_SCROLL, event.offsetX, event.offsetY);
    }

    /**
     * On user double click
     *
     * @param event
     */
    @action onDoubleClick(event: MouseEvent): void {
        // Double clicking while at the max zoom level resets the zoom level to the original.
        if (this.scale === MAX_ZOOM_SCALE) {
            this.reset();
            return;
        }

        this.triggerZoom(
            ZOOM_DOUBLE_CLICK_AMOUNT,
            CENTERING_FACTOR_FOR_ZOOM_ON_DOUBLE_CLICK,
            event.offsetX,
            event.offsetY
        );
    }

    /**
     * Begins the panning action.
     */
    @action startPanning(event: MouseEvent): void {
        // Can't pan if the feature is disabled, the image isnt zoomed in, or if this is a right click mousedown event.
        if (!this.isEnabled || this.scale === 1 || event.button !== 0) {
            return;
        }

        const { left, top } = this,
            { clientX: startX, clientY: startY } = event;

        this.mouseEventHandlers = [
            addWeakListener(this, document, 'mousemove', (event: MouseEvent) => {
                this.cancelEvent(event);

                this.constrainAndNotifyNewParams(
                    event.clientX - startX + left,
                    event.clientY - startY + top,
                    this.scale
                );
            }),

            addWeakListener(this, document, 'mouseup', () => this.tearDownListeners())
        ];
    }

    /**
     * Called before the component element is destroyed, to detach global event listeners.
     */
    @action tearDownListeners(): void {
        (this.mouseEventHandlers ?? []).forEach((id) => removeListener(id));
    }

    /**
     * Prevents parent components from doing drag drop events if we're zoomed in, because we just want mouse-down combined with panning to change the ptz
     * and nothing else.  Otherwise unexpected behavior can occur.
     */
    @action stopPropagationIfZoomedIn(e: DragEvent): void {
        if (this.scale === 1) {
            return;
        }

        this.cancelEvent(e);
    }
}
