export default function arrayIncludes(arr: any[] = [], item?: any): boolean {
    return arr.includes(item);
}

declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        /**
         * Returns a boolean indicating if the passed array includes the passed item.
         *
         * @example <div aria-selected={{array-includes this.items item}}>
         */
        'array-includes': typeof arrayIncludes;
    }
}
