import templateOnlyComponent from '@ember/component/template-only';

export interface CheckboxTreeAllNoneSelectorSignature {
    Element: HTMLDivElement;
    Args: {
        /** Unique identifier passed to the `data-id` attribute. */
        index: number | string;
        /** Triggered when user selected "All". */
        selectAll: VoidFunction;
        /** Triggered when user selected "None". */
        selectNone: VoidFunction;
    };
}
const CheckboxTreeAllNoneSelector = templateOnlyComponent<CheckboxTreeAllNoneSelectorSignature>();
export default CheckboxTreeAllNoneSelector;
