import { resolve } from 'rsvp';
import { computed, set } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import WebApiPromise from '../web-api-promise.ts';
import { inject as service } from '@ember/service';
import { assert } from '@ember/debug';

const CONTENT_WIDTHS = ['small', 'large', 'full'];

/**
 * @classdesc
 * Main content wrapper element.
 */
// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/require-tagless-components
export default class ViewContainer extends Component {
    @service performanceMonitor;
    @service router;
    @service intl;

    classNames = ['view-container', 'surface'];

    /** @override */
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/classic-decorator-hooks
    init(...args) {
        super.init(...args);

        if (this.removeDefaultClasses) {
            set(this, 'classNames', []);
        }

        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-observers
        addObserver(this, 'promise.isFulfilled', this, this.isFulfilledObserver);

        this.isFulfilledObserver();
    }

    willDestroy() {
        removeObserver(this, 'promise.isFulfilled', this, this.isFulfilledObserver);

        super.willDestroy();
    }

    /**
     * Remove the default classes defined in classNames?
     *
     * @type {boolean}
     */
    removeDefaultClasses = false;

    /**
     * Have we already reported the route timing?
     *
     * @type {boolean}
     */
    wasRouteTimingReported = false;

    /**
     * Records the route URL used when the route timing was reported.
     *
     * @type {string}
     */
    urlForReportedRouteTiming = '';

    /**
     * Tag name for the inner content element.
     *
     * @note Predefining to null so that it can be easily detected that it was overridden.
     *
     * @type {String|null}
     */
    contentTagName = null;

    /**
     * Additional class names for the inner content element.
     *
     * @type {String}
     */
    contentClass = '';

    /**
     * The width of the content.
     *
     * @type {ContentWidth | undefined}
     */
    contentWidth;

    /**
     * Class name for the content width.
     *
     * @type {'small-width'|'large-width'|'full-width'|''}
     */
    @computed('contentWidth')
    get contentWidthClass() {
        if (this.contentWidth) {
            assert(
                '[@adc/ui-components] contentWidth value should be "small" or "large" or "full".',
                CONTENT_WIDTHS.includes(this.contentWidth)
            );

            return `${this.contentWidth}-width`;
        }

        return '';
    }

    /**
     * Has the consumer 'dataLoaded' action been triggered, meaning the container.body
     * section is ready to be displayed?
     *
     * @type {boolean}
     */
    hasCalledDataLoadedAction = false;

    /**
     * Tag name that is passed to view-content.
     *
     * @type {String}
     */
    @computed('contentTagName', 'tagName', 'contentClass')
    get computedContentTagName() {
        const { contentTagName, tagName, contentClass } = this;

        // If there was a passed in contentTagName then use that.
        if (contentTagName !== null) {
            return contentTagName;
        }

        // If tagName of view-container is empty, then pass that through unless contentClass is defined.
        if (!tagName && !contentClass) {
            return tagName;
        }

        // Generic case - return div.
        return 'div';
    }

    /**
     * String to be shown when promise rejects.
     *
     * @type {String}
     */
    @computed('promise.reason.errors.firstObject.detail')
    get errorText() {
        // Return either error from the promise or generic error.
        return this.promise?.reason?.errors?.[0]?.detail || this.intl.t('generic.requestError');
    }

    /**
     * Wrapper for the passed in promise that gives us information about the promise.
     *
     * @type {WebApiPromise}
     */
    @computed('model')
    get promise() {
        // Right now we do not allow RSVP hash. The reason is that it would be hard to observe changes so it might not be worth it.
        // Additionally, we will most likely always have only 1 thing to load up to display and others can be loaded asynchronously.
        return WebApiPromise.create({
            promise: resolve(this.model)
        });
    }

    /**
     * Sends action with data once promise data loads.
     */
    isFulfilledObserver() {
        const { dataLoaded, promise: { isFulfilled, content } = {} } = this;

        // NOTE: isFulfilled will be true even if RouteView does not have a model.
        if (
            isFulfilled &&
            (!this.wasRouteTimingReported || this.urlForReportedRouteTiming !== this.router.currentURL)
        ) {
            // isFulfilledObserver can fire multiple times if the model changes.  We can't fire this multiple times though, because
            // our metrics aren't built around having the same route resolve multiple times.
            this.wasRouteTimingReported = true;
            this.urlForReportedRouteTiming = this.router.currentURL;
            this.performanceMonitor.markModelResolved(this.router.currentRouteName);
        }

        if (!isFulfilled) {
            return;
        }

        if (dataLoaded) {
            dataLoaded(content);
        }

        set(this, 'hasCalledDataLoadedAction', true);
    }
}
