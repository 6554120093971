/**
 * Utility helper module for sentry related methods
 * @module
 */

import { withScope } from '@sentry/ember';

import type { Extras, Primitive } from '@sentry/types';

type SentryTags = {
    [key: string]: Primitive;
};

/**
 * Does a sentry log as a warning.
 *
 * @param warningMessage The warning message to log to sentry
 * @param extras Optional extras to append to the sentry log
 * @param tags Optional tags to append to the sentry log
 */
export function sentryWithScopeAndWarn(warningMessage: string, extras?: Extras, tags?: SentryTags): void {
    sentryWithScopeAndErrorOrWarn(warningMessage, extras, tags, false);
}

/**
 * Does a sentry log as an error.
 *
 * @param errorMessage The warning message to log to sentry
 * @param extras Optional extras to append to the sentry log
 * @param tags Optional tags to append to the sentry log
 */
export function sentryWithScopeAndError(errorMessage: string, extras?: Extras, tags?: SentryTags): void {
    sentryWithScopeAndErrorOrWarn(errorMessage, extras, tags, true);
}

/**
 * Does a sentry log with the passed in message, extras (if provided), tags (if provided).
 *
 * @param message The message to log to sentry
 * @param extras Optional extras to append to the sentry log
 * @param tags Optional tags to append to the sentry log
 * @param isError if we are reporting it as an error, otherwise reporting it as warning
 */
function sentryWithScopeAndErrorOrWarn(message: string, extras?: Extras, tags?: SentryTags, isError?: boolean) {
    withScope((scope) => {
        if (extras) {
            scope.setExtras(extras);
        }

        if (tags) {
            scope.setTags(tags);
        }

        if (isError) {
            console.error(message);
        } else {
            console.warn(message);
        }
    });
}
