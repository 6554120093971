import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';
import { intlPath } from '@adc/i18n/path';
import { inject as service } from '@ember/service';

import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type NotificationManager from '../services/notification-manager.ts';

export interface DragDropFileUploadSignature {
    Element: HTMLDivElement;
    Args: {
        /** The maximum allowed file size, in bytes. */
        maxFileSize?: number;
        /** A CSV string indicating the accepted file types. */
        accept?: string;
        /** The File as read from the data URL.  */
        file?: File;
        /**  Flag to disable file uploading and deleting. */
        disabled?: boolean;
        /** Triggered when the file has been selected. */
        fileChanged?: (file: File | null, dataUrl: string) => void;
    };
}

/**
 * Default max file size in bytes.
 */
const DEFAULT_MAX_FILE_SIZE_BYTES = 3 * 1024 * 1024;

/**
 * Convert number of bytes to a readable string with units.
 */
function toByteString(bytes: number): string {
    const units = ['B', 'KB', 'MB', 'GB'];
    const k = 1024;

    if (bytes <= 0) {
        return '0 B';
    }

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + units[i];
}

/**
 * Validate the given file by checking some data in the file.
 */
function isFileDataValid(file: File): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = (e) => {
            const buffer = e.target?.result as ArrayBuffer;

            if (buffer.byteLength < 4) {
                resolve(false);
            }

            const view = new DataView(buffer);
            const magicNumbers = {
                jpeg: [0xff, 0xd8],
                png: [0x89, 0x50, 0x4e, 0x47],
                pdf: [0x25, 0x50, 0x44, 0x46],
                svg1: [0x3c, 0x3f, 0x78, 0x6d],
                svg2: [0x3c, 0x73, 0x76, 0x67]
            };

            switch (file.type) {
                case 'image/jpeg': {
                    const isJPEG =
                        view.getUint8(0) === magicNumbers.jpeg[0] && view.getUint8(1) === magicNumbers.jpeg[1];
                    resolve(isJPEG);
                    break;
                }
                case 'image/png': {
                    const isPNG =
                        view.getUint8(0) === magicNumbers.png[0] &&
                        view.getUint8(1) === magicNumbers.png[1] &&
                        view.getUint8(2) === magicNumbers.png[2] &&
                        view.getUint8(3) === magicNumbers.png[3];
                    resolve(isPNG);
                    break;
                }
                case 'image/svg+xml': {
                    const isSVG =
                        (view.getUint8(0) === magicNumbers.svg1[0] &&
                            view.getUint8(1) === magicNumbers.svg1[1] &&
                            view.getUint8(2) === magicNumbers.svg1[2] &&
                            view.getUint8(3) === magicNumbers.svg1[3]) ||
                        (view.getUint8(0) === magicNumbers.svg2[0] &&
                            view.getUint8(1) === magicNumbers.svg2[1] &&
                            view.getUint8(2) === magicNumbers.svg2[2] &&
                            view.getUint8(3) === magicNumbers.svg2[3]);
                    resolve(isSVG);
                    break;
                }
                case 'application/pdf': {
                    const isPDF =
                        view.getUint8(0) === magicNumbers.pdf[0] &&
                        view.getUint8(1) === magicNumbers.pdf[1] &&
                        view.getUint8(2) === magicNumbers.pdf[2] &&
                        view.getUint8(3) === magicNumbers.pdf[3];
                    resolve(isPDF);
                    break;
                }
                default:
                    resolve(false);
            }
        };

        reader.onerror = (e) => {
            reject(e);
        };

        reader.readAsArrayBuffer(file);
    });
}

@intlPath({ module: '@adc/ui-components', path: 'drag-drop-file-upload' })
export default class DragDropFileUploadComponent extends Component<DragDropFileUploadSignature> {
    @service declare intl: ADCIntlService;
    @service declare notificationManager: NotificationManager;

    /**
     * Max file size that this field will support.
     */
    get maxFileSize(): number {
        return this.args.maxFileSize ?? DEFAULT_MAX_FILE_SIZE_BYTES;
    }

    /**
     * Readable file size string with units.
     */
    get maxFileSizeString(): string {
        return toByteString(this.maxFileSize);
    }

    /**
     * Name of the currently selected file.
     */
    @tracked fileName = '';

    @tracked fileSizeString = '';

    /**
     * Is the file being loaded?
     */
    @tracked isLoading = false;

    /**
     * Is a file being hovered over the drop zone?
     */
    @tracked isHovering = false;

    /**
     * Has a file been chosen/loaded?
     */
    @computed('args.file', 'fileName')
    get hasFile(): boolean {
        // Consider the initial file argument as having a file to have a smoother initial render for the addon.
        return this.args.file != null || this.fileName != '';
    }

    /**
     * Source of the image, if applicable.
     */
    @tracked imageSrc = '';

    @action
    async setupInitialFile(): Promise<void> {
        if (this.args.file) {
            await this.processFile(this.args.file);
        }
    }

    /**
     * Upload a file when a user selects one to be uploaded.
     */
    @action
    async fileSelected(evt: Event & { target: HTMLInputElement }): Promise<void> {
        const fileInput = evt.target,
            [file] = fileInput.files ?? [];

        if (!file) {
            return;
        }

        await this.processFile(file);

        fileInput.files = null;
        fileInput.value = '';
    }

    @action
    selectFile(ev: KeyboardEvent): void {
        if (!this.args?.disabled && (ev.key === ' ' || ev.key === 'Enter')) {
            ev.preventDefault();
            (ev.target as HTMLElement).closest('.drag-drop-file-upload')?.querySelector('input')?.click();
        }
    }

    @action
    async dropHandler(ev: DragEvent): Promise<void> {
        if (!this.args?.disabled && !this.hasFile) {
            ev.preventDefault();
            this.isHovering = false;

            const [item] = ev.dataTransfer?.items ?? [];

            if (item && item.kind === 'file') {
                const file = item.getAsFile();

                if (file != null) {
                    await this.processFile(file);
                    return;
                }
            }
        }
    }

    private async processFile(file: File): Promise<void> {
        const extensions = this.args.accept?.split(', ') ?? [];

        // Check if the file extension is one of the accepted file extensions and the file data is valid
        if (
            !(
                extensions &&
                extensions.some((ext) => file.name.toLowerCase().match(ext + '$')) &&
                (await isFileDataValid(file))
            )
        ) {
            this.notificationManager.addError(
                this.intl.tc(this, 'invalidFileType', {
                    accept: this.args.accept
                })
            );
            return;
        }

        // Check if the file is too large
        if (file.size > this.maxFileSize) {
            this.notificationManager.addError(
                this.intl.tc(this, 'invalidFileSize', {
                    maxFileSizeString: this.maxFileSizeString
                })
            );
            return;
        }

        const reader = new FileReader();
        this.isLoading = true;

        return new Promise<void>((resolve, reject) => {
            reader.onload = (e) => {
                const dataUrl = e.target?.result as string;

                if (file.type.match('^image')) {
                    this.imageSrc = dataUrl;
                }

                this.fileName = file.name;
                this.fileSizeString = toByteString(file.size);
                this.isLoading = false;

                this.args.fileChanged?.(file, dataUrl);
                resolve();
            };

            reader.onerror = (e) => {
                reject(e);
            };

            reader.readAsDataURL(file);
        });
    }

    @action
    dragOverHandler(ev: Event): void {
        if (!this.args?.disabled && !this.hasFile) {
            ev.preventDefault();
            this.isHovering = true;
        }
    }

    @action
    dragLeaveHandler(ev: Event): void {
        if (!this.args?.disabled && !this.hasFile) {
            ev.preventDefault();
            this.isHovering = false;
        }
    }

    @action
    deleteFile(): void {
        if (!this.args?.disabled) {
            this.fileName = '';
            this.fileSizeString = '';
            this.imageSrc = '';
            this.args.fileChanged?.(null, '');
        }
    }
}
