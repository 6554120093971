import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { and, notEmpty, reads } from '@ember/object/computed';

import type CheckboxTreeItem from '../../utils/dropdown-select-item.ts';

export interface CheckboxTreeItemSignature {
    Element: HTMLLIElement;
    Args: {
        /** The item to render. */
        item: CheckboxTreeItem;
        /** Indicates the check box tree item should be focusable (defaults to true). */
        isFocusable?: boolean;
        /** The index of this check box tree item. */
        index: number | string;
        /** Triggered when an action is executed in the checkbox tree. */
        'on-action-execute'?: VoidFunction;
        /** Indicates only root items will be rendered. */
        hideSubitems?: boolean;
        /** Indicates each item group should be visually separated. */
        showGroupSeparator?: boolean;
        /** Indicates the checkbox tree item parent (LI) element is focusable (not sure why this would ever be since it's not interactive). */
        isListItemFocusable?: boolean;
        /** Toggles an items collapses/expanded state. */
        'toggle-subitems-visibility'?: VoidFunction;
    };
}

/**
 * @classdesc
 * Represents a row in the checkbox-tree component. Contains a
 * state, optional subitems, and multiple other properties.
 */
export default class CheckboxTreeItemComponent extends Component<CheckboxTreeItemSignature> {
    /**
     * Should the checkbox item be focusable?
     */
    get isFocusable(): boolean {
        return this.args.isFocusable ?? true;
    }

    /**
     * The subitems to render.
     */
    @reads('args.item.subitems')
    declare subitems: CheckboxTreeItem[];

    /**
     * Indicates there are subitems.
     */
    @notEmpty('args.item.subitems')
    declare hasSubItems: boolean;

    /**
     * Indicate this item should have a tabindex of zero.
     */
    @and('isFocusable', 'args.isListItemFocusable', 'hasSubItems')
    declare canReceiveFocus: boolean;

    /**
     * Should we use a border as group separator?
     */
    @and('args.showGroupSeparator', 'hasSubItems')
    declare addGroupSeparator: boolean;

    /**
     * Toggles the item's collapsed state.
     */
    @action toggleSubitemsVisibility(item: CheckboxTreeItem): void {
        this.args['on-action-execute']?.();

        set(item, 'isCollapsed', !item.isCollapsed);
    }
}
