import Component from '@glimmer/component';
import { assert } from '@ember/debug';
import { intlPath } from '@adc/i18n/path';

import type { SvgSymbolSignature } from '@adc/svg-system/components/svg-symbol';

export const ISSUE = 'issue';
export const INFORMATIVE = 'informative';

export interface AdcBannerSignature {
    Element: HTMLDivElement;
    Args: {
        /** The text to appear within the banner. */
        message: string | Promise<string>;
        /** The banner type (defaults to `issue`) */
        type?: typeof ISSUE | typeof INFORMATIVE;
        /** Optional icon to appear in banner. */
        icon?: string;
        /** Optional title for icon. */
        iconTitle?: string;
        /** Optional fill color for icon. */
        iconColor?: SvgSymbolSignature['Args']['fillColor'];
        /** Text to be rendered inside the banner option button. */
        optionText?: string;
        /** Triggered when the user interacts with the banner option button. */
        'on-option-click': VoidFunction;
    };
}

/**
 * @classdesc
 * Component designed to show status related information with an optional action button.
 */
@intlPath({ module: '@adc/ui-components', path: 'adc-banner' })
export default class AdcBannerComponent extends Component<AdcBannerSignature> {
    /**
     * The type of the banner. Affects the styling and the composition of the component.
     */
    get type(): string {
        const { type = ISSUE } = this.args;
        return [ISSUE, INFORMATIVE].includes(type) ? type : ISSUE;
    }

    get message(): Promise<string> {
        return (async () => {
            const message = await this.args.message;
            assert('[@adc/ui-components] A message needs to be provided for the <AdcBanner /> to make sense.', message);
            return message ?? '';
        })();
    }

    /**
     * Is this an Issue banner?
     */
    get isIssue(): boolean {
        return this.type === ISSUE;
    }

    /**
     * Is this an Informative banner?
     */
    get isInformative(): boolean {
        return this.type === INFORMATIVE;
    }
}
