import Component from '@glimmer/component';

import type { SpinnerSvgSignature } from './spinner-svg.ts';

export interface AsyncLoaderSignature<T> {
    Element: HTMLDivElement;
    Args: {
        /** The promise to resolve. */
        promise: Promise<T> | T;
        /** The spinner size to show (defaults to "small"). */
        spinnerSize?: SpinnerSvgSignature['Args']['size'];
        /** Indicates whether to show "Loading..." text with the spinner. */
        showText?: boolean;
    };
    Blocks: {
        default: [T];
        'skeleton-loading': [];
    };
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class AsyncLoader<T> extends Component<AsyncLoaderSignature<T>> {}
