import { default as EmberObject, computed, setProperties } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import { once } from '@ember/runloop';
import { dasherize } from '@ember/string';
import { A } from '@ember/array';
import { isPresent } from '@ember/utils';
import { ITEMS_CHANGED_ACTION } from '../components/checkbox-tree.ts';

// region Constants

/**
 * The unselected state for an item.
 *
 * @type {number}
 *
 * @public
 * @memberof utils.CheckboxTreeItem
 */
export const UNSELECTED = 0;

/**
 * The partially-selected state for an item.
 *
 * @type {number}
 *
 * @public
 * @memberof utils.CheckboxTreeItem
 */
export const PARTIALLY_SELECTED = 1;

/**
 * The selected state for an item.
 *
 * @type {number}
 *
 * @public
 * @memberof utils.CheckboxTreeItem
 */
export const SELECTED = 2;

// endregion

// region CheckboxTreeItem Class

/**
 * @classdesc
 *
 * An item to display in a checkbox tree component.
 *
 * @class utils.CheckboxTreeItem
 * @extends Ember.Object
 * @public
 *
 * @property {String} name - The main text for the item.
 * @property {String} [value] - A unique identifier and the corresponding string used as a "value" when used as a dropdown option.
 * @property {number} [state=UNSELECTED] - The current state of the item (e.g. selected, partially selected, etc.).
 * @property {boolean} [disabled=false] - Is the item disabled (unselectable)?
 * @property {String} [description] - Additional description text displayed along side the name.
 * @property {String} [icon] - The icon name to use for the item.
 * @property {String} [iconTitle] - The icon title.
 * @property {String} [iconDesc] - The icon description.
 * @property {boolean} [iconIsHiddenForAccessibility] - Should the icon be hidden for screen readers?
 * @property {String} [secondaryDescription] - A secondary description to display below the name and primary description.
 * @property {boolean} [isSelectable=true] - Should the item itself be selectable and display a checkbox?
 * @property {Array<CheckboxTreeItem>|undefined}} subitems - An optional array of subitems the items contains.
 * @property {boolean} [isCollapsible=false] - Should the subitems be collapsible?
 * @property {boolean} [isCollapsed=false] - Does the item currently have its subitems collapsed?
 * @property {boolean} [showAllNoneSelectors=false] - Should the item have an "All | None" selector for managing subitem states?
 *
 */
// Do not copy this deprecated usage. If you see this, please fix it
// eslint-disable-next-line ember/no-classic-classes
export default EmberObject.extend(
    /** @lends utils.CheckboxTreeItem# */ {
        // region Hooks

        /** @override */
        init() {
            this._super(...arguments);

            ensureValueExists.call(this);
            validateSubitemReliantProperties.call(this);
            setSubitemsParent.call(this);
        },

        // endregion

        // region Default Properties

        /**
         * The current state of the item.
         *
         * @type {number}
         */
        state: UNSELECTED,

        /**
         * Is the item supposed to render a checkbox and be capable of being selected?
         *
         * @type {boolean}
         */
        isSelectable: true,

        // endregion

        // region Computed Properties

        /**
         * The state that determines how the item is displayed in the tree (e.g. selected, unselected, etc.).
         *
         * @function
         * @returns {number}
         */
        internalState: computed('state', {
            get() {
                return this.state;
            },
            set(key, newState) {
                if (this.hasSubitems) {
                    // Only update the leaf items. The rest of the CheckboxTreeItems
                    // will figure out their state based on the states of subitems.
                    setLeafItemStates(this, newState);
                } else {
                    // This is a leaf item, so update its state and trigger parent updates.
                    setStateAndUpdateParent.call(this, newState);
                }

                return this.state;
            }
        }),

        /**
         * Does the item have subitems?
         *
         * @function
         * @returns {boolean}
         */
        hasSubitems: notEmpty('subitems'),

        /**
         * The selected items within this checklist.  Will return array of just this item if all subitems are selected.
         *
         * @function
         * @returns {EmberArray<utils.CheckboxTreeItem>}
         */
        selectedItems: computed('hasSubitems', 'state', 'subitems.@each.state', function () {
            const { isFullySelected } = this;

            // Are there NO subitems OR are they all selected?
            if (!this.hasSubitems || isFullySelected) {
                // Return item if all subitems selected or this item is selected.
                return A([isFullySelected || this.state === SELECTED ? this : undefined]).compact();
            }

            // Return selected subitems.
            return A(this.subitems.filterBy('state', SELECTED));
        }),

        /**
         * Indicates this item or all it's sub items are selected.
         *
         * @function
         * @returns {boolean}
         */
        isFullySelected: computed('hasSubitems', 'state', 'subitems.@each.state', function () {
            if (!this.hasSubitems) {
                return this.state === SELECTED;
            }

            // Is there only one state AND is it selected?
            const uniqueStates = getAllUnitItemStates(this);
            return uniqueStates.length === 1 && uniqueStates.firstObject === SELECTED;
        }),

        /**
         * Indicates this item or all it's sub items are partially selected.
         *
         * @function
         * @returns {boolean}
         */
        isPartiallySelected: computed('hasSubitems', 'state', 'subitems.@each.state', function () {
            // If an item has no subitems, it cannot be partially selected.
            if (!this.hasSubitems) {
                return false;
            }

            // Is there more than one state?
            return getAllUnitItemStates(this).length > 1;
        }),

        /**
         * Indicates this item and all it's sub items are not selected.
         *
         * @function
         * @returns {boolean}
         */
        isNotSelected: computed('isFullySelected', 'isPartiallySelected', function () {
            return !this.isFullySelected && !this.isPartiallySelected;
        })

        // endregion
    }
);

// endregion

// region Validation

/**
 * Ensures an item has a value by setting it to a dasherized form of the name, if a value was not specified.
 *
 * @private
 * @memberof utils.CheckboxTreeItem
 */
function ensureValueExists() {
    const { name } = this;

    window.console.assert(name, `@adc/ui-components/checkbox-tree: Item name="${name}" must have a name.`);

    if (!isPresent(this.value)) {
        this.set('value', dasherize(name));
        return;
    }

    // Ensure value is a string.
    if (typeof this.value !== 'string') {
        this.set('value', String(this.value));
    }
}

/**
 * Validates that properties that rely on the existence of subitems are correct.
 *
 * @private
 * @memberof utils.CheckboxTreeItem
 */
function validateSubitemReliantProperties() {
    if (!this.hasSubitems) {
        setProperties(this, {
            isCollapsed: false,
            isCollapsible: false,
            showAllNoneSelectors: false
        });

        return;
    }

    if (!this.isCollapsible) {
        this.set('isCollapsed', false);
    }
}

// endregion

// region Linking Subitems with Parents

/**
 * Stores a reference to "this" (i.e. the parent) on all of its subitems.
 *
 * @private
 * @instance
 * @memberof utils.CheckboxTreeItem
 */
function setSubitemsParent() {
    if (this.hasSubitems) {
        A(this.subitems).setEach('parent', this);
    }
}

// endregion

// region State Management

/**
 * Sets all leaf items' visualState properties to the provided value.
 *
 * @note Setting the visualState will update the internalState and state properties and
 *       then trigger the parent items to update their states.
 *
 * @param {utils.CheckboxTreeItem} item - The item we are updating the leaf item states for.
 * @param {number} newState - The new state to set for all leaf items.
 *
 * @private
 * @memberof utils.CheckboxTreeItem
 */
function setLeafItemStates(item, newState) {
    if (item.hasSubitems) {
        // NOTE: Only set the leaf items, then send the action to recalculate the rest of the parents' states.
        // We don't want to manually set the parent to selected, because a subitem might be unselected and disabled.
        item.subitems.forEach((subitem) => setLeafItemStates(subitem, newState));
        return;
    }

    if (isNotModifiable(item)) {
        return;
    }

    // Set the visual state so the computed property will trigger parent updates.
    item.set('internalState', newState);
}

/**
 * Updates the item's state to the provided state and then tells its parent to update itself.
 *
 * @param {number} newState - The state we should update the item to have.
 *
 * @private
 * @instance
 * @memberof utils.CheckboxTreeItem
 */
function setStateAndUpdateParent(newState) {
    this.set('state', newState);

    updateParentState.call(this);
}

/**
 * If there is a parent item, this tells the parent to update its state,
 * as there was likely a change to the state of subitems in the tree.
 *
 * @private
 * @instance
 * @memberof utils.CheckboxTreeItem
 */
function updateParentState() {
    const { parent } = this;
    if (parent) {
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        once(parent, setStateAndUpdateParent, getItemState(parent));
    }

    (this.get(ITEMS_CHANGED_ACTION) || (() => {}))();
}

// endregion

// region Determining Item State

/**
 * Gets the state of the current {@link CheckboxTreeItem} based on its state or the states of its subitems.
 *
 * @param {utils.CheckboxTreeItem} item - The item in the tree we are getting the state of.
 *
 * @returns {number}
 *
 * @private
 * @memberof utils.CheckboxTreeItem
 * @static
 */
function getItemState(item) {
    if (!item.hasSubitems) {
        // If there are no subitems, an item is either selected or unselected.
        return item.state === SELECTED ? SELECTED : UNSELECTED;
    }

    if (item.isPartiallySelected) {
        return PARTIALLY_SELECTED;
    }

    if (item.isFullySelected) {
        return SELECTED;
    }

    return UNSELECTED;
}

/**
 * Returns a flattened array containing the unique set of states for all descendant items (e.g. [ SELECTED, UNSELECTED, etc.]).
 *
 * @param item - The item we are getting subitems' states for.
 * @param isBaseItem - Is this the root item? Note: you should not need to use this parameter.
 *
 * @returns {EmberArray<number>}
 *
 * @private
 * @memberof utils.CheckboxTreeItem
 * @static
 */
function getAllUnitItemStates(item, isBaseItem = true) {
    let subItemStates = A([]);

    if (!isBaseItem) {
        subItemStates.push(item.state);
    }

    if (!item.hasSubitems) {
        return subItemStates;
    }

    return A(
        item.subitems.reduce((states, subitem) => [...states, ...getAllUnitItemStates(subitem, false)], subItemStates)
    ).uniq();
}

/**
 * Is the item's state not capable of being changed?
 *
 * @param {utils.CheckboxTreeItem} item - The item we are checking the state of.
 *
 * @returns {boolean}
 *
 * @public
 * @static
 * @memberof utils.CheckboxTreeItem
 */
export function isNotModifiable(item) {
    return item.disabled || !item.isSelectable;
}

/**
 * Returns a flattened array of all items that are selected in the tree.
 *
 * @param {Array<utils.CheckboxTreeItem>} items - An array of root-level items to search through.
 * @param {boolean} useParentIfAllSubitemsSelected - When enabled, if all subitems are selected, this option will only add the parent item.
 *
 * @returns {EmberArray<utils.CheckboxTreeItem>}
 *
 * @private
 * @static
 * @memberof components.dropdown-select.MultiSelect
 */
export function getAllSelectedItems(items, useParentIfAllSubitemsSelected = true) {
    let selectedItems = [];

    (items || []).forEach((item) => {
        const subitems = item.subitems || [];

        if (useParentIfAllSubitemsSelected && item.isFullySelected) {
            // All the subitems are selected in this group, so use the parent item if specified.
            selectedItems.push(item);
            return;
        }

        if (item.state === SELECTED) {
            selectedItems.push(item);
        }

        selectedItems = selectedItems.concat(getAllSelectedItems(subitems, useParentIfAllSubitemsSelected));
    });

    return A(selectedItems);
}

// endregion
