import Transform from '@ember-data/serializer/transform';
import { A } from '@ember/array';

/**
 * @classdesc
 *
 * A transform that allows specifying type 'array' for use with Ember Data.
 */
export default class ArrayTransform<T> extends Transform {
    /**
     * Converts the deserialized data to an array.
     */
    serialize(deserialized: ReturnType<ArrayTransform<T>['deserialize']> | null): T[] | null {
        return deserialized ? deserialized.toArray() : null;
    }

    /**
     * Converts an array from a server side response into an {@link Ember.Array}.
     */
    deserialize(array: T[]): ReturnType<typeof A<T>> {
        return A(array);
    }
}
